import React from 'react';
import PropTypes from 'prop-types';
import {Input, Button, Form, Select, DatePicker, Icon} from 'antd';
import Config from "../../config";
import Moment from 'moment';
import { t } from './../../core/library/i18n';

const Search = Input.Search;

class MediaSearch extends React.Component{

    state = {
        searchField: ""
    };

    submitHandle = (e) => {
        e.preventDefault();
        let values = this.props.form.getFieldsValue();
        if (values.dateRange) {
            values['from'] = values.dateRange[0].format("YYYY-MM-DD");
            values['to'] = values.dateRange[1].format("YYYY-MM-DD");
            delete values.dateRange
        }
        values.source = values.source.toLowerCase();
        let filter = {...this.props.filter, ...values};
        this.props.paginator(1, this.props.limit, filter)
    };

    clearFilter = () => {
        let filter = this.props.filter;
        ['type', 'source', 'from', 'to'].forEach(key => delete filter[key]);
        this.props.paginator(1, this.props.limit, filter);
        this.props.form.resetFields();
    };

    clearSearch = () => {
        this.setState({
            searchField: ""
        }, () => this.props.paginator(1, this.props.limit, {eq: this.state.search}));

    };

    onSearch = (val) => {
        this.props.paginator(1,60, {eq: val});
    };

    onChange = (e) => {
      this.setState({
          searchField: e.target.value
      })
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <div style={{display: "flex", flexDirection: "column", padding: 20, marginTop: 20, border: '1px solid #dee1e5', borderRadius: 4}}>
                <Search
                    placeholder={t('SEARCH_MEDIA_PLACEHOLDER')}
                    defaultValue={this.props.filter.eq}
                    value={this.state.searchField}
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    style={{marginBottom: 20}}
                    addonAfter={<div onClick={this.clearSearch}><Icon type="close-circle" /><span style={{paddingLeft: 5}}>Сбросить</span></div>}
                />
                <Form layout={'inline'} style={{width: '100%', display: "flex", flexDirection: "row"}} onSubmit={this.submitHandle}>
                    <Form.Item label={'Media type'} >
                        {getFieldDecorator('type', {
                            initialValue: this.props.filter.type || '',
                        })(
                            <Select placeholder="Select media type" style={{width: 160}}>
                                {(Config.MEDIA_DATABASE_TYPES && Config.MEDIA_DATABASE_TYPES.length > 0) && Config.MEDIA_DATABASE_TYPES.map(i => {
                                    return <Select.Option value={i} key={`media_type_${i}`}>{i}</Select.Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={'Media source'}>
                        {getFieldDecorator('source', {
                            initialValue: this.props.filter.source || '',
                        })(
                            <Select placeholder="Select media source" style={{width: 160}}>
                                {(Config.MEDIA.SOURCES && Config.MEDIA.SOURCES.length > 0) && Config.MEDIA.SOURCES.map(i => {
                                    return <Select.Option value={i} key={`media_source_${i}`}>{i}</Select.Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={'Date range'}>
                        {getFieldDecorator('dateRange', {
                            initialValue: this.props.filter.from ? [Moment(this.props.filter.from) , Moment(this.props.filter.to)] : null,
                        })(
                            <DatePicker.RangePicker allowClear />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button.Group>
                            <Button type={'primary'} htmlType={'submit'}>{t('APPLY')}</Button>
                            <Button type={'danger'} onClick={this.clearFilter}>{t('CLEAR')}</Button>
                        </Button.Group>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

MediaSearch.defaultProps = {
    paginator: () => {},
    filter: {},
    limit: 60,
};

MediaSearch.propTypes = {
    paginator: PropTypes.func.isRequired,
    filter: PropTypes.instanceOf(Object).isRequired,
    limit: PropTypes.number.isRequired,
};

export default Form.create({ name: `MediaSearch` })(MediaSearch);
