import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {Table, Button, message, Checkbox, Tooltip, Input, Modal, Popconfirm} from 'antd';
import * as Actions from "./../../store/profiles/actions";
import * as C from './../../store/profiles/constants';
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import {generateParamsFromObject, parseUrlParams, prepareParams} from "../../core/library/url";
import CreateGroup from './groups/create';


const mapStateToProps = state => ({
    answer: state.ProfilesReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class GroupsHeader extends React.Component {
    static defaultProps = {
        context: {},
    };
    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };
    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('GROUPS_LIST'),
        })
    }
    render() {
        return null;
    }
}


class GroupsControllerClass extends React.Component {

    static defaultProps = {
        actions: {},
        answer: {},
    };

    columns = [
        {
            title: t('TITLE'),
            dataIndex: 'title'
        },
        {
            title: t('ACTIVE'),
            key: 'active',
            align: 'center',
            render: r => <Checkbox checked={r.active} onChange={(e) => {
                this.onUpdate(r.id, {active: e.target.checked});
            }}/>
        },
        {
            title: t('PERMISSION'),
            key: 'permissions',
            width: 350,
            render: r => {
                let p = [];
                if (r.permissions && r.permissions.length > 0) {
                    r.permissions.map(i => {
                        this.state.permissions.map(j => {
                            if (j.id === i) {
                                p.push(j.title);
                            }
                            return j;
                        });
                        return i;
                    });
                }
                return p.join(', ');
            },
        },
        {
            title: '',
            key: 'action',
            render: r => {
                return (
                    <Button.Group>
                        <Popconfirm
                            title={t("DELETE_GROUP_REQUEST")}
                            onConfirm={() => this.onDelete(r.id)}
                        >
                            <Tooltip title={t('DELETE_GROUP')}><Button type={`danger`} icon={'delete'}/></Tooltip>
                        </Popconfirm>
                        <Tooltip title={t('EDIT_GROUP')}>
                            <Button type={`primary`} icon={`edit`} onClick={() => this.setState({ modal: true, item: r})}/>
                        </Tooltip>
                    </Button.Group>
                )
            }
        }
    ];

    constructor(props) {
        super(props);
        let url = parseUrlParams();
        this.state = {
            list: [],
            permissions: [],
            limit: 0,
            total: 0,
            page: url.page ? parseInt(url.page, 10) : 1,
            loading: false,
            filter: prepareParams(['search']),
            item: {},
            modal: false,
        };
    }

    loadGroups = () => {
        this.props.actions.LoadGroups(this.state.page, this.state.filter);
    };

    loadPermissions = () => {
        this.props.actions.LoadPermissions(1, {});
    };

    componentDidMount() {
        this.loadPermissions();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.PERMISSIONS_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.PERMISSIONS_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : [];
                    this.setState({
                        permissions: data.list || [],
                    }, () => {
                        this.loadGroups();
                    });
                    break;
                case C.PERMISSIONS_LOAD_ERROR:
                    message.error(t('ERROR_LOAD_PERMISSIONS'));
                    this.loadGroups();
                    break;

                case C.GROUPS_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.GROUPS_LOAD_FINISH:
                    let groupsRes = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    this.setState({
                        loading: false,
                        list: groupsRes.list || [],
                        total: groupsRes.total || 0,
                        limit: groupsRes.limit || 0,
                    });
                    break;
                case C.GROUPS_LOAD_ERROR:
                    message.error(t('ERROR_LOAD_GROUPS'));
                    this.setState({ loading: false });
                    break;
                case C.GROUPS_CREATE_START:
                case C.GROUPS_DELETE_START:
                case C.GROUPS_UPDATE_START:
                    this.setState({ modal: false, loading: true });
                    break;

                case C.GROUPS_UPDATE_FINISH:
                    message.success(t('GROUP_UPDATE_FINISH'));
                    this.setState({ item: {}, modal: false, }, () => this.loadGroups());
                    break;
                case C.GROUPS_UPDATE_ERROR:
                    message.error(t('GROUP_UPDATE_ERROR'));
                    this.setState({ loading: false, modal: true});
                    break;

                case C.GROUPS_CREATE_FINISH:
                    message.success(t('GROUP_CREATE_FINISH'));
                    this.setState({ item: {}, modal: false }, () => this.loadGroups());
                    break;
                case C.GROUPS_CREATE_ERROR:
                    message.error(t('GROUP_CREATE_ERROR'));
                    this.setState({ loading: false, modal: true});
                    break;

                case C.GROUPS_DELETE_FINISH:
                    message.success(t('GROUP_DELETE_FINISH'));
                    this.loadGroups();
                    break;
                case C.GROUPS_DELETE_ERROR:
                    message.error(t('GROUP_DELETE_ERROR'));
                    this.setState({ loading: false });
                    break;
                default: break;
            }
        }
    }



    onSearch = (search) => {
        this.setState({ page: 1, filter: {search}}, () => {
            let filter = {...this.state.filter};
            filter["page"] = this.state.page;
            this.props.history.push(`${this.props.location.pathname}?${generateParamsFromObject(filter)}`);
            this.loadGroups();
        });
    };

    onCreate = (data) => {
        this.props.actions.CreateGroup(data);
    };

    onUpdate = (id, data = {}) => {
        this.props.actions.UpdateGroup(id, data);
    };

    onDelete = (id) => {
        this.props.actions.DeleteGroup(id);
    };

    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <GroupsHeader context={context}/>
                            <div className={`comments--view`}>
                                <Modal
                                    visible={this.state.modal}
                                    title={this.state.item.id ? t('UPDATE_GROUP') : t('CREATE_GROUP')}
                                    onCancel={() => this.setState({ modal: false, item: {}})}
                                    footer={null}
                                >
                                    <CreateGroup
                                        item={this.state.item}
                                        permissions={this.state.permissions}
                                        onUpdate={this.onUpdate}
                                        onCreate={this.onCreate}
                                    />
                                </Modal>
                                <Table
                                    title={() => {
                                        return (
                                            <React.Fragment>
                                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    <Button type={`primary`} onClick={() => this.setState({ modal: true, item: {}})} style={{marginRight: 10}}>{t('CREATE_GROUP')}</Button>
                                                    <Input.Search
                                                        onSearch={this.onSearch}
                                                        defaultValue={this.state.filter.search || ""}
                                                        placeholder={t('GROUPS_SEARCH_PLACEHOLDER')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    }}
                                    bordered={true}
                                    loading={this.state.loading}
                                    columns={this.columns}
                                    dataSource={this.state.list}
                                    rowKey={(item) => item.id}
                                    pagination={{
                                        pageSize: this.state.limit,
                                        defaultCurrent: this.state.page,
                                        total: this.state.total,
                                        onChange: (page) => this.setState({ page }, () => { this.loadGroups() }),
                                        current: this.state.page,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}




export const GroupsController = connect(
    mapStateToProps,
    mapDispatchProps
)(GroupsControllerClass);

export default GroupsController;
