import React from 'react';
import { message, Button, Input, Table, Modal, Form, Spin, Popconfirm } from 'antd';
import moment from 'moment';
import { CommentsRequest } from '../../core/fetch';
// import Config from '../../config';

export class StopWordsComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: false,
            form_loading: false,
            page: 0,
            limit: 30,
            search: '',
            total: 0,
            item: null,
            modal: false,
        };

        this.columns = [
            {
                key: 'title',
                title: 'Стопслово',
                render: r => {
                    return r.word || "[not set]";
                },
            },
            {
                title: 'Дата обновления',
                key: 'updated_at',
                render: r => moment(r.updated_at).format('LLL'),
            },
            {
                key: 'actions',
                width: 120,
                alignItems: 'center',
                title: '',
                render: r => {
                    return (
                        <Button.Group>
                           <Button
                                type='primary'
                                icon='edit'
                                onClick={() => {
                                    this.setState({modal: true, item: r});
                                }}
                           />
                           <Popconfirm
                                title={`Вы уверены, что хотите удалить стоп-слово?`}
                                onConfirm={() => this.onDelete(r.id)}
                            ><Button type='danger' icon='delete' /></Popconfirm>
                        </Button.Group>
                    );
                }
            }
            
        ];
    }

    loadList = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                const { page, limit, search } = this.state;
                CommentsRequest(`/words/?page=${page}&limit=${limit}&search=${search}`).then(res => {
                    this.setState({
                        loading: false,
                        list: res.data || [],
                        total: res.total || 0
                    });
                }).catch(e => {
                    console.error(`[StopWords.list]: `, e);
                    message.error(`Ошибка загрузки стопслов`);
                    this.setState({ loading: false });
                });
            });
            
        }
    }

    componentDidMount() {
        this.loadList();
    }

    onSearch = (search) => {
        this.setState({ search, page: 1, loading: false }, () => {
            this.loadList();
        });
    }


    onDelete = (id) => {
        this.setState({loading: true}, () => {
            CommentsRequest(`/words/${id}`, `DELETE`).then(res => {
                message.success(`Стоп-слово успешно удалено`);
                this.setState({loading: false}, () => {
                    this.loadList();
                });
            }).catch(e => {
                console.error(`[Words.add]: `, e);
                message.error(`Ошибка удаления стоп-слова`);
                this.setState({loading: false}, () => {
                    this.loadList();
                })
            });
        })
    }

    onCreate = (values) => {
        this.setState({form_loading: true}, () => {
            CommentsRequest(`/words/add`, `POST`, values).then(res => {
                message.success(`Стоп-слово успешно добавлено`);
                setTimeout(() => {
                    this.setState({form_loading: false, item: null, modal: false}, () => {
                        this.loadList();
                    });
                }, 1500);
            }).catch(e => {
                console.error(`[Words.add]: `, e);
                message.error(`Ошибка создания стоп-слова`);
                this.setState({form_loading: false, item: null, modal: false}, () => {
                    this.loadList();
                })
            })
        });
    }

    onUpdate = (values) => {
        this.setState({form_loading: true}, () => {
            CommentsRequest(`/words/${this.state.item.id}`, 'PUT', values).then(res => {
                message.success(`Стоп-слово успешно обновлено`);
                setTimeout(() => {
                    this.setState({form_loading: false, item: null, modal: false}, () => {
                        this.loadList();
                    });
                }, 1500);
            }).catch(e => {
                console.error(`[Words.update]: `, e);
                message.error(`Ошибка обновления стоп-слова`);
                this.setState({form_loading: false, item: null, modal: false}, () => {
                    this.loadList();
                })
            });
        });
    }

    render() {
        const {loading, list, total, page, limit, modal, form_loading, item} = this.state;
        return (
            <div className='comments stopwords'>
                <Modal
                    visible={modal}
                    title={item !== null ? `Обновление стоп-слова` : `Добавление нового стоп-слова`}
                    width={720}
                    footer={null}
                >
                    <Spin spinning={form_loading}>
                        <WordsCreateForm
                            item={item}
                            handleCreate={this.onCreate}
                            handleUpdate={this.onUpdate}
                        />
                    </Spin>
                </Modal>
                <CommentsFilter
                    handleAdd={() => this.setState({ modal: true })}
                    handleSearch={this.onSearch}
                />
                <div className='comments--block table'>
                    <Table
                        columns={this.columns}
                        loading={loading}
                        bordered
                        dataSource={list}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: limit,
                            defaultCurrent: page,
                            total: total,
                            onChange: (page) => {
                                this.setState({ page }, () => {
                                    this.loadList();
                                });
                            },
                            current: page,
                            hideOnSinglePage: true,
                            position:"both",
                        }}
                    />
                </div>
            </div>
        );
    }
}


const WordsCreate = ({ form, handleCreate, handleUpdate, item }) => {
    const { getFieldDecorator } = form;

    const onSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (item && item.id) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        });
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Item label={`Введите стоп-слово`} help={`Блок поддерживает регулярные выражения, например л*х`}>
                {getFieldDecorator('word', {
                    initialValue: item && item.word ? item.word : '',
                    rules: [{required: true, message: 'Введите стоп-слово'}]
                })(<Input />)}
            </Form.Item>
            <Form.Item>
                <Button type={`primary`} htmlType='submit'>{item ? 'Обновить' : 'Создать'}</Button>
            </Form.Item>
        </Form>
    );
}


const CommentsFilter = ({ handleSearch, handleAdd }) => {
    return (
        <div className='comments--block filter'>
            <Button type="primary" onClick={handleAdd}>Создать</Button>
            <Input.Search
                allowClear
                placeholder='Введите поисковый запрос'
                enterButton="Поиск"
                onSearch={(s) => handleSearch(s)}
            />
        </div>
    );
}


const WordsCreateForm = Form.create({ name: 'WordsCreate' })(WordsCreate);

export default StopWordsComments;