import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';


import Config from "../../../../config";



export class MediaItem extends React.Component {

    static defaultProps = {
        files: {},
        title: '',
        CAN_HIDE: false,
        type: ''
    };

    static propTypes = {
        files: PropTypes.instanceOf(Object).isRequired,
        title: PropTypes.string.isRequired,
        CAN_HIDE: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    };

    getMedia = () => {
        let dimensions = ['image-m', 'image-l', 'cover', 'preview', 'medium', 'original'];
        let path = '';
        let files = this.props.files;

        for (let index in dimensions) {
            let i = dimensions[index];
            if (files[i] && files[i].path) {
                path = files[i].path;
                break;
            }
        }

        let path_prefix = ``;
        let original_prefix = ``;
        switch (this.props.type) {
            case "Image":
            case "Cover":
                path_prefix = Config.MEDIA.CDN.IMAGES + "/";
                original_prefix = Config.MEDIA.CDN.IMAGES + "/";
                break;
            case "Video":
                path_prefix = original_prefix = Config.MEDIA.CDN.VIDEO + "/";
                break;
            case "PDF":
                path_prefix = Config.MEDIA.CDN.PDF + "/";
                original_prefix = Config.MEDIA.CDN.PDF + "/";
                break;
            default:
                break;
        }

        if (["Image", "Cover"].indexOf(this.props.type) + 1 > 0 && path_prefix.indexOf('cdnimages.') + 1 > 0) {
            let path_params = path.split('/');
            path_params[path_params.length - 1] = `300_${path_params[path_params.length - 1]}`;
            path = path_params.join('/');
        }

        return {
            "preview": path ? `${path_prefix}${path}` : '',
            "original": files['original'] && files['original'].path ? `${original_prefix}${files['original'].path}` : '',
        };
    };

    getImageTitle = () => {
        let print_data = [];
        if (this.props['title']) {
            print_data.push(this.props['title']);
        }
        if (this.props['description']) {
            print_data.push(this.props['description']);
        }
        if (this.props['properties'] && this.props['properties']['copyright']) {
            print_data.push(this.props['properties']['copyright']);
        }
        return print_data.join(' / ');
    }

 

    render() {
        const view = this.props.view;
        let media = this.getMedia();
        let blockBackground = media.preview ? {backgroundImage: `url(${media.preview})`} : ``;
        return (
            <div className={`media__wrapper`}>
                <div className={`media__item item__${view}`}>
                    <div className="image" style={blockBackground} />
					{this.props.images.indexOf(media.original) + 1 === 0 && (
						<div className="item__actions">
							<Button type="primary" onClick={() => {
								this.props.onAddImage(media.original, this.props.properties.copyright || "");
							}}>Добавить</Button>
						</div>
					)}

                </div>
            </div>
        );
    }
}
