import React from 'react';
import { Input } from 'antd';

export class Timeclaculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '',
        };
    }

    calculateMinutes = () => {
        let params = this.state.time.split(':');
        if (params.length < 3) {
            return `Введено не корректное время`;
        }
        let hours = parseInt(params[0], 10);
        let minutes = parseInt(params[1], 10);
        let seconds = parseInt(params[2], 10);
        let result = hours * 60 + minutes;
        return `${result},${seconds > 0 ? seconds : '00'}`;
    }

    render() {
        return (
            <div className='wrapper' style={{padding: 20}}>
                <Input
                    placeholder='Введите время в формате HH:MM:SS'
                    value={this.state.time}
                    onChange={(e) => {
                        this.setState({time: e.target.value});
                    }}
                />
                {this.state.time !== '' && <div style={{marginTop: 10}}><b>Время в минутах:</b> {this.calculateMinutes()}</div>}
            </div>
        );
    }
}

export default Timeclaculator;