import React from 'react';
import {message, Button, Table, Upload, Icon, Spin} from "antd";
import { Link } from 'react-router-dom';
import Moment from 'moment';
import * as Actions from "./../../store/reports/actions";
import * as C from './../../store/reports/constants';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Config from './../../config';



const mapStateToProps = state => ({
    answer: state.ReportsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class BudgetReportController extends React.Component {

    static defaultProps = {
        answer: {},
    };

    state = {
        loading: false,
        list: [],
        showUpload: false,
        uploading: false,
    };

    columns = [
        {
            title: 'Заголовок',
            key: 'title',
            render: r => {
                return <Link to={`/reports/budget/${r.id}`}>{r.title}</Link>
            }
        },
        {
            title: 'Дата загрузки',
            key: 'createdAt',
            render: r => Moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: '',
            key: 'actions',
            render: r => {
                return <Button type={`danger`} icon={`delete`} onClick={() => this.props.actions.DeleteReport(r.id)}>Удалить</Button>;
            },
        }
    ];


    loadList = () => {
        this.props.actions.LoadTable();
    };

    componentDidMount() {
        this.loadList();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.REPORT_LIST_LOAD_START:
                    this.setState({ loading: true });
                    break;

                case C.REPORT_LIST_LOAD_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(`Ошибка загрузки отчета`);
                    });
                    break;

                case C.REPORT_LIST_LOAD_FINISH:
                    let list = p.answer.data.data && p.answer.data.data.list ? p.answer.data.data.list : [];
                    this.setState({
                        loading: false,
                        list,
                    });
                    break;
                case C.REPORT_REMOVE_START:
                    this.setState({ loading: true });
                    break;
                case C.REPORT_REMOVE_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(`Ошибка удаление отчета`);
                    });
                    break;
                case C.REPORT_REMOVE_FINISH:
                    setTimeout(() => {
                        message.success(`Отчет успешно удален`);
                        this.loadList();
                    }, 1500);
                    break;

                default: break;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showUpload && (
                    <div className="budget upload">
                        <Spin spinning={this.state.uploading} tip={`Идет загрузка и анализ`}>
                            <div style={{margin: 12}}><Button onClick={() => this.setState({ showUpload: false })}>Закрыть</Button></div>
                            <Upload.Dragger
                                name={`files`}
                                accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                                action={`${Config.PROJECT.API}/reports/table/upload`}
                                onChange={(info) => {
                                    const { status } = info.file;
                                    if (status === 'uploading' && this.state.uploading === false) {
                                        this.setState({ uploading: true});
                                    }
                                    if (status === 'error') {
                                        message.error(`Ошибка анализа отчета`);
                                        this.setState({ uploading: false, showUpload: false });
                                    }
                                    if (status === 'done') {
                                        message.success(`отчет успешно загружен`);
                                        this.setState({
                                            uploading: false,
                                            showUpload: false,
                                        }, () => {
                                            this.loadList();
                                        })
                                    }
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Кликните или перетащите файл</p>
                                <p className="ant-upload-hint">
                                    формат отчета должен соответствовать формату метрики по посещениям, формат отчета в Excel (.xlsx)
                                </p>
                            </Upload.Dragger>
                        </Spin>
                    </div>
                )}
                <div className={`report budget`}>
                    <Table
                        title={() => {
                            return (
                                <div className={`budget actions`}>
                                    <Button onClick={() => this.setState({ showUpload: true })} type={`primary`}>Загрузить</Button>
                                </div>
                            );
                        }}
                        borderd
                        loading={this.state.loading}
                        dataSource={this.state.list}
                        columns={this.columns}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: 250,
                            defaultCurrent: 1,
                            total: 250,
                            hideOnSinglePage: true,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}


export const BudgetController = connect(
    mapStateToProps,
    mapDispatchProps
)(BudgetReportController);

export default BudgetController;
