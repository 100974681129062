import React from 'react';
import {Upload, Icon, Button, message} from 'antd';
import UploadsList from './uploadList';
import Config from '../../config';
import {t} from './../../core/library/i18n';
import User from "../../core/user";

const Dragger = Upload.Dragger;

export default class MediaUploadController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            showUploadArea: true,
            loading: false,
        };
        this.formsList = {};
    }

    onRemove = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            if (newFileList.length === 0) {
                this.formsList = {};
                console.log(this.formsList);
                return {
                    showUploadArea: true,
                    fileList: [],
                    loading: false,
                }
            } else {
                return {
                    fileList: newFileList,
                };
            }
        });
    };

    beforeUpload = (file) => {
        this.setState(state => ({
            fileList: [...state.fileList, file],
            showUploadArea: false
        }));
        return false;
    };

    submitHandle = (uid, mupload = false) => {
        this.formsList[uid].props.form.validateFields((err, values) => {
            if (!err) {
                // Make formData for backend
                let formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'properties') {
                        Object.keys(values[key]).forEach(nestedKey => {
                            formData.append(`${key}.${nestedKey}`, values[key][nestedKey] || '')
                        })
                    } else {
                        formData.append(key, values[key])
                    }
                });
                const user = new User();
                // Sending request
                fetch(`${Config.PROJECT.API}/media`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    body: formData,
                    headers: {
                        'Authorization': user.getAccessToken(),
                    },
                }).then(res => res.json()).then(res => {
                    if (res.result !== 'error') {
                        // Removing file from list if it successfully uploaded
                        this.onRemove(this.formsList[uid].props.file);
                        message.success(`${t('FILE_HAS_BEEN_UPLOADED')}: ${values.title}`);
                        if (this.props.onFinish) {
                            clearTimeout(this.t);
                            this.t = setTimeout(() => {
                                this.props.onFinish();
                            }, 500);
                        }
                    } else {
                        message.error(`Upload failed: ${res.error}`)
                    }
                }).catch(e => {
                    console.log(e);
                })

            }
        });
    };

    submitAll = () => {
        if (Object.keys(this.formsList).length > 0) {
            this.setState({loading: true}, () => {
                Object.keys(this.formsList).forEach(formUID => {
                    if (this.formsList[formUID]) {
                        this.submitHandle(formUID);
                    }
                })
            })
        }
    };

    render() {
        return (
            <div style={{margin: '30px 0'}}>
                {this.state.showUploadArea && <Dragger
                    onRemove={this.onRemove}
                    beforeUpload={this.beforeUpload}
                    showUploadList={false}
                    multiple={true}
                >
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox"/>
                    </p>
                    <p className="ant-upload-text">{t('UPLOAD_FIRST_ROW')}</p>
                    <p className="ant-upload-hint">
                        {t('UPLOAD_NEXT_ROW')}
                    </p>
                </Dragger>}
                {this.state.fileList && this.state.fileList.map(fileItem => {
                    return (
                        <UploadsList loading={this.state.loading} key={`file_${fileItem.uid}`} wrappedComponentRef={inst => {
                            if (fileItem.uid) {
                                this.formsList[fileItem.uid] = inst
                            }
                        }} onRemove={this.onRemove} onUpload={this.submitHandle} file={fileItem}/>
                    )
                })}
                {this.state.fileList.length > 0 &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20}}>
                    <Button type={'primary'} onClick={this.submitAll}
                            style={{marginRight: 10}}>{t('SAVE_ALL')}</Button>
                    <Button type={'danger'} onClick={() => {
                    }} style={{marginLeft: 10}}>{t('REMOVE_ALL')}</Button>
                </div>
                }

            </div>
        )
    }
}
