import React from 'react';
import { Input, Button } from 'antd';
import FileUpload from './media/file';
import { t } from './../../library/i18n';
import Config from './../../../config';


export class Upload extends React.Component {
    constructor(props) {
        super(props);
        let media = this.props && this.props.initialValue ? this.props.initialValue.filter(i => i.type === this.props.type) : [];
        this.state = {
            currentTab: '1',
            media: media && media.length > 0 ? media[0] : {},
            mediaSelected: media && media.length > 0,
            editModal: false,
        };
    }

    selectMedia = (media) => {
        if (media.list) {
            this.setState({ media: media.list[0], mediaSelected: true })
        } else {
            this.setState({ media, mediaSelected: true })
        }
    };

    tabChangeHandle = (tab) => {
        this.setState({ currentTab: tab });
    };

    componentDidMount() {
        if (this.props.initialValue) {
            let media = this.props.initialValue.filter(i => i.type === this.props.type);
            if (media && media.length > 0) {
                this.setState({
                    media: media[0],
                    mediaSelected: true,
                });
            }
        }
    }

    componentWillReceiveProps(p) {
    }


    closeModalEdit = (item) => {
        this.setState({
            editModal: false,
        }, () => {
            if (item && item.id) {
                this.props.updateMedia.set(item);
            }
        })
    };


    buildMediaPath = () => {
        if (this.state.media.type === 'Podcasts') {
            let original = this.state.media.files['original'] || {};
            if (original && original.path) {
                let mime = original.mimeType;
                if (mime.indexOf('audio') + 1 > 0) {
                    return `${Config.MEDIA.CDN['AUDIO'] || ""}/${original.path}`;
                } else {
                    return `${Config.MEDIA.CDN['VIDEO'] || ""}/${original.path}`;
                }
            }
        } else {
            return `${Config.MEDIA.CDN[this.state.media.type] || ""}/${this.state.media.files.original.path}`;
        }
    }

    render() {
        const fieldName = this.props.overwrite || this.props.name;
        return (
            <React.Fragment>
                <div className={"media-input"}>
                    <div style={{display: 'none'}}>
                        {this.props.decorator(fieldName, {
                            initialValue: this.state.media.id,
                        })(
                            <Input />
                        )}
                    </div>
                    {this.state.mediaSelected === false && (<FileUpload {...this.props} selectMedia={this.selectMedia}/>)}
                    {this.state.mediaSelected === true && (
                        <div className={`pdf--selected`}>
                            <div className="col--name">
                                <a href={this.buildMediaPath()} target={"_blank"}>{this.state.media.id}</a>
                            </div>
                            <div className={"actions"}>
                                {this.props.type !== "STT" && (
                                    <Button type={"danger"} icon={"delete"} onClick={() => {
                                        let a = this.state.media;
                                        this.setState({ media : {}, mediaSelected: false }, () => {
                                            if (this.props.updateMedia && this.props.updateMedia.delete) {
                                                this.props.updateMedia.delete(a.id);
                                            }
                                        });
                                    }}>{t('DELETE')}</Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default Upload;
