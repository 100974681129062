import React from 'react';
import {Form, TreeSelect} from "antd";


const { SHOW_CHILD } = TreeSelect;

const PrepareInitialValue = (p) => {
    if (p.initialValue && Array.isArray(p.initialValue)) {
        let ids = p.initialValue.map(i => {
            if (i.id) return i.id;
            return i;
        });
        return ids;
    }
    if (p.initialValue && p.initialValue.id) return p.initialValue.id;
    if (p.initialValue) return p.initialValue;
    return "no initial";
};



export class TreeSelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = props;
    }

    // onChange = (v) => {
    //     if (v.length > 0) {
    //         let initialValue = [];
    //         for(let i in v) {
    //             let key = v[i];
    //             if (this.state.data[key]) {
    //                 initialValue = [...initialValue, ...this.state.data[key]];
    //             }
    //         }
    //         this.setState({ initialValue }, () => {
    //             let data = {};
    //             data[this.props.name] = this.state.initialValue;
    //             this.props.form.setFieldsInitialValue(data);
    //             this.props.form.setFieldsValue(data);
    //         });
    //     } else {
    //         this.setState({ initialValue: []});
    //     }
    // }

    render() {
        let p = this.state;
        const Initial = PrepareInitialValue(p);
        let props = {
            rules: p.rules || [],
        };
        if (Initial !== "no initial") {
            props.initialValue = Initial;
        }
        let treeData = [];
        let data = p.data || {};
        treeData = Object.keys(data).map(i => {
            let children = data[i].map(i => {
                return {
                    title: i,
                    value: i,
                    key: i,
                }
            });
            return {
                title: i,
                value: i,
                key: i,
                children,
            }
        });

        const tProps = {
            treeData,
            treeCheckable: true,
            showCheckedStrategy: SHOW_CHILD,
            searchPlaceholder: p.label,
            style: {
                width: '100%',
            },
        };


        return (
            <Form.Item label={p.label || ""}>
                {p.decorator(p.name, props)(<TreeSelect {...tProps} />)}
            </Form.Item>
        );
    }
}

// export const TreeSelectInput = (p) => {
//     const Initial = PrepareInitialValue(p);
//     let props = {
//         rules: p.rules || [],
//     };
//     if (Initial !== "no initial") {
//         props.initialValue = Initial;
//     }
//     let treeData = [];
//     let data = p.data || [];
//     treeData = Object.keys(data).map(i => {
//         console.log(i);
//         let children = data[i].map(i => {
//             return {
//                 title: i,
//                 value: i,
//                 key: i,
//             }
//         });
//         return {
//             title: i,
//             value: i,
//             key: i,
//             children,
//         }
//     });
//
//     const tProps = {
//         treeData,
//         treeCheckable: true,
//         showCheckedStrategy: SHOW_PARENT,
//         searchPlaceholder: p.label,
//         style: {
//             width: '100%',
//         },
//     };
//
//     return (
//         <Form.Item label={p.label || ""}>
//             {p.decorator(p.name, props)(<TreeSelect {...tProps} />)}
//         </Form.Item>
//     );
//     // const Initial = PrepareInitialValue(p);
//     // let props = {
//     //     rules: p.rules || [],
//     // };
//     // if (Initial !== "no initial") {
//     //     props.initialValue = Initial;
//     // }
//     //
//     // let data = p.data || [];
//     // if (p.is_object) {
//     //     let options = Object.keys(data).map(key => {
//     //         return <Select.OptGroup label={key} key={`group_${key}`}>{data[key].map(i => <Select.Option value={i} key={`key_${i}`}>{i}</Select.Option>)}</Select.OptGroup>
//     //     });
//     //     let select_props = {
//     //         'placeholder': `Выберите опции`
//     //     };
//     //
//     //     if (p.type) {
//     //         select_props['mode'] = 'multiple';
//     //     }
//     //     return (
//     //         <Form.Item label={p.label || ""}>
//     //             {p.decorator(p.name, props)(
//     //                 <Select {...select_props}>
//     //                     {options}
//     //                 </Select>
//     //             )}
//     //         </Form.Item>
//     //     );
//     // }
//     // let options = data.map(i => {
//     //     return <Select.Option value={i} key={`key_${i}`}>{i}</Select.Option>
//     // });
//     //
//     // let select_props = {
//     //     'placeholder': `Выберите опции`
//     // };
//     //
//     // if (p.type) {
//     //     select_props['mode'] = 'multiple';
//     // }
//     // return (
//     //     <Form.Item label={p.label || ""}>
//     //         {p.decorator(p.name, props)(
//     //             <Select {...select_props}>
//     //                 {options}
//     //             </Select>
//     //         )}
//     //     </Form.Item>
//     // );
// };
