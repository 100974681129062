import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Button } from 'antd';
import { t } from './../../library/i18n';
import * as Cards from './../cards';
import Config from './../../../config';

export class DefaultList extends React.Component {
    static defaultProps = {
        items: [],
        onCreate: () => {},
    };

    static propTypes = {
        items: PropTypes.arrayOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    getCardComponent = (type) => {
        let cardView = `${type}_card`;
        if(Config.PROJECT_PREFIX) {
            cardView = `${type}_card_${Config.PROJECT_PREFIX}`;
            if(!Config[cardView]) {
                cardView = `default_card_${Config.PROJECT_PREFIX}`;
            }
        }
        return Cards[cardView] || Cards["default_card"];
    };

    render() {
        return (
            <React.Fragment>
                {this.props.items.length === 0 && <Empty description={<span>{t('EMPTY_DOCUMENTS')}</span>}><Button type={`primary`} onClick={this.props.onCreate}>{t('CREATE')}</Button></Empty>}
                {this.props.items.length > 0 && (
                    <React.Fragment>
                        {this.props.items.map(i => {
                            const CardComponent = this.getCardComponent(i.type || "unknown");
                            return <CardComponent  {...this.props} {...i} key={`card_item_${i.id}`}/>;
                        })}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default DefaultList;
