
function prepareText(element) {
    let params = [element.title];
    if (element.properties) {
        Object.keys(element.properties).map(i => {
            if (element.properties[i]) {
                params.push(element.properties[i]);
            }
            return i;
        });
    }
    return (params.join(' / '));
}

export default function (p) {
    // let path = p && p.files ? p.files.original.path : "";
    return (
        `<div data-title="${prepareText(p)}" class="rmedia rmedia--external-video mceNonEditable">
            <div class="button primary updateMedia mceNonEditable"></div>
            <div class="button danger deleteMedia mceNonEditable"></div>
            <div class="cover">${p.embed}</div>
        </div>
       `
    );
};


