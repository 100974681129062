import React from 'react';
import {Form, Button, Input, DatePicker} from 'antd';
import PropTypes from 'prop-types';
import {t} from "../../../core/library/i18n";

class FilterForm extends React.Component {

    static defaultProps = {
        filter: {},
        onFilter: () => {},
    }

    static propTypes = {
        filter: PropTypes.instanceOf(Object).isRequired,
        onFilter: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    submitHandle = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, filter) => {
            if (err) {
                return;
            }
            this.props.onFilter(filter);
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={`mg-filter`}>
                <Form onSubmit={this.submitHandle} layout={`inline`}>
                    <Form.Item className={`search`}>
                        {getFieldDecorator('search', {
                            initialValue: this.props.filter.search || "",
                        })(
                            <Input style={{width: '100%'}} allowClear placeholder={t('MEDIAGALLERY_FILL_SEARCH')}/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('range', {
                            initialValue: this.props.filter.range && this.props.filter.range.length > 0 ? this.props.filter.range : [],
                        })(
                            <DatePicker.RangePicker allowClear placeholder={['c', 'по']} showTime />
                        )}
                    </Form.Item>
                    {this.props.onReload && <Form.Item><Button type={`default`} icon={`reload`} onClick={this.props.onReload}/></Form.Item>}
                    <Form.Item>
                        <Button type={`primary`} htmlType={`submit`} icon={`search`}>{t('SEARCH')}</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export const GalleryFilter = Form.create({
    name: `gallery_filter`,
})(FilterForm);