import React from 'react';
import { Layout, Select, DatePicker, Spin, message, Table, Button } from 'antd';
import { documentsElLoad, loadMapReport } from "../../store/documents/api";
import Moment from "moment";
import Config from "../../config";


export class MapReport extends React.Component {

    state = {
        loading: false,
        categories: [],
        filter_categories: [],
        filter_dates: '',
        materials: [],
        categories_report: {},
    };

    columns = [
        {
            title: 'Заголовок',
            key: 'title',
            render: r => {
                return r.title ? <a href={`/documents/${r.type.toLowerCase()}/${r.id}`} target={`_blank`} rel={`nofollow`}>{r.title}</a>: "[not set]"
            }
        },
        {
            title: 'Дата публикации',
            key: 'date',
            render: r => Moment(r.publishedAt).format(Config.DATE_FORMAT),
        },
        {
            title: 'Категория',
            key: 'cat',
            render: r => r.mcats && r.mcats.title ? r.mcats.title : "[not set]",
        }
    ]

    onUpdateCategories = (values) => {
        this.setState({ filter_categories: values })
    }

    onUpdateDates = (dates) => {
        this.setState({ filter_dates: dates })
    }

    loadCategories = () => {
        if (this.state.loading === true) {
            return;
        }
        this.setState({ loading: true }, () => {
            documentsElLoad('Mcat', 1, {}, '-publishedAt', 100).then(res => {
                const { list } = res || {};
                this.setState({
                    loading: false,
                    categories: list || [],
                })
            }).catch(e => {
                console.error(`[Failed] `, e);
                this.setState({ loading: false });
            });
        });
    }

    buildCategoriesReport = (data) => {
        let cats = {};
        for(let i in data) {
            let item = data[i];
            if (item.mcats && item.mcats.title) {
                if (!cats[item.mcats.title]) {
                    cats[item.mcats.title] = 0;
                }
                cats[item.mcats.title] = cats[item.mcats.title] + 1;
            }
        }
        return cats;
    }
    loadReport = () => {
        const { filter_categories, filter_dates } = this.state;
        if (filter_categories.length > 0 && filter_dates.length > 0) {
            this.setState({loading: true}, () => {
                loadMapReport(filter_categories, filter_dates[0].toISOString(), filter_dates[1].toISOString()).then(res => {
                    console.log(res);
                    this.setState({
                        loading: false,
                        categories_report: this.buildCategoriesReport(res.data || []),
                        materials: res.data || [],
                    })
                }).catch(e => {
                    console.error(`failed: `, e);
                    message.error(`Ошибка загрузки отчета`);
                    this.setState({loading: false});
                });
            })
        } else {
            message.warning(`Выберите категории и даты`);
        }

    }

    componentDidMount() {
        this.loadCategories();
    }

    render() {
        const { categories, loading, categories_report } = this.state;
        return (
            <Layout.Content style={{margin: 16,padding: 16, background: '#FFF'}}>
                <Spin spinning={loading}>
                    <h1>Отчет по категориям</h1>
                    <div className={`grid-filter grid-3`}>
                        <div className={`grid-item`}>
                            <div className="grid--label">Категории</div>
                            <Select onChange={this.onUpdateCategories} style={{width: '100%'}} mode={`multiple`} placeholder={`Выберите категории`} showSearch allowClear>
                                {categories.map(i => {
                                    return <Select.Option key={`item_${i.id}`} value={i.id}>{i.title}</Select.Option>
                                })}
                            </Select>
                        </div>
                        <div className="grid-item">
                            <div className={`grid--label`}>Даты публикации</div>
                            <DatePicker.RangePicker
                                placeholder={['Дата начала', 'Дата окончания']}
                                allowClear={true}
                                onChange={this.onUpdateDates}
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className="grid-item">
                            <div className={`grid--label`}>&nbsp;</div>
                            <Button type={`primary`} onClick={this.loadReport}>Искать</Button>
                        </div>
                    </div>
                    {(categories_report && Object.keys(categories_report).length > 0) && (<RenderCategoriesReport items={categories_report}/>)}
                    <Table
                        columns={this.columns}
                        bordered={true}
                        dataSource={this.state.materials}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: this.state.materials.length,
                            defaultCurrent: 0,
                            total: this.state.materials.length,
                            current: 0,
                            hideOnSinglePage: true,
                        }}
                    />
                </Spin>
            </Layout.Content>
        );
    }
}


class RenderCategoriesReport extends React.Component {
    render() {
        let total = 0;
        if (this.props.items) {
            for(let i in this.props.items) {
                total = total + this.props.items[i];
            }
        }
        return (
            <div className={`categories__report`}>
                {Object.keys(this.props.items) && (
                    Object.keys(this.props.items).map(i => {
                        return (
                            <div className={`categories__report--item`}>
                                <div className="category__title">{i}</div>
                                <div className="categories__count">{this.props.items[i]}</div>
                            </div>
                        );
                    })
                )}
                <div className={`categories__report--item`}>
                    <div className="category__title">Итого</div>
                    <div className="categories__count">{total}</div>
                </div>
            </div>
        );
    }
}
