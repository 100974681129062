import React from 'react';
import { Typography, Layout, Table, message, Spin } from 'antd';
import { AppConsumer } from './../../context/app';
import {t} from "../../core/library/i18n";
import Fetcher from './../../core/fetch';



class RenderHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let p = this.props;
        if (p.actions && p.actions.updateHeader) {
            p.actions.updateHeader({
                title: t('YANDEX'),
            });
        }
    }

    render() {
        return  <Typography.Title level={2}>{t('YANDEX_REPORT')}</Typography.Title>;
    }
};


export class YandexController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: {},
        };
    }

    loadYandexNews() {
        this.setState({ loading: true }, () => {
            Fetcher('/proxy/', 'POST', {url: "http://services.vm.ru/ynparser"}).then(res => {
                const data = res.data || {};
                let list = data.list || [];
                let result = {};
                result["Главное"] = [];
                list.map(i => {
                    if(i.is_main === true) {
                        result["Главное"].push(i);
                    } else {
                        if(!result[i.rubric]) result[i.rubric] = [];
                        result[i.rubric].push(i);
                    }
                    return i;
                });
                this.setState({ list: result, loading: false });
            }).catch(e => {
                console.error(`error load yandex: `, e);
                message.error(t('ERROR_LOAD_REPORT'));
            });
        });
    }

    componentDidMount() {
        this.loadYandexNews();
    }

    render() {
        return (
            <Layout.Content style={{margin: 16,padding: 16, background: '#FFF'}}>
                <AppConsumer>
                    {context => {
                        return  <RenderHeader {...context}/>
                    }}
                </AppConsumer>
                <Spin spinning={this.state.loading} tip={t('LOADING')}>
                    {Object.keys(this.state.list).map(i => {
                        return (
                            <div style={{marginTop: 20}} className={`report--table`}>
                                <Table
                                    title={() => <Typography.Title level={3}>{i}</Typography.Title>}
                                    dataSource={this.state.list[i]}
                                    rowKey={(item) => `${i}_${item.link}`}
                                    bordered={true}
                                    size={`small`}
                                    pagination={{hideOnSinglePage: true}}
                                    rowClassName={(r)  => r.topPosition === 0 ? 'red' : ''}
                                    columns={[
                                        {
                                            title: 'Заголовок',
                                            dataIndex: 'title'
                                        },
                                        {
                                            title: 'Позиция',
                                            dataIndex: 'topPosition'
                                        },
                                        {
                                            title: '',
                                            key: 'actions',
                                            render: (r) => {
                                                return (
                                                    <React.Fragment>
                                                        <a href={r.link} target={"_blank"} rel={"nofollow"}>[YANDEX]</a>
                                                        {r.origHREF && <a style={{marginLeft: 10}} href={r.origHREF} target={"_blank"} rel={"nofollow"}>[VM]</a>}
                                                    </React.Fragment>
                                                );
                                            }
                                        }
                                    ]}
                                />
                            </div>
                        );
                    })}
                </Spin>
            </Layout.Content>
        );
    }
}

export default YandexController;
