import Fetcher, {ElasticFetcher} from './../../core/fetch/'
import makeRequest from "../../core/fetch";

export function getWidgets(page = 0, search = '', wType = '', lang = '') {
    return Fetcher(`widgets?page=${page}&search=${search}&type=${wType}&sort=sort&limit=1000&lang=${lang}`, `GET`)
}

export function addWidget(widget = {}) {
    return Fetcher(`widgets`, `POST`, widget)
}

export function updateWidget(id = '',widget = {}) {
    return Fetcher(`widgets/${id}`, `PUT`, widget)
}

export function deleteWidget(id = '') {
    return Fetcher(`widgets/${id}`, `DELETE`);
}

export function getWidget(id = '') {
    return Fetcher(`widgets/${id}`, `GET`);
}

export function getWidgetDocs(page = 1, search = '', excludeIds = [], dataTypes = []) {
    let exclude_ids = excludeIds.map(i => `ex=${i}`);
    let data_types = dataTypes.map(i => `dt=${i}`);
    return Fetcher(`widgets/docs?page=${page}&search=${search}${exclude_ids.length > 0 ? `&${exclude_ids.join('&')}` : ``}${data_types.length > 0 ? `&${data_types.join('&')}` : ``}`, `GET`)
}

export function getWidgetDocsElastic(page = 1, search = '', excludeIds = [], dataTypes = [], cats = []) {
    let exclude_ids = excludeIds.map(i => `ex=${i}`);
    let data_types = dataTypes.join(',');
    let category = cats.join(',');
    return ElasticFetcher(`document?sort=-publishedAt&page=${page}&q=${search}${category.length > 0 ? `&category=${category}` : ``}${exclude_ids.length > 0 ? `&${exclude_ids.join('&')}` : ``}${data_types.length > 0 ? `&type=${data_types}` : ``}`, `GET`)
}

export function loadReport(filter) {
    return Fetcher(`reports/widgets`, `POST`, filter)
}


export function updateIndexSort(data) {
    return Fetcher(`widgets/sort`, `PUT`, {list: data});
}

export function updateDocument(id = '', data = {}) {
    return makeRequest(`documents/${id}`, `PUT`, data);
}
