import React from 'react';
import {Typography, Layout, Table, DatePicker, Button, message} from 'antd';
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import * as Actions from "./../../store/reports/actions";
import * as C from './../../store/reports/constants';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Moment from 'moment';
import Config from "../../config";


const mapStateToProps = state => ({
    answer: state.ReportsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class RenderHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let p = this.props;
        if (p.actions && p.actions.updateHeader) {
            p.actions.updateHeader({
                title: t('VIDEO_REPORT'),
            });
        }
    }

    render() {
        return <Typography.Title level={2}>{t('VIDEO_REPORT')}</Typography.Title>;
    }
};


class VideoControllerClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            by_date: [],
            loading: false,
            source: "Вечерняя Москва",
            filter: {
                date_from: Moment(new Date()),
                date_to: Moment(new Date()),
            },
        };
    }

    loadReport = () => {
        let filter = {
            source: "Вечерняя Москва",
            // source: "",
            date_from: this.state.filter.date_from.toISOString(),
            date_to: this.state.filter.date_to.toISOString()
        };
        this.props.actions.GetVideoReport(filter);
    };

    updateDates = (v) => {
        this.setState({
            filter: {
                date_from: v[0],
                date_to: v[1],
            }
        })
    };

    componentDidMount() {
        this.loadReport();
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.VIDEO_LOAD_START:
                    this.setState({loading: true});
                    break;
                case C.VIDEO_LOAD_ERROR:
                    message.error(`Ошибка загрузки отчета`);
                    break;
                case C.VIDEO_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    let byDate = [];
                    if (data.group_date) {
                        byDate = Object.keys(data.group_date).map(i => {
                            return data.group_date[i];
                        });

                        let totalVideos = 0;
                        let totalDuration = 0;
                        let totalMin = 0;
                        byDate.forEach(i => {
                            totalVideos += i.video_count;
                            totalDuration += i.video_duration;
                            totalMin += i.video_duration_minutes;
                        });
                        byDate.push({
                            date: 'Итого',
                            video_count: totalVideos,
                            video_duration: totalDuration,
                            video_duration_minutes: totalMin,
                        })

                    }


                    let list = data.full || [];

                    if (list.length > 0) {

                        list.sort((a, b) => {
                            let aDate = parseInt(Moment(a.date).format('x'), 10);
                            let bDate = parseInt(Moment(b.date).format('x'), 10);
                            return aDate - bDate;
                        });


                        let totals = this.getTableReportFooter(list);
                        list.push({
                            date: 'Итого',
                            title: '',
                            duration: totals[0],
                            duration_min: totals[3],
                            start: totals[1],
                            end: totals[2],
                        });
                    }

                    this.setState({
                        loading: false,
                        list: list,
                        by_date: byDate,
                    });
                    break;
                default:
                    break;
            }
        }

    }

    prepareDownloadLink = () => {
        let dateFrom = this.state.filter.date_from.toISOString();
        let dateTo = this.state.filter.date_to.toISOString();
        return `${Config.PROJECT.API}/reports/video/download?source=${this.state.source}&date_from=${dateFrom}&date_to=${dateTo}`;
    };

    getTableReportFooter = (list = []) => {
        let totalDuration = 0;
        let totalDurationMin = 0;
        let totalStart = 0;
        let totalEnd = 0;
        if (list && list.length > 0) {
            list.forEach(i => {
                totalDuration += i.duration;
                totalDurationMin += i.duration_min;
                totalStart += i.start;
                totalEnd += i.end;
            });
            return [totalDuration, totalStart, totalEnd, totalDurationMin];
        }


        return [0, 0, 0];

    };

    render() {
        return (
            <Layout.Content style={{margin: 16, padding: 16, background: '#FFF'}}>
                <AppConsumer>
                    {context => {
                        return <RenderHeader {...context}/>
                    }}
                </AppConsumer>
                <Table
                    loading={this.state.loading}
                    title={() => {
                        return (
                            <div>
                                <DatePicker.RangePicker
                                    onChange={this.updateDates}
                                    defaultValue={[this.state.filter.date_from, this.state.filter.date_to]}
                                />
                                <Button style={{marginLeft: 10}} onClick={this.loadReport}
                                        type={`primary`}>{t('SEARCH')}</Button>
                                <Button style={{marginLeft: 10}} onClick={() => {
                                    window.open(this.prepareDownloadLink());
                                }} type={`primary`}>{t('DOWNLOAD_XLS')}</Button>
                            </div>
                        );
                    }}
                    bordered
                    dataSource={this.state.list}
                    columns={[
                        {
                            title: 'Дата',
                            key: 'date',
                            render: r => {
                                if (r.date === 'Итого') return <strong>Итого: </strong>;
                                return Moment(r.date).format('Y.MM.DD')
                            },
                        },
                        {
                            title: 'Заголовок',
                            dataIndex: 'title',
                        },
                        {
                            title: 'Длина видео',
                            key: 'duration',
                            render: r => {
                              return `${r.duration_min} (${r.duration} сек)`
                            },
                        },
                        {
                            title: 'Start video',
                            dataIndex: 'start',
                        },
                        {
                            title: 'End video',
                            dataIndex: 'end',
                        },
                        {
                            title: 'Материал',
                            dateIndex: 'doc_title',
                            render: r => {
                                if (r.document && r.document.title && r.document.link) {
                                    return <a href={`https://absatz.media${r.document.link}`} target="_blank" rel="noopener noreferrer">{r.document.title}</a>
                                }
                                return `[не установлен]`;
                            }
                        }
                    ]}
                    rowKey={(item) => item.id}
                    pagination={{
                        pageSize: this.state.list.length,
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                    }}
                />
                <div style={{marginTop: 20}}>
                <Table
                    loading={this.state.loading}
                    title={() => {
                        return (
                            <Typography.Title level={3}>Группировка по дня</Typography.Title>
                        );
                    }}
                    bordered
                    dataSource={this.state.by_date}
                    columns={[
                        {
                            title: 'Дата',
                            key: 'date',
                            render: r => {
                                if (r.date === 'Итого') return <strong>Итого: </strong>;
                                return Moment(r.date).format('Y.MM.DD')
                            },
                        },

                        {
                            title: 'Количество видео',
                            dataIndex: 'video_count',
                        },
                        {
                            title: 'Длина видео',
                            key: 'duration',
                            render: r => `${r.video_duration_minutes} (${r.video_duration} сек)`,
                        },

                    ]}
                    rowKey={(item) => item.id}
                    pagination={{
                        pageSize: this.state.list.length,
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                    }}
                />
                </div>
            </Layout.Content>
        )
    }
}


export const VideoController = connect(
    mapStateToProps,
    mapDispatchProps
)(VideoControllerClass);

export default VideoController;
