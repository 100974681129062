import React from 'react';
import {Input, message, Modal, Pagination, Spin} from "antd";
import {documentsElLoadDocs} from "../../../../../store/documents/api";
import './mgallery.scss';
import DefaultCardVM from "../../../cards/default_vm";

export class ReadmoreList extends React.Component {
    state = {
        search: '',
        list: [],
        limit: 5,
        total: 0,
        page: 0,
        loading: false,
    }

    loadList = () => {
        this.setState({ loading: true }, async () => {
            try {
                const { search, page, limit } = this.state;
                let resp = await documentsElLoadDocs('News,Podcasts,Opinion', page, { search, published: true }, '-createdAt', limit);
                this.setState({
                    loading: false,
                    list: resp.list || [],
                    total: resp.total || 0,
                })
            } catch(e) {
                message.error(`Ошибка загрузки материалов`);
                this.setState({ loading: false });
            }
        })
    }

    componentDidMount() {
        this.loadList()
    }

    render() {
        const { list, total, limit, page } = this.state;
        return (
            <Modal visible={true} title={`Читайте также`} footer={null} onCancel={this.props.onCancel} width={1024}>
                <Spin spinning={this.state.loading}>
                    <div className={`editor_mgallery`}>
                        <div className="mgallery_filter">
                            <Input.Search placeholder={`Введите запрос`} onSearch={(search) => {
                                this.setState({ search, page: 1}, () => {
                                    this.loadList();
                                })
                            }}/>
                            <Pagination
                                defaultCurrent={page}
                                total={total}
                                current={page}
                                pageSize={limit}
                                hideOnSinglePage={true}
                                onChange={(page) => {
                                    this.setState({ page }, () => {
                                        this.loadList();
                                    })
                                }}
                            />
                        </div>
                        <div className="readmore__list">
                            {list.map(i => {
                                return (
                                    <DefaultCardVM
                                        {...i}
                                        key={`readmore_${i.id}`}
                                        isReadmore={true}
                                        onInsert={this.props.onInsert}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Spin>
            </Modal>
        );
    }
}
