import React from 'react';
import {Chart, Axis, Legend, Tooltip, Geom} from "bizcharts";

export default class ChartClass extends React.Component {
    render() {
        let data = [];
        for(let i = 0; i < 24; i ++) {
            Object.keys(this.props.materials).map(j => {
                let o = this.props.materials[j];
                data.push({
                    title: o.title,
                    time: i,
                    view_count: o.report && o.report.by_hour && o.report.by_hour[i] ? o.report.by_hour[i] : 0,
                });
                return j;
            });
        }



        return (
            <React.Fragment>
                <Chart height={400} data={data} forceFit>
                    <Legend />
                    <Axis name="time" title={`Время`} label={`Время`}/>
                    <Axis
                        name="views_count"
                        title={`Просмотры`} label={`Просмотры`}
                    />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="line"
                        position="time*view_count"
                        size={2}
                        color={"title"}
                        shape={"smooth"}
                    />
                    <Geom
                        type="point"
                        position="time*view_count"
                        size={4}
                        shape={"circle"}
                        color={"title"}
                        style={{
                            stroke: "#fff",
                            lineWidth: 1
                        }}
                    />
                </Chart>
            </React.Fragment>
        )
    }
}
