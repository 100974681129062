import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "./../../store/profiles/actions";
import * as C from './../../store/profiles/constants';
import { Spin, message } from 'antd';
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import ProfileEdit from './profile/edit';

const mapStateToProps = state => ({
    answer: state.ProfilesReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class ProfilesHeader extends React.Component {
    static defaultProps = {
        context: {},
    };
    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };
    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('PROFILE_EDIT'),
        })
    }
    render() {
        return null;
    }
}

class ProfileEditController extends React.Component {

    static defaultProps = {
        actions: {},
        answer: {},
        match: {
            params: {}
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params && this.props.match.params.id ? this.props.match.params.id : false,
            profile: {},
            groups: [],
        };
    }

    loadGroups = () => {
        this.props.actions.LoadGroups(1, {});
    };

    loadProfile = () => {
        this.props.actions.LoadProfile(this.state.id);
    };


    componentDidMount() {
        this.loadProfile();
    }

    updateProfile = (data) => {
        this.props.actions.UpdateProfile(this.state.id, data);
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {

                case C.PROFILES_UPDATE_START:
                    this.setState({ loading: true });
                    break;

                case C.PROFILES_UPDATE_FINISH:
                    window.location.reload();
                    this.setState({ loading: false });
                    break;

                case C.PROFILES_UPDATE_ERROR:
                    message.error(t(`ERROR_UPDATE_PROFILES`));
                    this.setState({ loading: false });
                    break;

                case C.PROFILE_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.PROFILE_LOAD_FINISH:
                    let profile = p.answer.data.data || {};
                    this.setState({
                        profile,
                    }, () => {
                        this.loadGroups();
                    });
                    break;
                case C.PROFILE_LOAD_ERROR:
                    message.error(t('ERROR_LOAD_PROFILE'));
                    break;
                case C.GROUPS_LOAD_FINISH:
                    let groups = p.answer.data.data || {};
                    this.setState({ loading: false, groups: groups.list});
                    break;
                case C.GROUPS_LOAD_ERROR:
                    this.setState({ loading: false });
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <ProfilesHeader context={context}/>
                            <div className={`comments--view`} style={{minHeight: '120px'}}>
                                <Spin spinning={this.state.loading} style={{minHeight: '120px'}}>
                                    <ProfileEdit
                                        item={this.state.profile}
                                        groups={this.state.groups}
                                        onUpdate={this.updateProfile}
                                    />
                                </Spin>
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}

export const ProfilesEdit = connect(
    mapStateToProps,
    mapDispatchProps
)(ProfileEditController);

export default ProfilesEdit;
