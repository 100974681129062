import React from 'react';
import {Upload, Icon, Button, message} from 'antd';
import {EditForm} from './editForm';
import User from './../../../core/user';
import {t} from "../../../core/library/i18n";
import Config from "../../../config";


const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export class GalleryUpload extends React.Component {
    static defaultProps = {
        reloadList: () => {},
    };


    constructor(props) {
        super(props);
        this.state = {
            files: [],
            loading: false,
            lock: false,
        };
        this.forms = {};
    }


    onChange = (file) => {
        this.forms = {};
        this.setState({
            files: file.fileList.map(i => {
                return {
                    ...i, ...{
                        props: {
                            title: ``,
                            description: ``,
                            keywords: ``,
                            properties: {
                                copyright: ``,
                                copyrightUrl: ``,
                            }
                        }
                    }
                }
            })
        });
    }

    beforeUpload = () => {
        return false;
    };

    submitHandle = (e) => {
        e.preventDefault();
        this.setState({ loading: true },  async () => {
            let files = [];

            for (let key in this.state.files) {
                let file = {...this.state.files[key]};
                file.props = this.forms[file.uid].props.form.getFieldsValue();
                try {
                    await this.uploadFile(file);
                    message.success(`${file.name} - успешно загружен`);
                } catch (err) {
                    files.push(this.state.files[key]);
                    console.error(`error load file: `, err);
                    message.error(`Ошибка загрузки файла: ${err.e && err.e.error ? err.e.error : ""}`);
                }
            }
            this.setState({ files , loading: false }, () => {
                if (this.state.files.length === 0) {
                    setTimeout(() => {
                        this.props.reloadList();
                    }, 100);
                }
            });
        });
    }


    uploadFile = (file) => {
        return new Promise((resolve, reject) => {
            let props = file.props;
            props['properties']['originalFilename'] = file.name;

            let formData = new FormData();
            if (file.originFileObj instanceof Blob) {
                formData.append('files', file.originFileObj, file.originFileObj.name);
            } else {
                formData.append('files', file.originFileObj);
            }


            Object.keys(props).forEach(key => {
                if (key === 'properties') {
                    Object.keys(props[key]).forEach(i => {
                        formData.append(`${key}.${i}`, props[key][i]);
                    })
                } else {
                    formData.append(key, props[key]);
                }
            });

            let type = file.originFileObj.type.split('/');
            formData.append('type', capitalize(type[0]))

            const user = new User();

            fetch(`${Config.PROJECT.API}/media`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Authorization': user.getAccessToken(),
                },
                body: formData
            }).then(res => res.json()).then(res => {
                if (res.error) {
                    reject({error: true, e: res});
                } else {
                    resolve({uploaded: true, res})
                }
            }).catch(e => {
                reject({error: true, e});
            });

        });
    };

    removeImageHandle = (item) => {
        this.forms[item] = null;
        let files = this.state.files.filter(i => i.uid !== item);
        this.setState({files});
    }

    copyFieldToAll = (uid, field) => {
        if (this.forms[uid] && this.forms[uid].props && this.forms[uid].props.form) {
            let value = this.forms[uid].props.form.getFieldValue(field);
            let setData = {};
            setData[field] = value;
            for(let i in this.forms) {
                this.forms[i].props.form.setFieldsValue(setData)
            }
        }
    }

    onUpdateFile = (id, file) => {
        let files = [...this.state.files];
        files = files.map(i => {
            if (i.uid === id) {
                i.originFileObj = file;
            }
            return i;
        });
        this.setState({ files });
    }

    render() {
        let props = {
            onChange: this.onChange,
            beforeUpload: this.beforeUpload,
            name: 'file',
            multiple: true,
            accept: "image/jpg,image/png,image/gif,image/jpeg,video/mov,video/mp4,video/quicktime"
        };
        return (
            <div className={`mg--uploader`}>
                {this.state.files.length === 0 && (
                    <Upload.Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text">{t(`MEDIGALLERY_UPLOAD_TITLE`)}</p>
                    </Upload.Dragger>
                )}
                {this.state.lock === false && this.state.files.length > 0 && (
                    <div className={`mgr__upload-form`}>
                        <div className="actions">
                            <h3>{t('MEDIAGALLERY_UPLOAD_TITLE')}</h3>
                        </div>
                        <div className="form--items">
                            {this.state.files.map(i => {
                                return (
                                    <EditForm
                                        wrappedComponentRef={(ref) => this.forms[i.uid] = ref}
                                        key={i.uid}
                                        loading={this.state.loading}
                                        isUploading={true}
                                        removeImageHandle={this.removeImageHandle}
                                        copyFieldToAll={this.copyFieldToAll}
                                        showCopy={this.state.files.length > 0}
                                        onUpdateFile={this.onUpdateFile}
                                        {...i}
                                    />
                                );
                            })}
                        </div>
                        {this.state.loading === false && (
                            <div className="form--actions">
                                <Button onClick={(e) => {
                                    e.preventDefault();
                                    this.forms = {};
                                    this.setState({files: []});
                                }} type={`danger`}>Отменить</Button>
                                <Button
                                    type={`primary`}
                                    onClick={this.submitHandle}
                                >
                                    Загрузить
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
