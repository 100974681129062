import Config from './../../../../../config';


function prepareText(element) {
    let params = [element.title];
    if (element.properties) {
        Object.keys(element.properties).map(i => {
            if (element.properties[i]) {
                params.push(element.properties[i]);
            }
            return i;
        });
    }
    return (params.join(' / '));
}

export default function (p) {
    let path = p && p.files ? p.files.original.path : "";
    return (
       `<div data-title="${prepareText(p)}" class="rmedia rmedia--image mceNonEditable" style="background-image: url(${Config.MEDIA.CDN.IMAGES}/${path})">
            <div class="button primary updateMedia mceNonEditable"></div>
            <div class="button danger deleteMedia mceNonEditable"></div>
        </div>
       `
    );
};


