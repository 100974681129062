import React from 'react';
import { Divider, Dropdown, Menu, Tag, Switch, Form } from 'antd';
import { t } from '../../library/i18n';
import Config from './../../../config';

export const Workflow = (p) => {
    if(!Config.WORKFLOW || !Config.WORKFLOW.STATUSES) return null;
    const statuses = (
        <Menu onClick={(e) => {
            let workflow = e.key;
            if (workflow === "READY") {
                let options = p.item.options ? {...p.item.options} : {};
                options.needPhoto = true;
                options.needVideo = true;
                options.needRayter = true;
                options.needEditor = true;
                options.needCorector = true;
                // options.needCorector = true;
                p.onUpdate({
                    workflow,
                    options
                })
            } else {
                p.onUpdate({workflow})
            }
        }}>
            {Config.WORKFLOW && Config.WORKFLOW.STATUSES && Config.WORKFLOW.STATUSES.map(i => {
                return <Menu.Item key={`${i}`}><Tag style={{cursor: 'pointer'}} color={(Config.WORKFLOW && Config.WORKFLOW.STATUSES_COLOR && Config.WORKFLOW.STATUSES_COLOR[i]) ? Config.WORKFLOW.STATUSES_COLOR[i] : ""}>{t(i)}</Tag></Menu.Item>
            })}
        </Menu>
    );

    

    return (
        <div className={`workflow`}>
            <Divider>{t('WORKFLOW')}</Divider>
            {Config.WORKFLOW && Config.WORKFLOW.STATUSES && (
                <div className={`workflow--status`}>
                    <Form.Item label={t('STATUS')}>
                        <Dropdown.Button overlay={statuses} style={{width: '100%'}}>
                            {t(p.item.workflow)}
                        </Dropdown.Button>
                    </Form.Item>
                </div>
            )}
            <div className="worflow--items">
              
                <Form.Item label={t('NEED_PHOTO')}>
                    <Switch checked={p.item.options && p.item.options.needPhoto} defaultChecked={p.item.options && p.item.options.needPhoto} onChange={checked => {
                        let options = p.item.options || {};
                        options.needPhoto = checked;
                        p.onUpdate({ options });
                    }}/>
                </Form.Item>
        
                <Form.Item label={t('NEED_EDITOR')}>
                    <Switch defaultChecked={p.item.options && p.item.options.needEditor} onChange={checked => {
                        let options = p.item.options || {};
                        options.needEditor = checked;
                        p.onUpdate({ options });
                    }}/>
                </Form.Item>
                <Form.Item label={t('NEED_CORRECTOR')}>
                    <Switch defaultChecked={p.item.options && p.item.options.needCorector} onChange={checked => {
                        let options = p.item.options || {};
                        options.needCorector = checked;
                        p.onUpdate({ options });
                    }}/>
                </Form.Item>
            </div>
            <Divider/>
        </div>
    )
};

export default Workflow;
