import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tag, Button, Tooltip } from 'antd';
import { t } from './../../library/i18n';

export const Tags = (p) => {

    const [open, setOpen] = useState(false);
    const showBtn = p.list.length > p.limit;
    let list = [...p.list];
    if (open === false) {
        list = list.length > p.limit ? list.splice(0, p.limit) : list;
    }
    return (
        <React.Fragment>
             <div className={`tags tags--default`}>
                 <div className={`label`}>
                     {t('TAGS')}:
                 </div>
                 {list.map(i => {
                     let url = `${p.urlPrefix}`;
                     url = `${url}${url.indexOf("?") + 1 > 0 ? `&${p.urlParams}=${i.id}` : `?${p.urlParams}=${i.id}`}`;
                     return (
                         <Tag color={"blue"} key={`tag_${i.id}`}>
                             <Link to={url}>{i.title}</Link>
                         </Tag>
                     );
                 })}
                 {showBtn && (<Tooltip title={open === false ? t('SHOW_ALL_TAGS') : t('HIDE_ALL_TAGS')}><Button onClick={() => setOpen(!open)} type={`primary`} icon={open === false ? "right" : "left"} shape={"circle"} size={"small"} /></Tooltip>)}
             </div>
        </React.Fragment>
    );
};

Tags.defaultProps = {
    list: [],
    urlPrefix: '',
    urlParams: 'tags',
    limit: 3,
};

Tags.propTypes = {
    list: PropTypes.arrayOf(Object).isRequired,
    urlPrefix: PropTypes.string.isRequired,
    urlParams: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
};

export default Tags;
