import React from 'react';
import {Form, Input, Switch, Button} from 'antd';
import {t} from './../../library/i18n';


export class Coords extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            coordsList: this.props.initialValue || [],
        };
    }

    componentWillReceiveProps(p) {
        this.setState({coordsList: p.initialValue || []});
    }


    addNewPoint = () => {
        let coordsList = this.state.coordsList;
        coordsList.push({});
        this.setState({ coordsList });
    };


    onDelete = (index) => {
        let coordsList = this.state.coordsList;
        coordsList.splice(index, 1);
        this.setState({ coordsList });
    };


    updateItemByIndex = (index, data) => {
        let coordsList = this.state.coordsList;
        coordsList[index] = data;
        this.setState({ coordsList });
    };


    render() {
        let values = this.state.coordsList.filter(i => {
            return i.point;
        });
        return (
            <div className={`coords__list`}>
                <Form.Item label={this.props.label || ""}>
                    <div style={{display: 'none'}}>
                        {this.props.decorator(this.props.name, {
                            rules: this.props.rules || [],
                            initialValue: values || "",
                        })(<Input {...this.props.props}/>)}
                    </div>
                    {this.state.coordsList.map((i, index) => {
                        return (
                            <CoordsInput
                                {...i}
                                key={`coords_${index}`}
                                index={index}
                                onDelete={this.onDelete}
                                onUpdate={this.updateItemByIndex}
                            />
                        );
                    })}
                    <div className="coords--actions" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Button onClick={this.addNewPoint}>{t('ADD_ADDRESS')}</Button>
                    </div>
                </Form.Item>
            </div>
        );
    }
}


class CoordsInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            map: false,
            id: Math.random()
        };
        this.map = null;
    }

    toggleMap = (map) => {
        this.setState({map}, () => {
            if (this.state.map) {
                this.renderMap();
            }
        });
    };


    renderMap = () => {
        let mySearchControl = new window.ymaps.control.SearchControl({
            // options: {
            //     noPlacemark: true,
            //     noPopup: false,
            // }
        });

        let placeMark = null;
        mySearchControl.events.add('resultselect', (e) => {
            this.map.geoObjects.removeAll();
            let index = e.get('index');
            let point = mySearchControl.getResult(index);
            let geoObjectsArray = mySearchControl.getResultsArray();
            if (geoObjectsArray.length) {
                let fields = {
                    address: mySearchControl.getRequestString(),
                    point: {
                        type: "Point",
                        coordinates: point._value.geometry._coordinates,
                    }
                };
                this.props.onUpdate(this.props.index, fields);
            }

        });


        this.map = new window.ymaps.Map(`map_${this.state.id}`, {
            center: [55.76, 37.64],
            zoom: 7,
            controls: [mySearchControl, 'zoomControl'],
        });


        if (this.props.point && this.props.point.coordinates) {
            placeMark = new window.ymaps.GeoObject({
                geometry: {
                    type: "Point",
                    coordinates: this.props.point.coordinates,
                }
            });
            this.map.geoObjects.add(placeMark);
        }


        this.map.events.add('click', async (e) => {
            // this.map.geoObjects.removeAll();
            mySearchControl.clear();
            let coords = e.get('coords');


            if (placeMark) {
                placeMark.geometry.setCoordinates(coords);
            } else {
                placeMark = new window.ymaps.GeoObject({
                    geometry: {
                        type: "Point",
                        coordinates: coords,
                    }
                });
                this.map.geoObjects.add(placeMark);
            }

            let address = await this.getAddress(coords);

            let fields = {
                address,
                point: {
                    type: "Point",
                    coordinates: coords,
                }
            };


            this.props.onUpdate(this.props.index, fields);

        });
    };

    getAddress = (coords) => {
        return new Promise((resolve, reject) => {
            window.ymaps.geocode(coords).then((res) => {
                let firstGeoObject = res.geoObjects.get(0);
                resolve(firstGeoObject.properties ? firstGeoObject.properties._data.text : '');
            }).catch(e => {
                reject({error: true, e});
            });
        });

    };


    render() {
        return (
            <div className={`coord`} style={{marginBottom: 10}}>
                <div className="coords__wrapper" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Input {...this.props.props} defaultValue={this.props.address || ""}
                           value={this.props.address || ""} disabled={true}/>
                    <Button type={`danger`} icon={`delete`} style={{margin: '0 10px'}} onClick={() => {
                        this.props.onDelete(this.props.index);
                    }}/>
                    <Switch defaultChecked={this.state.map} unCheckedChildren={t('HIDE_MAP')}
                            checkedChildren={t('SHOW_MAP')} onChange={this.toggleMap}/>
                </div>
                {this.state.map && (<div style={{marginTop: 10}} className={`map`} id={`map_${this.state.id}`}/>)}
            </div>
        );
    }
}


// export class Coordsold extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             map: false,
//         };
//     }
//
//
//     toggleMap = (map) => {
//         this.setState({map}, () => {
//             if (this.state.map) {
//                 this.renderMap();
//             }
//         });
//     };
//
//     prepareCoords = (coords) => {
//         if (coords !== "") {
//             return coords.split(",").map(i => i.replace(" ", ""));
//         }
//         return "";
//     };
//
//     renderMap = () => {
//         let mySearchControl = new window.ymaps.control.SearchControl({
//             // options: {
//             //     noPlacemark: true,
//             //     noPopup: false,
//             // }
//         });
//
//         let placeMark = null;
//
//         // mySearchControl.events.add('load', function () {
//         //     console.log('request: ' + mySearchControl.getSelectedIndex(), mySearchControl.getResultsArray());
//         // }, this);
//
//
//         mySearchControl.events.add('resultselect', (e) => {
//             this.map.geoObjects.removeAll();
//             let index = e.get('index');
//             let point = mySearchControl.getResult(index);
//             console.log(`index: `, index, point, point._value.geometry._coordinates);
//
//             let geoObjectsArray = mySearchControl.getResultsArray();
//             if (geoObjectsArray.length) {
//                 let fields = {};
//                 fields[this.props.name] = fields[this.props.name] || [];
//                 fields[this.props.name][0] = {
//                     address: mySearchControl.getRequestString(),
//                     point: {
//                         type: "Point",
//                         coordinates: point._value.geometry._coordinates,
//                     }
//                 };
//                 this.props.form.setFieldsValue(fields);
//             }
//
//         });
//
//
//         this.map = new window.ymaps.Map("map", {
//             center: [55.76, 37.64],
//             zoom: 7,
//             controls: [mySearchControl, 'zoomControl'],
//         });
//
//
//         if (this.props.initialValue && this.props.initialValue !== "") {
//             if (this.props.initialValue[0] && this.props.initialValue[0].point && this.props.initialValue[0].point.coordinates) {
//                 placeMark = new window.ymaps.GeoObject({
//                     geometry: {
//                         type: "Point",
//                         coordinates: this.props.initialValue[0].point.coordinates,
//                     }
//                 });
//                 this.map.geoObjects.add(placeMark);
//             }
//
//
//         }
//
//
//         this.map.events.add('click', async (e) => {
//             // this.map.geoObjects.removeAll();
//             mySearchControl.clear();
//             let coords = e.get('coords');
//
//
//             if (placeMark) {
//                 placeMark.geometry.setCoordinates(coords);
//             } else {
//                 placeMark = new window.ymaps.GeoObject({
//                     geometry: {
//                         type: "Point",
//                         coordinates: coords,
//                     }
//                 });
//                 this.map.geoObjects.add(placeMark);
//             }
//
//             let address = await this.getAddress(coords);
//
//             // only first index
//             let fields = {};
//             fields[this.props.name] = fields[this.props.name] || [];
//             fields[this.props.name][0] = {
//                 address,
//                 point: {
//                     type: "Point",
//                     coordinates: coords,
//                 }
//             };
//             this.props.form.setFieldsValue(fields);
//
//         });
//     };
//
//     getAddress = (coords) => {
//         return new Promise((resolve, reject) => {
//             window.ymaps.geocode(coords).then((res) => {
//                 let firstGeoObject = res.geoObjects.get(0);
//                 resolve(firstGeoObject.properties ? firstGeoObject.properties._data.text : '');
//             }).catch(e => {
//                 reject({error: true, e});
//             });
//         });
//
//     };
//
//     render() {
//
//         let FormValue = this.props.form.getFieldValue(this.props.name);
//         let address = this.props.initialValue && this.props.initialValue[0] && this.props.initialValue[0].address ? this.props.initialValue[0].address : "";
//
//         if (FormValue && FormValue[0] && FormValue[0].address) {
//             address = FormValue[0].address;
//         }
//
//         return (
//             <Form.Item label={this.props.label || ""} help={this.state.map ? t('COORDS_MAP_HELP') : t('COORDS_HELP')}
//                        className={`coords--form`}>
//                 <div className="coords--wrapper">
//                     <div style={{display: "none"}}>
//                         {this.props.decorator(this.props.name, {
//                             rules: this.props.rules || [],
//                             initialValue: this.props.initialValue || "",
//                         })(<Input {...this.props.props}/>)}
//                     </div>
//                     <Input {...this.props.props} defaultValue={address} value={address} disabled={true}/>
//                     <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
//                         {address !== "" && <Button type={`danger`} icon={`delete`} onClick={() => {
//                             if (this.props.props.onClear) {
//                                 let fields = {};
//                                 fields[this.props.name] = fields[this.props.name] || [];
//
//                                 this.props.props.onClear(0);
//                                 this.props.form.setFieldsValue(fields);
//                             }
//                         }} style={{marginRight: 10}}/>}
//                         <Switch disabled={this.props.props && this.props.props.disabled} defaultChecked={this.state.map}
//                                 unCheckedChildren={t('HIDE_MAP')}
//                                 checkedChildren={t('SHOW_MAP')} onChange={this.toggleMap}/>
//                     </div>
//
//                 </div>
//                 {this.state.map && (<div className={`map`} id={`map`}/>)}
//             </Form.Item>
//         );
//     }
// }


export default Coords;
