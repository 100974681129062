import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { t } from './../../../core/library/i18n';

class CreatePermissionForm extends React.Component {
    static defaultProps = {
        item: {},
        form: {},
        onCreate: (data) => {},
        onUpdate: (id, data) => {},
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        onCreate: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
    };


    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.item.id) {
                    this.props.onUpdate(this.props.item.id, values);
                } else {
                    this.props.onCreate(values);
                }
            }
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={t('PERMISSION_CODE')}>
                    {getFieldDecorator('code', {
                        initialValue: this.props.item.code || "",
                        rules: [{required: true, message: t('PERMISSION_CODE_FILL')}]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={t('PERMISSION_TITLE')}>
                    {getFieldDecorator('title', {
                        initialValue: this.props.item.title || "",
                        rules: [{required: true, message: t('PERMISSION_TITLE_FILL')}]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={t('PERMISSION_DESCRIPTION')}>
                    {getFieldDecorator('description', {
                        initialValue: this.props.item.description || "",
                    })(<Input />)}
                </Form.Item>
                <Form.Item>
                    <Button htmlType={`submit`} type={`primary`}>{this.props.item.id ? t('UPDATE') : t('CREATE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}
export default Form.create({ name: 'PermissionCreate' })(CreatePermissionForm);
