/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Spin, Button, Modal} from 'antd';
import {parseUrlParams} from './core/library/url';
import User from './core/user';
import AppStore from './core/library/store';
import * as I18N from './core/library/i18n';
import { AppProvider } from './context/app';
import Config from './config';
import Layout from './layout';
import { AuthController } from './controllers';
// import AppStore from './core/library/store/index';
// AppStore.set('oauth_access', 'eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzU4NTM2ODgsImlhdCI6MTY3MzI2MTY4OCwic2Vzc2lvbiI6IjYzYmJmMjc4YjA5YzYwMDMyNWI4Y2Q2ZiIsInN1YiI6IjYyMzcxNTlmNzFlODA2Mzk0MzBkMmI1MCJ9.j7ZB0AWNrDdgpnILY4WlTcIAAOT17CjdxrWljDTlTjTQkE-fORdkB7NpUjzSWWZ3FF-8zZ378BumeoYAqAe13pn9uwubrVDidQeLEMkokpzE8oYTCFJJHt1gp0GsnrS4eYoF7dIQXa6UL_JDr1K0uPygFsCe2rVitoF1851NVtCZr4JUskqInjG_o4udP0i1Pckhl9CbYLu3cGx7Z-UtylFByYbeNo87vRynzvfwAiZNh--SzS-MVuk5Re6lhsWdALfwHPZR9ZOGffKyLnW6E9U8l9fH88l1zNekAZF_pb_qtCar7vDtn4gdO-2A-apjtn7mq01o4ZHvx3S43p9gsr5qLfDlK1DnjRE9iN5809zUt8imONQqyKIihMVFZ7ElmokTeN_JMAY-QDH61Dib4SJ9QhQN2bvcD5mnxXfrc1WJnMqioUjafPybfnL815c-WeNQhIjank-wmZvDd4tAwKXHXkRD1nSFO5Ri2uhXaKyiPVSFDFNgZNtlQL9md43pgFqFR3c8CHxfLK4ekr-z3qjhz437ckVHSeNywfNnAn3Dq7gei4w_khoqchcS1tQc5mQxTDEf_CeqGTtd2IKeZJO_onlIlPlQXAE59d8QKEuKN0pWbz4klGt5NckqWyEgkJVwCZGGnPG6wXzgldFoKgRyFBs52fg58pKz98fW1fw');

const TIMEOUT_CHECK_AUTHORIZATION = 300000;

const AppAuthorizationErr = (p) => {
    return (
        <React.Fragment>
            <span>{I18N.t('AUTHORIZATION_ERROR')}</span>
            <Button
                type={`primary`}
                htmlType={`button`}
                onClick={() => p.logout()}
            >
                {I18N.t('LOGIN')}
            </Button>
        </React.Fragment>
    );
};

const AppAuthorizationWait = (p) => {
    return (
        <React.Fragment>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 300, margin: '0 auto', alignItem: 'center', alignContent: 'center'}}>
                <div style={{textAlign: 'center', marginBottom: 40}}>{I18N.t('AUTHORIZATION_WAIT')}</div>
                <Button
                    type={`primary`}
                    htmlType={`button`}
                    onClick={() => window.location.href = '/'}
                >
                    {I18N.t('LOGIN')}
                </Button>
            </div>
        </React.Fragment>
    );
};

class App extends React.Component {

    static defaultProps = {
        history: {},
    };

    static propTypes = {
        history: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.user = new User();
        let url = parseUrlParams();
        this.state = {
            key: url.key || "",
            message: I18N.t('LOADING'),
            loading: true,
            isAuth: false,
            isWait: window.location.href.indexOf('logout') + 1 > 0,
            // authModal: false,
            // authLoading: false,
            header: {
                title: I18N.t('DASHBOARD_WELCOME'),
                showBack: false,
            },
        };
        this.refreshTimeout = 0;
    }

    updateHeader = (header) => {
        this.setState({ header });
    };

    // checkTokens = () => {
    //     this.user.isAuth().then(auth => {
    //         this.refreshTimeout = setTimeout(() => {
    //             this.checkTokens();
    //         }, TIMEOUT_CHECK_AUTHORIZATION)
    //     }).catch(e => {
    //         this.setState({ authModal: true });
    //     });
    // };


    // load user authorization
    // userAuthorization = () => {
    //     this.user.isAuth().then(auth => {
            
    //         this.setState({
    //             loading: false,
    //         }, () => {
    //             const redirectPath = AppStore.get('redirect_path');
    //             AppStore.remove(`redirect_path`);
    //             if (redirectPath) {
    //                 this.props.history.push(redirectPath);
    //             }
    //             this.refreshTimeout = setTimeout(() => {
    //                 this.checkTokens();
    //             }, TIMEOUT_CHECK_AUTHORIZATION)
    //         });
    //     }).catch(e => {
    //         AppStore.set(`redirect_path`, `${window.location.pathname}${window.location.search}`);
    //         window.location.href = this.user.generateAuthorizatoinUrl();
    //     });
    // };


    // generateTokens = () => {
    //     this.user.generateUserTokens(this.state.key).then(data => {
    //         if (data.result) {
    //             this.props.history.push('/');
    //             this.userAuthorization();
    //         } else {
    //             this.setState({message: <AppAuthorizationErr logout={this.logout}/>});
    //         }
    //     }).catch(e => {
    //         this.setState({message: <AppAuthorizationErr logout={this.logout}/>});
    //     });
    // };

    async componentDidMount() {
        try {
            let user = await this.user.isAuth();
            this.setState({loading: false, isAuth: true});
        } catch(e) {
            this.setState({ loading: false, isAuth: false });
        }
    };

    resumeAuthorization = () => {
        // this.setState({authLoading: true}, () => {
        //     this.user.updateTokens().then(data => {
        //         if (data.data && data.data.tokens) {
        //             this.user.setTokens(data);
        //             this.setState({ authLoading: false, authModal: false });
        //         } else {
        //             this.user.logout();
        //             window.location.reload();
        //         }
        //     }).catch(e => {
        //         console.error(`error load tokens: `, e);
        //         this.user.logout();
        //         window.location.reload();
        //     });
        // });

    };

    logout = () => {
        // this.user.logout();
        // window.location.href='?logout';
    };

    userAuthorize = async (access_token, refresh_token) => {
        this.user.setTokens(access_token, refresh_token);
        try {
            let user = await this.user.isAuth();
            console.log(`[userAuthorize][user]`, user);
            this.setState({ isAuth: true });
        } catch(e) {
            console.error(`[userAuthorize]: `, e);
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        }
    }

    render() {
        const { loading, isAuth } = this.state;
        return (
            <Spin
                wrapperClassName={`app${this.state.loading ? ` app_loading`: ''}${Config.APP_CLASS ? ` project_${Config.APP_CLASS}` : ''}`}
                spinning={loading}
                tip={this.state.message}>
                {(loading === false && isAuth === false) && <AuthController setAuth={this.userAuthorize} />}
                {(loading === false && isAuth === true ) && (
                    <AppProvider value={{
                        user: this.user,
                        header: this.state.header,
                        actions: {
                            updateHeader: this.updateHeader,
                        }
                    }}>
                        <Modal
                            visible={this.state.authModal}
                            title={I18N.t('AUTHORIZATION_EXPIRED')}
                            footer={!this.state.authLoading ? [
                                <Button type={`danger`} key="back" icon={`logout`} onClick={this.logout}>
                                    {I18N.t('LOGOUT')}
                                </Button>,
                                <Button type={`primary`} key="back" icon={`login`} onClick={this.resumeAuthorization}>
                                    {I18N.t('RESUME')}
                                </Button>,
                            ] : null}
                        >
                            <Spin spinning={this.state.authLoading}>
                                {I18N.t('YOUR_AUTHORIZATION_EXPIRED')}
                            </Spin>
                        </Modal>
                        <Layout layout={Config.PROJECT.LAYOUT} user={this.user}/>
                    </AppProvider>
                )}
            </Spin>
        );
    }
}


export default withRouter(App);
