import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Modal } from 'antd';
import { t } from '../../../../library/i18n';
import * as PluginTabs from './tabs';


export default class InsertMedia extends React.Component {

    static defaultProps = {
        visible: false,
        closeModal: () => {},
        onInsert: () => {},
        onDeleteMedia: () => {},
        openModalEdit: () => {},
        media: [],
    };

    static propTypes = {
        visible: PropTypes.bool.isRequired,
        closeModal: PropTypes.func.isRequired,
        onInsert: PropTypes.func.isRequired,
        onDeleteMedia: PropTypes.func.isRequired,
        openModalEdit: PropTypes.func.isRequired,
        media: PropTypes.arrayOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeTab: `mgallery`,
            tabs: ['image', 'video', 'externalVideo', 'code'],
        };
    }

    onInsert = (data) => {
        this.props.onInsert(data);
        // this.props.editor.editorCommands.execCommand('mceInsertContent', false, GenerateMedia(data));
    };

    render() {
        const { mediaVideos, mediaTypesImage } = this.props;
        return (
            <div className={`plugin__insert-media`}>
                <Modal visible={this.props.visible} title={t('INSERT_PLUGIN')} width={1000} onOk={this.props.closeModal} onCancel={this.props.closeModal}>
                    <Tabs type='card' defaultActiveKey={this.state.activeTab} animated={false}>
                        {this.state.tabs.map(i => {
                            const RenderTabComponent = PluginTabs[i] || null;
                            return (
                                <Tabs.TabPane key={i} tab={t(`INSERT_${i}`)}>
                                    <RenderTabComponent
                                        onInsert={this.props.onInsert}
                                        media={this.props.media}
                                        onDeleteMedia={this.props.onDeleteMedia}
                                        openModalEdit={this.props.openModalEdit}
                                        mediaVideos={mediaVideos}
                                        mediaTypesImage={mediaTypesImage}
                                    />
                                </Tabs.TabPane>
                            );
                        })}
                    </Tabs>
                </Modal>
            </div>
        );
    }
}
