import React from 'react';
import Moment from 'moment';
import * as Actions from "./../store/push/actions";
import * as C from './../store/push/constant';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Config from './../config';
import FormCreate from './../components/push/form';
import {Spin, message, Table, Divider, Statistic} from 'antd';

const mapStateToProps = state => ({
    answer: state.PushReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class PushListController extends React.Component {
    state = {
        formLoading: false,
        clearForm: false,
        page: 1,
        total: 0,
        limit: 120,
        loading: false,
        list: [],
        stats: {
            total: 0,
            month: 0,
            week: 0,
            day: 0,
        }
    };

    columns = [
        {
            key: 'title',
            title: 'Заголовок',
            render: r => {
                return <a target={"_blank"} href={r.link} rel={`nofollow`}>{r.title}</a>
            },
        },
        {
            key: 'message',
            title: 'Сообщение',
            render: r => {
                return <a target={"_blank"} href={r.link} rel={`nofollow`}>{r.message}</a>
            },
        },
        {
            key: 'image',
            title: 'Изображение',
            render: r => {
                if (!r.image) return "";
                return <a target={"_blank"} href={r.image} rel={`nofollow`}><img src={r.image} alt={r.title} width={100}/></a>
            },
        },
        {
            key: 'delivered',
            title: 'Получено',
            align: 'center',
            render: r => r.views || 0
        },
        {
            key: 'opens',
            title: 'Открыто',
            align: 'center',
            render: r => r.clicks || 0
        },
        {
            key: 'createdAt',
            title: 'Отправлен',
            render: r=> Moment(r.createdAt).format(Config.DATE_FORMAT),
        },
        {
            key: 'topics',
            title: 'Топики',
            render: r=> {
                if (r.topics.length > 0) {
                    return r.topics.join('/');
                }
                return "Все";
            },
        }
    ];


    createMessage = (message) => {
       if (message.topics.length === 1 && message.topics[0] === "") {
           delete message.topics;
       }
       this.props.actions.AddPush(message);
    };

    loadList = () => {
        this.props.actions.LoadList(this.state.page)
    };

    componentDidMount() {
        this.loadList();
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.PUSHES_ADD_START:
                    this.setState({formLoading: true});
                    break;
                case C.PUSHES_ADD_ERROR:
                    message.error(`Ошибка добавления рассылки`);
                    this.setState({clearForm: true}, () => {
                        setTimeout(() => {
                            this.setState({ formLoading: false, clearForm: false });
                        }, 20);
                    });
                    break;
                case C.PUSHES_ADD_FINISH:
                    message.success(`Рассылка успешно отправлена`);
                    this.setState({clearForm: true}, () => {
                        setTimeout(() => {
                            this.setState({ formLoading: false, clearForm: false }, () => {
                                this.loadList();
                            });
                        }, 20);
                    });
                    break;
                case C.PUSHES_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.PUSHES_LOAD_FINISH:
                    let data = p.answer.data.data || {};
                    console.log(data.stats);
                    this.setState({
                        total: data.total || 0,
                        list: data.list || [],
                        loading: false,
                        stats: data.stats || {total: 0, week: 0, month: 0, day: 0},
                    });
                    break;
                case C.PUSHES_LOAD_ERROR:
                    message.error(`Ошибка загрузки уведомлений`);
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={`layout push`}>
                    <div className="push form">
                        <Spin spinning={this.state.formLoading}>
                            {this.state.clearForm === false && <FormCreate onCreate={this.createMessage}/>}
                        </Spin>
                    </div>
                </div>
                <div className="layout stats">
                    <Divider>Статистика подписавшихся</Divider>
                    <div className="push--stats__container">
                        <div className="push--stat">
                            <Statistic title={`Всего`} value={this.state.stats.total} />
                        </div>
                        <div className="push--stat">
                            <Statistic title={`30 дней`} value={this.state.stats.month} />
                        </div>
                        <div className="push--stat">
                            <Statistic title={`7 дней`} value={this.state.stats.week} />
                        </div>
                        <div className="push--stat">
                            <Statistic title={`24 часа`} value={this.state.stats.day} />
                        </div>
                    </div>
                </div>
                <div className={`layout push`}>
                    <Divider>Отправленные сообщения</Divider>
                    <Table
                        columns={this.columns}
                        loading={this.state.loading}
                        bordered
                        dataSource={this.state.list}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: this.state.limit,
                            defaultCurrent: this.state.page,
                            total: this.state.total,
                            onChange: (page) => {
                                this.setState({ page }, () => {
                                    this.loadList();
                                });
                            },
                            current: this.state.page,
                            hideOnSinglePage: true,
                            position:"both",
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export const PushController = connect(
    mapStateToProps,
    mapDispatchProps
)(PushListController);

export default PushController;

