import * as API from './api';
import * as C from './constants';

export function LoadChat(page = 1, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.CHAT_LOAD_START, C.CHAT_LOAD_FINISH, C.CHAT_LOAD_ERROR],
        promise: API.loadChat(page, filter)
    };
}

export function UpdateChatMessage(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.CHAT_UPDATE_START, C.CHAT_UPDATE_FINISH, C.CHAT_UPDATE_ERROR],
        promise: API.updateMessage(id, data)
    }
}
