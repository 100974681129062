import React from 'react';
import PropTypes from 'prop-types';
import {Form, Divider, Button, Select, Popconfirm, Modal, notification} from 'antd';
import * as FormComponents from './../form_components/index';
import {t} from '../../library/i18n';
import History from './../history';
import DocStatus from './../statuses/doc';
import Workflow from './../workflow';
import Config from "../../../config";
import PushForm from './../../../components/push/form';
import Moment from 'moment';


const FormGroup = (p) => {
    return (
        <React.Fragment>
            <Divider>{p.label}</Divider>
            {p.fields.map((i, index) => {
                const PComponent = FormComponents[i.component] || null;
                if (PComponent === null) {
                    console.error(`Component for field [${i.component}] not found`);
                    return null;
                }
                return (
                    <PComponent
                        {...i}
                        key={`form_component_${index}`}
                        decorator={p.decorator}
                        form={p.form}
                        item={p.item}
                        updateMedia={p.updateMedia}
                        onBlur={p.saveStash}
                    />
                );
            })}
        </React.Fragment>
    );
};



class FormGenerator extends React.Component {

    static defaultProps = {
        fields: [],
        form: {},
        showLock: false,
        showChatBtn: false,
        hideHistory: false,
        hideTypeChange: false,
        hideOptions: false,
        formLayout: {},
        onSubmit: () => {},
        onUpdate: () => {},
        onCreate: () => {},
        onPreview: () => {},
        updateStash: () => {},
        openChat: () => {},
        updateMaterialType: () => {},
        updateMedia: {
            delete: () => {},
            update: () => {},
        },
        isEdit: false,
    };

    static propTypes = {
        fields: PropTypes.arrayOf(Object).isRequired,
        form: PropTypes.instanceOf(Object).isRequired,
        onSubmit: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
        onPreview: PropTypes.func.isRequired,
        updateStash: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
        updateMedia: PropTypes.instanceOf(Object).isRequired,
        openChat: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPushModal: false,
        };
        this.intervalSave = 0;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };


    saveStash = () => {
        clearTimeout(this.intervalSave);
        this.intervalSave = setTimeout(() => {
            let fields = this.props.form.getFieldsValue();
            for (let i in fields) {
                if (fields[i] === undefined) {
                    delete fields[i];
                }
            }
            this.props.updateStash(fields);
        }, 200);
    };

    prepareMaterialLink = (item) => {
        if (item.published) {
            if (Config.WORKFLOW && Config.WORKFLOW.PREPARE_LINK) {
                let link = Config.WORKFLOW.PREPARE_LINK(item);
                return <a href={link} target={`_blank`}>{link}</a>;
            }
        }
        return "";
    };

    downloadDeveloperLog = () => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + JSON.stringify(window.documentUpdateHistory));
        element.setAttribute('download', `developer_log.json`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    checkPermissionDisplay = () => {
        if (this.props.item.type === 'Stop') {
            return false;
        }
        if (['Article', 'News', 'Meaning'].indexOf(this.props.item.type) + 1 === 0) {
            return true;
        }

        if(window.user.permissions.indexOf('ONLY_EDIT_NEWS') + 1 === 0) {
            return true;
        }
        return false;
    }

    canEdit = () => {
        if (['Article', 'News', 'Meaning'].indexOf(this.props.item.type) + 1 === 0) {
            return true;
        }
        if(window.user.permissions.indexOf('ONLY_EDIT_NEWS') + 1 === 0) {
            return true;
        }

        if (this.props.item.published === true && window.user.permissions.indexOf('ONLY_EDIT_NEWS') + 1 > 0) {
            return false;
        }

        return true;
    }

    publishMaterialsToRegions = () => {
        this.props.onPublishToRegions(this.props.item.id);
    }

    downloadRegionReport = () => {
        let host = window.location.hostname.indexOf('localhost') + 1 > 0 ? 'http://localhost:8086/api/backend' : 'https://emadm.vmdaily.ru/api/backend';
        window.open(`${host}/regions/${this.props.item.id}/report`);
    }




    render() {
        const Fields = this.props.fields || [];
        const {getFieldDecorator} = this.props.form;

        return (
            <div className={`form`}>
                <Modal visible={this.state.showPushModal} destroyOnClose={true} width={1024} title={t('SEND_PUSH')} onCancel={() => this.setState({showPushModal: false})} footer={null}>
                    <PushForm item={this.props.item} onCreate={(message) => {
                        this.setState({ showPushModal: false}, () => {
                            this.props.sendPush(message);
                        })
                    }}/>
                </Modal>
                <Form {...this.props.formLayout} onSubmit={this.onSubmit} onBlur={this.saveStash}>
                    {this.props.isEdit && (
                        <div className={`form__layout-edit`}>
                            <div className="form_container">
                                {Fields.map((p, index) => {
                                    if (p.component !== 'group') {
                                        const PComponent = FormComponents[p.component] || null;
                                        if (PComponent === null) {
                                            console.error(`Component for field [${p.component}] not found`);
                                            return null;
                                        }
                                        return (
                                            <PComponent
                                                {...p}
                                                key={`form_component_${index}`}
                                                decorator={getFieldDecorator}
                                                form={this.props.form}
                                                item={this.props.item}
                                                updateMedia={this.props.updateMedia}
                                                onBlur={this.saveStash}
                                            />
                                        );
                                    } else {
                                        return (
                                            <FormGroup
                                                {...p}
                                                key={`form_component_${index}`}
                                                decorator={getFieldDecorator}
                                                form={this.props.form}
                                                item={this.props.item}
                                                updateMedia={this.props.updateMedia}
                                                onBlur={this.saveStash}
                                            />
                                        );
                                    }
                                })}
                                {this.props.hideOptions && <div style={{maxWidth: 230}}><Button type={`primary`} htmlType={`submit`}>{t('UPDATE_DOCUMENT')}</Button></div>}
                            </div>

                            {this.props.hideOptions === false && (
                                <div className={`form__options--container`}>
                              
                                    <div className={`form__options--container--wrp`}>
                                        {!this.props.showLock && (
                                            <div className="actions">
                                                <div className="actions--row">
                                                    {this.canEdit() && (<Button type={`primary`} htmlType={`submit`}>{t('UPDATE_DOCUMENT')}</Button>)}
                                                    {this.props.showPreview && <Button type={`primary`} icon={'eye'} onClick={this.props.onPreview}/>}
                                                    {this.props.item.published && this.canEdit() && this.props.sendPush && <Button type={`primary`} onClick={() => {this.setState({showPushModal: true})}} icon={`notification`} />}
                                                </div>
                                                
                                                <div className="actions--row" style={{flexDirection: 'column', alignItems: 'center'}}>
                                                    {!this.props.item.prepublished && (
                                                        <>
                                                            {(this.props.item.published && this.checkPermissionDisplay()) && <Popconfirm title={t('UNPUBLISH_POPCONFIRM')} okText="Да" cancelText="Нет" onConfirm={() => {
                                                                let published = false;
                                                                this.props.onUpdate({ published, workflow: published === true ? "PUBLISH" : "DRAFT", redirectTo: this.props.item.redirectTo || "" });
                                                            }}><Button type={`danger`} icon={`stop`}>{t('UNPUBLISH_ACTION')}</Button></Popconfirm>}

                                                            {(!this.props.item.published && this.checkPermissionDisplay())  && <Popconfirm okText="Да" cancelText="Нет" onConfirm={() => {
                                                                let published = true;
                                                                let publishedAt = this.props.item.publishedAt || Moment(new Date());
                                                                this.props.onUpdate({ publishedAt, published, workflow: published === true ? "PUBLISH" : "DRAFT", redirectTo: this.props.item.redirectTo || "" });
                                                            }} title={t('PUBLISH_POPCONFIRM')}><Button type={`primary`} icon={`check`}>{t('PUBLISH_ACTION')}</Button></Popconfirm>}
                                                        </>
                                                    )}
                                                    {(!this.props.item.published && this.props.item.publishedAt && this.checkPermissionDisplay()) && (
                                                        <>
                                                            {this.props.item.prepublished && (<Popconfirm okText="Да" cancelText="Нет" onConfirm={() => {
                                                                let prepublished = false;
                                                                this.props.onUpdate({ prepublished, redirectTo: this.props.item.redirectTo || "" });
                                                            }} title={`Материал будет снять с запланированных`}>
                                                                <Button style={{margin: '10px 0 0 0'}} type={`danger`} icon={`clock-circle`}>Снять с запл.</Button>
                                                            </Popconfirm>)}
                                                            {!this.props.item.prepublished && (<Popconfirm okText="Да" cancelText="Нет" onConfirm={() => {
                                                                if (this.props.item.publishedAt) {
                                                                    let prepublished = true;
                                                                    this.props.onUpdate({
                                                                        prepublished,
                                                                        redirectTo: this.props.item.redirectTo || ""
                                                                    });
                                                                } else {
                                                                    notification.error({
                                                                        message: 'Невозможно запланировать материал',
                                                                        description: 'Укажите дату публикации у материала',
                                                                    });
                                                                }
                                                            }} title={`Материал будет опубликован к указаному времени (максимальная задержка публикации 3 минуты)`}>
                                                                <Button style={{margin: '10px 0 0 0'}} type={`default`} icon={`clock-circle`}>Запланировать</Button>
                                                            </Popconfirm>)}
                                                        </>
                                                    )}
                                                </div>
                                                
                                            </div>
                                        )}
                                        {Config.WORKFLOW && Config.WORKFLOW.SHOW_LINK && this.prepareMaterialLink(this.props.item)}
                                        {window.user && window.user.permissions && window.user.permissions.indexOf('DEVELOPER_LOG') + 1 > 0 && (
                                            <div className={`developer__log`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '10px 0'}}>
                                                <Button type={`primary`} onClick={this.downloadDeveloperLog}>{t("DOWNLOAD_DEVELOPER_LOG")}</Button>
                                            </div>
                                        )}
                                        
                                        {this.props.showChatBtn && (
                                            <div className="options--row chat--row" style={{textAlign: 'center'}}>
                                                <Button onClick={this.props.openChat} type={`primary`} icon={`message`}>{t('CHAT')}</Button>
                                            </div>
                                        )}

                                        <div className="options--row statuses-row">
                                            <DocStatus {...this.props.item}/>
                                        </div>

                                        
                                        {!this.props.hideTypeChange && Config.DOCUMENTS && Config.DOCUMENTS.MOVE_TYPES && (
                                            <div className="options--row statuses-row">
                                                <Divider>{t('MOVE_TYPE')}</Divider>
                                                <Select defaultValue={this.props.item ? this.props.item.type : ""} onChange={(type) => {
                                                    this.props.updateMaterialType(type);
                                                }}>
                                                    {Config.DOCUMENTS.MOVE_TYPES.map(i => {
                                                        return <Select.Option value={i} key={`type_move_${i}`}>{t(i)}</Select.Option>
                                                    })}
                                                </Select>
                                            </div>
                                        )}
                                        {!this.props.showLock && (Config.WORKFLOW && Config.WORKFLOW.LOCK_FOR && this.props.item && this.props.item.type && Config.WORKFLOW.LOCK_FOR.indexOf(this.props.item.type.toLowerCase()) + 1 > 0) && (<Workflow item={this.props.item} onUpdate={this.props.onUpdate}/>)}
                                        {!this.props.hideHistory && (<div className={`options--row history--row`}>
                                            {this.props.loading === false && <History item={this.props.item} updateItem={this.props.updateItem}/>}
                                        </div>)}
                                        
                                    </div>
                                    </div>
                                )}
                        </div>
                    )}

                </Form>
            </div>
        );
    }
}

export default Form.create({name: 'form_generator'})(FormGenerator);
