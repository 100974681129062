import React from 'react';
import PropTypes from 'prop-types';
import {Button, Upload, Icon, message, Spin} from 'antd';
import { t } from './../../../../../library/i18n';
import MediaList from './image/index';
import User from "../../../../../user";
import Config from "../../../../../../config";
import UploadForm from "../../../media/upload_form";
import List from './image/list';

export class VideoTab extends React.Component {
    static defaultProps = {
        onInsert: () => {},
        onDeleteMedia: () => {},
        openModalEdit: () => {},
    };

    static propTypes = {
        onInsert: PropTypes.func.isRequired,
        onDeleteMedia: PropTypes.func.isRequired,
        openModalEdit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showUpload: false,
            files: [],
            uploading: false,
            showList: false,
        };
    }
    componentDidMount() {
    }

    deleteFiles = () => {
        this.setState({ files: [], showUpload: true});
    };

    uploadFile = (values) => {
        let user = new User();
        let form = new FormData();

        Object.keys(values).map(i => {
            if(values[i] !== undefined) {
                if (typeof values[i] === "object") {
                    Object.keys(values[i]).map(j => {
                        if(values[i][j] !== undefined) {
                            form.append(`${i}.${j}`, values[i][j]);
                        }
                        return j;
                    });
                } else {
                    form.append(i, values[i]);
                }
            }
            return i;
        });
        form.append('type', this.props.mediaVideos || 'Video');
        form.append("files", this.state.files[0]);

        this.setState({ uploading: true }, () => {
            fetch(Config.UPLOAD.path, {
                method: "POST",
                headers: {
                    // "Content-type": "multipart/form-data",
                    "Authorization": user.getAccessToken(),
                },
                body: form,
            }).then(data => data.json()).then(data => {
                message.success(t('FILE_UPLOADED_SUCCESS'));
                setTimeout(() => {
                    this.setState({ files: [], showUpload: false, uploading: false})
                }, 1000);
            }).catch(e => {
                message.error(t('ERROR_UPLOAD_FILE'));
                console.error(e);
                setTimeout(() => {
                    this.setState({ files: [], showUpload: false, uploading: false})
                }, 1000);
            });
        });

    };

    onInsert = (media) => {
        this.setState({
            showList: false,
        }, () => {
            this.props.onInsert(media);
        });
    };

    render() {
        return (
            <div className={`insert--image_plg`}>
                <div className={`actions`}>
                    <Button.Group>
                        {!this.state.showUpload && (<Button type={`primary`} icon={`upload`} onClick={() => this.setState({showUpload: true, files: []})}>{t(`UPLOAD`)}</Button>)}
                        {(this.state.showUpload || this.state.showList)&& (<Button type={`default`} icon={`close`} onClick={() => this.setState({showUpload: false,showList:false, files: []})}>{t(`CLOSE`)}</Button>)}
                        {this.state.showList === false && (<Button type={`primary`} icon={`picture`} onClick={() => this.setState({showList: true, files: []})}>{t(`SELECT_MEDIA`)}</Button>)}
                    </Button.Group>
                </div>

                {(this.state.showUpload && this.state.files.length === 0) && (
                    <div className={`uploader`}>
                        <Upload.Dragger
                            accept={"video/mp4, video/webm, video/quicktime"}
                            beforeUpload={(file) => {
                                this.setState(state => ({
                                    files: [...state.files, file],
                                }));
                                return false;
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">{t('UPLOAD_TEXT')}</p>
                        </Upload.Dragger>
                    </div>
                )}
                {this.state.showUpload === false && this.state.showList === false && (
                    <List
                        onDeleteMedia={this.props.onDeleteMedia}
                        openModalEdit={this.props.openModalEdit}
                        onInsert={this.onInsert}
                        media={this.props.media}
                        type={window.location.pathname.indexOf('regionmat') + 1 > 0 ? 'RegionVideo' : 'Video'}
                        mediaVideos={this.props.mediaVideos}
                    />
                )}
                {!this.state.showUpload && this.state.showList && (
                    <div className={`media--list`}>
                        <MediaList onInsert={this.onInsert} type={window.location.pathname.indexOf('regionmat') + 1 > 0 ? 'RegionVideo' : 'Video'} limit={9}/>
                    </div>
                )}
                {this.state.files.length > 0 && (
                    <Spin spinning={this.state.uploading}>
                        <UploadForm file={this.state.files[0]} deleteFile={this.deleteFiles} uploadFile={this.uploadFile}/>
                    </Spin>
                )}
            </div>
        );
    }
}

export default VideoTab;
