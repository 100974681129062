export const buildCopyright = ({ name }) => {
	if (name.indexOf('LSA') === 0) {
		return `Сергей Лантюхов / Абзац`;
	}
	if (name.indexOf('DUB') === 0) {
		return `Дмитрий Дубинский / Абзац`;
	}
	return '';
}

export const getUnique = (arr = []) => {
	let data = [];
	arr.forEach(i => {
		if (data.indexOf(i) + 1 === 0) {
			data.push(i);
		}
	});
	return data;
}