import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Switch, Transfer, Select } from 'antd';
import Config from './../../config';
import { t } from './../../core/library/i18n';

class GroupAddForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPermission: this.props.item && this.props.item.permissions ? this.props.item.permissions : [],
            permissionsError: false,
            alias: '',
        };
    }

    transferHandleChange = (selectedPermission) => {
        this.setState({ selectedPermission });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err === null) {
                let item = {...values, ...{permissions: this.state.selectedPermission}};
                this.props.onSubmit(item);
            }
        });
    };

    renderItem = (item) => {
        return {
            label: `${item.title} [${item.code}]`,
            value: item.id,
        };
    };

    componentDidMount() {
        this.props.onMounted();
    }

    componentWillReceiveProps(p) {
        this.setState({
            selectedPermission: p.item && p.item.permissions ? p.item.permissions : [],
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const permissions = this.props.permissions.map(i => {
            return {...i, ...{key: i.id}};
        });
        const item = this.props.item;
        return (
            <React.Fragment>
                <div className="form groups--form">
                    <Form onSubmit={this.onSubmit}>
                        <Form.Item label={"Title"}>
                            {getFieldDecorator('title', {
                                initialValue: item.title || "",
                                rules: [{ required: true, message: 'Please input group title' }],
                            })(
                                <Input placholder={"Fill group title"}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"Description"}>
                            {getFieldDecorator('description', {
                                initialValue: item.description || "",
                                rules: [{ required: true, message: 'Please input group description' }],
                            })(
                                <Input.TextArea placholder={"Fill group description"}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"Default group"}>
                            {getFieldDecorator('default', {
                                valuePropName: 'checked',
                                initialValue: item.default || false,
                            })(
                                <Switch/>
                            )}
                        </Form.Item>
                        <Form.Item label={"Group active"}>
                            {getFieldDecorator('active', {
                                valuePropName: 'checked',
                                initialValue: item.default || true,
                            })(
                                <Switch/>
                            )}
                        </Form.Item>
                        {Config.WORKFLOW && Config.WORKFLOW.STATUSES && (
                            <Form.Item label={t(`WRK_STATUSES`)}>
                                {getFieldDecorator('workflow', {
                                    initialValue: item.workflow || [],
                                })(
                                    <Select placeholder={t('SELECT_WORKFLOW')} mode={'multiple'} allowClear={true}>
                                        <Select.Option value={""}>All</Select.Option>
                                        {Config.WORKFLOW.STATUSES.map(i => {
                                            return <Select.Option value={i} key={`status_${i}`}>{t(i)}</Select.Option>;
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        )}
                        <Form.Item label={"Permissions"}>
                            <Transfer
                                showSearch
                                titles={['Available', 'Selected']}
                                dataSource={permissions}
                                targetKeys={this.state.selectedPermission}
                                render={this.renderItem}
                                onChange={this.transferHandleChange}
                                listStyle={{
                                    width: 300,
                                    height: 300,
                                }}
                            />
                        </Form.Item>
                        <Button htmlType={"submit"} type={"primary"}>{item && item.id ? `Update group` : `Create new group`}</Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

GroupAddForm.defaultProps = {
    form: {},
    item: {},
    permissions: [],
    onSubmit: () => {},
    onMounted: () => {},
};

GroupAddForm.propTypes = {
    form: PropTypes.instanceOf(Object).isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    permissions: PropTypes.arrayOf(Object).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const AddRoleForm = Form.create({name: 'form_role'})(GroupAddForm);

export default AddRoleForm;
