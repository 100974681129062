import React from 'react';
import {Upload, Icon, message, Progress} from 'antd';
import {t} from './../../../library/i18n';
import Config from './../../../../config';
import User from './../../../user/index';
import axios from 'axios';

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            progress: 0,
            loading: false,
        }
    }


    deleteFiles = () => {
        this.setState({files: []});
    };

    uploadFile = (values) => {
        let user = new User();
        let form = new FormData();


        Object.keys(values).map(i => {
            if (values[i] !== undefined) {
                if (typeof values[i] === "object") {
                    Object.keys(values[i]).map(j => {
                        if (values[i][j] !== undefined) {
                            form.append(`${i}.${j}`, values[i][j]);
                        }
                        return j;
                    });
                } else {
                    form.append(i, values[i]);
                }
            }
            return i;
        });

        
        if (this.state.files[0].size <= 1073741824) {

            form.append('type', this.props.type);
            form.append("files", this.state.files[0]);

            this.setState({loading: true, progress: 0}, () => {
                axios.post(Config.UPLOAD.path, form, {
                    headers: {
                        "Authorization": user.getAccessToken(),
                    },
                    onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        this.setState({progress: percentCompleted});
                      }
                }).then(res => {
                    message.success(t('FILE_UPLOADED_SUCCESS'));
                    this.props.selectMedia(res.data)
                }).catch(e => {
                    message.error(t('ERROR_UPLOAD_FILE'));
                    console.error(e)
                });
            })


            // fetch(Config.UPLOAD.path, {
            //     method: "POST",
            //     headers: {
            //         // "Content-type": "multipart/form-data",
            //         "Authorization": user.getAccessToken(),
            //     },
            //     body: form,
            // }).then(data => data.json()).then(data => {
                // message.success(t('FILE_UPLOADED_SUCCESS'));
                // this.props.selectMedia(data)
            // }).catch(e => {
                // message.error(t('ERROR_UPLOAD_FILE'));
                // console.error(e)
            // });
        } else {
            alert('File is too large');
            this.setState({ files: [] });
        }
    };


    render() {
        return (
            <div className="upload--input">
                <p>Аудио подкаста</p>
                <Upload.Dragger accept=".mp3" beforeUpload={(file) => {
                    this.setState(state => ({
                        files: [...state.files, file],
                    }),() => {
                        this.uploadFile({});
                    });
                    return false;
                }}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox"/>
                    </p>
                    <p className="ant-upload-text">{t('UPLOAD_TEXT')}</p>
                </Upload.Dragger>
                {this.state.loading && (
                    <div className='upload-progress'>
                        <Progress type="circle" percent={this.state.progress} />
                    </div>
                )}
            </div>
        );
    }
}
