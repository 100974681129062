import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spin, notification } from 'antd';
import * as Actions from "./../../../store/groups/actions";
import * as C from './../../../store/groups/constants';
import propExists from './../../../core/library/objects/getprop';
import GroupsAddForm from './../../../components/forms/group_add';
import {AppConsumer} from './../../../context/app';
import {t} from "../../../core/library/i18n";

const mapStateToProps = state => ({
    answer: state.GroupsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class AddRoleController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: propExists(this.props, 'match.params.id') ? this.props.match.params.id : false,
            isLoading: false,
            permissions: [],
            item: {},
        };
    }

    onSubmitHandle = (item) => {
        if (this.state.id === false) {
            this.props.actions.createGroup(item);
        } else {
            let stateItem = {...this.state.item, ...item};
            this.setState({ item: stateItem }, () => {
                this.props.actions.updateGroup(this.state.id, item);
            });
        }
    };

    componentDidMount() {
        this.props.actions.LoadPermissions();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.PERMISSIONS_START_LOAD:
                case C.GROUPS_CREATE_START:
                case C.GROUPS_LOAD_START:
                    this.setState({
                        isLoading: true,
                    });
                    break;

                case C.PERMISSIONS_FINISH_LOAD:
                    this.setState({
                        permissions: propExists(p.answer.data, "data.list") ? p.answer.data.data.list : [],
                        isLoading: this.state.id !== false,
                    }, () => {
                        if (this.state.id !== false) {
                            this.props.actions.loadGroup(this.state.id);
                        }
                    });
                    break;

                case C.GROUPS_LOAD_FINISH:
                    this.setState({
                        item: propExists(p.answer.data, "data") ? p.answer.data.data : {},
                        isLoading: false,
                    });
                    break;
                case C.GROUPS_CREATE_FINISH:
                    notification.success({
                       message: `Group has been created`,
                       delay: 5
                    });
                    window.location.href = `/users/groups/`;
                    break;

                case C.PERMISSIONS_ERROR_LOAD:
                    notification.error({
                        message: `Error load permissions list`,
                    });
                    break;
                case C.GROUPS_CREATE_ERROR:
                    notification.error({
                        message: `Error create group`,
                    });
                    this.setState({isLoading: false});
                    break;
                default: break;
            }
        }
    }


    render() {
        return (
            <React.Fragment>
                <Spin spinning={this.state.isLoading}>
                    <div className={`group-add`}>
                        <AppConsumer>
                            {context => {
                                return (
                                    <GroupsAddForm
                                        onMounted={() => {
                                            if (context.actions.updateHeader) {
                                                context.actions.updateHeader({
                                                    title: this.state.id === false ? t(`CREATE_NEW_GROUP`) : `${t('EDIT_GROUP')}`,
                                                    showBack: true,
                                                })
                                            }
                                        }}
                                        onSubmit={this.onSubmitHandle}
                                        permissions={this.state.permissions}
                                        item={this.state.item}
                                    />
                                );
                            }}

                        </AppConsumer>
                    </div>
                </Spin>
            </React.Fragment>
        );
    }
}




AddRoleController.defaultProps = {
    actions: {},
    answer: {},
    form: {},
};

AddRoleController.propTypes = {
    actions: PropTypes.instanceOf(Object).isRequired,
    answer: PropTypes.instanceOf(Object).isRequired,
    form: PropTypes.instanceOf(Object).isRequired,
};

export const AddGroup =  connect(
    mapStateToProps,
    mapDispatchProps
)(AddRoleController);

export default AddGroup;
