import React from 'react';
import { Modal, Button, Spin } from 'antd';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Config from "../../config";


export default class Cropper extends React.Component {
    static defaultProps = {
        visible: false,
        fileType: 'image/jpeg',
        name: 'cover.jpg',
        image: null,
        onClose: () => {},
    }

    state = {
        crop: { aspect: 6 / 4, width: Config.CROPPER.WIDTH, height:Config.CROPPER.HEIGHT, minWidth: Config.CROPPER.WIDTH, minHeight: Config.CROPPER.HEIGHT, maxWidth: 1280, maxHeight: 768 },
        loading: false,
        image: null,
        cWidth: Config.CROPPER.WIDTH
    };

    onChange = (crop) => {
        this.setState({ crop });
    }



    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                if (!file) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                file.name = fileName;
                const base64Image = canvas.toDataURL(this.props.fileType)
                console.log(fileName, this.props.fileType);
                resolve({file , base64Image });
            }, this.props.fileType);
        });
    }


    onFinish = () => {
        this.setState({ loading: true }, async () => {
            let d = await this.getCroppedImg(this.imageRef, this.state.crop, this.props.name);
            this.props.onFinish(d.file, d.base64Image);
            this.setState({ loading: false })
        })
    }

    onImageLoaded = (image) => {
        this.imageRef = image;
        this.setState({
            cWidth: image.naturalWidth > 1280 ? 1280 : image.naturalWidth,
        })
    }

    render() {
        const { visible, onClose, image } = this.props;
        const { crop, loading, cWidth } = this.state;
        return (
            <Modal visible={visible} title={`Кадрирование изображения`} footer={null} destroyOnClose={true} onCancel={onClose} width={1280}>
                <Spin spinning={loading}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', maxWidth: cWidth, width: '100%', margin: '0 auto'}}>
                        <ReactCrop
                            src={image}
                            crop={crop}
                            onChange={this.onChange}
                            onImageLoaded={this.onImageLoaded}
                        />
                    </div>
                </Spin>
                <div className="actions" style={{marginTop: 12}}>
                    <Button type={`primary`} onClick={this.onFinish}>Сохранить</Button>
                </div>
            </Modal>
        );
    }
}
