import React from 'react';
import { Form, Divider, Switch, Button } from 'antd';
import PropTypes from 'prop-types';

class WidgetsFilterClass extends React.Component {

    static defaultProps = {
        widgets: [],
        onSubmit: (data) => {},
    };

    static propTypes = {
        widgets: PropTypes.arrayOf(Object).isRequired,
        selected_widgets: PropTypes.arrayOf(PropTypes.string).isRequired,
        addWidget: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };


    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const WidgetsList = this.props.widgets.map(i => {
            return {
                value: i.id,
                label: i.title,
            };
        });
        return (
            <Form onSubmit={this.onSubmit}>
                <Button type={`primary`} onClick={this.props.onUpdatePrev}>{this.props.prev ? `Сегодня` : 'Вчера'}</Button>
                <Divider>Виджеты: </Divider>
                <div className="widgets--list">
                    {WidgetsList.map(i => {
                        return (
                            <div className={`widget--item`} key={`item_${i.value}`}>
                                <Switch defaultChecked={this.props.selected_widgets.indexOf(i.value) + 1 > 0} onChange={(s) => {
                                    this.props.addWidget(i.value);
                                }}/>
                                <div className="label">{i.label}</div>
                            </div>
                        );
                    })}
                </div>
            </Form>
        );
    }
}
const WidgetsFilter = Form.create({ name: 'WidgetsFilter' })(WidgetsFilterClass);

export default WidgetsFilter;
