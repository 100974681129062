import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spin, Button, Pagination, Tooltip, Popconfirm } from 'antd';
import {t} from "../../../core/library/i18n";
import Store from './../../../core/library/store';
import { MediaItem } from './item';
import { GalleryFilter } from './filter';

const STORE_VIEW_KEY = 'mgview';



export class MediaList extends React.Component {

    static defaultProps = {
        page: 0,
        total: 0,
        loading: false,
        error: false,
        list: [],
        filter: {},
        onPageChange: (page) => {},
        reloadList: () => {},
        onDelete: (id = '') => {},
        onUpdate: (id = '', data = {}) => {},
        onFilter: (filter = {}) => {},
    };

    static propTypes = {
        page: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        list: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
        onPageChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired,
        filter: PropTypes.instanceOf(Object).isRequired,
    };


    constructor(props) {
        super(props);
        let view = Store.get(STORE_VIEW_KEY) || "card";
        this.state = {
            view,
            removeMode: false,
            deleteList: [],
        };
    }

    addToDelete = (id) => {
        if (this.state.deleteList.indexOf(id) + 1 === 0) {
            this.setState({ deleteList: [...this.state.deleteList, id] });
        }
    }

    removeFromDelete = (id) => {
        let index = this.state.deleteList.indexOf(id);
        if (index > -1) {
            let deleteList = [...this.state.deleteList];
            deleteList.splice(index, 1);
            this.setState({ deleteList });
        }

    }

    changeView = (view) => {
        this.setState({ view }, () => {
            Store.set(STORE_VIEW_KEY, view);
        });
    }

    componentDidMount() {
        window.$().fancybox({
            selector: '.fimage',
            buttons: ["zoom", "fullscreen", "thumbs", "close"],
            thumbs: {
              autoStart: false,
              hideOnClose: true,
              axis: "y",
            },
            youtube: {
                controls: 0,
                showinfo: 0
            },
            vimeo: {
                color: 'f00'
            }
        });
    }

    render() {
        const CAN_HIDE = window.user && window.user.permissions && window.user.permissions.indexOf('HIDE_MEDIA') + 1 > 0;
        return (
            <div className={`mg-list`}>
                <Spin spinning={this.props.loading} tip={t('LOADING')}>
                    {this.props.error && <Alert
                        message={t("MEDIAGALLERY_LOADING_ERROR_MESSAGE")}
                        description={
                            <React.Fragment>
                                <p>{t('MEDIAGALLERY_LOADING_ERROR_DESCRIPTION')}</p>
                                <Button
                                    type={`primary`}
                                    icon={`reload`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.reloadList();
                                    }}
                                >
                                    {t('MEDIAGALLERY_RELOAD')}
                                </Button>
                            </React.Fragment>
                        }
                        type="error"
                    />}
                    {this.props.error === false && (
                        <React.Fragment>
                            <div className="actions">
                                <div className="actions--row">
                                    <GalleryFilter
                                        filter={this.props.filter}
                                        onFilter={this.props.onFilter}
                                    />
                                </div>
                                <div className="actions--row">
                                    <div className="pagination" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                                        <div style={{display: 'flex', flexDirection: 'row',}}>
                                            <div className="views">
                                                {this.state.deleteList.length > 0 && (
                                                    <Tooltip title={`Режим группового удаления`}>
                                                        <Popconfirm onConfirm={() => {
                                                            this.props.groupsDeleteImages([...this.state.deleteList]);
                                                            this.setState({deleteList: []});
                                                        }} title={`Вы уверены, что хотите выполнить удаление`} okText={`да`} cancelText={`нет`}>
                                                            <Button
                                                                icon={"delete"}
                                                                type={`danger`}
                                                            >Групповое удаление ({this.state.deleteList.length})</Button>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                )}
                                              
                                                <Button
                                                    icon={"appstore"}
                                                    type={this.state.view === 'card' ? `primary` : `default`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.changeView('card');
                                                    }}
                                                />
                                                <Button
                                                    icon={"unordered-list"}
                                                    type={this.state.view === 'list' ? `primary` : `default`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.changeView('list');
                                                    }}
                                                />
                                            </div>
                                            
                                        </div>
                                        <Pagination
                                            onChange={this.props.onPageChange}
                                            total={this.props.total}
                                            pageSize={this.props.limit}
                                            defaultCurrent={this.props.page}
                                            current={this.props.page}
                                            hideOnSinglePage={true}
                                            showTotal={(total) => `${total} ${t('MEDIAGALLERY_OBJECT')}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`list list__${this.state.view}`}>
                                {this.props.list.map(i => {
                                    return (
                                        <MediaItem
                                            key={`media_${i.id}`}
                                            {...i}
                                            view={this.state.view}
                                            CAN_HIDE={CAN_HIDE}
                                            isCanDropImages={this.props.isCanDropImages}
                                            onDelete={this.props.onDelete}
                                            onDeleteForever={this.props.onDeleteForever}
                                            onUpdate={this.props.onUpdate}
                                            toggleMediaBookmark={this.props.toggleMediaBookmark}
                                            showMaterialModal={this.props.showMaterialModal}
                                            addToDelete={this.addToDelete}
                                            removeFromDelete={this.removeFromDelete}
                                            deleteList={this.state.deleteList}
                                        />
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    )}
                </Spin>
            </div>
        );
    }
}
