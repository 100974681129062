import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import UsersFilter from './../filters/users';


export default class UsersTable extends React.Component {
    Columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            render: row => {
                return <a href={`mailto:${row}`} rel="nofollow">{row}</a>;
            }
        },
        {
            title: 'Name',
            key: 'name',
            render: row => `${row.firstname} ${row.lastname}`,
        },
       
        {
            title: 'Actions',
            key: 'column_actions',
            render: row => {
                return (
                    <React.Fragment>
                        <Button.Group>
                        <Button
                            htmlType={"button"}
                            type={"primary"}
                            icon={"edit"}
                            loading={this.props.btnLoading}
                            onClick={() => this.props.onEditUser(row.id)}
                        >
                           Редактировать
                        </Button>
                        <Button
                            htmlType={"button"}
                            icon={"user"}
                            loading={this.props.btnLoading}
                            onClick={() => {
                                this.props.onDeleteUser(row.id);
                            }}
                        >
                            Удалить
                        </Button>
                        </Button.Group>
                    </React.Fragment>
                )
            },
        }
    ];

    componentDidMount() {
        if (this.props.onMounted) {
            this.props.onMounted();
        }
    }

    onFilterSubmit = (search) => {
        this.props.onUpdateFilter({search});
    };

    render() {

        return (
            <React.Fragment>
                <Table
                    title={() => {
                        return (
                            <div className='users__table-header'>
                                <Button type={`primary`} onClick={() => this.props.onCreateUser()}>Создать</Button>
                                <UsersFilter
                                    onSubmit={this.onFilterSubmit}
                                    filter={this.props.filter}
                                />
                            </div>
                        );
                    }}
                    loading={this.props.loading}
                    bordered
                    columns={this.Columns}
                    pagination={{
                        pageSize: this.props.limit,
                        defaultCurrent: this.props.page,
                        total: this.props.total,
                        onChange: this.props.onPageChange,
                        current: this.props.page,
                    }}
                    dataSource={this.props.list}
                    rowKey={(item) => item.id}
                />
            </React.Fragment>
        );
    }
}

UsersTable.defaultProps = {
    list: [],
    page: 0,
    total: 0,
    limit: 0,
    filter: {},
    loading: false,
    onPageChange: () => {},
    onUpdateFilter: () => {},
    onEditUser: () => {},
};

UsersTable.propTypes = {
    filter: PropTypes.instanceOf(Object).isRequired,
    list: PropTypes.arrayOf(Object).isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onEditUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

