import React from 'react';
import {Button} from "antd";
import insertHTML from './../../../../../library/hacks/inject';
import { t } from './../../../../../library/i18n';

export default class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // let doc = document.getElementById(`code_${this.props.id}`);
        // doc.innerHTML = this.props.embed;
        // let scripts = doc.getElementsByTagName('script');
        insertHTML(this.props.item.embed, document.getElementById(`code_${this.props.item.id}`), false);
    }

    render() {
        return (
            <div className={"code--list_item"}>
                <div className="code--actions">
                    <Button type={`primary`} icon={`plus`} onClick={() => {
                        this.props.onInsert(this.props.item)
                    }}>{t('INSERT')}</Button>
                    <Button type={`primary`} icon={`edit`} onClick={() => {
                        this.props.onEdit(this.props.item)
                    }}/>
                    <Button type={`danger`} icon={`delete`} onClick={() => {
                        this.props.onDeleteMedia(this.props.item.id)
                    }}/>
                </div>
                <div className="code--preview" id={`code_${this.props.item.id}`}/>
            </div>
        );
    }
}
