import React from 'react';
import {Button, message} from "antd";
import {t} from "../../../../../library/i18n";
import CodeForm from './../../../media/codeForm';
import {createMedia} from './../../../../../../store/media/api';
import CodeItem from './code_item';

export class Code extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
        };
    }

    onSubmit = (values) => {
        values.type = 'Code';
        values.files = {
            'original': {
                width: values.options.width,
                height: values.options.height,
            }
        };
        delete values.options;
        createMedia(values).then(data => {
            if (data && data.data) {
                this.setState({ showForm: false }, () => {
                    this.props.onInsert(data.data);
                });
            }
        }).catch(e => {
            console.error(`error create code: `, e);
            message.error(t(`ERROR_CREATE_CODE`));
        });
    };

    render() {
        const media = this.props.media || [];
        const mediaList = media.filter(i => i.type === "Code");
        return (
            <div className={`insert--image_plg`}>
                <div className={`actions`}>
                    <Button.Group>
                        {this.state.showForm === false && <Button icon={`plus`} type={`primary`} onClick={() => {
                            this.setState({showForm: true})
                        }}>{t('ADD')}</Button>}
                        {this.state.showForm && <Button onClick={() => {
                            this.setState({showForm: false})
                        }}>{t('CLOSE')}</Button>}
                    </Button.Group>
                    {this.state.showForm && <CodeForm onSubmit={this.onSubmit}/>}
                    <div className={`code--list`}>
                        {mediaList.map(i => {
                            return (
                                <CodeItem
                                    key={`item_${i.id}`}
                                    item={i}
                                    onDeleteMedia={this.props.onDeleteMedia}
                                    onInsert={this.props.onInsert}
                                    onEdit={this.props.openModalEdit}
                                />);
                        })}

                    </div>
                </div>
            </div>
        );
    }
}

export default Code;
