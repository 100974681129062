import React from 'react';
import { Form, Switch, DatePicker } from 'antd';
import { t } from './../../library/i18n';
import Moment from "moment";

export class SwitchAnons extends React.Component {

    state = {
        displayInput: false,
    };

    componentWillReceiveProps(p) {
        if (p && p.initialValue) {
            this.setState({ displayInput: true });
        }
    }

    render() {
        const p = this.props;
        return (
            <React.Fragment>
                <Form.Item label={p.label || ""}>
                    <Switch checked={this.state.displayInput} onChange={(displayInput) => {
                        this.setState({displayInput});
                    }}/>
                </Form.Item>
                {this.state.displayInput && (
                    <Form.Item label={t('ANONS_DATE')}>
                        {p.decorator(p.name, {
                            initialValue: p.initialValue ? Moment(p.initialValue) : false,
                        })(<DatePicker {...p.props} />)}
                    </Form.Item>
                )}
            </React.Fragment>
        );
    }
}

// export const SwitchAnons = (p) => {
//     return (
//         <Form.Item label={p.label || ""}>
//             <Switch />
//             {/*{p.decorator(p.name, {*/}
//             {/*    valuePropName: 'checked',*/}
//             {/*    initialValue: p.initialValue || false,*/}
//             {/*})(<Switch {...p.props}/>)}*/}
//         </Form.Item>
//     );
// };
