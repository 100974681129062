import React from 'react';
import { message, Table, Button } from 'antd';
import * as Actions from "./../../store/reports/actions";
import * as C from './../../store/reports/constants';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Moment from 'moment';
import Config from './../../config';


const mapStateToProps = state => ({
    answer: state.ReportsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class ViewBudgetController extends React.Component {

    columns = [
        {
            title: 'Номер',
            key: 'number',
            render: (r, d, index) => {
                return index + 1;
            }
        },
        {
            title: 'Дата',
            key: 'date',
            render: r => {
                if (r.doc_id ) {
                    return Moment(r.doc_id.createdAt).format('YYYY-MM-DD HH:mm:ss');
                }
                return '';
            },
        },
        {
            title: 'Автор',
            key: 'author',
            render: r => {
                if (r.author_id ) {
                    return <Link to={`/documents/${r.author_id.type.toLowerCase()}/${r.author_id.id}`}>{r.author_id.title}</Link>;
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Роль',
            key: 'role',
            render: r => {
                let role = this.getRoleById(r.role_id);
                if (role !== false) {
                    return <Link to={`/documents/${role.type.toLowerCase()}/${role.id}`}>{role.title}</Link>;
                }
                return '';
            },
        },
        {
            title: 'Группа',
            key: 'group',
            render: r => {
                if (r.group_id) {
                    return `${r.group_id.options["views_from"]} - ${r.group_id.options["views_to"]}`;
                }
                return '';
            },
        },
        {
            title: 'Кол. авторов',
            dataIndex: 'authors_count'
        },
        {
            title: 'Просмотры',
            dataIndex: 'views_count'
        },
        {
            title: 'Гонорар',
            dataIndex: 'sum'
        },
        {
            title: 'Статья',
            key: 'doc',
            render: r => {

                if (r.doc_id) {
                    return <Link to={`/documents/${r.doc_id.type.toLowerCase()}/${r.doc_id.id}`}>{r.doc_id.title}</Link>;
                }
                return '';
            },
        },
        {
            title: 'Ссылка',
            key: 'link',
            render: r => {
                return <a href={`https://absatz.media${r.link}`} target={"_blank"}>{r.link}</a>
            },
        }

    ];

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : false,
            file: {},
            table: [],
            groups: [],
            author_total: [],
        };
    }

    getRoleById = (id) => {
        let role = this.state.groups.filter(i => i.id === id);
        return role && role[0] ? role[0] : false;
    }


    componentDidMount() {
        if (this.state.id === false) {
            message.error(`Не передан id отчета`);
        } else {
            this.props.actions.GetReport(this.state.id);
        }
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.REPORT_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.REPORT_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    let aTotal = [];

                    if (Object.keys(data.author_total).length > 0) {
                        aTotal = Object.keys(data.author_total).map(i => {
                            return data.author_total[i];
                        });
                    }

                    aTotal.sort((a, b) => {
                        return b.total_sum - a.total_sum;
                    });



                    this.setState({
                        loading: false,
                        groups: data.groups || [],
                        file: data.file || {},
                        table: data.table || [],
                        author_total: aTotal,
                    });
                    break;
                case C.REPORT_LOAD_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(`ошибка загрузки отчета`);
                    });
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={`report budget--view`} style={{margin: 12, background: '#FFF'}}>
                    <Table
                        size={`small`}
                        title={() => {
                            return (
                                <div>
                                    <Button icon={`cloud`} type={`primary`} onClick={() => {window.open(`${Config.PROJECT.API}/reports/table/${this.state.id}/download`)}}>Скачать отчет</Button>
                                    <Button style={{marginLeft: 10}} icon={`cloud`} type={`primary`} onClick={() => {window.open(`${Config.PROJECT.API}/reports/table/${this.state.id}/download_new`)}}>Скачать новый отчет</Button>
                                </div>
                            );
                        }}
                        bordered
                        loading={this.state.loading}
                        dataSource={this.state.table}
                        columns={this.columns}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: 100,
                            defaultCurrent: 1,
                            total: this.state.table.length,
                            hideOnSinglePage: true,
                            position: 'both',
                        }}
                    />
                </div>
                <div className={`report budget--view`} style={{margin: 12, background: '#FFF'}}>
                    <Table
                        size={`small`}
                        title={() => {
                            return <h4>Суммарно по авторам:</h4>
                        }}
                        bordered
                        loading={this.state.loading}
                        dataSource={this.state.author_total}
                        columns={[
                            {title: 'Автор', key: 'name', render: r => {
                                    return <Link to={`/documents/author/${r.id}`}>{r.name}</Link>;
                                }},
                            {title: 'Роль', key: 'role', render: r => {
                                    let role = this.getRoleById(r.role_id);
                                    if (role !== false) {
                                        return <Link to={`/documents/${role.type.toLowerCase()}/${role.id}`}>{role.title}</Link>;
                                    }
                                    return '';
                            }},
                            {title: 'Количество мат.', dataIndex: 'material_count'},
                            {title: 'Всего просмотров', dataIndex: 'total_views'},
                            {title: 'Гонорар', dataIndex: 'total_sum'},
                        ]}
                        rowKey={(item) => item.name}
                        pagination={{
                            pageSize: this.state.author_total.length,
                            defaultCurrent: 1,
                            total: this.state.author_total.length,
                            hideOnSinglePage: true,
                            position: 'both',
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}


export const ViewBudget = connect(
    mapStateToProps,
    mapDispatchProps
)(ViewBudgetController);

export default ViewBudget;
