import React from 'react';
import * as PreviewLayouts from './layouts';
import Config from './../../../config';



export class PreviewDefault extends React.Component {

    render() {
        const Component = PreviewLayouts[Config.PROJECT_PREFIX] ? PreviewLayouts[Config.PROJECT_PREFIX] : PreviewLayouts['default_view'];
        return (
            <React.Fragment>
                <Component item={this.props.item}/>
            </React.Fragment>
        );
    }
}

export default PreviewDefault;
