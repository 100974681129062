import React from 'react';
import { Tag } from 'antd';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";


const colors = {
    'pending': '#2db7f5',
    'process': '#f50',
    'finished': 'green',
    'error': 'red',
}

export class AddSTT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'upload',
                'type': 'STT',
                'label': t('STT_LABEL'),
                'name': 'media',
            },

        ];

    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {
            });
        }
    }

    onSubmit = (values) => {
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }

            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.redirectTo = values.redirectTo || "";
        values.media = media;

        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        const { stt_status } = this.props.item;
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <div className={`stt-info`} style={{background: "#FFF", marginBottom: 12, padding: 12,borderRadius: 4, boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)'}}>
                    <div className="st-column" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className="label" style={{fontWeight: 600, marginRight: 12}}>Статус</div>
                        {stt_status && <Tag color={colors[stt_status] || ''}>{t(`STT_${stt_status}`)}</Tag>}
                    </div>
                </div>
                <div className="st-result" style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
                    <div className="st-result-column" style={{flex: 1, flexDirection: 'column'}}>
                        <FormGenerator
                            showLock={this.props.showLock}
                            fields={this.Fields}
                            onSubmit={this.onSubmit}
                            onUpdate={this.props.onUpdate}
                            onPreview={this.props.onPreview}
                            isEdit={this.props.isEdit}
                            loading={this.props.loading}
                            item={this.props.item}
                            showPreview={false}
                            hideOptions={true}
                            updateStash={this.props.updateStash}
                            updateMedia={this.props.updateMedia}
                            updateItem={this.props.updateItem}
                            updateMaterialType={this.props.updateMaterialType}
                            sendPush={this.props.sendPush}
                        />
                    </div>
                    <div className="st-result-column" style={{flex: 1, flexDirection: 'column', background: '#FFF', padding: 12,borderRadius: 4, boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)'}}>
                        <h3>Результат: </h3>
                        <div className={`text-block`} style={{maxHeight: 400, overflow: 'scroll', whiteSpace: 'pre-wrap', lineHeight: 2,background: "rgba(0, 0, 0, 0.05)", padding: 12, borderRadius: 4}}>
                            {this.props.item.summary}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AddSTT;
