import React from 'react';
import PropTypes from 'prop-types';
import { t } from './../../../../../library/i18n';
import MediaList from './image/index';
import List from './image/list';
import {Button, message, Spin} from "antd";
import UploadForm from "../../../media/upload_form";
import { createMedia } from './../../../../../../store/media/api';
import ExternalForm from './../../../media/external';



export class ExternalVideo extends React.Component {
    static defaultProps = {
        onInsert: () => {},
        onDeleteMedia: () => {},
        openModalEdit: () => {},
    };

    static propTypes = {
        onInsert: PropTypes.func.isRequired,
        onDeleteMedia: PropTypes.func.isRequired,
        openModalEdit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showUpload: false,
            files: [],
            uploading: false,
            showList: false,
        };
    }
    componentDidMount() {
    }

    deleteFiles = () => {
        this.setState({ files: [], showUpload: true});
    };

    uploadFile = (values) => {
        values.type = 'ExternalVideo';
        values.files = {
            "original" : {
                path: values.options.path,
                width: values.options.width,
                height: values.options.height,
                mimeType: "iframe",
                size: 0,
                duration: 0,
            },
        };
        // values.provider = '';
        delete values.options;

        createMedia(values).then(data => {
            this.setState({
                showUpload: false,
            }, () => {
                if (data && data.data) {
                    this.props.onInsert(data.data);
                }
            });
        }).catch(e => {
            console.error(`error create media: `, e);
            message.error(t('ERROR_CREATE_EXTERNAL_VIDEO'));
        });
    };

    onInsert = (media) => {
        this.setState({
            showList: false,
        }, () => {
            this.props.onInsert(media);
        });
    };

    render() {
        return (
            <div className={`insert--image_plg`}>
                <div className={`actions`}>
                    <Button.Group>
                        {!this.state.showUpload && (<Button type={`primary`} icon={`upload`} onClick={() => this.setState({showUpload: true, files: []})}>{t(`UPLOAD`)}</Button>)}
                        {(this.state.showUpload || this.state.showList)&& (<Button type={`default`} icon={`close`} onClick={() => this.setState({showUpload: false,showList:false, files: []})}>{t(`CLOSE`)}</Button>)}
                        {this.state.showList === false && (<Button type={`primary`} icon={`picture`} onClick={() => this.setState({showList: true, files: []})}>{t(`SELECT_MEDIA`)}</Button>)}
                    </Button.Group>
                </div>

                {(this.state.showUpload && this.state.files.length === 0) && (
                    <div className={`uploader`}>
                        <ExternalForm onSubmit={this.uploadFile}/>
                    </div>
                )}

                {this.state.showUpload === false && this.state.showList === false && (
                    <List
                        onDeleteMedia={this.props.onDeleteMedia}
                        openModalEdit={this.props.openModalEdit}
                        onInsert={this.onInsert}
                        media={this.props.media}
                        type={"ExternalVideo"}
                    />
                )}

                {!this.state.showUpload && this.state.showList && (
                    <div className={`media--list`}>
                        <MediaList onInsert={this.onInsert} type={"ExternalVideo"}/>
                    </div>
                )}
                {this.state.files.length > 0 && (
                    <Spin spinning={this.state.uploading}>
                        <UploadForm file={this.state.files[0]} deleteFile={this.deleteFiles} uploadFile={this.uploadFile}/>
                    </Spin>
                )}
            </div>
        );
    }
}

export default ExternalVideo;
