import React from 'react';
import { Typography, Layout } from 'antd';
import { t } from './../../core/library/i18n';
import { AppConsumer } from './../../context/app';
import Config  from './../../config';
import DashboardItems from './dashboardItems';
import DashboardSearch from './Search';

class RenderHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let p = this.props;
        if (p.actions && p.actions.updateHeader) {
            p.actions.updateHeader({
                title: t('DASHBOARD_WELCOME'),
            });
        }
    }

    render() {
        return  <Typography.Title level={2}>{t('WELCOME_MESSAGE')}</Typography.Title>;
    }
};


export class Dashboard extends React.Component {
    render() {
        return (
            <Layout.Content style={{margin: 16,padding: 16, background: '#FFF'}}>
                <AppConsumer>
                    {context => {
                        return  <RenderHeader {...context}/>
                    }}
                </AppConsumer>
                {Config.WORKFLOW && Config.WORKFLOW.SHOW_SEARCH  && <DashboardSearch />}
                {Config.WORKFLOW && Config.WORKFLOW.STATUSES && (
                    <DashboardItems />
                )}
            </Layout.Content>
        );
    }
}

export default Dashboard;
