import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddAfishaVm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            // {
            //     'component': 'textarea',
            //     'label': t('SUBTITLE'),
            //     'name': "subtitle",
            //     "show_count": true,
            //     'props': {
            //         'type' : 'text',
            //         'placeholder': 'Sub title',
            //     }
            // },
            {
                'component': 'textarea',
                'label': t('SUMMARY'),
                'name': 'summary',
                "show_count": true,
            },
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'input',
                'label': t('AFISHA_REDIRECT_TO'),
                'name': "redirectTo",
                'props': {
                    'type': 'text',
                    'placeholder': t('AFISHA_REDIRECT_TO'),
                },
                'rules': [
                    {required: true, message: t("AFISHA_REDIRECT_TO_ERROR")},
                ]
            }
        ];


        this.Fields=[...this.Fields, ...[
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AFISHA_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'selected date',
                    showTime: true,
                }
            },
            {
                'component': 'group',
                'label': t('COORDS_GROUP'),
                'fields': [
                    {
                        'component': 'coords',
                        'label': t('COORDS'),
                        'name': 'geo',
                        'props': {
                            onClear: (index = 0) => {
                                this.props.setFields('geo', []);
                            },
                        }
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'MCat',
                        'limit': 40,
                        'label': t('MCAT_FIELD'),
                        'name': 'mcats',
                        'props': {
                            placeholder: t('SELECT_MCAT'),
                            allowClear: true,
                            showSearch: true,
                        },
                    },

                ],
            },

        ]];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {
            });
        }
    }

    onSubmit = (values) => {
        let result_values = {};
        values.slug = values.title || "slug";
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }

            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }



        values.redirectTo = values.redirectTo || "";
        values.media = media;
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    updateMaterialType={this.props.updateMaterialType}
                    sendPush={this.props.sendPush}
                />
            </div>
        );
    }
}

export default AddAfishaVm;
