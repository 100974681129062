import * as C from './constant';
import * as API from './api';


export function LoadList(page = 1) {
    return {
        type: "PROMISE",
        actions: [C.PUSHES_LOAD_START, C.PUSHES_LOAD_FINISH, C.PUSHES_LOAD_ERROR],
        promise: API.loadList(page),
    };
}

export function AddPush(data = {}) {
    return {
        type: "PROMISE",
        actions: [C.PUSHES_ADD_START, C.PUSHES_ADD_FINISH, C.PUSHES_ADD_ERROR],
        promise: API.addItem(data),
    };
}
