import * as API from './api';
import { __loadAllPermissions as LoadAllPermissions } from './../permissions/api';
import * as C from './constants';

export function createGroup(data = {}) {
    return {
        "type": "PROMISE",
        "actions": [
            C.GROUPS_CREATE_START,
            C.GROUPS_CREATE_FINISH,
            C.GROUPS_CREATE_ERROR
        ],
        "promise": API.__createGroup(data)
    };
}

export function loadGroups(page = 1, filter = {}) {
    return {
        "type": "PROMISE",
        "actions": [
            C.GROUPS_LOAD_START,
            C.GROUPS_LOAD_FINISH,
            C.GROUPS_LOAD_ERROR
        ],
        "promise": API.__loadGroups(page, filter),
    };
}

export function deleteGroup(id = '') {
    return {
        "type": "PROMISE",
        "actions": [
            C.GROUPS_DELETE_START,
            C.GROUPS_DELETE_FINISH,
            C.GROUPS_DELETE_ERROR,
        ],
        "promise": API.__deleteGroups(id),
    };
}

export function updateGroup(id = '', data = {}) {
    return {
        "type": "PROMISE",
        "actions": [
            C.GROUPS_UPDATE_START,
            C.GROUPS_UPDATE_FINISH,
            C.GROUPS_UPDATE_ERROR,
        ],
        "promise": API.__updateGroups(id, data),
    };
}

export function loadGroup(id = '') {
    return {
        "type": "PROMISE",
        "actions": [
            C.GROUPS_LOAD_START,
            C.GROUPS_LOAD_FINISH,
            C.GROUPS_LOAD_ERROR,
        ],
        "promise": API.__loadGroupInfo(id),
    };
}

export function LoadPermissions(page = 1, filter = {}) {
    return {
        type: "PROMISE",
        actions: [
            C.PERMISSIONS_START_LOAD,
            C.PERMISSIONS_FINISH_LOAD,
            C.PERMISSIONS_ERROR_LOAD
        ],
        promise: LoadAllPermissions(page, filter),
    };
}
