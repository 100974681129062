import Fetcher from "./../../core/fetch";
import { generateParamsFromObject } from './../../core/library/url';

export function __loadGroups(page = 1, filter = {}) {
    return Fetcher(`groups?page=${page}&${generateParamsFromObject(filter)}`, `GET`);
}

export function __loadGroupInfo(group_id = '') {
    return Fetcher(`groups/${group_id}`, `GET`);
}

export function __createGroup(data = {}) {
    return Fetcher(`groups`, `POST`, data);
}

export function __deleteGroups(id = '') {
    return Fetcher(`groups/${id}`, `DELETE`);
}

export function __updateGroups(id = '', data = {}) {
    return Fetcher(`groups/${id}`, `PUT`, data)
}
