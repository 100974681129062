import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Switch} from 'antd';

class PermissionsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            this.props.onSubmit(values);
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit} layout={"vertical"} className={"filter filter--permissions"}>
                    <div className="filter--column create-new">
                        <Button htmlType={"button"} type={"primary"} onClick={this.props.onCreateClick}>
                            Create new
                        </Button>
                    </div>
                    <div className="filter--column search--column">
                        {getFieldDecorator('search', {})(
                            <Input placeholder={"enter code, title or description"}/>
                        )}
                    </div>
                    <div className="filter--column active--column">
                        <label>Active:</label>
                        {getFieldDecorator('active', {})(
                            <Switch />
                        )}
                    </div>
                    <div className="filter--column btn-column">
                        <Button htmlType={"submit"} type={"primary"}>
                            Search
                        </Button>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}


PermissionsFilter.defaultProps = {
    onSubmit: () => {},
    onCreateClick: () => {},
    form: {},
};

PermissionsFilter.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.instanceOf(Object).isRequired,
    onCreateClick: PropTypes.instanceOf(Object).isRequired,
};


export default Form.create({name: 'permissions_filter'})(PermissionsFilter);
