import React from 'react';
import {Form, Input, Select, Slider, Button, message, Spin} from 'antd';
import Request from '../../core/fetch';
import { loadMediaList } from '../../store/media/api';
import Config from '../../config';
const Option = Select.Option;

const voices = [
    {name: 'Алёна', value: 'alena'},
    {name: 'Джейн', value: 'jane'},
    {name: 'Элис', value: 'alyss'},
    {name: 'Оксана', value: 'oksana'},
    {name: 'Филипп', value: 'filipp'},
    {name: 'Омаж', value: 'omazh'},
    {name: 'Захар', value: 'zahar'},
    {name: 'Ермил', value: 'ermil'},
]


export class TTS extends React.Component {
    state = {
        loading: false,
        media_loading: false,
        list: [],
    };


    onSubmit = (values) => {
        this.setState({ loading: true }, () => {
            Request('/tts', `POST`, values).then(res => {
                console.log(`result: `, res);
                setTimeout(() => {
                    this.setState({ loading: false }, () => {
                        this.loadDocuments();
                    });
                }, 1500);
            }).catch(e => {
                console.error(`[Failed]`, e);
                message.error(`Произошла ошибка при обращение к Yandex. Попробуйте пожалуйста позднее`);
                this.setState({ loading: false });
            })
        });
    }


    loadDocuments = () => {
        this.setState({ media_loading: true }, () => {
            loadMediaList(0, 100, {type: 'TTS'}).then(res => {
                this.setState({ media_loading: false, list: res.list || []});
            }).catch(e => {
                console.error(`Failed to load media list: `, e);
                this.setState({ media_loading: false });
            })
        })
    }

    componentDidMount() {
        this.loadDocuments();
    }

    render() {
        return (
            <div className={`controller tts_controller`}>
                <div className="controller__wrapper">
                    <WrapperTTSForm
                        loading={this.state.loading}
                        onSubmit={this.onSubmit}
                    />
                </div>
                <h3 style={{marginTop: 20}}>Список</h3>
                <Spin spinning={this.state.media_loading}>
                    {this.state.list.map(i => {
                        return (
                            <div className={`stt_item`}>
                                <div className="text">{i.description || ""}</div>
                                <audio src={`${Config.MEDIA.CDN.AUDIO}/${i.files.original.path}`} controls/>
                            </div>
                        );
                    })}
                </Spin>
            </div>
        );
    }
}


class TTSForm extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loading } = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={`Текст`}>
                    {getFieldDecorator('text', {
                        rules: [{required: true, message: `Укажите текст`}]
                    })(<Input.TextArea rows={12}/>)}
                </Form.Item>
                <div className="form__row">
                    <Form.Item label={`Голос`}>
                        {getFieldDecorator('voice', {
                            initialValue: voices[0].value,
                            rules: [{required: true, message: `Выберите голос`}]
                        })(
                            <Select>
                                {voices.map(i => {
                                    return <Option key={i.value} value={i.value}>{i.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={`Скорость`}>{getFieldDecorator('speed', {initialValue: 1.1})(<Slider min={0.1} max={3} step={0.1}/>)}</Form.Item>
                </div>
                <Form.Item><Button loading={loading} type={`primary`} htmlType={`submit`}>Синтезировать</Button></Form.Item>
            </Form>
        );
    }
}

const WrapperTTSForm = Form.create({ name: 'horizontal_login' })(TTSForm);
