import AppStore from './../library/store';
import Config from './../../config';
import * as UserApi from './../../store/user/api';

export default class User {
    tokensNS = ['access', 'refresh', 'expire'];

    constructor(props) {
        this.tokens = this.getUserTokens();
        this.info = {};
    }

    // generate user tokens
    generateUserTokens = (key = '') => {
        return new Promise((resolve, reject) => {
            UserApi.getUserTokens(key).then(data => {
                if (data.data && data.data.tokens) {
                    Object.keys(data.data.tokens).map(i => {
                        AppStore.set(`${Config.OAUTH.PREFIX}${i}`, data.data.tokens[i]);
                        return i;
                    });
                    resolve({result: true, tokens: data.data.tokens});
                } else {
                    reject({result: false, error: 'Authorization failed'});
                }
            }).catch(e => {
                console.error(`Error generate tokens: `, e);
                reject(e);
            });
        });

    };

    // check that user auth
    isAuth = () => {
        return new Promise((resolve, reject) => {
            UserApi.validateToken().then(data => {
                const user = data && data.data && data.data.user ? data.data.user : {};
                if (user.permissions) {
                    let permissions = user.permissions.map(i => i.code);
                    user.permissions = permissions;
                }
                window.user = user;
                this.info = user;
                resolve(user);
            }).catch(e => {
                console.error(`error: `, e);
                reject({error: true, status: e.status || 403, text: e.statusText || "Forbidden"});
            });
        });
    };

    // return all user tokens
    getUserTokens = () => {
        let tokens = {};
        this.tokensNS.map(i => {
            tokens[i] = AppStore.get(`${Config.OAUTH.PREFIX}${i}`);
            return i;
        });
        return tokens;
    };

    // get user access token
    getAccessToken = () => {
        return this.tokens['access'] || undefined;
    };

    // get user refresh token
    getRefreshToken = () => {
        return this.tokens['refresh'] || undefined;
    };

    // generate authorization url for oauth service
    generateAuthorizatoinUrl = () => {
        return `${Config.OAUTH.URL}?callback=${Config.OAUTH.PROJECT_PATH}&front=1`;
    };

    // update user tokens (using refresh token)
    updateTokens = () => {
        return new Promise((resolve, reject) => {
            UserApi.refreshToken()
                .then(data => {
                    resolve(data);
                })
                .catch(e => { reject(e); });
        });
    };

    // logout user
    logout = () => {
        this.tokensNS.map(i => {
            AppStore.remove(`${Config.OAUTH.PREFIX}${i}`);
            return i;
        });
    }

    //set tokens
    setTokens = (data) => {
        Object.keys(data.data.tokens).map(i => {
            AppStore.set(`${Config.OAUTH.PREFIX}${i}`, data.data.tokens[i]);
            return i;
        });
    };

    setTokens = (access_token, refresh_token) => {
        AppStore.set(`${Config.OAUTH.PREFIX}access`, access_token);
        AppStore.set(`${Config.OAUTH.PREFIX}refresh`, refresh_token);
    }
}
