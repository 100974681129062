import React from 'react';
import { Button, Table, } from 'antd';
import { t } from './../../library/i18n';

export  class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.columns = [
            {
                title: t('TITLE'),
                key: 'title',
                render: (r) => 'title',
            },
            {
                title: t('DATE_START'),
                key: 'date_start',
                render: (r) => 'date_start'
            },
            {
                title: t('DATE_END'),
                key: 'date_end',
                render: (r) => 'date_end'
            },
            {
                title: '',
                render: () => null,
            }
        ];
    }

    render() {
        return (
            <React.Fragment>
                <Table
                    bordered={true}
                    size={`small`}
                    columns={this.columns}
                    title={() => {
                        return (
                            <React.Fragment>
                                <div className="table--row__title">
                                    <Button type={`primary`}>{t('CREATE')}</Button>
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

export default Schedule;
