import React from 'react';
import { Typography, Layout, Table, DatePicker, Button, message, Radio } from 'antd';
import { AppConsumer } from './../../context/app';
import {t} from "../../core/library/i18n";
import * as Actions from "./../../store/reports/actions";
import * as C from './../../store/reports/constants';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Moment from 'moment';
import Config from './../../config';


//
// function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
//     if(start.isAfter(end)) throw new Error('start must precede end')
//     const next = Moment(start).add(1, key).startOf(key);
//     if(next.isAfter(end, key)) return arr;
//     return getRangeOfDates(next, end, key, arr.concat(next));
// }


let getDates = function(startDate, endDate) {
    let dates = [],
        currentDate = startDate,
        addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

const mapStateToProps = state => ({
    answer: state.ReportsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class RenderHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let p = this.props;
        if (p.actions && p.actions.updateHeader) {
            p.actions.updateHeader({
                title: t('MATERIAL'),
            });
        }
    }

    render() {
        return  <Typography.Title level={2}>{t('MATERIAL_REPORT')}</Typography.Title>;
    }
};

class MaterialControllerClass extends React.Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Название',
                key: 'title',
                render: r => {
                    if (r.title === "Итого") return `Итого: ${this.state.total}`;
                    let link = `https://absatz.media/news/${r.publicId}-${r.slug}`;
                    switch (r.type) {
                        case "Episode": link = `https://absatz.media/news/${r.publicId}-${r.slug}`;break;
                        case "Article":  link = `https://absatz.media/${r.category.slug}/${r.publicId}-${r.slug}`; break;
                        case "Meaning": link = `https://absatz.media/opinion/${r.publicId}-${r.slug}`; break;
                        default: break;
                    }
                    return <a rel="noopener noreferrer" href={link} target="_blank">{r.title}</a>;
                },
            },
            {
                title: 'Автор',
                key: 'authors',
                render: r => {
                    let authors = r.authors && Array.isArray(r.authors) ? r.authors.map(i => i.title) : [];
                    return authors.join(', ');
                }
            },
            {
                title: 'Рубрика',
                key: 'cat',
                render: r => {
                    if (r.type === "Episode") return "Телевидение";
                    if (r.category && r.category.title) {
                        return r.category.title;
                    }
                    return "";
                },
            },
            {
                title: 'Тип материала',
                key: 'type',
                render: r => {
                    if (typeof r.type === "undefined") return "";
                    switch(r.type) {
                        case "Article": return "Статья";
                        case "Meaning": return "Колумнист";
                        case "Episode": return "Статья";
                        default: return "Новость";
                    }
                }
            },
            {
                title: 'Просмотры',
                dataIndex: 'views_count',
                sorter: true,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comments_count',
            },
            {
                title: 'К-во знаков',
                dataIndex: 's_count',
            },
            {
                title: 'Видео',
                key: 'video',
                render: r => {
                    if (r.title === 'Итого') return r.video_count;
                    return r.video_count > 0 ? 'да' : 'нет';
                }
            },
            {
                title: 'Длит.(мин)',
                dataIndex: 'video_duration',
            },
            {
                title: 'К-во картинок',
                key: 'image_count',
                render: r => {
                    return r.image_count || 0;
                }
            },
            {
                title: 'Опубликовано в газете',
                key: 'publish_at_newspaper',
                render: r => {
                    if (typeof r.newspaperCount !== "undefined") return r.newspaperCount;
                    return r.options && r.options.publishNewspaper ? 'да' : 'нет';
                }
            },
            {
                title: 'Дата',
                key: 'date',
                render: r => {
                    if (r.title === 'Итого') return "";
                    return Moment(r.publishedAt).format('YY-MM-DD HH:mm:ss');
                },
            },
        ];

        this.state = {
            list: [],
            sort: '-publishedAt',
            filter: {
                date_from: Moment(new Date()),
                date_to: Moment(new Date()),
                date: Moment(new Date()),
            },
            page: 0,
            total: 0,
            limit: 50,
            loading: false,
            bydate: false,
        }
    }


    updateDates = (v) => {
        this.setState({
            bydate: false,
            filter: {
                date_from: v[0],
                date_to: v[1],
            }
        })
    };

    componentDidMount() {
        this.loadReport();
    }


    loadReport = () => {

        if (this.state.bydate) {
            let filter = {date_from: this.state.filter.date.toISOString(), date_to: this.state.filter.date.toISOString()};
            this.props.actions.LoadReport(filter, this.state.page, this.state.sort, true);
        } else {
            let filter = {date_from: this.state.filter.date_from.toISOString(), date_to: this.state.filter.date_to.toISOString()};
            this.props.actions.LoadReport(filter, this.state.page, this.state.sort);
        }

    };


    calculateTotalToFields = (list = [], fields = []) => {
        let totals = {newspaperCount: 0};
        fields.map(i => {
            totals[i] = 0;
            return i;
        });

        list.map(i => {
            Object.keys(i).map(j => {
                if (typeof totals[j] !== "undefined") {
                    totals[j] += i[j];
                }
                if (j === "options" && i[j].publishNewspaper) {
                    totals.newspaperCount += 1;
                }
                return j;
            });
            return i;
        });
        return totals;
    };

    prepareDownloadLink = () => {
        let dateFrom = this.state.filter.date_from.toISOString();
        let dateTo = this.state.filter.date_to.toISOString();
        return `${Config.PROJECT.API}/reports/docs/download?date_from=${dateFrom}&date_to=${dateTo}`;
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.RMATERIAL_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.RMATERIAL_LOAD_ERROR:
                    message.error(t(`ERROR_LOAD_REPORT`));
                    break;
                case C.RMATERIAL_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};

                    let list = [];
                    if (this.state.bydate) {
                        let totals = this.calculateTotalToFields(data.list || [], ['views_count', 's_count', 'comments_count', 'video_count', 'video_duration', 'image_count']);
                        list = [{...totals, ...{id: 'Total', title: 'Итого'}}, ...data.list || []];
                    } else {
                        list = data.list || [];
                    }
                    this.setState({
                        loading: false,
                        list: list || [],
                        total: data.total || 0,
                        limit: this.state.bydate === true ? 10000 : data.limit || 0,
                    });

                    break;
                default: break;
            }
        }
    }


    prepareItemsDates = () => {
        let dates = getDates(this.state.filter.date_from, this.state.filter.date_to);
        // let dates = getRangeOfDates(this.state.filter.date_from, this.state.filter.date_to, 'day');
        let items = dates.map(i => <Radio.Button value={i} key={i}>{Moment(i).format('DD MMM')}</Radio.Button>);
        if (items.length > 1) {
            return <div style={{marginTop: 10}}><Radio.Group defaultValue={`${this.state.filter.date ? this.state.filter.date._d : null}`} onChange={this.filterByDay}>{items}</Radio.Group></div>;
        }
        return null;
    };

    filterByDay = (v) => {
        let date = Moment(v.target.value);
        this.setState({
            bydate: true,
            filter: {...this.state.filter, ...{date}}
        }, () => {
            this.loadReport();
        });
    };

    render() {
        return (
            <Layout.Content style={{margin: 16,padding: 16, background: '#FFF'}}>
                <AppConsumer>
                    {context => {
                        return  <RenderHeader {...context}/>
                    }}
                </AppConsumer>
                <Table
                    bordered={true}
                    loading={this.state.loading}
                    title={() => (
                        <React.Fragment>
                            <div>
                                <DatePicker.RangePicker
                                    onChange={this.updateDates}
                                    defaultValue={[this.state.filter.date_from, this.state.filter.date_to]}
                                />
                                <Button style={{marginLeft: 10}} onClick={this.loadReport} type={`primary`}>{t('SEARCH')}</Button>
                                <Button style={{marginLeft: 10}} onClick={() => {
                                        window.open(this.prepareDownloadLink());
                                }} type={`primary`}>{t('DOWNLOAD_XLS')}</Button>
                            </div>
                            <div>
                                {this.prepareItemsDates()}
                            </div>
                        </React.Fragment>)
                    }
                    columns={this.columns}
                    dataSource={this.state.list}
                    rowKey={(item) => item.id}
                    onChange = {(pagination, filters, sorter) => {
                        
                        if (sorter.field) {
                            this.setState(
                                {sort: `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`},
                                () => {
                                    this.loadReport()
                                }
                            );
                        } else {
                            this.setState(
                                {sort: `-publishedAt`},
                                () => {
                                    this.loadReport()
                                }
                            );
                        }
                    //    if (sorter.column)
                    }}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: (page) => this.setState({ page }, () => { this.loadReport() }),
                        current: this.state.page,
                        hideOnSinglePage: true,
                        position: 'both',
                    }}
                />
            </Layout.Content>
        )
    }
}


export const MaterialController = connect(
    mapStateToProps,
    mapDispatchProps
)(MaterialControllerClass);

export default MaterialController;
