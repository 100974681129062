import React from 'react';
import { Form, Input } from 'antd';

const Counter = (p) => {
    const value = p.form.getFieldValue(p.name);
    return value ? value.length : 0;
};

export const FormInput = (p) => {
    let cProps = {};
    if (p.show_count) {
        cProps.addonAfter = <Counter {...p} />;
    }
    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                rules: p.rules || [],
                initialValue: p.initialValue ? p.initialValue : "",
            })(
                <Input {...p.props} {...cProps}/>
            )}
        </Form.Item>
    )
};
