import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import propExists from './../../../core/library/objects/getprop';
import * as Actions from "./../../../store/user/actions";
import * as C from './../../../store/user/constants';
import UsersTable from './../../../components/tables/users';
import {generateParamsFromObject, parseUrlParams} from "./../../../core/library/url";
import {AppConsumer} from './../../../context/app';
import {t} from "../../../core/library/i18n";
import { message } from 'antd';


const mapStateToProps = state => ({
    answer: state.UserReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class UsersIndexController extends React.Component {
    constructor(props) {
        super(props);

        let urlParams = parseUrlParams();
        let filter = {};

        if (urlParams.search && urlParams.search !== '') {
            filter.search = decodeURIComponent(urlParams.search);
        }

        this.state = {
            isLoading: false,
            limit: 1,
            total: 0,
            page: urlParams.page ? parseInt(urlParams.page, 10) : 1,
            users: [],
            filter: filter,
            btnLoading: false,
        };
    }

    loadUsers = () => {
        this.props.actions.LoadUsers(this.state.page, this.state.filter);
    };

    updateFilter = (filter) => {
        this.setState({ page: 1, filter }, () => {
            this.props.history.push(`${window.location.pathname}?${generateParamsFromObject({...this.state.filter, ...{page: this.state.page}})}`);
            this.loadUsers();
        });
    };

    updatePage = (page) => {
        this.setState({ page }, () => {
            this.props.history.push(`${window.location.pathname}?${generateParamsFromObject({...this.state.filter, ...{page: this.state.page}})}`);
            this.loadUsers();
        });
    };

    onUserEditHandle = (user_id = '') => {
        this.props.history.push(`users/${user_id}`);
    };

    componentDidMount() {
        this.loadUsers();
    }

    onUserCreate = () => {
        this.props.history.push(`users/create`);
    }

    onDeleteUser = (user_id) => {
        this.props.actions.DeleteUser(user_id)
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.USERS_START_LOAD:
                    this.setState({
                        isLoading: true,
                    });
                    break;
                case C.USERS_FINISH_LOAD:
                    console.log(p.answer);
                    this.setState({
                        isLoading: false,
                        users: propExists(p.answer.data, 'data.users') ? p.answer.data.data.users : [],
                        total: propExists(p.answer.data, 'data.total') ? p.answer.data.data.total : 0,
                        limit: propExists(p.answer.data, 'data.limit') ? p.answer.data.data.limit : 30,
                    });
                    break;
                case C.USERS_ERROR_LOAD:
                    this.setState({
                        isLoading: false,
                    }, () => {
                       console.error(`error load users: `, p.aswer)
                    });
                    break;
                case C.USERS_START_DELETE:
                    this.setState({btnLoading: true});
                    break;
                case C.USERS_ERROR_DELETE:
                    this.setState({btnLoading: false}, () => {
                        message.error(`Ошибка удаления пользователя`);

                    });
                    break;
            case C.USERS_FINISH_DELETE:
                    this.setState({btnLoading: false}, () => {
                        message.success(`Пользователь успешно удален`);
                        this.loadUsers();
                    });
                    break;
                default: break;
            }
        }
    }

    render() {
        console.log(this.state.users);
        return (
            <React.Fragment>
                <div className={'users--list'}>
                    <AppConsumer>
                        {context => {
                            return (
                                <UsersTable
                                    onMounted={() => {
                                        if (context.actions.updateHeader) {
                                            context.actions.updateHeader({
                                                title: t(`USERS_LIST`),
                                            })
                                        }
                                    }}
                                    filter={this.state.filter}
                                    list={this.state.users}
                                    page={this.state.page}
                                    total={this.state.total}
                                    limit={this.state.limit}
                                    loading={this.state.isLoading}
                                    onEditUser={this.onUserEditHandle}
                                    onCreateUser={this.onUserCreate}
                                    onPageChange={this.updatePage}
                                    onUpdateFilter={this.updateFilter}
                                    btnLoading={this.state.btnLoading}
                                    onDeleteUser={this.onDeleteUser}
                                />
                            );
                        }}
                    </AppConsumer>
                </div>
            </React.Fragment>
        );
    }
}

UsersIndexController.propTypes = {
    actions: PropTypes.instanceOf(Object).isRequired,
    answer: PropTypes.instanceOf(Object).isRequired,
};

export const UsersIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(UsersIndexController);

export default UsersIndex;
