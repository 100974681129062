import { ElasticFetcher } from './../../core/fetch/';
import Fetcher  from './../../core/fetch/';
import { generateParamsFromObject } from './../../core/library/url';

export function loadMedia(page=0, limit = 30, filter = {}, showUserPhoto = false, isBookmark, isExclude, excluded = []) {
    let params = generateParamsFromObject(filter);
    let prefix = window.location.pathname.indexOf('documents') + 1 > 0 ? 'media_search' : 'media';
    let showUP = ``;
    let bookmark = isBookmark === true ? `&is_bookmark=true` : ``;
    let exclude = isExclude === true ? `&is_exclude=true` : ``;
    let excludeList = excluded && excluded.length > 0 ? `&` + excluded.map(i => `ex=${i}`).join('&') : '';
    return ElasticFetcher(`${prefix}?page=${page}&limit=${limit}${params.length > 0 ? `&${params}` : ''}${showUP}${bookmark}${exclude}${excludeList}`, `GET`);
}


export function loadMediaList(page=0, limit = 30, filter = {}, foto_user = false, isPhoto = false, is_bookmark = false, is_exclude = false) {
    let params = generateParamsFromObject(filter);
    if (foto_user && isPhoto === false) {
        params = `${params}`;
    }

    if (isPhoto === true && foto_user === false) {
        params = `${params}`;
    }

    if (is_bookmark) {
        params = `${params}&is_bookmark=true`;
    }

    if (is_exclude) {
        params = `${params}&is_exclude=true`;
    }


    return ElasticFetcher(`media_search?page=${page}&limit=${limit}${params.length > 0 ? `&${params}` : ''}`, `GET`);
}


export function loadMediaDB(page=0, limit = 30, filter = {}) {
    let params = generateParamsFromObject(filter);
    return Fetcher(`media?page=${page}&limit=${limit}${params.length > 0 ? `&${params}` : ''}`, `GET`);
}

export function updateMedia(media_id = '', data = {}) {
    if(window.user && window.user.permissions && window.user.permissions.indexOf('DEVELOPER_LOG') + 1 > 0) {
        window.documentUpdateHistory = window.documentUpdateHistory || [];
        window.documentUpdateHistory.push({time: (new Date()).getTime(), action: 'update media', media_id, data});
    }
    return Fetcher(`media/${media_id}`, `PUT`, data)
}

export function createMedia(data = {}) {
    if(window.user && window.user.permissions && window.user.permissions.indexOf('DEVELOPER_LOG') + 1 > 0) {
        window.documentUpdateHistory = window.documentUpdateHistory || [];
        window.documentUpdateHistory.push({time: (new Date()).getTime(), action: 'create media', data});
    }
    return Fetcher(`media`, `POST`, data);
}

export function getUsingMedia(ids = []) {
    return Fetcher(`media/usage`, `POST`, { ids });
}

export function getMediaMaterials(id = '') {
    return Fetcher(`media/${id}/materials`, `GET`);
}
