import * as C from './constants';
import * as API from './api';

export function loadPermissions(page = 1, filter = {}) {
    return {
        type: "PROMISE",
        actions: [C.START_LOAD_PERMISSIONS, C.FINISH_LOAD_PERMISSIONS, C.ERROR_LOAD_PERMISSIONS],
        promise: API.__loadPermission(page, filter),
    }
}

export function updatePermissions(id = '', data = {}) {
    return {
        type: "PROMISE",
        actions: [C.START_UPDATE_PERMISSION, C.FINISH_UPDATE_PERMISSION, C.ERROR_UPDATE_PERMISSION],
        promise: API.__updatePermission(id, data),
    }
}

export function deletePermission(id = '') {
    return {
        type: "PROMISE",
        actions: [C.START_DELETE_PERMISSION, C.FINISH_DELETE_PERMISSION, C.ERROR_DELETE_PERMISSION],
        promise: API.__deletePermission(id),
    };
}

export function createPermission(data = {}) {
    return {
        type: "PROMISE",
        actions: [C.START_CREATE_PERMISSION, C.FINISH_CREATE_PERMISSION, C.ERROR_CREATE_PERMISSION],
        promise: API.__createPermission(data),
    };
}
