import React from 'react';
import { Spin, message, Empty, Pagination, Input, Button, Switch } from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from "./../../../../store/media/actions";
import * as C from './../../../../store/media/constants';
import { getUsingMedia } from './../../../../store/media/api';
import { t } from './../../../library/i18n';
import MediaCard from './item';

const mapStateToProps = state => ({
    answer: state.MediaReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class MediaList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
            page: 1,
            limit: 6,
            total: 0,
            search: '',
            showUserMedia: false,
            isBookmark: false,
            isExclude: false,
        };
    }



    loadMedia = () => {
        const type = this.props.searchType ? this.props.searchType : this.props.type;
        this.props.actions.GetMediaList(this.state.page, this.state.limit, {type , q: this.state.search}, this.state.showUserMedia, this.state.isBookmark, this.state.isExclude);
        // this.props.actions.GetMediaListDB(this.state.page, this.state.limit, {type: this.props.type, search: this.state.search});
    };

    componentDidMount() {
        this.loadMedia();
    }

    componentWillReceiveProps = async (p) => {
        if (p.answer && p.answer.state) {

            switch(p.answer.state) {
                case C.MEDIA_START_LOAD:
                    this.setState({ loading: true });
                    break;
                case C.MEDIA_FINISH_LOAD:
                    const data = p.answer.data || {};
                    if (data.list && Array.isArray(data.list)) {
                        let ids = data.list.map(i => i.id);
                        let mediaIds = await getUsingMedia(ids);
                        if (mediaIds.media) {
                            data.list = data.list.map(i => {
                                let a = {...i};
                                if (a.files && a.files["original"] && a.files["original"].path) {
                                    if (mediaIds.media.indexOf(a.files["original"].path) + 1 > 0) {
                                        a.isUsing = true;
                                    }
                                }
                                return a;
                            });
                        }
                    }
                    this.setState({
                        list: data.list || [],
                        total: data.total || 0,
                        loading: false,
                    });
                    break;
                case C.MEDIA_ERROR_LOAD:
                    this.setState({ loading: false });
                    message.error(t('MEDIA_LOAD_ERROR'));
                    console.error(`error load media: `, p);
                    break;
                case C.MEDIA_CREATE_START:
                    this.setState({ loading: true });
                    break;
                case C.MEDIA_CREATE_ERROR:
                    message.error(t("MEDIA_CREATE_ERROR"));
                    this.setState({ loading: false });
                    break;
                case C.MEDIA_CREATE_FINISH:
                    this.setState({ loading: false }, () => {
                        let d = p.answer.data && p.answer.data.data ? p.answer.data.data : null;
                        if (d) {
                            this.props.selectMedia(d);
                        } else {
                            message.error(t("MEDIA_CREATE_ERROR"));
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    updatePageHandle = (page) => {
        this.setState({ page }, () => {
            this.loadMedia();
        });
    };

    selectMedia = (data) => {
        let a = {...data};
        a.type = this.props.type;
        a.virtual = true;
        a.original_id = data.id || "";

        if (Array.isArray(a.tags) && a.tags.length === 0) {
            a.tags = null;
        }

        if (Array.isArray(a.coverId) && a.coverId.length === 0) {
            a.coverId = null;
        }

        if (Array.isArray(a.docId) && a.docId.length === 0) {
            a.docId = null;
        }

        this.props.actions.CreateMedia(a);
    };

    render() {
        return (
            <div className={`media--list`}>
                <Spin spinning={this.state.loading}>
                    <div className="search" style={{flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '140px', marginBottom: 10}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{marginRight: 10}}>
                                    <span style={{marginRight: 4}}>Весь архив</span>
                                    <Switch onChange={(status) => {
                                        this.setState({ page: 1, showUserMedia: status }, () => {
                                            this.loadMedia();
                                        })
                                    }}/>
                                </div>
                                <div style={{marginRight: 10}}>
                                    <span style={{marginRight: 4}}>Избранное</span>
                                    <Switch onChange={(status) => {
                                        this.setState({ page: 1, isBookmark: status }, () => {
                                            this.loadMedia();
                                        })
                                    }}/>
                                </div>
                                <div>
                                    <span style={{marginRight: 4}}>Искл. копр.</span>
                                    <Switch onChange={(status) => {
                                        this.setState({ page: 1, isExclude: status }, () => {
                                            this.loadMedia();
                                        })
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div style={{flexDirection: 'row', display: 'flex', width: '100%', paddingLeft: 12, paddingRight: 12}}>
                            <Input
                                onChange={(e) => {
                                    this.setState({
                                        search: e.target.value
                                    })
                                }}
                                onPressEnter={(e) => {
                                    e.preventDefault();
                                    this.setState({ page: 1 }, () => {
                                        this.loadMedia();
                                    })
                                }}
                                placeholder={t('SEARCH_MEDIA_PLACEHOLDER')}
                            />
                            <Button type={`primary`} onClick={() => {
                                this.setState({ page: 1 }, () => {
                                    this.loadMedia();
                                })
                            }}>{t('SEARCH')}</Button>
                        </div>
                    </div>
                    {this.state.list.length === 0 && <Empty  description={t('NO_MEDIA')}/>}
                    {this.state.list.length > 0 && (
                        <div className={"media--list_wrapper"}>

                            <div className={`pagination`}>
                                <Pagination
                                    onChange={this.updatePageHandle}
                                    total={this.state.total}
                                    pageSize={this.state.limit}
                                    defaultCurrent={this.state.page}
                                    current={this.state.page}
                                    hideOnSinglePage={true}
                                />
                            </div>
                            <div className={"media--list_container"}>
                                {this.state.list.map(i => {
                                    return <MediaCard key={`media_${i.id}`} {...i} selectMedia={this.selectMedia}/>;
                                })}
                            </div>
                        </div>
                    )}
                </Spin>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchProps
)(MediaList);
