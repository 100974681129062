import Fetcher from './../../core/fetch';
import { generateParamsFromObject } from './../../core/library/url';

export function __loadPermission(page = 1, filter = {}) {
    return Fetcher(`permissions?page=${page}&${generateParamsFromObject(filter)}`, `GET`);
}

export function __createPermission(data = {}) {
    return Fetcher(`permissions`, `POST`, data);
}

export function __updatePermission(id, data) {
    return Fetcher(`permissions/${id}`, `PUT`, data);
}

export function __deletePermission(id) {
    return Fetcher(`permissions/${id}`, `DELETE`);
}

export function __loadAllPermissions(page = 1, filter = {}) {
    return Fetcher(`permissions?page=-1`, `GET`);
}
