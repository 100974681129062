import React from 'react';
import {Input, Table, message, Divider} from 'antd';
import debounce from 'lodash/debounce';
import {t} from './../../core/library/i18n';
import {documentsElLoadDocs} from './../../store/documents/api';
import Moment from 'moment';
import 'moment/locale/ru';


export default class SearchBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            q: '',
            loading: false,
            list: [],
        };
        this.columns = [
            {
                title: t('TITLE'),
                key: `title`,
                render: r => {
                    return <a target={`_blank`} href={`/documents/${r.type.toLowerCase()}/${r.id}`}>{r.title}</a>
                },
            },
            {
                title: t('CREATED_AT'),
                key: `created_at`,
                render: r => {
                    return Moment(r.createdAt).format(`YYYY-MM-DD HH:mm:ss`);
                }
            },
            {
                title: t('TYPE'),
                key: `type`,
                render: r => {
                    return <a target={`_blank`} href={`/documents/${r.type.toLowerCase()}`}>{t(r.type)}</a>;
                }
            }
        ];
        this.loadDocuments = debounce(this.loadDocuments, 200);
    }

    loadDocuments = () => {

        this.setState({loading: true}, () => {
            documentsElLoadDocs('', 1, {search: this.state.q}, '-createdAt', 10).then(data => {
                this.setState({list: data.list || [], loading: false});
            }).catch(e => {
                console.error(`error load search result: `, e);
                message.error(t(`ERROR_LOAD_SEARCH`));
            });
        });

    };

    render() {
        return (
            <div className={`dashboard--search`}>
                <Divider>{t('SEARCH')}</Divider>
                <Input.Search placeholder={t('SEARCH_PLACEHOLDER')} onChange={(e) => {
                    this.setState({q: e.target.value}, () => {
                        this.loadDocuments();
                    });
                }}/>
                {this.state.q !== "" && (
                    <div className={`search--table`} style={{marginTop: 20}}>
                        <Table
                            loading={this.state.loading}
                            columns={this.columns}
                            bordered={true}
                            size={`small`}
                            dataSource={this.state.list}
                            rowKey={(item) => item.id}
                            pagination={false}
                        />
                    </div>
                )}
            </div>
        );
    }
}
