import * as API from './api';
import * as C from './constants';


export function LoadReport(filter = {}, page = 0, sort = 'publishedAt', byDate = false) {
    return {
        type: "PROMISE",
        actions: [C.RMATERIAL_LOAD_START, C.RMATERIAL_LOAD_FINISH, C.RMATERIAL_LOAD_ERROR],
        promise: API.loadReport(filter, page, sort, byDate)
    }
}

export function LoadTable() {
    return {
        type: `PROMISE`,
        actions: [C.REPORT_LIST_LOAD_START, C.REPORT_LIST_LOAD_FINISH, C.REPORT_LIST_LOAD_ERROR],
        promise: API.loadTable(),
    }
}

export function DeleteReport(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.REPORT_REMOVE_START, C.REPORT_REMOVE_FINISH, C.REPORT_REMOVE_ERROR],
        promise: API.removeTable(id),
    }
}

export function GetReport(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.REPORT_LOAD_START, C.REPORT_LOAD_FINISH, C.REPORT_LOAD_ERROR],
        promise: API.getReport(id)
    }
}

export function GetVideoReport(filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.VIDEO_LOAD_START, C.VIDEO_LOAD_FINISH, C.VIDEO_LOAD_ERROR],
        promise: API.loadReportVideo(filter),
    };
}
