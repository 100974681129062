export const USER_START_LOAD_INFO = 'USER_START_LOAD_INFO';
export const USER_FINISH_LOAD_INFO = 'USER_FINISH_LOAD_INFO';
export const USER_ERROR_LOAD_INFO = 'USER_ERROR_LOAD_INFO';

export const USERS_START_LOAD = 'USERS_START_LOAD';
export const USERS_FINISH_LOAD = 'USERS_FINISH_LOAD';
export const USERS_ERROR_LOAD = 'USERS_ERROR_LOAD';

export const USERS_START_UPDATE = 'USERS_START_UPDATE';
export const USERS_FINISH_UPDATE = 'USERS_FINISH_UPDATE';
export const USERS_ERROR_UPDATE = 'USERS_ERROR_UPDATE';

export const USERS_START_LOAD_INFO = 'USERS_START_LOAD_INFO';
export const USERS_FINISH_LOAD_INFO = 'USERS_FINISH_LOAD_INFO';
export const USERS_ERROR_LOAD_INFO = 'USERS_ERROR_LOAD_INFO';

export const USERS_START_LOAD_PERMISSIONS = 'USERS_START_LOAD_PERMISSIONS';
export const USERS_ERROR_LOAD_PERMISSIONS = 'USERS_ERROR_LOAD_PERMISSIONS';
export const USERS_FINISH_LOAD_PERMISSIONS = 'USERS_FINISH_LOAD_PERMISSIONS';

export const USERS_START_LOAD_GROUPS = 'USERS_START_LOAD_GROUPS';
export const USERS_FINISH_LOAD_GROUPS = 'USERS_FINISH_LOAD_GROUPS';
export const USERS_ERROR_LOAD_GROUPS = 'USERS_ERROR_LOAD_GROUPS';


export const USERS_START_CREATE = 'USERS_START_CREATE';
export const USERS_FINISH_CREATE = 'USERS_FINISH_CREATE';
export const USERS_ERROR_CREATE = 'USERS_ERROR_CREATE';


export const USERS_START_DELETE = 'USERS_START_DELETE';
export const USERS_FINISH_DELETE = 'USERS_FINISH_DELETE';
export const USERS_ERROR_DELETE = 'USERS_ERROR_DELETE';