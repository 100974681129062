import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/ru';
import { Form, Input, Button, Select, Row, Col, Typography, Table } from 'antd';
import { t } from './../../../core/library/i18n';

class ProfileEditForm extends React.Component {
    static defaultProps = {
        item: {},
        form: {},
        groups: [],
        onUpdate: (data) => {},
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        groups: PropTypes.arrayOf(Object).isRequired,
        onUpdate: PropTypes.func.isRequired,
    };

    columns = [
        {
            title: 'Улица',
            key: 'address',
            render: r => {
                console.log(r);
                return r.coords.address || "[not set]"
            },
        },
        {
            title: 'Радиус',
            key: 'radius',
            render: r => {
                return r.radius || 0;
            }
        },
        {
            title: 'Тип',
            key: 'type',
            render: r => {
                return r.device_type;
            }
        }
    ];

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onUpdate(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const devices = this.props.item && this.props.item.profile_devices ? this.props.item.profile_devices : [];
        return (
            <Form onSubmit={this.onSubmit}>
                <Row>
                    <Col span={10}>
                        <Typography.Title>{t('PROFILE_INFO')}</Typography.Title>
                        <Form.Item label={t(`PROFILE_NAME`)}>
                            {getFieldDecorator('name', {
                                initialValue: this.props.item.name || "",
                                rules: [{required: true, message: t('PROFILE_NAME_FILL')}]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label={t(`PROFILE_LAST_NAME`)}>
                            {getFieldDecorator('last_name', {
                                initialValue: this.props.item.last_name || "",
                                rules: [{required: true, message: t('PROFILE_LAST_NAME_FILL')}]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label={t('PROFILE_AVATAR')}>
                            <div style={{backgroundImage: `url(${this.props.item.photo})`}} className={`profile__avatar`}/>
                        </Form.Item>
                        <Form.Item label={t(`PROFILE_PASSWORD`)}>
                            {getFieldDecorator('password', {})(<Input />)}
                        </Form.Item>
                        <Form.Item label={t(`PROFILE_GROUPS`)}>
                            {getFieldDecorator('groups', {
                                initialValue: this.props.item.groups || [],
                            })(
                                <Select mode={`multiple`}>
                                    {this.props.groups.map(i => {
                                        return <Select.Option key={`group_${i.id}`} value={i.id}>{i.title}</Select.Option>;
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={t('PROFILE_LOGIN')}>
                            <Input value={this.props.item.login || ""} disabled={true} />
                        </Form.Item>
                        <Form.Item label={t('PROFILE_EMAIL')}>
                            <Input value={this.props.item.email || ""} disabled={true} />
                        </Form.Item>
                        <Form.Item label={t('PROFILE_BIRTHDAY')}>
                            <Input value={this.props.item.birthday ? Moment(this.props.item.birthday).format('YYYY-MM-DD') : ""} disabled={true} />
                        </Form.Item>
                        <Form.Item label={t('PROFILE_ABOUT')}>
                            <Input.TextArea value={this.props.item.about || ""} disabled={true} />
                        </Form.Item>
                        <Form.Item label={t('PROFILE_ADDRESS')}>
                            <Input value={this.props.item.location || ""} disabled={true} />
                        </Form.Item>
                        <Form.Item><Button type={`primary`} htmlType={`submit`}>{t('SAVE')}</Button></Form.Item>
                    </Col>
                    <Col span={12} offset={1}>
                        <Typography.Title>{t('PROFILE_DEVICES')}</Typography.Title>
                        <Table
                            columns={this.columns}
                            pagination={false}
                            bordered={true}
                            size={`small`}
                            dataSource={devices}
                            rowKey={(item) => item.id}
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}


export default Form.create({ name: 'ProfileEditForm' })(ProfileEditForm);

