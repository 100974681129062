import React from 'react';
import { Select, Input, Button, message, Table, Tooltip, Modal } from 'antd';
import moment from 'moment';

import { CommentsRequest } from '../../core/fetch';
import Config from '../../config';

export class CommentsUsers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            search: '',
            page: 1,
            limit: 100,
            total: 0,
            list: [],
            filter_type: '',
            loading: false,
            user_modal: false,
            user: null,
        };
        this.columns = [
            {
                key: 'title',
                title: 'Логин',
                render: r => {
                    return r.name || "[not set]";
                },
            }, {
                key: 'type',
                title: 'Платформа',
                align: 'center',
                render: r => {
                    return <div className="platform"><img alt={r.platform} src={`/images/icons/${r.platform}.svg`} /></div>;
                },
            },
            {
                key: 'social_id',
                title: 'ID пользователь',
                render: r => {
                    return r.social_id || "[not set]";
                },
            },
            {
                key: 'createdAt',
                title: 'Дата создания',
                render: r => {
                    return moment(r.created_at).format('LLL');
                },
            },
            {
                key: 'comments',
                title: 'Комменатрии',
                render: r => {
                    return <div>{r.message_count || 0} / {moment(r.last_comment_date).fromNow()}</div> ;
                },
            },
            {
                key: 'actions',
                title: '',
                render: r => {
                    return (
                        <Button.Group>
                            <Tooltip title={`Посмотреть профиль`}>
                                <Button
                                    type={`primary`}
                                    icon={'eye'}
                                    onClick={() => {
                                        this.setState({user_modal: true, user: r})
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={`Модерация`}>
                            <Button
                                    type={`primary`}
                                    icon={'filter'}
                                    onClick={() => {
                                        this.setState({user_moderated: true, user: r})
                                    }}
                                />
                            </Tooltip>
                        </Button.Group>
                    );
                }
            }
            
        ];
    }

    onUpdateType = (type) => {
        this.setState({filter_type: type, page: 1}, () => {
            this.loadUsers();
        });
    }

    onUpdateSearch = (search = '') => {
        this.setState({search, page: 1}, () => {
            this.loadUsers();
        });
    }

    loadUsers = () => {
        if (this.state.loading === false) {
            this.setState({loading: true}, () => {
                const {page, limit, search, filter_type} = this.state;
                CommentsRequest(`/socialusers/?page=${page}&limit=${limit}&type=${filter_type}&search=${search}`, `GET`).then(res => {
                    this.setState({loading: false, list: res.data || [], total: res.total || 0});
                }).catch(e => {
                    console.error(`[Users.get] `, e);
                    message.error(`Ошибка загрузки списка пользователей`);
                });
            });
        }
    }

    componentDidMount = () => {
        this.loadUsers();
    }

    render() {
        const { loading, total, list, page, limit, user_modal, user, user_moderated } = this.state;
        return (
            <div className='comments users'>
                <Modal
                    visible={user_modal}
                    title={`Просмотр информации о пользователе ${user ? (user.name || user.social_id) : ''}`}
                    style={{width: 720}}
                    destroyOnClose={true}
                    onCancel={() => this.setState({user_modal: false, user: null})}
                    footer={null}
                />
                <Modal
                    visible={user_moderated}
                    title={`Правила валидации для  пользователя ${user ? (user.name || user.social_id) : ''}`}
                    style={{width: 720}}
                    destroyOnClose={true}
                    onCancel={() => this.setState({user_moderated: false, user: null})}
                    footer={null}
                />
                <UsersFilter
                    onUpdateSearch={this.onUpdateSearch}
                    onUpdateType={this.onUpdateType}
                />
                <div className='comments--block table'>
                    <Table
                        columns={this.columns}
                        loading={loading}
                        bordered
                        dataSource={list}

                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: limit,
                            defaultCurrent: page,
                            total: total,
                            onChange: (page) => {
                                this.setState({ page }, () => {
                                    this.loadUsers();
                                });
                            },
                            current: page,
                            hideOnSinglePage: true,
                            position:"both",
                        }}
                    />
                </div>
            </div>
        );
    }
}

const UsersFilter = ({ onUpdateSearch, onUpdateType }) => {
    return (
        <div className='comments--block filter'>
                <Select
                    allowClear
                    placeholder="Выберите тип сервиса"
                    style={{minWidth: 230, marginRight: 10}}
                    onChange={e => {
                        console.log(`call`, e);
                        onUpdateType(e);
                    }}
                >
                    {Config.COMMENTS.SERVICES.map(i => {
                        return <Select.Option value={i} key={`service_${i}`}>{i}</Select.Option>
                    })}
                </Select>
                <Input.Search
                    allowClear
                    placeholder='Введите поисковый запрос'
                    enterButton="Поиск"
                    onSearch={(s) => onUpdateSearch(s)}
                />
        </div>
    );
}

export default CommentsUsers;