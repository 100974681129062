import Config from './../../../../config';

function prepareText(element) {
    let params = [element.title];
    if (element.properties) {
        Object.keys(element.properties).map(i => {
            if (element.properties[i]) {
                params.push(element.properties[i]);
            }
            return i;
        });
    }
    return (params.join(' / '));
}

export const VideoTemplate = (p) => {
    let path = p && p.files ? p.files.original.path : "";
    return `<div class="preview--block video"><video controls src="${Config.MEDIA.CDN.VIDEO}/${path}"></video><div class="info">${prepareText(p)}</div></div>`;
};

export default VideoTemplate;
