import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, InputNumber, Button } from 'antd';
import { t } from './../../core/library/i18n';
import Config from './../../config';
import AutocompleteField from '../../core/components/autocomplete/documents';


const PrepareInitialValue = (p) => {
    if (Array.isArray(p)) {
        let ids = p.map(i => i.id);
        return ids;
    } else {
        if (p && p.id) {
            return p.id;
        }
    }
    return null;
    // if (p.initialValue && Array.isArray(p.initialValue)) {
    //     let ids = p.initialValue.map(i => {
    //         if (i.id) return i.id;
    //         return i;
    //     });
    //     return ids;
    // }
    // if (p.initialValue && p.initialValue.id) return p.initialValue.id;
    // if (p.initialValue) return p.initialValue;
    // return "no initial";
};


export class WidgetForm extends React.Component {

    static defaultProps = {
        onSubmit: () => {},
        item: {},
    };

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        item: PropTypes.instanceOf(Object).isRequired,
    };


    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={t('WIDGET_TITLE')}>
                    {getFieldDecorator('title', {
                        initialValue: this.props.item.title || "",
                        rules: [{required: true, message: t('WIDGET_TITLE_ERROR')}]
                    })(<Input placeholder={t('WIDGET_TITLE_PLACEHOLDER')}/>)}
                </Form.Item>
                
                <Form.Item label={t('ADMIN_WIDGET_CODE')}>
                    {getFieldDecorator('code_name', {
                        initialValue: this.props.item.code_name || "",
                        rules: [{required: true, message: t('ADMIN_WIDGET_CODE_ERROR')}]
                    })(<Input placeholder={t('ADMIN_WIDGET_CODE_PLACEHOLDER')}/>)}
                </Form.Item>
                <Form.Item label={t('ADMIN_WIDGET_TITLE')}>
                    {getFieldDecorator('adminTitle', {
                        initialValue: this.props.item.adminTitle || "",
                        rules: [{required: true, message: t('WIDGET_ADMIN_TITLE_ERROR')}]
                    })(<Input placeholder={t('WIDGET_ADMIN_TITLE_PLACEHOLDER')}/>)}
                </Form.Item>
                <Form.Item label={t('MAX_SIZE')}>
                    {getFieldDecorator('max_size', {
                        initialValue: this.props.item.max_size || "",
                    })(<InputNumber min={1}/>)}
                </Form.Item>
                <Form.Item label={t('WIDGET_CATEGORY')}>
                    {getFieldDecorator('category', {
                        initialValue: this.props.item.category ? PrepareInitialValue(this.props.item.category) : []
                    })(
                        <AutocompleteField
                            filter={false}
                            type={'Category'}
                            limit={250}
                            initial={this.props.item.category ? PrepareInitialValue(this.props.item.category) : []}
                            props={{
                                allowClear: true,
                                showSearch: true,
                                mode: 'multiple',
                            }}
                        />
                    )}
                </Form.Item>
                
                <Form.Item label={t('DOC_TYPES')}>
                    {getFieldDecorator('data_type', {
                        initialValue: this.props.item.data_type || [],
                    })(
                        <Select placeholder={t('WIDGET_DOC_TYPE_PLACEHOLDER')} allowClear mode={"multiple"}>
                            {Config.WIDGETS.TYPES.map(i => {
                                return <Select.Option key={`doc_${i}`} value={i}>{t(`type_${i}`)}</Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('WIDGET_TYPE')}>
                    {getFieldDecorator('type', {
                        initialValue: this.props.item.type ||'',
                    })(
                        <Select placeholder={t('WIDGET_TYPE_PLACEHOLDER')} allowClear>
                            {Config.WIDGETS.WTYPE.map(i => {
                                return <Select.Option key={`type_${i}`} value={i}>{t(`widget_type_${i}`)}</Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('WIDGET_VIEW')}>
                    {getFieldDecorator('view', {
                        initialValue: this.props.item.view ||'',
                    })(
                        <Select placeholder={t('WIDGET_VIEW_PLACEHOLDER')} allowClear>
                            {Config.WIDGETS.VIEWS.map(i => {
                                return <Select.Option key={`type_${i.key}`} value={i.key}>{i.name}</Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type={`primary`} htmlType={`submit`}>{t('SAVE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedWidgetForm = Form.create({ name: 'widget_form' })(WidgetForm);
export default WrappedWidgetForm;
