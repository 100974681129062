import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, InputNumber } from 'antd';
import { t } from './../../../library/i18n';
import Config from './../../../../config';


class External extends React.Component {

    static defaultProps = {
        onSubmit: () => {}
    };

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    validateSource = (rule, value, callback) => {
        let a = document.createElement('a');
        a.href = value;
        let hostname = a.hostname.replace('www.', '');
        if (Config.MEDIA.EMBEDDED_SOURCES.indexOf(hostname) + 1 === 0) {
            callback(t('INCORRECT_SOURCE'));
        } else {
            const { form } = this.props;
            form.setFieldsValue({
                provider: a.hostname,
                options: {
                    width: 560,
                    height: 315,
                },
                path: value,
                embed: Config.MEDIA.EMEDDED_PREPARE && Config.MEDIA.EMEDDED_PREPARE[hostname] ? Config.MEDIA.EMEDDED_PREPARE[hostname](value) : `<iframe src="${value}" width="560" height=""315"></iframe>`
            });
            callback();
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <div style={{opacity: 0, height: 0}}>
                    <Form.Item label={t('PROVIDER')}>
                        {getFieldDecorator('provider', {})(<Input />)}
                    </Form.Item>
                </div>
                <Form.Item label={t('URL')} help={Config.MEDIA.EMBEDDED_SOURCES.join(', ')}>
                    {getFieldDecorator('options.path', {
                        rules: [
                            {
                                validator: this.validateSource,
                                message: t('INCORRECT_SOURCE'),
                            }
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={t('WIDTH')}>
                    {getFieldDecorator('options.width', {})(<InputNumber />)}
                </Form.Item>
                <Form.Item label={t('HEIGHT')}>
                    {getFieldDecorator('options.height', {})(<InputNumber />)}
                </Form.Item>
                <Form.Item label={t('MEDIA_COPYRIGHT')}>
                    {getFieldDecorator('properties.copyright', {})(<Input />)}
                </Form.Item>
                <Form.Item label={t('MEDIA_COPYRIGHT_URL')}>
                    {getFieldDecorator('properties.copyrightUrl', {})(<Input />)}
                </Form.Item>
                <Form.Item label={t('EMBEDDED')}>
                    {getFieldDecorator('embed', {})(<Input />)}
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.onSubmit} type={`primary`}>{t('SAVE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedExternal = Form.create({ name: 'External' })(External);
export default WrappedExternal;
