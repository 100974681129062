import React from 'react';
import { Input, Button } from 'antd';
import { t } from './../../core/library/i18n';
// import {PU} from "../../core/permissions";

export default class AuthorsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { type } = this.props;
        return (
            <div className={`app--filter authors--filter`}>
                {type !== 'TAGS' && (
                    <div className={`app-filter--col authors--filter--col`}>
                        <Button type={`primary`} onClick={this.props.onCreate}>{t('CREATE_BTN')}</Button>
                    </div>
                )}
                {type === 'TAGS' && (<div className={`app-filter--col authors--filter--col`}><Button type={`primary`} onClick={this.props.onCreate}>{t('CREATE_BTN')}</Button></div>)}
                <div className={`app-filter--col authors--filter--col search--col`}>
                    <Input.Search onSearch={this.props.onSearch} enterButton={true} placeholder={t('TITLE_OR_URL')}/>
                </div>
            </div>
        );
    }
}


AuthorsFilter.defaultProps = {
    onCreate: () => {},
    onSearch: () => {},
};
