export default function (p) {
    return (
        `<div data-title="${p.title}" class="rmedia rmedia--image vmgallery mceNonEditable">
            <div class="button danger deleteMedia mceNonEditable"></div>
        </div>
       `
    );
};


