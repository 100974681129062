import React from 'react';
import {Typography, Table, Tag, message} from "antd";
import {Link} from "react-router-dom";
import Request from './../../core/fetch';

export class ConverterIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],
        };
        this.columns = [
            {
                title: 'Type',
                dataIndex: 'type',
                render: (t, row) => {
                    return <Link to={`/documents/${row.type.toLowerCase()}`}>{t}</Link>;
                },
            },
            {
                title: 'Title',
                dataIndex: 'title',
                render: (t, row) => {
                    let params = [];
                    console.log(row);
                    if (row.title_locale) {
                        for(let i in row.title_locale) {
                            params.push(row.title_locale[i])
                        }
                    }
                    return <Link to={`/documents/${row.type.toLowerCase()}/${row.id}`}>{params.join(' / ')}</Link>;
                },
            },
            {
                title: 'Step',
                dataIndex: 'transcoder_step',
                render: (t, row) => {
                    return t ? <Tag color={'orange'}>{t}</Tag> : <Tag>pending</Tag>;
                },
            },
            {
                title: 'Status',
                dataIndex: 'transcoder_status',
                render: (t, row) => {
                    return t ? <Tag color={`green`}>{t === 'process' ? 'process' : t}</Tag> : <Tag>pending</Tag>;
                },
            },
            {
                title: 'Percent',
                dataIndex: 'transcode_progress',
                render: (t, row) => {
                    return `${t || 0}%`;
                },
            }
        ];
    }

    loadList =  () => {
        if (this.state.loading === false) {
            this.setState({loading: true}, async () => {
                try {
                    let resp = await Request("/v1/converter")
                    this.setState({
                        loading: false,
                        items: resp.data && resp.data.list ? resp.data.list : []
                    }, () => {
                        setTimeout(() => {
                            this.loadList();
                        }, 5000);
                    });
                } catch(e) {
                    message.error(`Failed to load converter list`);
                    this.setState({ loading: false })
                }
            })
        }
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        return (
            <div className={`converter`}>
                <Typography.Title level={1}>Converter [Queue: {this.state.items.length}]</Typography.Title>
                <div className="converter_table">
                    <Table
                        loading={this.state.loading}
                        columns={this.columns}
                        bordered
                        dataSource={this.state.items}
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: 500,
                            total: 500,
                            hideOnSinglePage: true,
                        }}
                    />
                </div>
            </div>
        );
    }
}