
import React from 'react';
import { Button, Form, Input, Select, Popconfirm } from 'antd';

export class ContactsBlock extends React.Component {
	constructor(props) {
		super(props);
		const itemText = this.props.item && this.props.item.text ? this.props.item.text : "";
		let contacts = [];

		if (itemText) {
			contacts = JSON.parse(itemText);
		}

		this.state = {
			contacts
		};
	}

	onAddContact = () => {
		let contacts = [...this.state.contacts];
		contacts.push({type: '', value: '', comment: ''})
		this.setState({ contacts });
	}

	onChangeValue = (value, index) => {
		let contacts = [...this.state.contacts];
		if (contacts[index]) {
			contacts[index].value = value;
			this.setState({ contacts });
		}
	}

	onChangeType = (type, index) => {
		let contacts = [...this.state.contacts];
		if (contacts[index]) {
			contacts[index].type = type;
			this.setState({ contacts });
		}
	}

	onChangeComment = (comment, index) => {
		let contacts = [...this.state.contacts];
		if (contacts[index]) {
			contacts[index].comment = comment;
			this.setState({ contacts });
		}
	}



	onDeleteRecords = (index) => {
		let contacts = [...this.state.contacts];
		contacts.splice(index, 1);
		this.setState({ contacts });
	}

	
	render() {
		return (
			<div className="contacts__form--block">
					{this.state.contacts.map((i, index) => {
						return (
							<div className="contact__block--row" key={`item_${index}`}>
								<Form.Item>
									{this.props.decorator(`contact_types[${index}]`, {
										rules: [{required: true, message: 'Выберите тип'}],
										initialValue: i.type,
									})(
										<Select
											placeholder={`Выберите тип`}
											style={{width: 230}}
											onChange={(value) => {
												this.onChangeType(value, index);
											}}
										>
											<Select.Option value="phone">Телефон</Select.Option>
											<Select.Option value="email">Email</Select.Option>
											<Select.Option value="vk">VK</Select.Option>
											<Select.Option value="facebook">Facebook</Select.Option>
											<Select.Option value="instagram">Instagram</Select.Option>
											<Select.Option value="telegram">Telegram</Select.Option>
											<Select.Option value="other">Другое</Select.Option>
										</Select>
									)}
									{this.props.decorator(`contact_values[${index}]`, {
										rules: [{required: true, message: `укажите контакт`}],
										initialValue: i.value,
									})(
										<Input
											placeholder={`Введите контакт`}
											onChange={(e) => {this.onChangeValue(e.target.value, index)}}
										/>
									)}
									{this.props.decorator(`contact_comments[${index}]`, {
										initialValue: i.comment,
									})(
										<Input
											placeholder={`Комментарий`}
											onChange={(e) => {this.onChangeComment(e.target.value, index)}}
										/>
									)}
									<div className={`citem--contacts`}>
										<Popconfirm title={`Вы уверены?`} okText={`Да`} cancelText={`Нет`} onConfirm={() => this.onDeleteRecords(index)}>
											<Button icon={'delete'} type={`danger`} />
										</Popconfirm>
									</div>
								</Form.Item>
							</div>
						);
					})}

				<Button icon={'plus'} onClick={this.onAddContact}>Добавить</Button>
			</div>
		);
	}
}

export default ContactsBlock;