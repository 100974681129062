import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class AddBattle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('Title'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'textarea',
                'label': t('SUMMARY'),
                'name': 'summary',
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('SUMMARY_PLACEHOLDER'),
                    'autosize': {
                        minRows: 6,
                    },
                }
            },
            {
                'component': 'input_number',
                'label': t("READ_TIME"),
                "name": "options.read_time",
                "use_formater": "мин.",
                "props": {
                    "min": 1
                }
            },
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'selected date',
                    showTime: true,
                },
                'rules': [
                    {required: true, message: 'Please input your username!'},
                ]
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'props': {
                    'placeholder': t('SLUG_PLACEHOLDER')
                },
                'rules': [
                    {required: true, message: t('SLUG_FILL_ERROR')},
                ]
            },
            {
                'component': 'autocomplete',
                'type': 'Meaning',
                'limit': 15,
                'label': t('OPINIONS'),
                'name': 'crosslinks',
                'props': {
                    placeholder: t('SELECT_OPINIONS'),
                    allowClear: true,
                    showSearch: true,
                    mode: 'multiple',
                },
            },
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                />
            </div>
        );
    }
}

export default AddBattle;
