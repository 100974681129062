import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";
import Config from "../../../config";

export class RegionMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'tree_select',
                'name': 'options.regions',
                'label': 'Опубликовать на сайты',
                'type': 'multiple',
                'is_object': true,
                'data': Config.REGION_SITES,
            },
            {
                'component': 'media',
                'type': 'RegionCover',
                'searchType': 'RegionCover,RegionImage,Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'editor',
                'label': t('TEXT'),
                'name': 'text',
                'props': {
                    'mediaTypesImage': 'RegionCover,RegionImage',
                    'mediaVideos': 'RegionVideo',
                },
            },
            {
                'component': 'static_autocomplete',
                'name': 'options.regions_categories',
                'label': 'Категории',
                'type': 'multiple',
                'data': Config.REGION_SITES_CATEGORIES,
                'rules': [
                    {required: true, message: 'Укажите категорию'},
                ]
            },
            {
                'component': 'static_autocomplete',
                'name': 'options.regions_system_categories',
                'label': 'Служебные категории',
                'data': Config.REGION_SITES_CATEGORIES_SYSTEM,
                'rules': [
                    {required: true, message: 'Укажите категорию'},
                ]
            },
            {
                'component': 'static_autocomplete',
                'name': 'options.regions_tags',
                'label': 'Теги',
                'type': 'multiple',
                'data': Config.REGION_SITES_TAGS,
            },
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'selected date',
                    showTime: true,
                },
                'rules': [
                    {required: true, message: 'Please input your username!'},
                ]
            },
            {
                'component': 'group',
                'label': t('COORDS_GROUP'),
                'fields': [
                    {
                        'component': 'coords',
                        'label': t('COORDS'),
                        'name': 'geo',
                        'props': {
                            onClear: (index = 0) => {
                                this.props.setFields('geo', []);
                            },
                        }
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'MCat',
                        'limit': 40,
                        'label': t('MCAT_FIELD'),
                        'name': 'mcats',
                        'props': {
                            placeholder: t('SELECT_MCAT'),
                            allowClear: true,
                            showSearch: true,
                        },
                    },
                ],
            },
        ];

    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {
            });
        }
    }

    onSubmit = (values) => {
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }

            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.redirectTo = values.redirectTo || "";
        values.media = media;
        if (this.props.item.options && this.props.item.options["published_posts"]) {
            if (values.options) {
                values.options["published_posts"] = this.props.item.options["published_posts"];
            } else {
                values.options = {
                    "published_posts": this.props.item.options["published_posts"],
                };
            }
        }
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };


    onPublishToRegions = (id) => {
        this.props.onPublishToRegions(id);
    }

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    updateMaterialType={this.props.updateMaterialType}
                    sendPush={this.props.sendPush}
                    hideHistory={true}
                    onPublishToRegions={this.onPublishToRegions}
                />
            </div>
        );
    }
}

export default RegionMaterial;
