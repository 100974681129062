import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Spin, Button, message} from 'antd';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as Actions from "./../../../store/media/actions";
import * as C from './../../../store/media/constants';
import {t} from './../../library/i18n';
import EditForm from './../form_components/media/edit_form';


const mapStateToProps = state => ({
    answer: state.MediaReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class MediaEditModal extends React.Component {
    static defaultProps = {
        item: {},
        visible: true,
        onClose: () => {},
        onMediaSave: null,
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        onClose: PropTypes.func.isRequired,
        visible: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    updateMedia = (values) => {
        this.props.actions.UpdateMedia(this.props.item.id, values);
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.MEDIA_UPDATE_START:
                    this.setState({
                        loading: true,
                    });
                    break;
                case C.MEDIA_UPDATE_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(t('ERROR_UPDATE_MEDIA'));
                    });
                    break;
                case C.MEDIA_UPDATE_FINISH:
                    this.setState({ loading: false }, () => {
                        message.success(`Элемент успешно обновлен`);
                        this.props.onClose(p.answer.data.data);
                    });
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
            <Modal
                width={'80%'}
                title={t('EDIT_MEDIA')}
                visible={this.props.visible}
                destroyOnClose={true}
                onCancel={this.props.onClose}
                footer={[
                    <Button key="back" onClick={this.props.onClose}>
                        {t('CLOSE')}
                    </Button>,
                ]}
            >
                <div className={`media--update__modal`}>
                    <Spin spinning={this.state.loading}>
                        {this.props.item && this.props.item.id && (
                            <React.Fragment>
                                <EditForm
                                    item={this.props.item}
                                    updateItem={this.updateMedia}
                                    onMediaSave={this.props.onMediaSave}
                                />
                            </React.Fragment>
                        )}
                    </Spin>
                </div>
            </Modal>
        );
    }
}

export const MediaModal = connect(
    mapStateToProps,
    mapDispatchProps
)(MediaEditModal);

export default MediaModal;
