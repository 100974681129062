import React from 'react';
import {Card, Empty, Pagination, Tooltip, Icon, Button, Popconfirm} from 'antd';
import PropTypes from 'prop-types';
import Config from './../../config';
import {t} from './../../core/library/i18n';
import EditModal from '../../core/components/media/editModal';
import Moment from 'moment';

export default class MediaList extends React.Component {

    state = {
        editModal: false,
        editItem: {},
    };

    itemCover = (item) => {
        switch (item.type) {
            case "ExternalVideo":
                let exCoverPath = this.itemLinks(item);
                return (
                    <React.Fragment>
                    {exCoverPath.cover &&
                        <div className={'video-play'}
                             style={{backgroundImage: `url(${exCoverPath.cover})`}}>
                            <Icon type="youtube" theme={'filled'} style={{fontSize: 48, color: 'red'}}/>
                        </div>}
                    {(!exCoverPath.cover) && <Empty description={t("NO_COVER")}/>}
                    </React.Fragment>
                );

            case "Video":
                let pathCover = this.itemLinks(item).cover;
                // if (Config.MEDIA.PREPARE) {
                //     pathCover = Config.MEDIA.PREPARE(pathCover);
                // }
                return (
                    <React.Fragment>
                        {item.files.cover &&
                        <div className={'video-play'} style={{backgroundImage: `url(${pathCover})`}}>
                            <Icon type="play-square" theme={'filled'} style={{fontSize: 48, color: '#52c41a'}}/>
                        </div>}
                        {!item.files.cover && <Empty description={t("NO_COVER")}/>}
                    </React.Fragment>
                );

            case "PDF":
                let pdfCover = this.itemLinks(item).cover;
                return(
                    <React.Fragment>
                        {pdfCover &&
                             <img width={'100%'} src={`${pdfCover}`} alt={'Pic'} loading={'lazy'}/>
                        }
                        {!pdfCover && <Empty description={t("NO_COVER")}/>}
                    </React.Fragment>
                );

            default:
                let ll = this.itemLinks(item);
                let path = this.itemLinks(item).original;
                if (path.cover) {
                    path = path.cover;
                }

                if (ll.cover) {
                    path = ll.cover;
                }


                // if (Config.MEDIA.PREPARE) {
                //     path = Config.MEDIA.PREPARE(path);
                // }
                return (
                    <React.Fragment>
                        {item.files && item.files.original &&
                        <img width={'100%'} src={`${path}`} alt={'Pic'} loading={'lazy'}/>}
                    </React.Fragment>
                )
        }
    };

    itemLinks = (item) => {
        switch (item.type) {
            case "ExternalVideo":

                let tmp = item.files.original.path.split('/');
                let providerID = tmp[tmp.length-1] === '' ? tmp[tmp.length-2] : tmp[tmp.length-1];
                let thumbPath = '';
                if (item.provider.includes('facebook')){
                    thumbPath = `https://graph.facebook.com/${providerID}/picture`
                } else if (item.provider.includes('youtu')) {
                    thumbPath = `https://img.youtube.com/vi/${providerID}/0.jpg`
                }
                return {
                    original: `${item.embed}`,
                    cover: item.files.cover ? `${Config.MEDIA.CDN.IMAGES}/${item.files.original.path}` : thumbPath
                };

            case "Video":
                return {
                    original: `${Config.MEDIA.CDN.VIDEO}/${item.files.original.path}`,
                    cover: item.files.cover ? `${Config.MEDIA.CDN.VIDEO}/${item.files.cover.path}` : Empty.PRESENTED_IMAGE_SIMPLE
                };

            case "PDF":
                return {
                    original: `${Config.MEDIA.CDN.PDF}/${item.files.original.path}`,
                    cover: item.files.xxl ? `${Config.MEDIA.CDN.PDF}/${item.files.xxl.path}` : Empty.PRESENTED_IMAGE_SIMPLE,
                };

            default:
                let cover = item.files && item.files['image-l'] ? item.files['image-l'].path : item.files.original.path;
                return {
                    original: `${Config.MEDIA.CDN.IMAGES}/${item.files.original.path}`,
                    cover: `${Config.MEDIA.CDN.IMAGES}/${cover}`
                }
        }
    };

    prepareMediaInfo = (p) => {
        let result = [];
        if (p.title) result.push(p.title);
        if (p.properties) {
            if (p.properties.copyright) result.push(p.properties.copyright);
            if (p.properties.source) result.push(p.properties.source);
        }
        if(p.description) {
            result.push(p.description)
        }

        if(p.description) {
            result.push(p.description)
        }
        return result.join(' / ');
    };

    fancyList = () => {
        //fancyBox props
        window.$().fancybox({
            selector: '.fbox a:visible',
            buttons: ["zoom", "fullscreen", "thumbs", "close"],
            thumbs: {
                autoStart: true,
                source: item => {
                    return this.itemLinks(item).cover
                }
            },
            youtube: {
                controls: 0,
                showinfo: 0
            },
            vimeo: {
                color: 'f00'
            }
        });
        return (
            this.props.mediaList.filter(item => item.type !== 'Code').map((item, index) => {
                const Info = this.prepareMediaInfo(item);
                return (
                    <Card
                        key={`card_${item.id}`}
                        style={{width: 230, margin: 5, flexGrow: 1, maxWidth: 230}}
                        hoverable
                        cover={
                            <div style={{height: 130, width: '100%', overflow: 'hidden', position: 'relative'}}>
                                <a data-fancybox href={`${this.itemLinks(item).original}`}>
                                    {this.itemCover(item)}
                                    <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', fontSize: 12, background: `rgba(0, 0, 0, 0.6)`, color: '#FFF', padding: 4}} className={`created_at`}>{Moment(item.createdAt).format(Config.DATE_FORMAT)}</div>
                                </a>
                            </div>}
                    >
                        <span>{Info.length > 30 ? <Tooltip title={Info}>{Info.substr(0, 27)}...</Tooltip> : Info}</span>
                        <div style={{marginTop: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Button onClick={() => this.openEditModal(item)} icon={`edit`} type={`primary`} size={`small`} />
                            {(item.type === "Image" || item.type === "Cover") && (<Button onClick={() => {this.getItemOriginalPath(item);}} style={{marginLeft: 4}} icon={`cloud`} size={`small`} type={`primary`} />)}
                            {window.user && window.user.permissions && window.user.permissions.indexOf('HIDE_MEDIA') + 1 > 0 && (
                                <Popconfirm title={t('MEDIA_HIDE_CONFIRM')} okText={t('HIDE')} cancelText={t('CANCEL')} onConfirm={() => {
                                    this.props.hideMedia(item.id);
                                }}><Button  style={{marginLeft: 4}} icon={`delete`} type={`danger`}  size={`small`}/></Popconfirm>
                            )}
                        </div>
                    </Card>
                )
            })
        )
    };

    getItemOriginalPath = (item) => {
        if (item && item.files && item.files.original) {
            window.open(`${Config.MEDIA.CDN.IMAGES}/${item.files.original.path}`);
        }
    };

    closeEditModal = (item) => {
        this.setState({
            editModal: false,
            editItem: {},
        }, () => {
            this.props.reloadMedia();
            // if (item && item.id) {
            //     this.props.updateMedia.set(item);
            // }
        })
    };

    openEditModal = (item) => {
        this.setState({
            editModal: true,
            editItem: item,
        });
    };

    render() {
        let list = this.fancyList();
        return (
            <div style={{marginBottom: 20}}>
                {this.state.editModal && (<EditModal
                    visible={this.state.editModal}
                    onClose={this.closeEditModal}
                    item={this.state.editItem}
                />)}

                <div className={'fbox'}
                     style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'center'}}>
                    {list}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
                    <Pagination
                        total={this.props.totalItems}
                        current={this.props.currentPage}
                        pageSize={this.props.itemsLimit}
                        onChange={this.props.paginator}
                        hideOnSinglePage={true}
                    />
                </div>
            </div>
        )
    }
}

MediaList.defaultProps = {
    mediaList: [],
    currentPage: 1,
    totalItems: 0,
    itemsLimit: 60,
    paginator: () => {
    },
    reloadMedia: () => {
    },
};

MediaList.propTypes = {
    mediaList: PropTypes.arrayOf(Object).isRequired,
    currentPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    itemsLimit: PropTypes.number.isRequired,
    paginator: PropTypes.func.isRequired,
    reloadMedia: PropTypes.func.isRequired,
};
