import React from 'react';
import { Modal, Radio, Icon } from 'antd';
import FormGenerator from './../../../core/components/generators/form';
import {t} from '../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";
import Config from "../../../config";

export class AddBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            previewType: 'desktop',
        };
        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': 'Trend title',
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'textarea',
                'label': t('SUMMARY'),
                'name': 'summary',
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('SUMMARY_PLACEHOLDER'),
                },
            },
            {
                'component': 'input',
                'label': t('REDIRECT_TO'),
                'name': "redirectTo",
                'props': {
                    'type': 'text',
                    'placeholder': 'redirect url',
                },
            },

            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'group',
                'label': t('OTHER'),
                'fields': [
                    {
                        'component': 'switch',
                        'label': t('NEW_WINDOW'),
                        'name': 'options.new_window',
                    }
                ]
            },
           

           
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.media = media;
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    closeModal = () => {
        this.setState({ modal: false });
    }


    render() {
        const { modal, previewType } = this.state;
        let iframe_width = previewType === 'desktop' ? 720 : 375;
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <Modal visible={modal} title={`Превью документа`} width={980} onCancel={this.closeModal} onOk={this.closeModal} destroyOnClose={true}>
                    <div className={`material--preview`}>
                        <Radio.Group defaultValue={previewType} buttonStyle="solid" onChange={(e) => {
                            this.setState({ previewType: e.target.value });
                        }}>
                            <Radio.Button value={`desktop`}><Icon type={`desktop`} /> Desktop (720)</Radio.Button>
                            <Radio.Button value={`mobile`}><Icon type={`mobile`} /> Mobile (375)</Radio.Button>
                        </Radio.Group>
                        <iframe style={{ margin: '20px auto', display: 'inline-block', width: iframe_width}} title={`document preview`} src={`${Config.BASE_DOMAIN}/preview/${this.props.id}?v=${(new Date()).getTime()}`} frameBorder={0} />
                    </div>
                </Modal>
                <FormGenerator
                    showPreview={true}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    onPreview={() => {
                        this.setState({ modal: true });
                    }}
                />
            </div>
        );
    }
}

export default AddBanner;
