import React from 'react';
import { Icon, Tag } from 'antd';

export const Prop = (p) => {
    return (
        <div className={`card--prop`}>
            <Tag>
                {p.icon && <Icon type={p.icon} />}
                {p.title && <label>{p.title}:</label>}
                {p.value && (<div className="value">{p.value}</div>)}
            </Tag>
        </div>
    );
};

export default Prop;
