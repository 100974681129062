import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class AddStopwords extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('STOP_WORD'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': 'STOP WORD',
                },
                'rules': [
                    {required: true, message: t('STOP_WORD_FILL')},
                ]
            }
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    sendPush={false}
                />
            </div>
        );
    }
}

export default AddStopwords;
