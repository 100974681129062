import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {notification} from 'antd';
import * as Actions from "./../../../store/groups/actions";
import * as C from './../../../store/groups/constants';
import propExists from './../../../core/library/objects/getprop';
import GroupsTable from './../../../components/tables/groups';
import {parseUrlParams, generateParamsFromObject} from './../../../core/library/url';
import {t} from './../../../core/library/i18n';
import {AppConsumer} from './../../../context/app';


const mapStateToProps = state => ({
    answer: state.GroupsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


export class GroupsIndexController extends React.Component {
    constructor(props) {
        super(props);
        let urlParams = parseUrlParams();
        let filter = {};

        if (urlParams.search && urlParams.search !== '') {
            filter.search = decodeURIComponent(urlParams.search);
        }
        this.state = {
            'items': [],
            'page': urlParams.page ? parseInt(urlParams.page, 10) : 1,
            'total': 0,
            'limit': 0,
            'isLoading': false,
            'filter': filter,
        };
    }

    onEdit = (id = '') => {
        this.props.history.push(`${this.props.location.pathname}/${id}`);
    };

    onCreate = () => {
        this.props.history.push(`${this.props.location.pathname}/add`);
    };

    updateGroup = (id, data) => {
        this.props.actions.updateGroup(id, data);
    };

    removeGroup = (id) => {
        this.props.actions.deleteGroup(id);
    };

    updatePage = (page) => {
        this.setState({page}, () => {
            this.props.history.push(`${window.location.pathname}?${generateParamsFromObject({...this.state.filter, ...{page: this.state.page}})}`);
            this.props.actions.loadGroups(
                this.state.page,
                this.state.filter
            );
        });
    };

    updateFilter = (f) => {
        this.setState({page: 1, filter: f}, () => {
            this.props.history.push(`${window.location.pathname}?${generateParamsFromObject({...this.state.filter, ...{page: this.state.page}})}`);
            this.props.actions.loadGroups(
                this.state.page,
                this.state.filter
            );
        });
    };

    loadGroups = () => {
        this.props.actions.loadGroups(
            this.state.page,
            this.state.filter
        );
    };

    componentDidMount() {
        this.loadGroups();
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.GROUPS_LOAD_START:
                case C.GROUPS_UPDATE_START:
                case C.GROUPS_DELETE_START:
                    this.setState({isLoading: true});
                    break;
                case C.GROUPS_LOAD_FINISH:
                    this.setState({
                        isLoading: false,
                        list: propExists(p.answer.data, 'data.list') && p.answer.data.data.list ? p.answer.data.data.list : [],
                        total: propExists(p.answer.data, 'data.totalItems') && p.answer.data.data.totalItems ? p.answer.data.data.totalItems : 0,
                        limit: propExists(p.answer.data, 'data.limit') && p.answer.data.data.limit ? p.answer.data.data.limit : 0,
                    });
                    break;

                case C.GROUPS_DELETE_FINISH:
                    notification.success({
                        message: `Group has been deleted`,
                    });

                    this.setState({
                        isLoading: true,
                        list: [],
                    }, () => {
                        this.loadGroups();
                    });
                    break;

                case C.GROUPS_UPDATE_FINISH:
                    notification.success({
                        message: `Group has been updated`,
                    });
                    this.setState({
                        isLoading: true,
                        list: [],
                    }, () => {
                        this.loadGroups();
                    });
                    break;

                case C.GROUPS_DELETE_ERROR:
                    notification.error({
                        message: `Error delete group`,
                    });
                    this.setState({
                        isLoading: true,
                        list: [],
                    }, () => {
                        this.loadGroups();
                    });
                    break;

                case C.GROUPS_UPDATE_ERROR:
                    notification.error({
                        message: `Error update group`
                    });
                    this.setState({
                        isLoading: true,
                        list: [],
                    }, () => {
                        this.loadGroups();
                    });
                    break;

                case C.GROUPS_LOAD_ERROR:
                    notification.error({
                        message: `Error load groups`,
                    });
                    this.setState({
                        isLoading: false,
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={'groups--list'}>
                    <AppConsumer>
                        {context => {
                            return (
                                <GroupsTable
                                    onMounted={() => {
                                        if (context.actions.updateHeader) {
                                            context.actions.updateHeader({
                                                title: t(`GROUPS_LIST`),
                                            })
                                        }
                                    }}
                                    items={this.state.list}
                                    limit={this.state.limit}
                                    page={this.state.page}
                                    total={this.state.total}
                                    filter={this.state.filter}
                                    isLoading={this.state.isLoading}
                                    onUpdate={this.updateGroup}
                                    onDelete={this.removeGroup}
                                    onPageChange={this.updatePage}
                                    onUpdateFilter={this.updateFilter}
                                    onCreate={this.onCreate}
                                    onEdit={this.onEdit}
                                />
                            );
                        }}
                    </AppConsumer>
                </div>
            </React.Fragment>
        );
    }
}

GroupsIndexController.defaultProps = {
    actions: {},
    answer: {},
};

GroupsIndexController.propTypes = {
    actions: PropTypes.instanceOf(Object).isRequired,
    answer: PropTypes.instanceOf(Object).isRequired,
};

export const GroupsIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(GroupsIndexController);

export default GroupsIndex;
