import React from 'react';
import PropTypes from 'prop-types';
import { t } from './../../../core/library/i18n';
import { Table, Button, Switch } from 'antd';
import AuthorsFilter from './../../../components/filters/authors';
import Config from './../../../config';


export class AuthorsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.Columns = [
            {
              title: t('Cover'),
              render: row => {
                  if (row.media && row.media.length > 0) {
                      let path = row.media[0]["files"]["small"] ? row.media[0]["files"]["small"].path : row.media[0]["files"]["original"].path;
                      return <img style={{borderRadius: "3px"}} src={`${Config.MEDIA.CDN.IMAGES}/${path}`} alt={row.title} width={80} height={"auto"} />
                  }
              }
            },
            {
                title: t('TITLE'),
                dataIndex: 'title',
                sorter: true,
            },
            {
                title: t('POSITION'),
                dataIndex: 'summary',
                sorter: false,
            },
            {
                title: t('PUBLIC_ID'),
                dataIndex: 'publicId',
                sorter: true,
            },
            {
                title: t('PUBLISHED'),
                key: 'published',
                sorter: true,
                render: row => {
                    return <Switch size="small" checked={row.published} onChange={(published) => {
                        this.props.onUpdate(row.id, { published });
                    }} />;
                },
            },
            {
                title: t('ACTIONS'),
                key: 'actions',
                width: 120,
                render: row => {
                    return (
                        <Button.Group>
                            <Button type={`primary`} icon={`edit`} onClick={() => {
                                this.props.onEdit(row.id);
                            }}/>
                            <Button type={`danger`} icon={`delete`} onClick={() => {
                                this.props.onDelete(row.id);
                            }}/>
                        </Button.Group>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        const OptionsContext = this.props.options || {};
        if (OptionsContext.actions && OptionsContext.actions.updateHeader) {
            OptionsContext.actions.updateHeader({
                title: t('AUTHORS_LIST'),
            })
        }
    }
    updateFilter = (search) => {
        this.props.onFilter({ search });
    };

    render() {
        return (
            <div className={`app_view_table ${this.props.type}_view`}>
                <div className="app_view--wrapper">
                    <div className={`app_view--table`}>
                        <Table
                            bordered
                            title={() => <AuthorsFilter onCreate={this.props.onCreate} onSearch={this.updateFilter}/>}
                            dataSource={this.props.items}
                            columns={this.Columns}
                            rowKey={(item) => item.id}
                            onChange = {(pagination, filters, sorter) => {
                                let sortOrder = '-createdAt';
                                if(sorter.columnKey) {
                                    sortOrder = `${sorter.order === 'ascend' ? '' : '-'}${sorter.columnKey}`;
                                }
                                this.props.updateSort(sortOrder);
                            }}

                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ["30", "60", "100"],
                                onShowSizeChange: (current, size) => this.props.updateLimit(size),
                                pageSize: this.props.limit,
                                defaultCurrent: this.props.page,
                                total: this.props.total,
                                onChange: this.props.updatePageHandle,
                                current: this.props.page,
                                hideOnSinglePage: true,

                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AuthorsView.defaultProps = {
    options: {
        actions: {
            updateHeader: () => {},
        }
    },
};

AuthorsView.propTypes = {
    options: PropTypes.instanceOf(Object).isRequired,
};


export default AuthorsView;
