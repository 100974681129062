import GetProp from './../objects/getprop';


export const PrepareInitialValue = (item = {}, fields = {}, showLock = false) => {
    fields = fields.map(i => {
        if (i.fields) {
            i.fields = i.fields.map(j => {
                if (j.name && GetProp(item, j.name) !== undefined) {
                    j.initialValue = GetProp(item, j.name);
                }
                j.props = j.props || {};
                j.props.disabled = showLock;
                return j;
            });
        } else {
            if (i.name.indexOf('.') + 1 > 0) {
                i.initialValue = GetProp(item, i.name);
                i.props = i.props || {};
                i.props.disabled = showLock;
            } else {
                if (item[i.name] !== undefined) {
                    i.initialValue = item[i.name];
                }
                i.props = i.props || {};
                i.props.disabled = showLock;
            }

        }
        return i;
    });
    return fields;
};

export default PrepareInitialValue;

