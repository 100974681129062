import React from "react";
import FormGenerator from "./../../../core/components/generators/form";
import { t } from "./../../../core/library/i18n";
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddInfographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reload: false };
    this.Fields = [
      {
        component: "input",
        label: t("TITLE"),
        name: "title",
        show_count: true,
        props: {
          type: "text",
          placeholder: t("TITLE_PLACEHOLDER"),
          onBlur: (e) => {
            if (e.target.value !== this.props.item.title) {
              // this.props.onUpdate({title: e.target.value}, true);
            }
          },
        },
        rules: [{ required: true, message: t("TITLE_ERROR_FILL") }],
      },
      // {
      //     'component': 'textarea',
      //     'label': t('SUBTITLE'),
      //     'name': "subtitle",
      //     "show_count": true,
      //     'props': {
      //         'type' : 'text',
      //         'placeholder': 'Sub title',
      //     }
      // },
      {
        component: "textarea",
        label: t("SUMMARY"),
        name: "summary",
        show_count: true,
      },
      {
        component: "autocomplete",
        type: "Author",
        limit: 30,
        label: t("AUTHORS"),
        name: "authors",
        props: {
          placeholder: t("AUTHORS_PLACEHOLDER"),
          mode: "multiple",
          allowClear: true,
          showSearch: true,
        },
        rules: [{ required: true, message: t("SELECT_AUTHORS_ERROR") }],
      },
      {
        component: "media",
        type: "Cover",
        searchType: "Cover,Image",
        label: t("COVER_LABEL"),
        name: "media",
      },
      {
        component: "editor",
        label: t("TEXT"),
        name: "text",
        props: {},
      },
      {
        component: "slug",
        label: t("SLUG"),
        name: "slug",
        source_field: "title",
        rules: [{ required: true, message: t("SLUG_ERROR") }],
      },
      {
        component: "autocomplete",
        label: t("INFOGRAPHIC_MATERIAL"),
        name: "crosslinks",
        type: "News,Article,Meaning",
        limit: 15,
        props: {
          placeholder: t("SELECT_MATERIAL"),
          allowClear: true,
          showSearch: true,
        },
        // 'rules': [
        //     {required: true, message: t('SELECT_MATERIAL')},
        // ]
      },
    ];

    if (this.props.type !== "gallery") {
      this.Fields.push({
        component: "autocomplete",
        type: "Category",
        limit: 15,
        label: t("CATEGORY"),
        name: "category",
        props: {
          placeholder: t("SELECT_CATEGORIES"),
          allowClear: true,
          showSearch: true,
        },
        rules: [{ required: true, message: t("SELECT_CATEGORIES") }],
      });
    }
    this.Fields = [
      ...this.Fields,
      ...[
        {
          component: "datepicker",
          label: t("PUBLISHED_AT"),
          name: "publishedAt",
          props: {
            placeholder: "selected date",
            showTime: true,
          },
        },
        {
              'component': 'group',
              'label': t('PUBLISH_IN'),
              'fields': [
                {
                  'component': 'checkbox',
                  'label': t('PUBLISH_AT_MAIN'),
                  'name': 'options.main',
                },
              ]
        },
        {
          'component': 'group',
          'label': t('SEO_AND_VIEW'),
          'fields': [
            {
              'component': 'input',
              'label': t('SEO_TITLE'),
              'name': "seo.metaTitle",
              'props': {
                'type': 'text',
                'placeholder': 'seo title',
              },
            },
            {
              'component': 'textarea',
              'label': t('SEO_DESCRIPTION'),
              'name': "seo.metaDescription",
              'props': {
                'type': 'text',
                'placeholder': 'seo description',
              },
            },
            {
              'component': 'input',
              'label': t('REDIRECT_TO'),
              'name': "redirectTo",
              'props': {
                'type': 'text',
                'placeholder': 'redirect url',
              },
            }
          ],
        }
      ],

    ];
  }

  componentWillReceiveProps(p) {
    if (p.item && p.item.id) {
      this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
    }
    if (p.showLock !== this.props.showLock) {
      this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
      this.setState({ reload: !this.state.reload }, () => {});
    }
  }

  onSubmit = (values) => {
    let result_values = {};
    Object.keys(values).map((i) => {
      if (i !== "text" && values[i] !== "") {
        result_values[i] = values[i];
      }
      if (i === "text") {
        result_values[i] = values[i];
      }

      if (i === "summary") {
        result_values[i] = values[i];
      }
      return i;
    });

    values = result_values;

    let media = [];
    if (values.media) {
      media = Object.keys(values.media).map((i) => values.media[i]);
    }
    values.media = media;
    if (this.props.item && this.props.item.id) {
      this.props.onUpdate(values);
    } else {
      this.props.onCreate(values);
    }
  };

  render() {
    return (
      <div className={`app_add--view add_${this.props.type}`}>
        <FormGenerator
          showLock={this.props.showLock}
          fields={this.Fields}
          onSubmit={this.onSubmit}
          onUpdate={this.props.onUpdate}
          onPreview={this.props.onPreview}
          isEdit={this.props.isEdit}
          loading={this.props.loading}
          item={this.props.item}
          showPreview={true}
          updateStash={this.props.updateStash}
          updateMedia={this.props.updateMedia}
          updateItem={this.props.updateItem}
          updateMaterialType={this.props.updateMaterialType}
          sendPush={this.props.sendPush}
        />
      </div>
    );
  }
}

export default AddInfographics;
