import React from 'react';
import {Form, Input} from 'antd';


const TextareaWithoutCounter = (p) => {
    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                rules: p.rules || [],
                initialValue: p.initialValue ? p.initialValue : "",
            })(
                <Input.TextArea {...p.props} />
            )}
        </Form.Item>
    );
};


const CounterText = (p) => {
    let value = p.form.getFieldValue(p.name);
    if (value) return value.length;
    return 0;
};

const TextareaWithCounter = (p) => {
    return (
        <Form.Item label={p.label || ""}>
            <div className={`textarea textarea--counter`}>
                {p.decorator(p.name, {
                    rules: p.rules || [],
                    initialValue: p.initialValue ? p.initialValue : "",
                })(<Input.TextArea {...p.props}/>)}
                <div className={"counter"}>{CounterText(p)}</div>
            </div>
        </Form.Item>
    );
};

export const Textarea = (p) => {
    return p.show_count ? <TextareaWithCounter {...p}/> : <TextareaWithoutCounter {...p}/>
};
