import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import * as List from './../../../core/components/list';
import * as Filters from './../../../core/components/filter';
import { t } from '../../../core/library/i18n';
import Config from './../../../config';



const PaginationList = (p) => {
    return (
        <Pagination
            onChange={p.updatePageHandle}
            total={p.total}
            pageSize={p.limit}
            defaultCurrent={p.page}
            current={p.page}
            hideOnSinglePage={true}
            showTotal={(total) => `${total} ${t('DOCUMENTS')}`}
        />
    );
};

export class DefaultViewVM extends React.Component {
    static defaultProps = {
        items: [],
        limit: 0,
        total: 0,
        page: 0,
        filter: {},
        updatePageHandle: (page) => {},
        onFilter: (values) => {},
        onCreate: () => {},
        onDelete: () => {},
        onView: () => {},
        onEdit: () => {},
    };

    static propTypes = {
        items: PropTypes.arrayOf(Object).isRequired,
        filter: PropTypes.instanceOf(Object).isRequired,
        total: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        updatePageHandle: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onView: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    getListComponent = () => {
        let cName = `${this.props.type}_list`;
        if(Config.PROJECT_PREFIX) {
            cName = `${this.props.type}_list_${Config.PROJECT_PREFIX}`;
        }
        if (List[cName]) {
            return List[cName];
        }
        return List['default_list'];
    };


    render() {
        const paginationProps = {
            total: this.props.total,
            limit: this.props.limit,
            page: this.props.page,
            updatePageHandle: this.props.updatePageHandle,
        };

        const ListComponent = this.getListComponent();
        const FilterComponent = (Filters[`${this.props.type}_filter`]) ? Filters[`${this.props.type}_filter`] : Filters['default_filter'];
        return (
            <div className={`app_view ${this.props.type}_view`}>
                {FilterComponent && <div className={`app_view--filter`}><FilterComponent onCreate={this.props.onCreate} filter={this.props.filter} onFilter={this.props.onFilter}/></div>}
                <div className={`app_view--list`}>
                    <div className={`pagination`}><PaginationList { ...paginationProps }/></div>
                    <div className={`list`}>
                        <ListComponent {...this.props} />
                    </div>
                    <div className={`pagination`}><PaginationList { ...paginationProps }/></div>
                </div>
            </div>
        );
    }
}

export default DefaultViewVM;
