import React from 'react';
import { Form, Button, Input, Radio, Checkbox, AutoComplete } from 'antd';
import { t } from '../../../library/i18n';
import Config from '../../../../config';
import Cropper from "../../../../components/cropper";
import { getUnique, buildCopyright } from '../../../../core/library/helpers';


const RenderImage = (p) => {
    return (<><img src={p.content} alt={`cover preview`} />{p.isUpload && <Button onClick={p.openCropModal} style={{marginTop: 12}} type={`primary`} icon={"edit"}>Кадрировать</Button>}</>);
};

const RenderVideo = (p) => {
    return <video src={p.content} controls={true}/>;
};


class UploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filepath: '',
            content: '',
            originalFile: null,
            isUpload: false,
            cropModal: false,
            uploadImage: null,
        };
    }

    isImage = (name) => {
        let file_params = name.split('.');
        const ext = file_params[file_params.length - 1].toLowerCase();
        return ["jpg", "jpeg", "png", "gif"].indexOf(ext) + 1 > 0;
    };

    componentDidMount() {
        this.setState({
            originalFile: this.props.file,
            content: window.URL.createObjectURL(this.props.file),
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.uploadFile(values);
            }
        });
    };

    openCropModal = () => {
        let reader = new FileReader();
        reader.onload = (f) => {
            this.setState({ uploadImage: reader.result, cropModal: true });
            // this.setState({uploadImage: reader.result, cropModal: true});
        }
        reader.readAsDataURL(this.state.originalFile);
    }

    onCropClose = () => {
        this.setState({ cropModal: false });
    }

    onCropFinish = (image, base64) => {
        this.setState({ cropModal: false, content: base64 }, () => {
            this.props.onUpdateFile(image, base64)
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { cropModal, uploadImage } = this.state;
        const { file } = this.props;

        return (
            <div className={`upload--input__form`}>
                <Cropper
                    visible={cropModal}
                    image={uploadImage}
                    onClose={this.onCropClose}
                    onFinish={this.onCropFinish}
                    name={file.name}
                    type={file.type}
                />
                <div className="form--preview">
                    {this.isImage(this.props.file.name) ? (<RenderImage content={this.state.content} isUpload openCropModal={this.openCropModal}/>) : (<RenderVideo content={this.state.content}/>)}
                </div>
                <div className={"form"}>
                    <Form onSubmit={this.onSubmit}>
                        <Form.Item label={t('MEDIA_TITLE')}>
                            {getFieldDecorator('title', {})(<Input placeholder={t('FILL_MEDIA_TITLE')}/>)}
                        </Form.Item>
                        <Form.Item label={t('MEDIA_SOURCES')}>
                            {getFieldDecorator('properties.source', {})(
                                <Radio.Group>
                                    {Config.MEDIA.SOURCES && Config.MEDIA.SOURCES.map(i => {
                                        return <Radio value={i} key={`media_source_${i}`}>{i}</Radio>
                                    })}
                                </Radio.Group>
                            )}

                        </Form.Item>
                        <Form.Item label={t('MEDIA_COPYRIGHT')}>
                            {getFieldDecorator('properties.copyright', {
                                initialValue: buildCopyright(file)
                            })(
                                <AutoComplete
                                    backfill={true}
                                    dataSource={getUnique(Config.PHOTO_AUTHORS)}
                                    filterOption={true}><Input/></AutoComplete>
                            )}
                        </Form.Item>
                        <Form.Item label={t('MEDIA_COPYRIGHT_URL')}>
                            {getFieldDecorator('properties.copyrightUrl', {})(<Input placeholder={t('MEDIA_COPYRIGHT_URL')}/>)}
                        </Form.Item>
                        {this.isImage(this.props.file.name) && (
                            <Form.Item label={'Скрыть через 24 часа'}>
                                {getFieldDecorator('expired', {
                                    valuePropName: 'checked',
                                })(<Checkbox />)}
                            </Form.Item>
                        )}

                        {this.isImage(this.props.file.name) === false && (
                            <Form.Item label={t('MEDIA_ADD_WATERMARK')}>
                                {getFieldDecorator('watermark', {})(<Checkbox />)}
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button.Group>
                                <Button type={`danger`} icon={"delete"} onClick={this.props.deleteFile}>{t("MEDIA_DELETE")}</Button>
                                <Button type={`primary`} icon={"cloud"} htmlType={'submit'}>{t("MEDIA_UPLOAD")}</Button>
                            </Button.Group>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}


export default Form.create({ name: 'upload_form' })(UploadForm);
