import React from 'react';
import PropTypes from 'prop-types';
import {Select, Button, Transfer, Form} from 'antd'

export default class PermissionsSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPermissions: this.props.selectedPermissions || [],
            group: null,
        };
    }

    renderItem = (item) => {
        return {
            label: `${item.title} [${item.code}]`,
            value: item.id,
        };
    };

    changeGroupHandle = (group_id) => {
        let group = this.props.groups.filter(i => i.id === group_id);
        let permissions = [];
        if (group.length > 0) {
            permissions = group[0].permissions && group[0].permissions.length > 0 ? group[0].permissions : [];
        }

        if (permissions.length > 0) {
            permissions = permissions.map(i => {
                return (i.id) ? i.id : i;
            });
        }

        this.setState({
            group: group_id,
            selectedPermissions: permissions,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.updatePermissions(this.state.selectedPermissions, this.state.group);
    };

    transferHandleChange = (selectedPermissions) => {
        this.setState({ selectedPermissions });
    };

    render() {
        return (
            <React.Fragment>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={`Copy permissions from group`}>
                        <Select onChange={this.changeGroupHandle} defaultValue={this.props.groupId}>
                            <Select.Option value={""}>Select group</Select.Option>
                            {this.props.groups && this.props.groups.map(i => {
                                return <Select.Option value={i.id} key={`group_${i.id}`}>{i.title}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Transfer
                            showSearch
                            titles={['Available', 'Selected']}
                            dataSource={this.props.permissions}
                            targetKeys={this.state.selectedPermissions}
                            render={this.renderItem}
                            onChange={this.transferHandleChange}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                        />
                    </Form.Item>
                    <Button type={"primary"} htmlType={"submit"}>Save</Button>
                </Form>
            </React.Fragment>
        );
    }
}

PermissionsSelect.defaultProps = {
    groups: [],
    permissions: [],
    selectedPermissions: [],
    updatePermissions: () => {},
};

PermissionsSelect.propTypes = {
    groups: PropTypes.arrayOf(Object).isRequired,
    permissions: PropTypes.arrayOf(Object).isRequired,
    selectedPermissions: PropTypes.instanceOf(Array).isRequired,
    updatePermissions: PropTypes.func.isRequired,
};
