import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select } from 'antd';
import { t } from './../../../core/library/i18n';

class CreateGroupForm extends React.Component {
    static defaultProps = {
        item: {},
        form: {},
        permissions: [],
        onCreate: (data) => {},
        onUpdate: (id, data) => {},
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        permissions: PropTypes.arrayOf(Object).isRequired,
        onCreate: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.item.id) {
                    this.props.onUpdate(this.props.item.id, values);
                } else {
                    this.props.onCreate(values);
                }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={t('GROUP_TITLE')}>
                    {getFieldDecorator('title', {
                        initialValue: this.props.item.title || "",
                        rules: [
                            {required: true, message: t('GROUP_TITLE_FILL')}
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={t('GROUP_PERMISSIONS')}>
                    {getFieldDecorator('permissions', {
                        initialValue: this.props.item.permissions || [],
                    })(
                        <Select mode={`multiple`}>
                            {this.props.permissions.map(i => {
                                return <Select.Option key={`permission_${i.id}`} value={i.id}>{i.title}</Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button htmlType={`submit`} type={`primary`}>{this.props.item.id ? t('UPDATE') : t('CREATE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'CreateGroupForm' })(CreateGroupForm);

