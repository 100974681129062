import React from 'react';
import {Empty, Select, Spin, message, Button, Form} from "antd";
import {t} from "../../library/i18n";
import {documentsElLoadDocs} from "../../../store/documents/api";
import Moment from 'moment';
import Config from '../../../config';

export class Crosslinks extends React.Component{
    state = {
        search: '',
        list: [],
        selected: [],
        limit: 10,
        total: 0,
        page: 0,
        loading: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            list: [],
            selected: this.props.initialValue || [],
            limit: 30,
            total: 0,
            page: 0,
            loading: false,
        }
    }

    prepareName = (i) => {
        return `[${t(i.type)}] ${i.title} / ${Moment(i.publishedAt).format(Config.DATE_FORMAT)}`;
    }

    loadList = () => {
        this.setState({ loading: true }, async () => {
            try {
                const { search, page, limit } = this.state;
                let resp = await documentsElLoadDocs('Podcasts', page, { searchv: search, published: true }, '-publishedAt', limit);
                this.setState({
                    loading: false,
                    list: resp.list || [],
                    total: resp.total || 0,
                })
            } catch(e) {
                message.error(`Ошибка загрузки материалов`);
                this.setState({ loading: false });
            }
        })
    }

    updateFields = () => {
        let setFields = {};
        setFields[this.props.name] = this.state.selected.map(i => i.id);
        console.log(this.props);
        this.props.form.setFieldsValue(setFields);
    }

    onAddDocument = (id) => {
        let list = this.state.list.filter(i => i.id === id);
        if (list && list.length > 0) {
            let ids = this.state.selected.map(i => i.id);
            if (ids.indexOf(list[0].id) + 1 === 0) {
                this.setState({selected: [...this.state.selected, ...list]}, () => {
                   this.updateFields();
                });
            }
        }
    }

    onDeleteDocument = (id) => {
        let selected = this.state.selected.filter(i => i.id !== id);
        this.setState({ selected }, () => {
            this.updateFields();
        });
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        const { list, loading, selected } = this.state;
        return (
            <div className={`crosslinks`}>
                <React.Fragment>
                    <p>Используйте селектор для поиска и выбора документов, которые вы хотите добавить в подкасты по теме.</p>
                    <div style={{display: 'none'}}>
                        <Form.Item>
                            {this.props.decorator(this.props.name, this.props.props)(
                                <Select {...this.props.props} />
                            )};
                        </Form.Item>
                    </div>
                    <div className={`cross_links_search`}>
                        <Select
                            showSearch
                            allowClear
                            value={""}
                            placeholder={`Введите заголовок документа`}
                            notFoundContent={loading ? <Spin /> : <Empty description={t('EMPTY_DOCUMENTS')}/>}
                            filterOption={this.props && this.props.props.filterOption ? this.props.props.filterOption : false}
                            onSearch={(search) => {
                                this.setState({ search, page: 1 }, () => {
                                    this.loadList();
                                })
                            }}
                            onChange={(id) => {
                                this.onAddDocument(id);
                            }}
                        >
                            {list.map(i => {
                                return !i.title ? null : <Select.Option key={`doc_${i.id}`} value={i.id}>{this.prepareName(i)}</Select.Option>
                            })}
                        </Select>
                    </div>
                    <div className="cross_link__list">
                        {selected.map(i => {
                            return (
                                <div className={`list__item`}>
                                    <div className="list__item-title"><a href={`/documents/${i.type.toLowerCase()}/${i.id}`} target={`_blank`} rel={`nofollow`}>{i.title}</a></div>
                                    <div className="list__item-actions">
                                        <Button type={`danger`} icon={`delete`} onClick={() => {
                                            this.onDeleteDocument(i.id);
                                        }}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </React.Fragment>
            </div>
        );
    }
}
