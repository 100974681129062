import React from 'react';
import { Form, Switch } from 'antd';

export const CheckboxInput = (p) => {
    const dValue = p.defaultChecked ? p.defaultChecked : false;
    let v = typeof p.initialValue !== "undefined" ? p.initialValue : dValue;
    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                rules: p.rules || [],
                valuePropName: 'checked',
                initialValue: v,
            })(
                <Switch {...p.props}/>
            )}
        </Form.Item>
    );
};

export default CheckboxInput;
