import * as C from './constants';
import * as API from './api';
import { __loadAllPermissions as LoadAllPermissions } from './../permissions/api';
import { __loadGroups as LoadGroups } from './../groups/api';

export function LoadSelf() {
    return {
        type: "PROMISE",
        actions: [C.USER_START_LOAD_INFO, C.USER_FINISH_LOAD_INFO, C.USER_ERROR_LOAD_INFO],
        promise: API.GetUser(),
    };
}

export function LoadUsers(page = 1, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_LOAD, C.USERS_FINISH_LOAD, C.USERS_ERROR_LOAD],
        promise: API.loadUsers(page, filter),
    };
}

export function LoadUserInfo(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_LOAD_INFO, C.USERS_FINISH_LOAD_INFO, C.USERS_ERROR_LOAD_INFO],
        promise: API.loadUserInfo(id),
    };
}

export function UpdateUser(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_UPDATE, C.USERS_FINISH_UPDATE, C.USERS_ERROR_UPDATE],
        promise: API.updateUsers(id, data),
    };
}

export function LoadPermissions() {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_LOAD_PERMISSIONS, C.USERS_FINISH_LOAD_PERMISSIONS, C.USERS_ERROR_LOAD_PERMISSIONS],
        promise: LoadAllPermissions(),
    };
}

export function LoadUsersGroups() {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_LOAD_GROUPS, C.USERS_FINISH_LOAD_GROUPS, C.USERS_ERROR_LOAD_GROUPS],
        promise: LoadGroups(-1, {})
    };
}


export function CreateUser(data) {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_CREATE, C.USERS_FINISH_CREATE, C.USERS_ERROR_CREATE],
        promise: API.createUser(data)
    };
}


export function DeleteUser(id) {
    return {
        type: `PROMISE`,
        actions: [C.USERS_START_DELETE, C.USERS_FINISH_DELETE, C.USERS_ERROR_DELETE],
        promise: API.deleteUser(id)
    };
}