import Fetcher from './../../core/fetch/'
import {generateParamsFromObject} from "../../core/library/url";


export function loadReport(filter = {}, page = 0, sort = 'publishedAt', byDate = false) {
    let prepareFilter = generateParamsFromObject(filter);
    return Fetcher(`reports/docs?page=${page}&sort=${sort}&${prepareFilter}&bydate=1`, `GET`)
}


export function loadTable() {
    return Fetcher(`reports/table`, `GET`);
}

export function removeTable(id = '') {
    return Fetcher(`reports/table/${id}`, `DELETE`);
}

export function getReport(id = '') {
    return Fetcher(`reports/table/${id}`, `GET`);
}

export function loadReportVideo(filter = {}) {
    let prepareFilter = generateParamsFromObject(filter);
    return Fetcher(`reports/video?${prepareFilter}`, `GET`)
}
