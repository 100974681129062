import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

class GroupsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmitHandle = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            this.props.onUpdateFilter(values);
        });
    };

    clearFormFilter = (e) => {
        this.props.onUpdateFilter({});
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmitHandle} layout={"vertical"} className={"filter filter--permissions"}>
                    <div className="filter--column create-new">
                        <Button htmlType={"button"} type={"primary"} onClick={this.props.createClickHandle}>
                            Create new
                        </Button>
                    </div>
                    <div className="filter--column search--column">
                        {getFieldDecorator('search', {
                            initialValue: this.props.filter.search || "",
                        })(
                            <Input placeholder={"Fill name, description"}/>
                        )}
                    </div>
                    <div className="filter--column btn-column">
                        <Button htmlType={"submit"} type={"primary"}>
                            Search
                        </Button>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}


GroupsFilter.defaultProps = {
    form: {},
    filter: {},
    createClickHandle: () => {},
    onUpdateFilter: () => {},
};

GroupsFilter.propTypes = {
    form: PropTypes.instanceOf(Object).isRequired,
    filter: PropTypes.instanceOf(Object).isRequired,
    createClickHandle: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
};


export default Form.create({name: 'groups_filter'})(GroupsFilter);
