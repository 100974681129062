import * as API from './api';
import * as C from './constants';

export function LoadProfiles(page = 1, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.PROFILES_LOAD_START, C.PROFILES_LOAD_FINISH, C.PROFILES_LOAD_ERROR],
        promise: API.loadProfiles(page, filter),
    };
}

export function LoadGroups(page = 1, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.GROUPS_LOAD_START, C.GROUPS_LOAD_FINISH, C.GROUPS_LOAD_ERROR],
        promise: API.loadGroups(page, filter),
    };
}


export function LoadPermissions(page = 1, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.PERMISSIONS_LOAD_START,C.PERMISSIONS_LOAD_FINISH,C.PERMISSIONS_LOAD_ERROR],
        promise: API.loadPermissions(page, filter),
    }
}


export function CreatePermission(data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.PERMISSIONS_CREATE_START, C.PERMISSIONS_CREATE_FINISH, C.PERMISSIONS_CREATE_ERROR],
        promise: API.createPermission(data),
    };
}

export function UpdatePermission(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.PERMISSIONS_UPDATE_START, C.PERMISSIONS_UPDATE_FINISH, C.PERMISSIONS_UPDATE_ERROR],
        promise: API.updatePermission(id, data),
    };
}


export function DeletePermission(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.PERMISSIONS_DELETE_START, C.PERMISSIONS_DELETE_FINISH, C.PERMISSIONS_DELETE_ERROR],
        promise: API.deletePermission(id),
    };
}


export function CreateGroup(data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.GROUPS_CREATE_START,C.GROUPS_CREATE_FINISH,C.GROUPS_CREATE_ERROR],
        promise: API.createGroup(data),
    };
}

export function DeleteGroup(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.GROUPS_DELETE_START,C.GROUPS_DELETE_FINISH,C.GROUPS_DELETE_ERROR],
        promise: API.deleteGroup(id),
    };
}

export function UpdateGroup(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.GROUPS_UPDATE_START,C.GROUPS_UPDATE_FINISH,C.GROUPS_UPDATE_ERROR],
        promise: API.updateGroup(id, data),
    };
}

export function LoadProfile(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.PROFILE_LOAD_START, C.PROFILE_LOAD_FINISH, C.PROFILE_LOAD_ERROR],
        promise: API.loadProfile(id),
    };
}

export function UpdateProfile(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.PROFILES_UPDATE_START, C.PROFILES_UPDATE_FINISH, C.PROFILES_UPDATE_ERROR],
        promise: API.updateProfile(id, data),
    };
}


