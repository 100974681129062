import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class AddAuthor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('AUTHOR_NAME'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('AUTHOR_POSITION'),
                'name': 'text',
            },
            {
                'component': 'input',
                'label': t('AUTHOR_EMAIL'),
                'name': 'options.author_email',
            },
            {
                'component': 'textarea',
                'label': t('AUTHOR_DESCRIPTION'),
                'name': 'summary',
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('SUMMARY_PLACEHOLDER'),
                    'autosize': {
                        minRows: 6,
                    },
                },
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'props': {
                    'placeholder': t('SLUG_PLACEHOLDER')
                },
                'rules': [
                    {required: true, message: t('SLUG_FILL_ERROR')},
                ]
            },
            {
                'component': 'autocomplete',
                'type': 'Arates',
                'limit': 15,
                'label': t('AUTHORS_RATE'),
                'name': 'category',
                'props': {
                    placeholder: t('AUTHORS_RATE'),
                    allowClear: true,
                    showSearch: true,
                },
            },
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('AUTHOR_COVER'),
                'name': 'media',
                'rules': [
                    {required: true, message: t('AUTHOR_PHOTO_REQUIRED')},
                ]
            },
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}

                />
            </div>
        );
    }
}

export default AddAuthor;
