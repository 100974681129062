import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { t } from './../../core/library/i18n';

class UsersFilter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Input.Search
                    defaultValue={this.props.filter.search}
                    placeholder={"Login, email, name"}
                    enterButton={t('SEARCH')}
                    onSearch={(text) => this.props.onSubmit(text)}/>
            </React.Fragment>
        );
    }
}

UsersFilter.defaultProps = {
    onSubmit: () => {},
    filter: {search: ''},
};

UsersFilter.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    filter: PropTypes.shape({search: PropTypes.string}),
};


export default UsersFilter;
