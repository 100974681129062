import React from 'react';
import {Input, Spin, message, Button, Modal, Pagination} from 'antd';
import './mgallery.scss';
import {documentsElLoadDocs} from "../../../../../store/documents/api";
import { GetImagePath } from '../../../../../config';

const getImagePreview = (file, type) => {
    if (!file || !file.files || !file.files['original'] || !file.files['original'].path) {
        return `/images/nocover.png`;
    }
    if (type === 'Gallery') {
        return GetImagePath(file, '720');
    }
    return GetImagePath(file, '112');
}

export class MGalleryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            list: [],
            limit: 5,
            total: 0,
            page: 0,
            loading: false,
        }
    }

    loadList = () => {
        this.setState({ loading: true }, async () => {
            try {
                const { search, page, limit } = this.state;
                let resp = await documentsElLoadDocs('Mgallery,Gallery', page, { search }, '-createdAt', limit);
                this.setState({
                    loading: false,
                    list: resp.list || [],
                    total: resp.total || 0,
                })
            } catch(e) {
                message.error(`Ошибка загрузки фотоподборок`);
                this.setState({ loading: false });
            }
        })
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        const { list, total, limit, page } = this.state;
        return (
            <Modal visible={true} title={`Вставка фотоподборки`} footer={null} onCancel={this.props.onCancel} width={1024}>
            <Spin spinning={this.state.loading}>
                <div className={`editor_mgallery`}>
                    <div className="mgallery_filter">
                        <Input.Search placeholder={`Введите запрос`} onSearch={(search) => {
                            this.setState({ search, page: 1}, () => {
                                this.loadList();
                            })
                        }}/>
                        <Pagination
                            defaultCurrent={page}
                            total={total}
                            current={page}
                            pageSize={limit}
                            hideOnSinglePage={true}
                            onChange={(page) => {
                                this.setState({ page }, () => {
                                    this.loadList();
                                })
                            }}
                        />
                    </div>
                    <div className="mgallery__list">
                        {list.map(i => {
                            return (
                                <div className={`mgallery__list--item`}>
                                    <div className="list--item__header">
                                        <div className="title">{i.title}</div>
                                        <div className="actions">
                                            <Button type={`primary`} onClick={() => {
                                                this.props.onInsert(i);
                                            }}>Добавить</Button>
                                        </div>
                                    </div>
                                    <div className="list--item__images">
                                        {i.media && i.media.map(j => {
                                            return <div className={`image`} style={{backgroundImage: `url(${getImagePreview(j, i.type)})`}} />;
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Spin>
            </Modal>
        );
    }
}
