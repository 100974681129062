import React, { useState, useRef } from 'react';
import { Icon, Button, Modal, Slider, Spin, message, Empty } from 'antd';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { toPng } from 'html-to-image';
import './style.scss';
import ImagesModal from './modal';
import Config from '../../../config';
import User from './../../../core/user';


function urltoFile(url, filename, mimeType){
	return (fetch(url)
		.then(function(res){return res.arrayBuffer();})
		.then(function(buf){return new File([buf], filename,{type:mimeType});})
	);
}


export class CollageMaker extends React.Component {
	constructor(props) {
		super(props);
	
		this.state = {

		};
	}

	render() {
		return (
			<div style={{width: '100%'}} className={`instruments-dnd`}>
				<ImagesList />
			</div>
		);
	}
}


class ImagesList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [],
			modal: false,
		};
	}

	showModal = (modal = false) => {
		this.setState({ modal })
	}


	onAddImage = (image, copyright) => {
		this.setState({images: [...this.state.images, ...[{image, scale: 1, offsetX: 0, offsetY: 0, copyright}]]})
	}

	onDeleteImage = (index) => {

		if (index > -1) {
			let list = [...this.state.images];
			list.splice(index, 1)
			this.setState({ images: list });
		}
	}

	onUpdateImage = (imageObj, index) => {
		let list = [...this.state.images];
		list[index].imageObject = imageObj;
		this.setState({images: list});
	}

	onSortList = ({oldIndex, newIndex}) => {
		let images = [...this.state.images];
		let a = images[oldIndex];
		images[oldIndex] = images[newIndex];
		images[newIndex] = a;
		this.setState({ images });
	}

	onUpdateItem = (index, item) => {
		console.log(`call this`, item);
		let images = [...this.state.images];
		images[index] = {...item};
		this.setState({ images });
	}

	render() {
		return (
			<>
				<div className="image__list">
					<ImagesModal
						visible={this.state.modal}
						onClose={() => this.showModal(false)}
						onAddImage={this.onAddImage}
						images={this.state.images}
					/>
					<div className="image__wrapper">
						{this.state.images.map((i, index) => {
							return (
								<ImageItem
									image={i}
									index={index}
									key={`image:${i}`}
									onDeleteImage={this.onDeleteImage}
									onUpdateImage={this.onUpdateImage}
								/>
							);
						})}
					</div>
					<div className="image__add--btn" onClick={() => this.showModal(true)}>
						<Icon type="plus-circle" theme="filled" />
					</div>
				</div>
				{this.state.images.length === 0 && (
					<Empty description={`Добавьте изображения, нажав на кнопку + в шапке`}/>
				)}
				{this.state.images.length > 0 && (
					<DNDImages
						images={this.state.images}
						onSortList={this.onSortList}
						onUpdateItem={this.onUpdateItem}
					/>
				)}
				
			</>
		);
	}
}


const DragHandle = SortableHandle(() => <div className={`dnd-drag`}><Icon type={`drag`}/></div>);


const SortableItem = SortableElement((item) => {
	const image = item.imageObject || item.image; 
	return (
		<div className={`dnd-image`}>
			
			<div className="image" style={{
				backgroundImage: `url(${image})`,
				transform: `scale(${item.scale || 1}) translateX(${item.offsetX || 0}px) translateY(${item.offsetY || 0}px)`,
			}} />
			<div className="actions--header">
				<DragHandle />
				<div className="scale">
					<Slider
						min={0.1}
						max={10.0}
						step={0.1}
						defaultValue={item.scale || 1}
						onChange={(v) => {
							item.onUpdateItem(item.itemIndex, {...item.original, ...{scale: v}});
						}}
					/>
				</div>
			</div>
			<div className="move-axist-x">
				<Slider
						min={-1 * Math.ceil(item.width)}
						max={Math.ceil(item.width)}
						step={1}
						value={item.offsetX || 0}
						onChange={(v) => {
							item.onUpdateItem(item.itemIndex, {...item.original, ...{offsetX: v}});
						}}
					/>
			</div>

				<div className="move-axist-y">
					<Slider
								min={-1 * Math.ceil(item.height)}
								max={Math.ceil(item.height)}
								step={1}
								value={item.offsetY || 0}
								vertical={true}
								onChange={(v) => {
									item.onUpdateItem(item.itemIndex, {...item.original, ...{offsetY: v}});
								}}
							/>
				</div>

		</div>
	);
})

const SortableList = SortableContainer(({items, onUpdateItem}) => {
	return (<div className={`dnd-wrapper`}>{items.map((i, index) => <SortableItem original={i} itemIndex={index} onUpdateItem={onUpdateItem} height={720} width={1280/items.length} {...i} key={`item-${index}`} index={index}/>)}</div>);
})

const DNDImages = (props) => {
	const dndRef = useRef(null);
	const [ isDownload, setIsDownload ] = useState(false);


	const onDownloadImage = () => {
		if (dndRef.current === null) {
			return
		  }
	  
		  toPng(dndRef.current, { cacheBust: true, })
			.then((dataUrl) => {
				const link = document.createElement('a')
				link.download = `collage-${(new Date()).getTime()}.png`
				link.href = dataUrl
				link.click()
				console.log(dataUrl);
				setIsDownload(false);
			})
			.catch((err) => {
				setIsDownload(false)
				console.log(err);
			})
	}



	const uploadFile = (file) => {
	
        return new Promise((resolve, reject) => {
			let fileName = `collage-${(new Date()).getTime()}.png`;
			let formData = new FormData();
			let copyright = props.images.map(i => i.copyright).filter(i => i !== "").filter((value, index, array) => array.indexOf(value) === index);
			formData.append('files', file);
			formData.append('title', ''); 
			formData.append('description', ''); 
			formData.append('keywords', ''); 
			formData.append('expired', false) 
			formData.append('properties.copyright', copyright.join(' / ')); 
			formData.append('properties.copyrightUrl', ''); 
			formData.append('properties.originalFilename', fileName); 
            formData.append('type', 'Image')

            const user = new User();

            fetch(`${Config.PROJECT.API}/media`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Authorization': user.getAccessToken(),
                },
                body: formData
            }).then(res => res.json()).then(res => {
                if (res.error) {
                    reject({error: true, e: res});
                } else {
                    resolve({uploaded: true, res})
                }
            }).catch(e => {
                reject({error: true, e});
            });

        });
    };

	const onAddToArchive = () => {
		if (dndRef.current === null) {
			return
		  }
	  
		  toPng(dndRef.current, { cacheBust: true, })
			.then((dataUrl) => {
				console.log(dataUrl);
				urltoFile(dataUrl, 'collage.png', 'image/png').then(async file => {
					try {
						let result = await uploadFile(file);
						console.log(`file uploaded`, result);
						message.success(`Файл успешно загружен`);
						setIsDownload(false);
					} catch(e) {
						console.error(e);
						message.error(`Что-то пошло не так`);
						setIsDownload(false);
					}
				});
				// const link = document.createElement('a')
				// link.download = `collage-${(new Date()).getTime()}.png`
				// link.href = dataUrl
				// link.click()
				// console.log(dataUrl);
				// setIsDownload(false);
			})
			.catch((err) => {
				setIsDownload(false)
				console.log(err);
			})
	}

	return (
		
			<div className={`dnd-images ${isDownload ? ` is_download` : ``}`} style={{width: 1280, height: 853.33}}>
				<Spin spinning={isDownload} tip={`Идет генерация изображения`} >
					<div className="dnd--block" ref={dndRef}>
						<SortableList
							axis={'x'}
							items={props.images}
							useDragHandle={true}
							onSortEnd={props.onSortList}
							onUpdateItem={props.onUpdateItem}
						/>
					</div>
				</Spin>
				{props.images.length > 0 && (
					<div className={`dnd--actions`}>
						<Button.Group>
							<Button type="primary" loading={isDownload} onClick={() => {
								setIsDownload(true);
								onDownloadImage();
							}}>Скачать</Button>

							<Button type="primary" loading={isDownload} onClick={() => {
								setIsDownload(true);
								onAddToArchive();
							}}>Добавить в архив</Button>
						</Button.Group>
					</div>
				)}
			</div>
		
	)
}





const ImageItem = (props) => {
	const [ modal, setModal ] = useState(false);
	const imageEditor = useRef(null);
	return (
		<>
			<Modal title={`Редактирование изображения`} visible={modal} onCancel={() => setModal(false)} width={'80%'} footer={null}>
				<ImageEditor
					ref={imageEditor}
					usageStatistics={false}
					includeUI={{
						loadImage: {
						  path: props.image.image,
						  name: 'SampleImage',
						},
						initMenu: 'filter',
						uiSize: {
						  height: '600px',
						},
						menuBarPosition: 'bottom',
					}}
					selectionStyle={{
						cornerSize: 20,
						rotatingPointOffset: 70,
					}}
				/>
				<div className="image--action">
					<Button type="primary" onClick={() => {
						props.onUpdateImage(imageEditor.current.imageEditorInst._graphics._canvas.toDataURL(), props.index);
						setModal(false);
					}}>Сохранить</Button>
				</div>
			</Modal>
			<div className="image__item" style={{backgroundImage: `url(${props.image.imageObject ? props.image.imageObject : props.image.image})`}}>
				<div className="image__actions">
					<Button type={`danger`} icon={'delete'} onClick={() => props.onDeleteImage(props.index)} />
					<Button
						type={`primary`}
						icon={'edit'}
						onClick={() => setModal(true)}
					/>
				</div>
			</div>
		</>
	);
}



export default CollageMaker;