import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Button, Tooltip, Typography, Tag, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { t } from './../../library/i18n';
import { Prop, MediaProp } from './../props';
import Cover from './../cover';
import AuthorsCard from './../authors/card';
import Tags from './../tags';
import Config from './../../../config';


const CardStatus = (p) => {
    if (p.published) return <Tag color={"#87d068"}>{t('published')}</Tag>;
    if (p.prepublished) return <Tag color={"#2db7f5"}>{t('prepublished')}</Tag>;
    return <Tag color={"#F50"}>{t('unpublished')}</Tag>;
};

const CanDisplay = (p) => {
    if (['Article', 'News', 'Meaning'].indexOf(p.type) + 1 === 0) {
        return true;
    }
    if ( window.user.permissions.indexOf('ONLY_EDIT') + 1 === 0) {
        return true;
    }
    return false;
}

export const DefaultCard = (p) => {

    return (
        <div className={`list--card card--default card--${p.type}`}>
            <div className="card--info">
                <div className="header">
                    <div className="header--info">
                        {p.category && (
                            <div className={`categories`}>
                                {t('CATEGORY')}: <Link to={`?category=${p.category.title}`}>{p.category.title}</Link>
                            </div>
                        )}
                    </div>
                    <div className="header--status">
                        <div className="date">{Moment(p.publishedAt).format(Config.DATE_FORMAT)}</div>
                        <div className="status"><CardStatus {...p}/></div>
                    </div>
                </div>
                <div className="content">
                    <div className="cover"><Cover {...p}/></div>
                    <div className="info">
                        <div className={"title"}>{p.title || t('NOT_SET')}</div>
                        <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>
                            {p.summary || t('NOT_SET')}
                        </Typography.Paragraph>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer--row footer--authors">
                        {p.authors && p.authors.length > 0 && <div className={`header--authors`}>{t('AUTHOR')}: <AuthorsCard list={p.authors}/></div>}
                    </div>
                    {((p.createdBy && p.createdBy.id) || (p.updatedBy && p.updatedBy.id)) && (<div className={"footer--row card--dates"}>
                        {p.createdBy && p.createdBy.id && (<div>{t('CREATED')} <Link to={`/users/${p.createdBy.id}`}>{p.createdBy.email}</Link> {t('AT')} {Moment(p.createdAt).format(Config.DATE_FORMAT)}</div>)}
                        {p.updatedBy && p.updatedBy.id && (<div>{t('UPDATED')} <Link to={`/users/${p.updatedBy.id}`}>{p.updatedBy.email}</Link> {t('AT')} {Moment(p.updatedAt).format(Config.DATE_FORMAT)}</div>)}
                    </div>)}
                    {p.tags && p.tags.length > 0 && (<div className={`footer--row`}><Tags list={p.tags} limit={3}/></div>)}
                    <div className="footer--row footer--propw">
                        {p.crosslinks && <Prop icon={'tags'} title={t('CROSSLINKS')} value={p.crosslinks.length}/>}
                        {p.media && <MediaProp list={p.media}/>}
                    </div>
                </div>
            </div>
            <div className="card--actions">
                {(p.published && CanDisplay(p)) && (<Tooltip title={t('EDIT')}><Button type={`primary`} icon={`edit`} onClick={() => p.onEdit(p.id)}/></Tooltip>)}
                <Tooltip title={t('VIEW')}><Button type={`primary`} icon={`eye`} onClick={() => p.onView(p.id)}/></Tooltip>
                <Tooltip title={t('DELETE')}>
                    <Popconfirm title={t('SURE_DELETE')} okText={t('DELETE')} cancelText={t('CLOSE')} onConfirm={() => p.onDelete(p.id)}>
                        <Button type={`danger`} icon={`delete`}/>
                    </Popconfirm>
                </Tooltip>
            </div>
        </div>
    );
};

DefaultCard.defaultProps = {
    title: '',
    summary: '',
    media: [],
    crosslinks: [],
    tags: [],
    authors: [],
    category: {},
};


DefaultCard.propTypes = {
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(Object).isRequired,
    crosslinks: PropTypes.arrayOf(Object).isRequired,
    tags: PropTypes.arrayOf(Object).isRequired,
    authors: PropTypes.arrayOf(Object).isRequired,
    category: PropTypes.instanceOf(Object).isRequired,

};

export default DefaultCard;
