import React from 'react';
import { message, Modal } from "antd";
import {AppConsumer} from "../../context/app";
import Moment from 'moment';
import PropTypes from "prop-types";
import { loadMediaList, updateMedia, getUsingMedia } from './../../store/media/api';
import {t} from "../../core/library/i18n";
import { MediaList } from './components/list';
import { GalleryUpload } from './components/upload';
import { EditForm } from './components/editForm';
import { parseUrlParams } from './../../core/library/url';
import { MaterialsModal } from './components/materials';
import "./sass/media.scss";



export class MediaIndexController extends React.Component {

    constructor(props) {
        super(props);
        let params = parseUrlParams();
        let range = null;
        if (params["from"]) {
            if (params["from"] && params["to"]) {
                range = [Moment(params["from"]), Moment(params["to"])];
            } else {
                range = [Moment(params["from"]), Moment(params["from"])];
            }
        }
        this.state = {
            page: params.page ? parseInt(params.page, 10) : 1,
            total: 0,
            limit: 60,
            loading: false,
            error: false,
            filter: {
                type: params.type ? params.type : 'Image,Cover,Video',
                search: params.search ? params.search : '',
                range: range,
            },
            list: [],
            item: {},
            modal_id: false,
            isPhoto: this.props.isPhotoUser || false,
            ignorePhoto: this.props.isPhotoUser === false,
            isCanDropImages: this.props.isCanDropImages === false,
            isBookmark: this.props.isBookmark === false,
            isExclude: this.props.isExclude === false,
        }
    }

    hideMaterialModal = () => {
        this.setState({modal_id: false})
    }

    showMaterialModal = (id) => {
        this.setState({ modal_id: id})
    }

    updatePhotoFilter = (status) => {
        this.setState({
            isPhoto: this.state.ignorePhoto ? status : !status,
            page: 1,
        }, () => {
            this.loadList();
        })
    }

    updatePhotoBookmark = (status) => {
        this.setState({
            isBookmark: status,
            page: 1,
        }, () => {
            this.loadList();
        })
    }

    updatePhotoExclude = (status) => {
        this.setState({
            isExclude: status,
            page: 1,
        }, () => {
            this.loadList();
        })
    }

    buildFilter = () => {
        let filter =  {
            type: this.state.filter.type === '' ? 'Image,Cover,Video,PDF,ExternalVideo' : this.state.filter.type,
            q: this.state.filter.search || "",
        };

        if (this.state.filter.range && this.state.filter.range.length > 0) {
            filter.range = "createdAt";
            if (this.state.filter.range.length === 2) {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
                filter.to = this.state.filter.range[1].format('YYYY-MM-DDTHH:mm:ss');
            } else {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
            }
        }

        return filter;
    };

    loadList = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                // load media list from server
                window.scrollTo(0, 0);
                let filter = this.buildFilter();

                if (this.props.history) {
                    let url = `${window.location.pathname}?page=${this.state.page}&type=${this.state.filter.type || ""}&search=${encodeURIComponent(this.state.filter.search || "")}`;
                    if (this.state.filter.range && this.state.filter.range.length > 0) {
                        if (this.state.filter.range.length === 2) {
                            url = `${url}&from=${this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss')}&to=${this.state.filter.range[1].format('YYYY-MM-DDTHH:mm:ss')}`
                        } else {
                            url = `${url}&from=${this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss')}`
                        }
                    }
                    this.props.history.push(url);
                }

                loadMediaList(this.state.page, this.state.limit, filter, this.state.isPhoto, this.state.ignorePhoto, this.state.isBookmark, this.state.isExclude).then(async resp => {
                    let data = resp || {};
                    let ids = data.list ? data.list.map(i => i.id) : [];
                    if (data.list) {
                        try {
                            let mediaIds = await getUsingMedia(ids);
                            if (mediaIds.media) {
                                data.list = data.list.map(i => {
                                    let a = {...i};
                                    if (a.files && a.files["original"] && a.files["original"].path) {
                                        if (mediaIds.media.indexOf(a.files["original"].path) + 1 > 0) {
                                            a.isUsing = true;
                                        }
                                    }
                                    return a;
                                });
                            }
                        } catch (e) {
                            console.error(`error load using media: `, e);
                        }
                    }


                    this.setState({
                        list: data.list || [],
                        total: data.total || 0,
                        loading: false,
                        error: false,
                    })
                }).catch(e => {
                    console.error(`error load media list: `, e);
                    this.setState({
                        loading: false,
                        error: true,
                    })
                });
            });
        }
    };

    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadList();
        });
    };

    onDelete = (id = '') => {
        this.setState({ loading: true }, async () => {
            try {
                await updateMedia(id, {virtual: true});
                message.success(`Элемент успешно скрыт`);
                // time for waiting update elastic cache clear
                setTimeout(() => {
                    let list = this.state.list.filter(i => i.id !== id);
                    this.setState({ loading: false, list }, () => {
                        this.loadList();
                    })
                }, 350);
            } catch(e) {
                console.error(`error hide element: `, e);
                this.setState({ loading: false }, () => {
                    message.error(`Ошибка обновления данных. Попробуйте пожалуйста позднее`);
                });
            }
        })
    };

    onDeleteForever = (id = '') => {
        this.setState({ loading: true }, async () => {
            try {
                await updateMedia(id, {delete_forever: true, virtual: true});
                message.success(`Элемент успешно удален`);
                // time for waiting update elastic cache clear
                setTimeout(() => {
                    let list = this.state.list.filter(i => i.id !== id);
                    this.setState({ loading: false, list }, () => {
                        this.loadList();
                    })
                }, 500);
            } catch(e) {
                console.error(`error hide element: `, e);
                this.setState({ loading: false }, () => {
                    message.error(`Ошибка обновления данных. Попробуйте пожалуйста позднее`);
                });
            }
        })
    };

    onUpdate = (id = '', data = {}) => {
        this.setState({ item: data });
    };

    onFilter = (filter) => {
        this.setState({ filter, page: 1 }, () => {
            this.loadList();
        })
    }

    reloadList = () => {
        window.scrollTo(0, 0);
        this.setState({
            page: 1,
            filter: {},
            error: false,
            items: [],
        }, () => {
            this.loadList();
        })
    };

    toggleMediaBookmark = (id, is_bookmark) => {
       this.setState({ loading: true }, async () => {
           try {
               let updateData = {is_bookmark};
               await updateMedia(id, updateData);
               message.success(`Элемент обновлен`);
               let list = [...this.state.list];
               list = list.map(i => {
                   if (i.id === id) {
                       let a = {...i, ...updateData};
                       return a;
                   }
                   return i;
               });
               this.setState({ loading: false, list });
           } catch(e) {
               console.error(`error update element: `, e);
               this.setState({ loading: false }, () => {
                   message.error(`Ошибка обновления данных. Попробуйте пожалуйста позднее`);
               });
           }
       })
    }

    updateMedia = (values) => {
        this.setState({ loading: true }, async () => {
            let updateData = {
                title: values.title,
                description: values.description,
                keywords: values.keywords,
                expired: values.expired || false,
                vertical: values.vertical || false,
                properties: {},
                poster: values.poster || "",
            };

            updateData.properties = this.state.item && this.state.item.properties ? this.state.item.properties : {};
            updateData.properties = {
                copyright: values.properties.copyright,
                copyrightUrl: values.properties.copyrightUrl,
                source: values.properties.source || "",
            };

            try {
                await updateMedia(this.state.item.id, updateData);
                message.success(`Элемент обновлен`);
                let list = [...this.state.list];
                list = list.map(i => {
                    if (i.id === this.state.item.id) {
                        let a = {...i, ...updateData};
                        return a;
                    }
                    return i;
                });

                let item = {...this.state.item, ...updateData};


                this.setState({ loading: false, list, item });
            } catch (e) {
                console.error(`error update element: `, e);
                this.setState({ loading: false }, () => {
                    message.error(`Ошибка обновления данных. Попробуйте пожалуйста позднее`);
                });
            }
        });
    }

    componentDidMount() {
        this.loadList();
    }

    groupsDeleteImages = (list = []) => {
        if (list.length > 0) {
            this.setState({ loading: true }, async () => {
                for(let i in list) {
                    await updateMedia(list[i], {virtual: true});
                }
                setTimeout(() => {
                    this.setState({loading: false}, () => {
                        this.loadList();
                    })
                }, 2500);
            });
        }
    }

    render() {
        const showModal = this.state.item && this.state.item.id ? true : false;
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <MediaHeader context={context} />
                            <Modal
                                width={`80%`}
                                title={`Материалы`}
                                visible={this.state.modal_id !== false}
                                footer={null}
                                onCancel={() => {
                                    this.setState({modal_id: false})
                                }}
                                destroyOnClose
                            >
                                <MaterialsModal
                                    id={this.state.modal_id}
                                    isPhoto={this.props.isPhotoUser || false}
                                />
                            </Modal>
                            <Modal
                                width={500}
                                title={`Редактирование элемента`}
                                visible={showModal}
                                footer={null}
                                onCancel={() => {
                                    this.setState({item: {}})
                                }}
                                destroyOnClose
                            >
                                <EditForm
                                    loading={this.state.loading}
                                    showCopy={false}
                                    isEdit={true}
                                    item={this.state.item}
                                    onSubmit={this.updateMedia}
                                />
                            </Modal>

                            <div className="media--view">
                                <GalleryUpload
                                    reloadList={this.reloadList}
                                />
                                <MediaList
                                    {...this.state}
                                    onPageChange={this.onPageChange}
                                    groupsDeleteImages={this.groupsDeleteImages}
                                    onDelete={this.onDelete}
                                    onDeleteForever={this.onDeleteForever}
                                    onUpdate={this.onUpdate}
                                    toggleMediaBookmark={this.toggleMediaBookmark}
                                    reloadList={this.reloadList}
                                    onFilter={this.onFilter}
                                    showMaterialModal={this.showMaterialModal}
                                    isPhoto={this.props.isPhotoUser || false}
                                    isCanDropImages={this.props.isCanDropImages || false}
                                    updatePhotoFilter={this.updatePhotoFilter}
                                    updatePhotoBookmark={this.updatePhotoBookmark}
                                    updatePhotoExclude={this.updatePhotoExclude}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}

class MediaHeader extends React.Component {
    static defaultProps = {
        context: {},
    };
    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };
    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('MEDIA_GALLERY_LIST'),
        })
    }
    render() {
        return null;
    }
}
