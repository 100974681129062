import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Button, Drawer, Spin } from 'antd';
import PermissionFilter from './../filters/permissions';
import PermissionsAddForm from './../forms/permission_add';
import propExists from "../../core/library/objects/getprop";

export default class PermissionsTable extends React.Component {

    Columns = [
        {
            title: 'Code',
            dataIndex: 'code'
        },
        {
            title: 'Title',
            dataIndex: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Active',
            key: 'active',
            render: row => {
                return (
                    <Switch checked={row.active} onChange={(v) => {
                        this.props.onUpdate(row.id, {active: v});
                    }}/>
                );
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: row => {
                return (
                    <React.Fragment>
                        <Button.Group>
                            <Button type={"primary"} icon={"edit"} size={"small"} onClick={() => this.openDrawer(row)}/>
                            <Button type={"danger"} icon={"delete"} size={"small"} onClick={() => {
                                this.props.onDelete(row.id);
                            }}/>
                        </Button.Group>
                    </React.Fragment>
                )
            },
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
            item: {},
        };
    }

    openDrawer = (item = {}) => {
        this.setState({ showDrawer: true, item});
    };

    hideDrawer = () => {
        this.setState({ showDrawer: false, item: {}});
    };

    onSubmit = (values) => {
        if (propExists(this.state.item, 'id')) {
            this.props.onUpdate(this.state.item.id, values);
        } else {
            this.props.onCreate(values);
        }
    };

    componentWillReceiveProps(newProps, oldProps) {
        if (oldProps.loading !== newProps.loading) {
            this.setState({
                showDrawer: false,
                item: {},
            });
        }
    }

    componentDidMount() {
        this.props.onMounted();
    }

    render() {
        return (
            <React.Fragment>
                <Drawer
                    title={this.state.item.id ? `Edit permission` : `Create permission`}
                    placement={"right"}
                    closable={true}
                    onClose={this.hideDrawer}
                    visible={this.state.showDrawer}
                    width={520}
                >
                    {this.state.showDrawer && (
                        <Spin spinning={this.props.loading}>
                            <PermissionsAddForm onSubmit={this.onSubmit} item={this.state.item} />
                        </Spin>
                    )}

                </Drawer>
                <Table
                    title={() => {
                        return (
                            <PermissionFilter
                                onCreateClick={this.openDrawer}
                                onSubmit={this.props.onUpdateFilter}
                            />
                        );
                    }}
                    loading={this.props.loading}
                    bordered
                    columns={this.Columns}
                    pagination={{
                        pageSize: this.props.limit,
                        defaultCurrent: this.props.page,
                        total: this.props.total,
                        onChange: this.props.onPageChange,
                        current: this.props.page,
                    }}
                    dataSource={this.props.list}
                    rowKey={(item) => item.id}
                />
            </React.Fragment>
        );
    }
}

PermissionsTable.defaultProps = {
    loading: false,
    list: [],
    page: 0,
    limit: 0,
    total: 0,
    onPageChange: () => {},
    onUpdateFilter: () => {},
    onUpdate: () => {},
    onCreate: () => {},
    onMounted: () => {},
    onDelete: () => {},
};


PermissionsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(Object).isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

