/*eslint-disable*/
import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export class AddSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};
        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },

            {
                'component': 'datepicker',
                'label': t('DATE'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'selected date',
                    showTime: true,
                },
                'rules': [
                    {required: true, message: 'Select date'},
                ]
            },

            {
                'component': 'datepicker',
                'label': t('DATE_END'),
                'name': 'options.date_end',
                'props': {
                    placeholder: 'selected date',
                    showTime: true,
                },

            },


        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {});
        }
    }

    onSubmit = (values) => {
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.media = media;
        values.slug = uuidv4();

        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                />
            </div>
        );
    }
}

export default AddSchedule;
