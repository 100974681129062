import React from 'react';
import FormGenerator from '../../../core/components/generators/form';
import {t} from '../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";
import { createDocument } from '../../../store/documents/api';

export class AddContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};
        this.Fields = [
            {
                'component': 'input',
                'label': 'ФИО',
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': 'Должность',
                'name': "subtitle",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
            },
            {
                'component': 'textarea',
                'label': `Локация`,
                'name': 'summary',
                "show_count": true,
            },
            {
                'component': 'textarea',
                'label': `Комментарий`,
                'name': 'comment',
                "show_count": true,
            },
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': `Фотография`,
                'name': 'media',
            },
            {
                'component': 'autocomplete',
                'type': 'Contactstags',
                'limit': 15,
                'label': 'Теги',
                'name': 'tags',
                'props': {
                    placeholder: t('SELECT_TAGS'),
                    allowClear: true,
                    showSearch: true,
                    mode: 'tags',
                },
            },
            {
                'component': 'constacts_block',
                'label': t('TEXT'),
                'name': 'text',
                'props': {},
            }
        ];

      
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {
            });
        }
    }

    onSubmit = async (values) => {
    
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }

            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });
        

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.redirectTo = values.redirectTo || "";
        values.media = media;

        let contacts_data = [];
        for(let i in values.contact_values) {
            contacts_data.push({
                value: values.contact_values[i], 
                type: values.contact_types[i] || "other",
                comment: values.contact_comments[i] || "",
            });
        }

        let tags = [];
        for (let i in values.tags) {
            console.log(values.tags[i].match(/^[0-9a-fA-F]{24}$/));
            if (!values.tags[i].match(/^[0-9a-fA-F]{24}$/)) {
                try {
                    let d = await createDocument({title: values.tags[i], type: 'Contactstags', slug: 'item', published: true, publishedAt: new Date()});
                    if (d.id) {
                        tags.push(d.id);
                    }
                } catch(e) {
                    console.error(`[Create][Contactstags]`, e);
                }
            } else {
                tags.push(values.tags[i]);
            }
        }

    
        values.tags = tags;
        values.text = JSON.stringify(contacts_data);

        delete values.contact_values;
        delete values.contact_types;


        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    updateMaterialType={this.props.updateMaterialType}
                    sendPush={this.props.sendPush}
                    hideOptions={true}
                />
            </div>
        );
    }
}

export default AddContacts;
