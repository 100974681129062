import React from 'react';
import {Form, Input} from 'antd';
import {t} from './../../library/i18n'
import Slugify from 'slugify';

export class SlugInput extends React.Component {

    state = {
        value: '',
    };

    generateSlug = () => {
        if (this.props.source_field) {
            let v = this.props.form.getFieldValue(this.props.source_field);
            if (v) {
                let setFields = {};
                let value = Slugify(v, {
                    lower: true,
                    replacement: '-'
                });
                let a = value.replace(/[^a-zA-Z0-9-]/ig, '');
                setFields[this.props.name] = a;
                this.props.form.setFieldsValue(setFields);
            }
        }
    };

    componentWillReceiveProps(p) {


        let v = this.props.form.getFieldValue(this.props.source_field);
        if (v && this.props.form.getFieldValue(this.props.name) === "") {
            let setFields = {};
            let value = Slugify(v, {
                lower: true,
                replacement: '-'
            });
            let a = value.replace(/[^a-zA-Z0-9-]/ig, '');
            if (a !== this.props.form.getFieldValue(this.props.name)) {
                setFields[this.props.name] = a;
                p.form.setFieldsValue(setFields);
            }
        }

    }

    render() {
        const p = this.props;
        let cProps = {};
        if (p.props && !p.props.disabled) {
            cProps.addonAfter = <div onClick={this.generateSlug} style={{cursor: 'pointer'}}>{t('GENERATE')}</div>;
        }
        return (
            <Form.Item label={p.label || ""}>
                {p.decorator(p.name, {
                    rules: p.rules || [],
                    initialValue: p.initialValue ? p.initialValue : this.state.value,
                })(
                    <Input {...p.props} {...cProps}/>
                )}
            </Form.Item>
        )
    }

};
