import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import { Table, Button, message, Tooltip, Input, Modal, Popconfirm } from 'antd';
import * as Actions from "./../../store/profiles/actions";
import * as C from './../../store/profiles/constants';
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import {generateParamsFromObject, parseUrlParams, prepareParams} from "../../core/library/url";
import CreatePermission from './permissions/create';


const mapStateToProps = state => ({
    answer: state.ProfilesReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class PermissionsHeader extends React.Component {
    static defaultProps = {
        context: {},
    };
    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };
    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('PERMISSIONS_LIST'),
        })
    }
    render() {
        return null;
    }
}


class PermissionsControllerClass extends React.Component {

    static defaultProps = {
        actions: {},
        answer: {},
    };

    columns = [
        {
            title: t('TITLE'),
            dataIndex: 'title',
        },
        {
            title: t('CODE'),
            dataIndex: 'code'
        },
        {
            title: t('DESCRIPTION'),
            dataIndex: 'description',
        },
        {
            title: '',
            render: r => {
                return (
                    <Button.Group>
                        <Popconfirm
                            title={t("DELETE_PERMISSION_REQUEST")}
                            onConfirm={() => this.deletePermission(r.id)}
                        >
                            <Tooltip title={t('DELETE_PERMISSION')}><Button type={`danger`} icon={'delete'}/></Tooltip>
                        </Popconfirm>
                        <Tooltip title={t('EDIT_PERMISSION')}>
                            <Button type={`primary`} icon={`edit`} onClick={() => this.setState({ modal: true, item: r})}/>
                        </Tooltip>
                    </Button.Group>
                )
            }
        }
    ];

    constructor(props) {
        super(props);
        let url = parseUrlParams();
        this.state = {
            list: [],
            limit: 0,
            total: 0,
            page: url.page ? parseInt(url.page, 10) : 1,
            loading: false,
            filter: prepareParams(['search']),
            modal: false,
            item: {},
        };
    }


    loadPermissions = () => {
        this.props.actions.LoadPermissions(this.state.page, this.state.filter);
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.PERMISSIONS_LOAD_START:
                    this.setState({ loading: false });
                    break;
                case C.PERMISSIONS_LOAD_FINISH:
                    let permissionsRes = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    console.log(permissionsRes.list);
                    this.setState({
                        loading: false,
                        list: permissionsRes.list || [],
                        total: permissionsRes.total || 0,
                        limit: permissionsRes.limit || 0,
                    });
                    break;
                case C.PERMISSIONS_LOAD_ERROR:
                    this.setState({ loading: false });
                    message.error(t('ERROR_LOAD_PERMISSIONS'));
                    console.error(`error load permissions: `, p);
                    break;
                case C.PERMISSIONS_CREATE_START:
                case C.PERMISSIONS_UPDATE_START:
                    this.setState({ loading: true, modal: false });
                    break;
                case C.PERMISSIONS_CREATE_FINISH:
                    message.success(t('PERMISSION_CREATED'));
                    this.setState({ item: {}}, () => {
                        this.loadPermissions();
                    });
                    break;
                case C.PERMISSIONS_CREATE_ERROR:
                    message.error(t('ERROR_CREATE_PERMISSION'));
                    this.setState({ loading: false, modal: true });
                    break;


                case C.PERMISSIONS_UPDATE_FINISH:
                    message.success(t('PERMISSION_UPDATED'));
                    this.setState({ item: {}}, () => {
                        this.loadPermissions();
                    });
                    break;
                case C.PERMISSIONS_UPDATE_ERROR:
                    message.error(t('ERROR_UPDATE_PERMISSION'));
                    this.setState({ loading: false, modal: true });
                    break;

                case C.PERMISSIONS_DELETE_START:
                    this.setState({ loading: true });
                    break;
                case C.PERMISSIONS_DELETE_ERROR:
                    this.setState({ loading: false }, () => this.loadPermissions());
                    message.error(t('ERROR_DELETE_PERMISSION'));
                    break;
                case C.PERMISSIONS_DELETE_FINISH:
                    this.setState({ loading: false }, () => this.loadPermissions());
                    message.success(t('SUCCESS_DELETE_PERMISSION'));
                    break;

                default: break;
            }
        }
    }

    componentDidMount() {
        this.loadPermissions();
    }


    onSearch = (search) => {
        this.setState({ page: 1, filter: {search}}, () => {
            let filter = {...this.state.filter};
            filter["page"] = this.state.page;
            this.props.history.push(`${this.props.location.pathname}?${generateParamsFromObject(filter)}`);
            this.loadPermissions();
        });
    };

    createPermission = (data) => {
        this.props.actions.CreatePermission(data);
    };

    updatePermission = (id, data) => {
        this.props.actions.UpdatePermission(id, data);
    };

    deletePermission = (id) => {
        this.props.actions.DeletePermission(id);
    };

    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <PermissionsHeader context={context}/>
                            <Modal visible={this.state.modal} title={this.state.item.id ? t('PERMISSION_UPDATE') : t('PERMISSION_CREATE')} onCancel={() => this.setState({ modal: false, item: {}})} footer={null}>
                                <CreatePermission
                                    item={this.state.item}
                                    onCreate={this.createPermission}
                                    onUpdate={this.updatePermission}
                                />
                            </Modal>
                            <div className={`comments--view`}>
                                <Table
                                    title={() => {
                                        return (
                                            <React.Fragment>
                                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    <Button onClick={() => this.setState({ modal: true, item: {}})} type={`primary`} style={{marginRight: 10}}>{t('CREATE_PERMISSION')}</Button>
                                                    <Input.Search
                                                        onSearch={this.onSearch}
                                                        defaultValue={this.state.filter.search || ""}
                                                        placeholder={t('PERMISSIONS_PLACEHOLDER')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    }}
                                    bordered={true}
                                    loading={this.state.loading}
                                    columns={this.columns}
                                    dataSource={this.state.list}
                                    rowKey={(item) => item.id}
                                    pagination={{
                                        pageSize: this.state.limit,
                                        defaultCurrent: this.state.page,
                                        total: this.state.total,
                                        onChange: (page) => this.setState({ page }, () => { this.loadPermissions() }),
                                        current: this.state.page,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}




export const PermissionsController = connect(
    mapStateToProps,
    mapDispatchProps
)(PermissionsControllerClass);

export default PermissionsController;
