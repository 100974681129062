import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';


export const AuthorsCard = (p) => {
    if (p.list.length === 0) return null;
    const l = p.list.length - 1;
    return (
        <React.Fragment>
            {p.list.map((i, index) => {
                return <Link key={`author_${i.id}`} to={`/authors/${i.id}`}>{i.title}{l > index ? `, ` : ''}</Link>
            })}
        </React.Fragment>
    )
};

AuthorsCard.defaultProps = {
    list: [],
};

AuthorsCard.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
};

export default AuthorsCard;
