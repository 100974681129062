import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Empty, notification, Layout, Spin, message} from 'antd';

import {AppConsumer} from './../../context/app';
import * as Actions from "./../../store/documents/actions";
import * as C from './../../store/documents/constants';
import GetProp from './../../core/library/objects/getprop';
import { generateParamsFromObject, parseUrlParams, prepareParams } from './../../core/library/url';
import {t} from './../../core/library/i18n';
import * as Views from './views';
import Config from './../../config';
import LayoutController from './../../core/components/controller_layout';
import { ucfirst } from "../../core/library/string/ucfirst";

const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const ReloadTimeout = 120 * 1000;
const ReloadListTimeout = 1500;

class DefaultDocumentController extends React.Component {

    static defaultProps = {
        match: {},
    };

    static propTypes = {
        match: PropTypes.instanceOf(Object).isRequired,
        actions: PropTypes.instanceOf(Object).isRequired,
        answer: PropTypes.instanceOf(Object).isRequired,
    };


    constructor(props) {
        super(props);
        const docType = GetProp(this.props, 'match.params.type');
        let url = parseUrlParams();
        this.state = {
            type: docType || "unknown",
            loading: true,
            total: 0,
            limit: 0,
            items: [],
            page: url.page ? parseInt(url.page, 10) : 1,
            filter: prepareParams(['search', 'status', 'tags', 'category', 'df', 'dt', 'workflow']),
            sort: docType === "contacts" ? "title" : '-createdAt',
        };

        this.scrollTo = 0;
        this.reloadInterval = 0;
        this.scrollTimeout = 0;
    }

    updateLimit = (limit) => {
        this.setState({ limit }, () => {
            this.loadDocuments();
        });
    };

    updateSort = (sort) => {
        this.setState({ sort }, () => {
            this.loadDocuments();
        })
    };

    onDelete = (id) => {
        this.props.actions.DeleteDocument(id);
    };

    onView = (id) => {
        this.props.history.push(`${this.props.location.pathname}/${id}/preview`);
    };

    onEdit = (id) => {
        this.props.history.push(`${this.props.location.pathname}/${id}`);
    };

    onCreate = (id) => {
        let fields = {
            type: ucfirst(this.state.type),
            href: '',
            slug: '',
            visible: false,
            title: t(this.state.type),
            question: '',
            category: '000000000000000000000000'
        };
        if (Config.WORKFLOW && Config.WORKFLOW.DEFAULT) {
            fields.workflow = Config.WORKFLOW.DEFAULT;
        }



        this.props.actions.CreateDocument(fields);
    };

    onUpdate = (id = '', fields = {}) => {
        this.props.actions.UpdateDocument(id, fields);
    };

    onFilter = (filter) => {
        this.setState({ filter , page: 1}, () => {
            this.prepareUrl();
            this.loadDocuments();
        });
    };

    updatePageHandle = (page) => {
        this.setState({ page }, () => {
            this.prepareUrl();
            this.loadDocuments();
        });
    };

    prepareUrl = () => {
        let filter = {...this.state.filter};
        filter["page"] = this.state.page;
        this.props.history.push(`${this.props.location.pathname}?${generateParamsFromObject(filter)}`);
    };

    loadDocuments = (isSilent = false) => {
        let filter = {...this.state.filter};
        // remove field status from query and set value as param
        if (filter["status"]) {
            if (filter["status"] === "prepublished_status") {
                filter["prepublished"] = true;
            } else {
                if (filter["status"] === "unpublished") {
                    filter["published"] = false;
                } else {
                    filter[filter["status"]] = true;
                }

            }
            delete filter["status"];
        }

        // if (Config.USE_MONGO) {
            this.props.actions.LoadDocuments(this.state.type, this.state.page, filter, this.state.sort, this.state.limit === 0 ? 30 : this.state.limit);
        // } else {
            // this.props.actions.documentsElLoadDocs(this.state.type, this.state.page, filter, this.state.sort, this.state.limit === 0 ? 30 : this.state.limit);
        // }

        // //documentsElLoad
        // if (isSilent) {
        //     this.props.actions.LoadDocumentsSilent(this.state.type, this.state.page, filter, this.state.sort, this.state.limit === 0 ? 30 : this.state.limit);
        // } else {
        //     this.props.actions.LoadDocuments(this.state.type, this.state.page, filter, this.state.sort, this.state.limit === 0 ? 30 : this.state.limit);
        // }

    };

    getViewComponent = () => {
        if (Config.VIEWS && Config.VIEWS.documents && Config.VIEWS.documents[this.state.type] && Views[Config.VIEWS.documents[this.state.type]]) {
            return Views[Config.VIEWS.documents[this.state.type]];
        }
        return Views["default_view"];
    };

    componentWillReceiveProps(p) {
        const docType = GetProp(p, 'match.params.type');
        if (docType && docType !== this.state.type) {
            clearTimeout(this.reloadInterval);
            this.setState({
                filter: prepareParams(['search', 'status', 'tags', 'category', 'df', 'dt', 'workflow']),
                page: 1,
                total: 0,
                type: docType,
                sort: '-createdAt',
            }, () => {
                this.loadDocuments();
            });
        } else {
            if (p.answer && p.answer.state) {
                switch (p.answer.state) {
                    case C.DOCUMENT_CREATE_START:
                        this.setState({ loading: true });
                        break;

                    case C.DOCUMENT_CREATE_ERROR:
                        message.error(t('ERROR_CREATE_DOCUMENT'));
                        this.setState({ loading: false });
                        break;
                    case C.DOCUMENT_CREATE_FINISH:
                        message.success(t('DOCUMENT_CREATED'));
                        setTimeout(() => {
                            this.props.history.push(`${this.props.location.pathname}/${p.answer.data.id}`);
                        }, 2000);
                        break;

                    case C.DOCUMENT_UPDATE_START:
                        this.setState({ loading: true });
                        break;
                    case C.DOCUMENT_UPDATE_ERROR:
                        message.error(t('DOCUMENT_UPDATE_ERROR'));
                        setTimeout(() => {
                            this.loadDocuments();
                        }, ReloadListTimeout);

                        break;
                    case C.DOCUMENT_UPDATE_FINISH:
                        message.success(t('DOCUMENT_UPDATE_SUCCESS'));
                        setTimeout(() => {
                            this.loadDocuments();
                        }, ReloadListTimeout);
                        break;
                    case C.DOCUMENT_DELETE_START:
                        this.setState({loading: true});
                        break;
                    case C.DOCUMENT_DELETE_FINISH:
                        message.success(t('DOCUMENT_DELETED'));
                        setTimeout(() => {
                            this.loadDocuments();
                        }, ReloadListTimeout);
                        break;
                    case C.DOCUMENT_DELETE_ERROR:
                        message.error(t('DOCUMENT_DELETE_ERROR'));
                        console.error(`Error delete document: `, p.answer);
                        setTimeout(() => {
                            this.loadDocuments();
                        }, ReloadListTimeout);
                        break;
                    case C.DOCUMENTS_LOAD_START:
                        this.setState({loading: true});
                        break;
                    case C.DOCUMENTS_LOAD_ERROR:
                        this.setState({loading: false}, () => {
                            notification.error({
                                message: t('DOCUMENTS_LOAD_ERROR'),
                                description: t('REPEAT_AGAIN_LATER'),
                            })
                        });
                        break;
                    case C.DOCUMENTS_LOAD_FINISH:
                    case C.DOCUMENTS_SILENT_LOAD_FINISH:
                        const data = p.answer.data || {};
                        this.setState({
                            loading: false,
                            limit: data.limit || 0,
                            total: data.total || 0,
                            items: data.list || [],
                        }, () => {
                            if (this.scrollTo > 0) {
                                window.scrollTo(0, this.scrollTo);
                                this.scrollTo = 0;
                            }
                            clearTimeout(this.reloadInterval);
                            this.reloadInterval = setTimeout(() => {
                                this.loadDocuments(true);
                            }, ReloadTimeout)
                        });
                        break;
                    default:
                        break;
                }
            }
        }

    }

    componentDidMount() {
        if (window.location.hash.indexOf('scroll') + 1 > 0) {
            let params = window.location.hash.split('#scroll=');
            if (params.length === 2) {
                this.scrollTo = parseInt(params[1], 10);
            }
        }
        if (this.state.type !== 'unknown') {
            this.loadDocuments();
            window.addEventListener('scroll', this.updateScrollHash);
        }

    }

    componentWillUnmount() {
        clearInterval(this.reloadInterval);
        window.removeEventListener('scroll', this.updateScrollHash);
    }

    updateScrollHash = () => {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            window.location.hash = '#scroll=' + window.scrollY;
        }, 100);
    };

    render() {
        if (this.state.type === "unknown") return <div className={`container container__empty`}><Empty/></div>;
        return (
            <Layout.Content>
                <Spin spinning={this.state.loading} wrapperClassName={`${this.state.loading ? "app_loading_nflex" : ""}`}
                      tip={t('LOADING_DOCUMENTS')}>
                    <AppConsumer>
                        {context => {
                            const View = this.getViewComponent();
                                return (
                                    <LayoutController
                                        updatePageHandle={this.updatePageHandle}
                                        onFilter={this.onFilter}
                                        onDelete={this.onDelete}
                                        onView={this.onView}
                                        onEdit={this.onEdit}
                                        onCreate={this.onCreate}
                                        onUpdate={this.onUpdate}
                                        updateSort={this.updateSort}
                                        updateLimit={this.updateLimit}
                                        layout={View}
                                        type={this.state.type}
                                        options={context}
                                        {...this.state}
                                    />
                                );
                        }}
                    </AppConsumer>
                </Spin>
            </Layout.Content>
        );
    }
}

export const DefaultDocument = connect(
    mapStateToProps,
    mapDispatchProps
)(DefaultDocumentController);

export default DefaultDocument;
