import React from 'react';
import {Form, Select} from 'antd';

export const FormSelect = (p) => {

    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                rules: p.rules || [],
                initialValue: p.initialValue ? p.initialValue : "",
            })(
                <Select {...p.props || {}}>
                    {p.items && p.items.map(i => {
                        if (i.value) {
                            return <Select.Option value={i.value} key={`${p.name}_${i.value}`}>{i.label}</Select.Option>
                        }
                        return <Select.Option value={i} key={`${p.name}_${i}`}>{i}</Select.Option>
                    })}
                </Select>
            )}
        </Form.Item>
    )
};
