import React from 'react';
import { Spin, message } from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from "./../../../store/documents/actions";
import * as C from './../../../store/documents/constants';
import { t } from './../../../core/library/i18n';
import Config from './../../../config';
import SocketIO from 'socket.io-client';
import Messages from './../../../components/chat/message';

const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class EpisodeChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params && this.props.match.params.id ? this.props.match.params.id : "",
            loading: true,
            socketInit: false,
            document: {},
        };
        this.socket = null;
    }

    loadDocument = () => {
        this.props.actions.GetDocument(this.state.id);
    };

    componentDidMount() {
        this.loadDocument();

        // bind socket
        this.socket = new SocketIO(Config.SOCKET);
        this.socket.on("connect", () => {
            message.success('Socket connected');
            if (this.state.document.id) {
                this.setState({ loading: false, socketInit: true });
            }
            this.setState({ socketInit : true });
        });

        this.socket.on("error", (data) => {
            message.error('Socket error');
            console.error(`socket error`, data)
        });

        this.socket.on('disconnect', (data) => {
            message.warning('Socket disconnect');
            this.setState({loading: true, socketInit: false});
            console.error(`socket error`, data)
        });
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.DOCUMENT_LOAD_START:
                    this.setState({ loading: true, document: {}});
                    break;
                case C.DOCUMENT_LOAD_ERROR:
                    message.error(t('DOCUMENTS_LOAD_ERROR'));
                    break;
                case C.DOCUMENT_LOAD_FINISH:
                    const document = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    this.setState({ loading: false, document });
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
                <Spin spinning={this.state.loading}>
                    <div className={`chat--container`}>
                        <div className="chat--header">Чат трансляции: {this.state.document.title || ""}</div>
                        <div className="chat--messages">
                            {this.state.socketInit && <Messages id={this.state.id} socket={this.socket}/>}
                        </div>
                    </div>
                </Spin>
        )
    }
}

export const EpisodeChatController = connect(
    mapStateToProps,
    mapDispatchProps
)(EpisodeChat);

export default EpisodeChatController;
