import React from 'react';
import { Upload, Icon, message } from 'antd';
import { t } from './../../../library/i18n';
import UploadForm from './upload_form';
import Config from './../../../../config';
import User from './../../../user/index';

export default class MediaUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
    }


    deleteFiles = () => {
        this.setState({ files: []});
    };

    uploadFile = (values) => {
        let user = new User();
        let form = new FormData();


        Object.keys(values).map(i => {
            if(values[i] !== undefined) {
                if (typeof values[i] === "object") {
                    Object.keys(values[i]).map(j => {
                        if(values[i][j] !== undefined) {
                            form.append(`${i}.${j}`, values[i][j]);
                        }
                        return j;
                    });
                } else {
                    form.append(i, values[i]);
                }
            }
            return i;
        });

        form.append('type', this.props.type);
        if (this.state.files[0] instanceof Blob) {
            form.append('files', this.state.files[0], this.state.files[0].name);
        } else {
            form.append("files", this.state.files[0]);
        }



        fetch(Config.UPLOAD.path, {
            method: "POST",
            headers: {
                // "Content-type": "multipart/form-data",
                "Authorization": user.getAccessToken(),
            },
            body: form,
        }).then(data => data.json()).then(data => {
            message.success(t('FILE_UPLOADED_SUCCESS'));
            this.props.selectMedia(data)
        }).catch(e => {
            message.error(t('ERROR_UPLOAD_FILE'));
            console.error(e)
        });
    };


    onUpdateFile = (image, base64) => {
        this.setState({ files: [ image ] });
    }

    render() {
        return (
            <div className="upload--input">
                {this.state.files.length === 0 && (
                    <Upload.Dragger beforeUpload={(file) => {
                        this.setState(state => ({
                            files: [...state.files, file],
                        }));
                        return false;
                    }}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{t('UPLOAD_TEXT')}</p>
                    </Upload.Dragger>
                )}
                {this.state.files.length > 0 && (
                    <UploadForm file={this.state.files[0]} deleteFile={this.deleteFiles} uploadFile={this.uploadFile} onUpdateFile={this.onUpdateFile}/>
                )}
            </div>
        );
    }
}
