import React from 'react';
import { Input, InputNumber, Button, Form } from 'antd';
import PropTypes from 'prop-types';


class QuestsForm extends React.Component {
    static defaultProps = {
        item: {},
        onCreate: () => {},
        onUpdate: () => {},
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        onCreate: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
    };

    onSubmit = (e) => {
        if(e) e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.item && this.props.item.id) {
                    this.props.onUpdate(this.props.item.id, values);
                } else {
                    this.props.onCreate(values);
                }
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={`Вопрос`}>
                    {getFieldDecorator('title', {
                        initialValue: this.props.item.title || "",
                        rules: [
                            {required: true, message: `Заполните пожалуйста заголовок`}
                        ]
                    })(
                        <Input.TextArea />
                    )}
                </Form.Item>
                <Form.Item label={`Количество голосов`}>
                    {getFieldDecorator('total_votes', {
                        initialValue: this.props.item.total_votes || 0,
                    })(
                        <InputNumber step={1} min={0} />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type={`primary`} onClick={this.onSubmit}>{this.props.item.id ? `Сохранить` : `Создать`}</Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedQuestsForm = Form.create({ name: 'quests_form' })(QuestsForm);

export default WrappedQuestsForm;