import React from 'react';
import {Form, Select} from "antd";

const PrepareInitialValue = (p) => {
    if (p.initialValue && Array.isArray(p.initialValue)) {
        let ids = p.initialValue.map(i => {
            if (i.id) return i.id;
            return i;
        });
        return ids;
    }
    if (p.initialValue && p.initialValue.id) return p.initialValue.id;
    if (p.initialValue) return p.initialValue;
    return "no initial";
};


export const StaticAutocomplete = (p) => {
    const Initial = PrepareInitialValue(p);
    let props = {
        rules: p.rules || [],
    };
    if (Initial !== "no initial") {
        props.initialValue = Initial;
    }

    let data = p.data || [];
    if (p.is_object) {
        let options = Object.keys(data).map(key => {
            return <Select.OptGroup label={key} key={`group_${key}`}>{data[key].map(i => <Select.Option value={i} key={`key_${i}`}>{i}</Select.Option>)}</Select.OptGroup>
        });
        let select_props = {
            'placeholder': `Выберите опции`
        };

        if (p.type) {
            select_props['mode'] = 'multiple';
        }
        return (
            <Form.Item label={p.label || ""}>
                {p.decorator(p.name, props)(
                    <Select {...select_props}>
                        {options}
                    </Select>
                )}
            </Form.Item>
        );
    }
    let options = data.map(i => {
        return <Select.Option value={i} key={`key_${i}`}>{i}</Select.Option>
    });

    let select_props = {
        'placeholder': `Выберите опции`
    };

    if (p.type) {
        select_props['mode'] = 'multiple';
    }
    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, props)(
                <Select {...select_props}>
                    {options}
                </Select>
            )}
        </Form.Item>
    );
};
