import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form, Switch } from 'antd';
import propExists from './../../core/library/objects/getprop';

class PermissionsAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: propExists(this.props.item, 'id') ? this.props.item.code : '',
        };
        this.regReplace = new RegExp(/[^A-Za-z_]/gm);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err === null) {
                // console.log(values);
                this.props.onSubmit(values);
            }
        });
    };

    updateCode = (e) => {
        this.props.form.setFieldsValue({
            code: e.target.value.toUpperCase().replace(this.regReplace, ''),
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit} layout={"vertical"} className={"form form--add_permissions"}>
                    <Form.Item label={`Role code`}>
                        {getFieldDecorator('code', {
                            initialValue: this.state.code,
                            rules: [
                                { required: true, message: 'Code is required!' },
                                { min: 6, message: 'Min code length is 6'}
                            ],
                        })(
                            <Input onKeyUp={this.updateCode}/>
                        )}
                    </Form.Item>
                    <Form.Item label={`Title`}>
                        {getFieldDecorator('title', {
                            initialValue: propExists(this.props.item, 'id') ? this.props.item.title : '',
                            rules: [
                                { required: true, message: 'Title is required!' },
                            ]
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label={`Description`}>
                        {getFieldDecorator('description', {
                            initialValue: propExists(this.props.item, 'id') ? this.props.item.description : '',
                        })(
                            <Input.TextArea />
                        )}
                    </Form.Item>
                    <Form.Item label={`Active`}>
                        {getFieldDecorator('active', {
                            valuePropName: 'checked',
                            initialValue: propExists(this.props.item, 'id') ? this.props.item.active : true,
                        })(
                            <Switch />
                        )}
                    </Form.Item>
                    <Button htmlType={"submit"} type={"primary"}>{propExists(this.props.item, 'id') ? 'Update' : 'Create'}</Button>
                </Form>
            </React.Fragment>
        )
    }
}

PermissionsAddForm.defaultProps = {
    item: {},
    onSubmit: () => {},
    form: {},
};

PermissionsAddForm.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Form.create({name: 'permissions_add_form'})(PermissionsAddForm);

