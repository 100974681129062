import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {Form, Input, Alert, message} from 'antd';
import {t} from '../../library/i18n';
import Config from './../../../config';
import InsertMedia from './editor_plg/insert';
import {RenderMedia, GenerateMedia, GenerateGallery, GenerateReadmore} from './editor_plg/replacer';
import EditModal from './../media/editModal';
import {MGalleryList} from './editor_plg/lists/mgallery';
import {ReadmoreList} from './editor_plg/lists/readmore';
import Fetch from '../../fetch/index'

function strip_tags(input, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    let commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
    return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
    })
}

function removeStyle(input) {
    return input.replace(/style=\/"(.*)\/"/gi, '')
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

export class EditorForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue || "",
            showModal: false,
            mediaEditModal: false,
            modalGallery: false,
            modalReadmore: false,
            editor: {},
            media: [],
            edit_media: {},
            stopwords: [],
            stopWordsError: false,
            stopWordsEntry: [],
        };
        this.waitTimeout = 0;
        this.actions = {
            deleteMedia: this.onDeleteMedia,
            updateMedia: this.onUpdateMedia,
        };
    }


    prepareStr = (s) => {
        s = replaceAll(s, "&laquo;", '"')
        s = replaceAll(s, "&raquo;", '"')
        s = s.toLowerCase().split('«').join('"').split('»').join('"')
        return s;
    }

    loadStopWords = () => {
        Fetch('/stopwords').then(res => {
            if (res && res.data && res.data.length > 0) {
                let list = res.data.map(i => this.prepareStr(i));
                // let list = res.data.map(i => i);
                this.setState({stopwords: list});
            }
        }).catch(e => {
            console.error(`Failed to load stop words: `, e);
            message.error(`Ошибка загрузки стоп-слов`);
        })
    }

    componentDidMount() {
        this.loadStopWords();
    }

    closeModal = () => {
        this.setState({showModal: false, editor: {}});
    };

    componentWillReceiveProps(p) {
        if (p.initialValue !== this.state.value) {
            this.setState({
                value: p.initialValue,
                media: p.item && p.item.media ? p.item.media : []
            });
        } else {
            if (p.item && p.item.media && JSON.stringify(p.item.media) !== JSON.stringify(this.state.media)) {
                this.setState({
                    media: p.item.media,
                })
            }
        }
        if (this.editor) {
            RenderMedia(this.editor, this.state, this.actions);
        }
    }


    onInsertGallery = (media) => {
        GenerateGallery(media, this.editor);
        this.editor.save();
        this.setState({
            modalGallery: false,
        })
    }


    onInsertReadmore = (media) => {
        GenerateReadmore(media, this.editor);
        this.editor.save();
        this.setState({
            modalReadmore: false,
        })
    }


    onInsert = (media = {}) => {
        if (this.props.updateMedia && this.props.updateMedia.update) {
            this.props.updateMedia.update(media);
            setTimeout(() => {
                GenerateMedia(media, this.editor);
                this.editor.save();

                this.setState({showModal: false}, () => {
                    // if (this.props.updateMedia && this.props.updateMedia.update) {
                    //     this.props.updateMedia.update(media);
                    // }
                });
            }, 20);
        }
    };

    onDeleteMedia = (media) => {
        if (this.props.updateMedia && this.props.updateMedia.delete) {
            this.props.updateMedia.delete(media);
        }
    };

    onUpdateMedia = (media) => {
        console.log('update media: ', media);
    };

    closeModalEdit = (item) => {

        this.setState({
            mediaEditModal: false,
            edit_media: {},
        }, () => {

            if (item && item.id) {
                this.props.updateMedia.set(item);
            }
        })
    };

    openModalEdit = (item) => {
        this.setState({
            edit_media: item,
            mediaEditModal: true,
        })
    };


    onCloseGalleryModal = () => {
        this.setState({modalGallery: false, modalReadmore: false})
    }


    validateText = (text = '') => {
        text = this.prepareStr(text)
        let s = decodeEntities(text);
        let stopWordsError = false;
        let stopWordsEntry = [];
        for (let i in this.state.stopwords) {
            let w = this.state.stopwords[i];
            if (s.indexOf(w) + 1 > 0) {
                stopWordsError = true;
                stopWordsEntry.push(w)
            }
        }
        this.setState({stopWordsEntry, stopWordsError});
    }


    render() {
        // console.log(`current value: `,this.state.value);
        const props = this.props.props || {};
        const DStyle = {
            cursor: 'not-allowed',
            position: 'absolute',
            zIndex: 9999,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#f5f5f5',
            opacity: 0.5
        };
        return (
            <React.Fragment>

                <div className={"editor"}>
                    {props.disabled && <div style={DStyle}/>}
                    {this.state.modalGallery && (
                        <MGalleryList
                            onCancel={this.onCloseGalleryModal}
                            onInsert={this.onInsertGallery}
                        />
                    )}

                    {this.state.modalReadmore && (
                        <ReadmoreList
                            onCancel={this.onCloseGalleryModal}
                            onInsert={this.onInsertReadmore}
                        />
                    )}

                    {this.state.mediaEditModal && (<EditModal
                        visible={this.state.mediaEditModal}
                        onClose={this.closeModalEdit}
                        item={this.state.edit_media}
                    />)}

                    {this.state.showModal && <InsertMedia
                        visible={this.state.showModal}
                        closeModal={this.closeModal}
                        onInsert={this.onInsert}
                        onDeleteMedia={this.onDeleteMedia}
                        media={this.state.media}
                        openModalEdit={this.openModalEdit}
                        mediaTypesImage={props.mediaTypesImage || ""}
                        mediaVideos={props.mediaVideos || ""}
                    />}
                    <Form.Item label={this.props.label}>
                        {this.state.stopWordsError && (
                            <div className={`alerts`} style={{marginTop: 10,marginBottom: 10}}>
                                <Alert type={`error`}
                                       message={`В тексте встречаются следующие стоп-слова: ${this.state.stopWordsEntry.join(', ')}`}/>
                            </div>
                        )}
                        <div className={`editor--hidden`} style={{display: 'none'}}>
                            {this.props.decorator(this.props.name, {
                                initialValue: this.state.value,
                                rules: this.props.rules || []
                            })(
                                <Input.TextArea/>
                            )}
                        </div>
                        <Editor
                            initialValue={this.state.value || ""}
                            init={{
                                height: 450,
                                resize: true,
                                browser_spellcheck: true,
                                elementpath: false,
                                extended_valid_elements: 'rmedia[id,type],rcode[id],noindex,vmgallery[id,type],vmreadmore[id,type]',
                                custom_elements: 'rmedia, rcode,noindex,vmgallery,vmreadmore',
                                noneditable_editable_class: "mceNonEditable",
                                noneditable_regexp: /<rmedia>/g,
                                content_css: [
                                    '/libs/tinymce/css/index.css',
                                ],
                                language: Config.EDITOR.SETTINGS && Config.EDITOR.SETTINGS.language ? Config.EDITOR.SETTINGS.language : 'en_GB',
                                plugins: 'paste link code wordcount table pagebreak visualblocks charmap toc lists visualchars',
                                toolbar: "undo redo | visualchars | bold italic | alignleft aligncenter alignright | removeformat | formatselect | blockquote charmap | bullist numlist | frenchQuotes | middleDash longDash haveMeaning | insertMedia | insertGallery | insertReadmore",
                                contextmenu: "link image imagetools table spellchecker",
                                default_link_target: "_blank",
                                paste_preprocess: (plugin, args) => {
                                    args.content = strip_tags(args.content, '<a><p><strong><b><i>');
                                    let remove = ['<p> </p>', '<p></p>'];
                                    remove.forEach(i => {
                                        args.content = args.content.split(i).join('');
                                    });
                                    args.content = removeStyle(args.content);
                                },
                                setup: (editor) => {
                                    this.editor = editor;

                                    editor.on('blur', (e) => {
                                        // save stash after blur
                                        if (this.props.onBlur) {
                                            this.props.onBlur();
                                        }
                                        this.validateText(e.target.getContent());
                                    });

                                    editor.on('change', (e) => {
                                        editor.dom.setHTML(editor.dom.select('rmedia'), '');
                                        editor.dom.setAttribs(editor.dom.select('[data-mce-contenteditable]'), {'data-mce-contenteditable': null});
                                        let values = {};
                                        values[this.props.name] = e.target.getContent();
                                        this.props.form.setFieldsValue(values);
                                        // validate text
                                        this.validateText(e.target.getContent());
                                    });

                                    editor.on('Undo', (e) => {
                                        console.log('nigga change', e)
                                        editor.dom.setHTML(editor.dom.select('rmedia'), '');
                                        editor.dom.setAttribs(editor.dom.select('[data-mce-contenteditable]'), {'data-mce-contenteditable': null});
                                        let values = {};
                                        values[this.props.name] = e.target.getContent();
                                        this.props.form.setFieldsValue(values);
                                        // validate text
                                        this.validateText(e.target.getContent());
                                    });

                                    editor.on('keyup', (e) => {
                                        this.validateText(editor.getContent());
                                    })

                                    editor.on('PreProcess', (e) => {
                                        editor.dom.setHTML(editor.dom.select('rmedia'), '');
                                        editor.dom.setAttribs(editor.dom.select('[data-mce-contenteditable]'), {'data-mce-contenteditable': null});
                                        RenderMedia(editor, this.state, this.actions);
                                    });

                                    editor.on('LoadContent', () => {
                                        clearTimeout(this.waitTimeout);
                                        this.waitTimeout = setTimeout(() => {
                                            RenderMedia(editor, this.state, this.actions);
                                        }, 2000);
                                    });

                                    editor.on('click', (e) => {
                                        let target = e.target;
                                        if (target && target.className) {
                                            if (target.className.indexOf("updateMedia") + 1 > 0) {
                                                let parent = editor.dom.getParents(target, 'rmedia');
                                                if (parent && parent[0].id) {
                                                    let media = this.state.media.filter(i => i.id === parent[0].id);
                                                    this.openModalEdit(media[0]);
                                                }
                                            }
                                            if (target.className.indexOf("deleteMedia") + 1 > 0) {
                                                let parent = editor.dom.getParents(target, 'rmedia');
                                                editor.dom.remove(parent);

                                                let parentG = editor.dom.getParents(target, 'vmgallery');
                                                editor.dom.remove(parentG);

                                                let parentReadmore = editor.dom.getParents(target, 'vmreadmore');
                                                editor.dom.remove(parentReadmore);
                                            }
                                        }
                                    });

                                    editor.ui.registry.addButton('insertMedia', {
                                        text: t('EDITOR_INSERT_MEDIA'),
                                        onAction: () => {
                                            this.setState({showModal: true});
                                        }
                                    });

                                    editor.ui.registry.addButton('frenchQuotes', {
                                        text: t('FRENCH_QUOTES'),
                                        onAction: () => {
                                            let text = editor.selection.getContent();

                                            if (text) {
                                                editor.insertContent('«' + text + '»');
                                            } else {
                                                editor.insertContent('«»');
                                            }
                                            ;
                                        }
                                    });

                                    editor.ui.registry.addButton('longDash', {
                                        text: t('LONG_DASH'),
                                        onAction: () => {
                                            editor.insertContent('—');
                                        }
                                    });

                                    editor.ui.registry.addButton('middleDash', {
                                        text: t('MIDDLE_DASH'),
                                        onAction: () => {
                                            editor.insertContent('–');
                                        }
                                    });

                                    editor.ui.registry.addButton('haveMeaning', {
                                        text: `Мнение`,
                                        onAction: () => {
                                            editor.insertContent(`Есть мнение `);
                                        }
                                    });

                                    editor.ui.registry.addButton('insertGallery', {
                                        text: `Фотоподборка`,
                                        onAction: () => {
                                            this.setState({modalGallery: true});
                                        }
                                    })

                                    editor.ui.registry.addButton('insertReadmore', {
                                        text: `Читайте также`,
                                        onAction: () => {
                                            this.setState({modalReadmore: true});
                                        }
                                    });
                                },
                            }}
                        />

                    </Form.Item>

                </div>
            </React.Fragment>
        );
    }

};

export default EditorForm;
