import React from 'react';
import { Form, Row, Col, Input, Switch, message, Button, Pagination } from 'antd';
import {documentsElLoad } from './../../../store/documents/api';
import Config from "../../../config";




class EmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            list: [],
            rec: true,
            search: '',
            page: 0,
            total: 0
        };
    }

    loadElements = () => {
        let filter = {q: this.state.search};

        if (this.state.rec) {
            filter['pulse'] = true;
        }

        documentsElLoad(`Article,News`, this.state.page, filter).then(d => {
            this.setState({ list: d.list || [], total: d.total || 0});
        }).catch(e => {
            message.error(`Ошибка загрузки документов`);
            console.error(`error load docs`, e);
        });
    };


    componentDidMount() {
        this.loadElements();
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };


    onSelect = (r) => {
        this.setState({ selected: [...this.state.selected, r]});
    };

    onRemove = (id) => {
        let selected = this.state.selected.filter(i => i.id !== id);
        this.setState({ selected });
    };


    render() {
        let ids = this.state.selected.map(i => i.id);
        return (
            <Row>
                <Col span={15}>
                    <Form>
                        <Form.Item label={`Заголовок`}>
                            {this.props.form.getFieldDecorator('name', {
                                rules: [{required: true, message: 'Заполните заголовок рассылки'}]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label={`Приветственный текст`}>
                            {this.props.form.getFieldDecorator('text', {
                                rules: [{required: true, message: 'Заполните заголовок рассылки'}]
                            })(<Input.TextArea />)}
                        </Form.Item>
                        <Form.Item label={`Материалы`}>
                            {this.state.selected.map(i => {
                                return <ElementListDel del={true} onRemove={this.onRemove} {...i} key={`item_${i.id}`}/>
                            })}
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={8} offset={1}>
                    <Row>
                        <Col span={8}>
                            <div>
                                <label style={{display: 'block'}}>Рекомендуемые</label>
                                <Switch defaultChecked={this.state.rec} onChange={(e) => {
                                    this.setState({ rec: e, page: 1 }, () => {
                                        this.loadElements()
                                    })
                                }}/>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div>
                                <label style={{display: 'block'}}>Поиск</label>
                                <Input.Search onSearch={(search) => {
                                    this.setState({ search, page: 1 }, () => {
                                        this.loadElements()
                                    });
                                }}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div style={{marginTop: 10}}>
                            <Pagination
                                size={`small`}
                                pageSize={20}
                                defaultCurrent={this.state.page}
                                total={this.state.total}
                                onChange={(page) => {
                                    this.setState({ page }, () => {
                                        this.loadElements();
                                    })
                                }}
                                current={this.state.page}
                                hideOnSinglePage={true}
                            />
                        </div>
                        {this.state.list.map(i => {
                            if (ids.indexOf(i.id) + 1 > 0) return null;
                            return <ElementList del={false} onSelect={this.onSelect} {...i} key={`item_${i.id}`}/>
                        })}
                        <div style={{marginTop: 10}}>
                            <Pagination
                                size={`small`}
                                pageSize={20}
                                defaultCurrent={this.state.page}
                                total={this.state.total}
                                onChange={(page) => {
                                    this.setState({ page }, () => {
                                        this.loadElements();
                                    })
                                }}
                                current={this.state.page}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export const GetMediaPath = (files) => {
    let keys = Object.keys(files);
    let media = files[keys[0]];

    if (files.thumbnail) {
        media = files.thumbnail;
    }

    return `${Config.MEDIA.CDN.IMAGES}/${media.path}`
};

export const GetCover = (p) => {
    if (!p.media) return "";
    let covers = p.media.filter(i => i.type === 'Cover');
    if (covers.length > 0) {
        let mainCover = covers.filter(i => i.main);
        let cover = covers[0];
        if (mainCover.length > 0) {
            cover = mainCover[0];
        }
        return GetMediaPath(cover.files)
    }
    return "";
};


const ElementList = (r) => {
    return (
        <div className={`search--element`} style={{display: 'flex', flexDirection: 'row', marginTop: 10, borderBottom: '1px solid #dfdfdf', padding: '4px 0'}}>
            <div className="cover" style={{width: 120}}><img alt={r.title} width={120} src={`${GetCover(r)}`} /></div>
            <div className="info" style={{ paddingLeft: 4}}>
                <div className="title">{r.title}</div>
                {r.del === false && <Button onClick={() => r.onSelect(r)} icon={'plus-circle'} type={`primary`} size={`small`}>Выбрать</Button>}
                {r.del === true && <Button onClick={() => r.onRemove(r.id)} icon={'delete'} type={`danger`} size={`small`}>Удалить</Button>}
            </div>
        </div>
    )
};

const ElementListDel = (r) => {
    return (
        <div className={`search--element`} style={{display: 'flex', flexDirection: 'row', marginTop: 10, borderBottom: '1px solid #dfdfdf', padding: '4px 0'}}>
            <div className="cover" style={{width: 120}}><img alt={r.title} width={120} src={`${GetCover(r)}`} /></div>
            <div className="info" style={{ paddingLeft: 4}}>
                <div className="title">{r.title}</div>
                <Button onClick={() => r.onRemove(r.id)} icon={'delete'} type={`danger`} size={`small`}>Удалить</Button>
            </div>
        </div>
    )
};


const WrappedEmailForm = Form.create({ name: 'EmailForm' })(EmailForm);
export default WrappedEmailForm;
