import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notification } from 'antd';
import * as Actions from "./../../../store/permissions/actions";
import * as C from './../../../store/permissions/constants';
import PermissionsTable from './../../../components/tables/permissions';
import propExists from './../../../core/library/objects/getprop';
import {AppConsumer} from './../../../context/app';
import {t} from "../../../core/library/i18n";

const mapStateToProps = state => ({
    answer: state.PermissionsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class PermissionsIndexController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            isLoading: false,
            total: 0,
            limit: 30,
            filter: {},
        };
    }

    componentDidMount() {
        this.props.actions.loadPermissions(this.state.page, this.state.filter);
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.START_LOAD_PERMISSIONS:
                    this.setState({
                        isLoading: true,
                    });
                    break;
                case C.FINISH_LOAD_PERMISSIONS:
                    this.setState({
                        isLoading: false,
                        list: propExists(p.answer.data, 'data.list') && p.answer.data.data.list ? p.answer.data.data.list : [],
                        total: propExists(p.answer.data, 'data.totalItems') && p.answer.data.data.totalItems ? p.answer.data.data.totalItems : 0,
                        limit: propExists(p.answer.data, 'data.limit') && p.answer.data.data.limit ? p.answer.data.data.limit : 0,
                    });
                    break;
                case C.ERROR_LOAD_PERMISSIONS:
                    this.setState({
                        isLoading: false,
                    }, () => {
                        notification.error({
                            message: `Error load permissions`,
                            delay: 0,
                        });
                    });
                    break;
                case C.START_CREATE_PERMISSION:
                case C.START_UPDATE_PERMISSION:
                    this.setState({isLoading: true});
                    break;
                case C.FINISH_CREATE_PERMISSION:
                case C.FINISH_UPDATE_PERMISSION:
                    notification.success({
                        delay: 3,
                        message: p.answer.state === C.FINISH_CREATE_PERMISSION ?  `Permission has been created` : `Permission has been updated`,
                    });
                    this.props.actions.loadPermissions(this.state.page, this.state.filter);
                    break;
                case C.ERROR_CREATE_PERMISSION:
                case C.ERROR_UPDATE_PERMISSION:
                    notification.error({
                        message: p.answer.state === C.ERROR_CREATE_PERMISSION ? `Error create permission` : `Error update permission`,
                    });
                    this.setState({
                        isLoading: false,
                    });
                    break;
                case C.START_DELETE_PERMISSION:
                    this.setState({ isLoading: true });
                    break;
                case C.ERROR_DELETE_PERMISSION:
                    notification.error({ message: `Error delete permission`});
                    this.props.actions.loadPermissions(this.state.page, this.state.filter);
                    break;
                case C.FINISH_DELETE_PERMISSION:
                    notification.success({ message: `Permission has been deleted` });
                    this.props.actions.loadPermissions(this.state.page, this.state.filter);
                    break;
                default: break;
            }
        }
    }

    changePage = (page) => {
        this.setState({ page }, () => {
            this.props.actions.loadPermissions(this.state.page, this.state.filter);
        });
    };

    updateFilter = (filter) => {
        this.setState({
            page: 1,
            filter: filter,
        }, () => {
            this.props.actions.loadPermissions(this.state.page, this.state.filter);
        });
    };

    createPermission = (data) => {
        this.props.actions.createPermission(data);
    };

    updatePermission = (id, data) => {
        this.props.actions.updatePermissions(id, data);
    };


    deletePermission = (id) => {
        this.props.actions.deletePermission(id);
    };

    render() {
        return (
            <React.Fragment>
                <div className={"permissions--list"}>
                    <AppConsumer>
                        {context => {
                            return (
                                <PermissionsTable
                                    onMounted={() => {
                                        if (context.actions.updateHeader) {
                                            context.actions.updateHeader({
                                                title: this.state.id === false ? t(`CREATE_NEW_PERMISSION`) : `${t('EDIT_PERMISSION')}`,
                                            })
                                        }
                                    }}
                                    loading={this.state.isLoading}
                                    list={this.state.list}
                                    page={this.state.page}
                                    limit={this.state.limit}
                                    total={this.state.total}
                                    onPageChange={this.changePage}
                                    onUpdateFilter={this.updateFilter}
                                    onCreate={this.createPermission}
                                    onUpdate={this.updatePermission}
                                    onDelete={this.deletePermission}
                                />
                            );
                        }}
                    </AppConsumer>
                </div>
            </React.Fragment>
        );
    }
}

PermissionsIndexController.propTypes = {
    actions: PropTypes.instanceOf(Object).isRequired,
    answer: PropTypes.instanceOf(Object).isRequired,
};

export const PermissionsIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(PermissionsIndexController);


export default PermissionsIndex;
