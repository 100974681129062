import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Input, Radio, InputNumber, Checkbox, AutoComplete} from 'antd';
import { t } from '../../../library/i18n';
import Config from './../../../../config';
import innerHTML from './../../../library/hacks/inject';
import { getUnique } from '../../../../core/library/helpers';

const RenderPreview = (p) => {
    const path = p.files && p.files.original ? p.files.original.path : "";
    if (p.type === "Image" || p.type === "Cover") {
        return (
            <div className={`preview preview--image`}>
                <img src={`${Config.MEDIA.CDN.IMAGES}/${path}`} alt={`alt media `}/>
            </div>
        );
    }
    if (p.type === "Video") {
        return (
            <div className={`preview preview--video`}>
                <video controls src={`${Config.MEDIA.CDN.VIDEO}/${path}`} />
            </div>
        );
    }

    if (p.type === "ExternalVideo") {
        return (
            <div className={`preview preview--ext-video`} dangerouslySetInnerHTML={{__html: p.embed }}/>
        )
    }

    if (p.type === "Code") {
        return (
            <div className={`preview preview--code`} id={`code_${p.id}`} />
        );
    }


    return (
        <div>Preview</div>
    )
};


class EditForm extends React.Component {

    static defaultProps = {
        item: {},
        updateItem: () => {},
        onMediaSave: null,
    };

    static propTypes = {
        item: PropTypes.instanceOf(Object).isRequired,
        updateItem: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            filepath: '',
            content: '',
            embed : this.props.item && this.props.item.embed ? this.props.item.embed : "",
        };
    }

    isImage = (name) => {
        let file_params = name.split('.');
        const ext = file_params[file_params.length - 1].toLowerCase();
        return ["jpg", "jpeg", "png", "gif"].indexOf(ext) + 1 > 0;
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.updateItem(values);
            }
        });
    };

    validateSource = (rule, value, callback) => {
        let a = document.createElement('a');
        a.href = value;
        let hostname = a.hostname.replace('www.', '');
        if (Config.MEDIA.EMBEDDED_SOURCES.indexOf(hostname) + 1 === 0) {
            callback(t('INCORRECT_SOURCE'));
        } else {
            const { form } = this.props;
            form.setFieldsValue({
                provider: a.hostname,
                options: {
                    width: 560,
                    height: 315,
                },
                path: value,
                embed: Config.MEDIA.EMEDDED_PREPARE && Config.MEDIA.EMEDDED_PREPARE[hostname] ? Config.MEDIA.EMEDDED_PREPARE[hostname](value) : `<iframe src="${value}" width="560" height=""315"></iframe>`
            });
            this.setState({
                embed: Config.MEDIA.EMEDDED_PREPARE && Config.MEDIA.EMEDDED_PREPARE[hostname] ? Config.MEDIA.EMEDDED_PREPARE[hostname](value) : `<iframe src="${value}" width="560" height=""315"></iframe>`
            });
            callback();
        }
    };

    componentDidMount() {
        const item = this.props.item || {};
        let doc = document.getElementById(`code_${item.id}`);
        if (doc !== null) {
            innerHTML(item.embed, doc, false);
        }
    }

    updateEmbed = (value) => {
        const item = this.props.item || {};
        let doc = document.getElementById(`code_${item.id}`);
        if (doc !== null) {
            innerHTML(value, doc, false);
        }
    };

    prepareCopyrightValue = (item) => {
        if (item.properties && item.properties.copyright) return item.properties.copyright;
        if (item.properties && item.properties.authorName) return item.properties.authorName;
        return "";
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const item = this.props.item || {};
        return (
            <div className={`upload--input__form`}>
                <div className="form--preview">
                   <RenderPreview {...item} embed={this.state.embed}/>
                </div>
                <div className={"form"}>
                    <Form onSubmit={this.onSubmit}>
                        {item.type && item.type === "Code" && (
                            <React.Fragment>
                                <Form.Item label={t('EMBEDDED')}>
                                    {getFieldDecorator('embed', {
                                        initialValue: item.embed || "",
                                    })(<Input.TextArea rows={4} onChange={(e) => {
                                        this.updateEmbed(e.target.value);
                                    }}/>)}
                                </Form.Item>
                                <Form.Item label={t('WIDTH')}>
                                    {getFieldDecorator('files.original.width', {
                                        initialValue: item.files && item.files.original  ? item.files.original.width : 0,
                                    })(<InputNumber />)}
                                </Form.Item>
                                <Form.Item label={t('HEIGHT')}>
                                    {getFieldDecorator('files.original.height', {
                                        initialValue: item.files && item.files.original  ? item.files.original.height : 0,
                                    })(<InputNumber />)}
                                </Form.Item>
                            </React.Fragment>
                        )}
                        {item.type && (item.type !== "ExternalVideo" && item.type !== "Code") && (
                            <React.Fragment>
                                <Form.Item label={t('MEDIA_TITLE')}>
                                    {getFieldDecorator('title', {
                                        initialValue: item.title || "",
                                        rules: []
                                    })(<Input placeholder={t('FILL_MEDIA_TITLE')}/>)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_DESCRIPTION')}>
                                    {getFieldDecorator('description', {
                                        initialValue: item.description || "",
                                        rules: []
                                    })(<Input.TextArea placeholder={t('MEDIA_DESCRIPTION')}/>)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_KEYWORDS')}>
                                    {getFieldDecorator('keywords', {
                                        initialValue: item.keywords || "",
                                        rules: []
                                    })(<Input placeholder={t('MEDIA_KEYWORDS')}/>)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_SOURCES')}>
                                    {getFieldDecorator('properties.source', {
                                        initialValue: item.properties && item.properties.source ? item.properties.source : "",
                                    })(
                                        <Radio.Group>
                                            {Config.MEDIA.SOURCES && Config.MEDIA.SOURCES.map(i => {
                                                return <Radio value={i} key={`media_source_${i}`}>{i}</Radio>
                                            })}
                                        </Radio.Group>
                                    )}

                                </Form.Item>
                                <Form.Item label={t('MEDIA_COPYRIGHT')}>
                                    {getFieldDecorator('properties.copyright', {
                                        initialValue: this.prepareCopyrightValue(item),
                                    })(
                                        <AutoComplete
                                            backfill={true}
                                            dataSource={getUnique(Config.PHOTO_AUTHORS)}
                                            filterOption={true}
                                        >
                                            <Input/>
                                        </AutoComplete>
                                    )}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_COPYRIGHT_URL')}>
                                    {getFieldDecorator('properties.copyrightUrl', {
                                        initialValue: item.properties && item.properties.copyrightUrl ? item.properties.copyrightUrl : "",
                                    })(<Input placeholder={t('MEDIA_COPYRIGHT_URL')}/>)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_MAX_WIDTH')}>
                                    {getFieldDecorator('properties.maxWidth', {
                                        initialValue: item.properties && item.properties.maxWidth ? item.properties.maxWidth : "",
                                    })(<Input placeholder={t('MEDIA_MAX_WIDTH')}/>)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_INFOGRAPHIC')}>
                                    {getFieldDecorator('is_infographic', {
                                        valuePropName: 'checked',
                                        initialValue: item.is_infographic || false,
                                    })(<Checkbox />)}
                                </Form.Item>
                                {item.virtual === false && (
                                    <Form.Item label={`Скрыть через 24 часа`}>
                                        {getFieldDecorator('expired', {
                                            valuePropName: 'checked',
                                            initialValue: item.expired || false,
                                        })(<Checkbox />)}
                                    </Form.Item>
                                )}
                            </React.Fragment>
                        )}

                        {item.type && item.type === "ExternalVideo" && (
                            <React.Fragment>
                                <div style={{opacity: 0, height: 0}}>
                                    <Form.Item label={t('PROVIDER')}>
                                        {getFieldDecorator('provider', {
                                            initialValue: item.provider || "",
                                        })(<Input />)}
                                    </Form.Item>
                                </div>
                                <Form.Item label={t('URL')} help={Config.MEDIA.EMBEDDED_SOURCES.join(', ')}>
                                    {getFieldDecorator('files.original.path', {
                                        initialValue: item.files && item.files.original  ? item.files.original.path : "",
                                        rules: [
                                            {
                                                validator: this.validateSource,
                                                message: t('INCORRECT_SOURCE'),
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label={t('WIDTH')}>
                                    {getFieldDecorator('files.original.width', {
                                        initialValue: item.files && item.files.original  ? item.files.original.width : 0,
                                    })(<InputNumber />)}
                                </Form.Item>
                                <Form.Item label={t('HEIGHT')}>
                                    {getFieldDecorator('files.original.height', {
                                        initialValue: item.files && item.files.original  ? item.files.original.height : 0,
                                    })(<InputNumber />)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_COPYRIGHT')}>
                                    {getFieldDecorator('properties.copyright', {
                                        initialValue: item.properties && item.properties.copyright ? item.properties.copyright : "",
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label={t('MEDIA_COPYRIGHT_URL')}>
                                    {getFieldDecorator('properties.copyrightUrl', {
                                        initialValue: item.properties && item.properties.copyrightUrl ? item.properties.copyrightUrl : "",
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label={t('EMBEDDED')}>
                                    {getFieldDecorator('embed', {
                                        initialValue: item.embed || "",
                                    })(<Input />)}
                                </Form.Item>
                            </React.Fragment>
                        )}
                        <Form.Item>
                            <Button.Group>
                                <Button onClick={this.onSubmit} type={`primary`} icon={"cloud"} htmlType={'button'}>{t("SAVE")}</Button>
                            </Button.Group>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}


export default Form.create({ name: 'edit_form' })(EditForm);
