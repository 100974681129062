import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {Table, Button, message, Checkbox, Tooltip, Input} from 'antd';
import Moment from 'moment';
import 'moment/locale/ru';
import * as Actions from "./../../store/profiles/actions";
import * as C from './../../store/profiles/constants';
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import {generateParamsFromObject, parseUrlParams, prepareParams} from "../../core/library/url";


const mapStateToProps = state => ({
    answer: state.ProfilesReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class ProfilesHeader extends React.Component {
    static defaultProps = {
        context: {},
    };
    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };

    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('PROFILES_LIST'),
        })
    }

    render() {
        return null;
    }
}


class ProfilesControllerClass extends React.Component {

    static defaultProps = {
        actions: {},
        answer: {},
    };

    columns = [
        {
            title: t('PROFILE_AVATAR'),
            dataIndex: 'photo',
            width: 70,
            render: t => t && <img src={t} width={40} height={`auto`} alt={`avatar`}/>
        },
        {
            title: t('PROFILE_LOGIN'),
            dataIndex: 'login'
        },
        {
            title: t('PROFILE_EMAIL'),
            dataIndex: 'email'
        },
        {
            title: t('PROFILE_NAME'),
            key: 'name',
            render: r => `${r.name || ""} ${r.last_name || ""}`
        },
        {
            title: t('PROFILE_CREATED'),
            dataIndex: 'createdAt',
            render: t => Moment(t).format('hh:mm:ss DD-MM-YYYY')
        },
        {
            title: t('PROFILE_ACTIVE'),
            key: 'profile_active',
            align: 'center',
            render: r => <Checkbox checked={r.active} onChange={(e) => this.updateProfile(r.id, {active: e.target.checked})}/>
        },

        {
            title: t('PROFILE_APPROVED'),
            key: 'profile_approved',
            align: 'center',
            render: r => <Checkbox checked={r.approved} onChange={(e) => this.updateProfile(r.id, {approved: e.target.checked})}/>
        },
        {
            title: t('PROFILE_TYPE'),
            key: 'profile_type',
            render: r => {
                let photo = r.photo || "";
                if (photo.indexOf('facebook') + 1 > 0) return `facebook`;
                if (photo.indexOf('twimg') + 1 > 0) return `twitter`;
                if (photo.indexOf('userapi') + 1 > 0) return `vk`;
                if (photo.indexOf('mycdn.me') + 1 > 0) return `ok`;
                return `email`;
            }
        },
        {
            title: t('GROUPS'),
            key: 'groups',
            render: r => {
                let result = [];
                if (r.groups && r.groups.length > 0) {
                    result = r.groups.map(i => i.title);
                }
                return result.join(', ');
            },
        },
        {
            title: '',
            key: 'actions',
            render: r => {
                return (
                    <Tooltip title={t('EDIT_PROFILE')}>
                        <Button type={`primary`} icon={`edit`}
                                onClick={() => this.props.history.push(`/profiles/${r.id}`)}/>
                    </Tooltip>
                );
            },
        }
    ];

    constructor(props) {
        super(props);
        let url = parseUrlParams();
        this.state = {
            list: [],
            limit: 0,
            total: 0,
            page: url.page ? parseInt(url.page, 10) : 1,
            loading: false,
            filter: prepareParams(['search']),
        };
    }

    loadProfiles = () => {
        this.props.actions.LoadProfiles(this.state.page, this.state.filter);
    };

    updateProfile = (id, data = {}) => {
        this.props.actions.UpdateProfile(id, data);
    };

    componentDidMount() {
        this.loadProfiles();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.PROFILES_LOAD_START:
                case C.PROFILES_UPDATE_START:
                    this.setState({loading: true});
                    break;

                case C.PROFILES_UPDATE_ERROR:
                    message.error(t('ERROR_UPDATE_PROFILES'));
                    this.setState({loading: false}, () => this.loadProfiles());
                    break;

                case C.PROFILES_UPDATE_FINISH:
                    message.success(t('PROFILES_UPDATED'));
                    this.setState({loading: false}, () => this.loadProfiles());
                    break;


                case C.PROFILES_LOAD_ERROR:
                    message.error(t('ERROR_LOAD_PROFILES'));
                    this.setState({loading: false});
                    break;
                case C.PROFILES_LOAD_FINISH:
                    let profilesRes = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    this.setState({
                        loading: false,
                        list: profilesRes.list || [],
                        total: profilesRes.total || 0,
                        limit: profilesRes.limit || 0,
                    });
                    break;
                default:
                    break;
            }
        }
    }


    onSearch = (search) => {
        this.setState({page: 1, filter: {search}}, () => {
            let filter = {...this.state.filter};
            filter["page"] = this.state.page;
            this.props.history.push(`${this.props.location.pathname}?${generateParamsFromObject(filter)}`);
            this.loadProfiles();
        });
    };

    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <ProfilesHeader context={context}/>
                            <div className={`comments--view`}>
                                <Table
                                    title={() => {
                                        return (
                                            <Input.Search
                                                onSearch={this.onSearch}
                                                defaultValue={this.state.filter.search || ""}
                                                placeholder={t('PROFILE_SEARCH_PLACEHOLDER')}
                                            />
                                        );
                                    }}
                                    bordered={true}
                                    loading={this.state.loading}
                                    columns={this.columns}
                                    dataSource={this.state.list}
                                    rowKey={(item) => item.id}
                                    pagination={{
                                        pageSize: this.state.limit,
                                        defaultCurrent: this.state.page,
                                        total: this.state.total,
                                        onChange: (page) => this.setState({ page }, () => { this.loadProfiles() }),
                                        current: this.state.page,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}


export const ProfilesController = connect(
    mapStateToProps,
    mapDispatchProps
)(ProfilesControllerClass);

export default ProfilesController;
