import React from 'react';
import Config from './../../../config';
// import Fetcher from "../../fetch";




export const GetMediaPath = (files) => {
    let keys = Object.keys(files);
    let media = files[keys[0]];

    if (files.thumbnail) {
        media = files.thumbnail;
    }

    if (Config.MEDIA.CDN.IMAGES.indexOf('cdnimages.') + 1 > 0) {
        let media_params = media.path.split('/');
        media_params[media_params.length - 1] = `500_${media_params[media_params.length - 1]}`;
        return `${Config.MEDIA.CDN.IMAGES}/${media_params.join('/')}`
    }

    return `${Config.MEDIA.CDN.IMAGES}/${media.path}`
};

export const GetCover = (p) => {
    if (!p.media) return "";
    let covers = p.media.filter(i => i.type === 'Cover');
    if (covers.length > 0) {
        let mainCover = covers.filter(i => i.main);
        let cover = covers[0];
        if (mainCover.length > 0) {
            cover = mainCover[0];
        }
        return GetMediaPath(cover.files)
    }
    return "";
};

export const Cover = (p) => {
    console.log(`ge cover for`, p);
    if (!p.media) return <div className={`cover__empty`} />;
    let covers = p.media.filter(i => i.type === 'Cover');
    if (p.type === 'Mgallery') {
        covers = p.media.filter(i => i.type === 'Cover' || i.type === 'Image');
    }
    if (covers.length > 0) {
        let mainCover = covers.filter(i => i.main);
        let cover = covers[0];
        if (mainCover.length > 0) {
            cover = mainCover[0];
        }
        return <div className="document__image bg__image" style={{
            backgroundImage: `url(${GetMediaPath(cover.files)})`,
            width: '100%',
            paddingBottom: '66%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
        }}/> 
        
        // <img src={GetMediaPath(cover.files)} alt={`document cover`}/>
    }
    return <div className={`cover__empty`} />
};




// class OpinionCover extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             img_path: '',
//         };
//     }

//     loadImageCover = (id) => {
//         return new Promise((resolve, reject) => {
//             Fetcher(`documents/get_cover/${id}`, `GET`).then(res => {
//                 resolve(res);
//             }).catch(e => {
//                 reject(e);
//             });
//         });
//     }

//     async componentDidMount() {
//         if (this.props.authors && this.props.authors.length > 0) {
//             try {
//                 let data = await this.loadImageCover(this.props.authors[0].id);
//                 if (data && data.data) {
//                     this.setState({ img_path : data.data });
//                 }
//             } catch (e) {
//                 // skip
//                 console.error(`error load author cover`, e);
//             }
//         }
//     }

//     render() {
//         return this.state.img_path ? <img src={this.state.img_path} alt={`author`} /> : null;
//     }
// }





export default Cover;


