import React from 'react';
import {Button, Icon} from "antd";
import Config from "../../../../config";



export class DefaultView extends React.Component {
    state = {
        width: 1104,
    };
    render() {
        const item = this.props.item || {};
        if (!item.title) return null;
        return (
            <div className={`preview--container`}>
                <div className={`preview--btns`}>
                    <Button.Group size={'medium'}>
                        <Button type={this.state.width === 375 ? 'primary' : "default"} onClick={() => this.setState({width: 375})}>
                            <Icon type="mobile" />
                            Mobile (375)
                        </Button>
                        <Button type={this.state.width === 768 ? 'primary' : "default"} onClick={() => this.setState({width: 768})}>
                            <Icon type="tablet" />
                            Tablet (768)
                        </Button>
                        <Button type={this.state.width === 1104 ? 'primary' : "default"} onClick={() => this.setState({width: 1280})}>
                            <Icon type="desktop" />
                            Desktop (1104)
                        </Button>
                    </Button.Group>
                </div>
                <div className="preview--container__iframe" style={{width: this.state.width}}>
                    <iframe title={item.title} src={`${Config.PREVIEW_DOMAIN}/preview/${item.id}`} />
                </div>
            </div>
        )
    }
}


export default DefaultView;
