import * as Elements from './elements';

export function GenerateMedia(image = {}, editor) {
    let newElement = editor.dom.create(`rmedia`, {id: image.id}, '');
    let node = editor.selection.getNode();

    if (editor.dom.doc.querySelectorAll('rmedia[id="' + image.id + '"]').length === 0) {
        if (node.nodeName === 'BODY') {
            node = editor.dom.select('p')[0];
        } else if (node.nodeName !== 'P') {
            node = editor.dom.getParent(node, 'p')
                || editor.dom.getParent(node, 'ul')
                || editor.dom.getParent(node, 'ol')
                || editor.dom.getParent(node, 'blockquote')
                || editor.dom.getParent(node, 'h1')
                || editor.dom.getParent(node, 'h2')
                || editor.dom.getParent(node, 'h3')
                || editor.dom.getParent(node, 'h4')
                || editor.dom.getParent(node, 'h5')
                || editor.dom.getParent(node, 'h6')
                || editor.dom.getParent(node, 'div')
            ;
        }
        let prev = node.previousSibling;
        if (editor.dom.isEmpty(node) && prev !== null) {
            editor.dom.remove(node);
            node = prev;
        }
        editor.dom.insertAfter(newElement, node);
    }
    // pAfter(newElement, editor);
}

export function GenerateGallery(gl, editor) {
    let newElement = editor.dom.create(`vmgallery`, {id: gl.id}, 'Фотоподборка: ' + gl.title);
    let node = editor.selection.getNode();
    if (editor.dom.doc.querySelectorAll('vmgallery[id="' + gl.id + '"]').length === 0) {
        if (node.nodeName === 'BODY') {
            node = editor.dom.select('p')[0];
        } else if (node.nodeName !== 'P') {
            node = editor.dom.getParent(node, 'p')
                || editor.dom.getParent(node, 'ul')
                || editor.dom.getParent(node, 'ol')
                || editor.dom.getParent(node, 'blockquote')
                || editor.dom.getParent(node, 'h1')
                || editor.dom.getParent(node, 'h2')
                || editor.dom.getParent(node, 'h3')
                || editor.dom.getParent(node, 'h4')
                || editor.dom.getParent(node, 'h5')
                || editor.dom.getParent(node, 'h6')
                || editor.dom.getParent(node, 'div')
            ;
        }
        let prev = node.previousSibling;
        if (editor.dom.isEmpty(node) && prev !== null) {
            editor.dom.remove(node);
            node = prev;
        }
        editor.dom.insertAfter(newElement, node);
    }
}


export function GenerateReadmore(gl, editor) {
    let newElement = editor.dom.create(`vmreadmore`, {id: gl.id}, 'Читайте также: ' + gl.title);
    let node = editor.selection.getNode();
    if (editor.dom.doc.querySelectorAll('vmreadmore[id="' + gl.id + '"]').length === 0) {
        if (node.nodeName === 'BODY') {
            node = editor.dom.select('p')[0];
        } else if (node.nodeName !== 'P') {
            node = editor.dom.getParent(node, 'p')
                || editor.dom.getParent(node, 'ul')
                || editor.dom.getParent(node, 'ol')
                || editor.dom.getParent(node, 'blockquote')
                || editor.dom.getParent(node, 'h1')
                || editor.dom.getParent(node, 'h2')
                || editor.dom.getParent(node, 'h3')
                || editor.dom.getParent(node, 'h4')
                || editor.dom.getParent(node, 'h5')
                || editor.dom.getParent(node, 'h6')
                || editor.dom.getParent(node, 'div')
            ;
        }
        let prev = node.previousSibling;
        if (editor.dom.isEmpty(node) && prev !== null) {
            editor.dom.remove(node);
            node = prev;
        }
        editor.dom.insertAfter(newElement, node);
    }
}



// function pAfter(el, editor) {
//     editor.dom.insertAfter(editor.dom.create('p', {}, '<br data-mce-bogus="true">'), el);
// }

export function PrepareMedia(media) {

}

export function RenderMedia(editor, obj = {}, actions = {}) {
    // editor available

    if (editor.dom === undefined) {
        return false;
    }
    let mediaList = editor.dom.select("rmedia");
    if (mediaList && mediaList.length > 0) {

        let mediaMap = {};
        obj.media.map(i => {
            if (i.id) {
                mediaMap[i.id] = i;
            }
            return i;
        });

        mediaList.map(i => {
            i.setAttribute('data-mce-contenteditable', false);
            let mediaId = i.getAttribute('id');
            let mediaItem = mediaMap[mediaId] || false;
            if (mediaItem) {
                editor.dom.setHTML(i, RenderMediaElement(editor, mediaItem, mediaItem.type.toLowerCase(), obj, actions));
                // pAfter(i, editor);
            } else {
                editor.dom.remove(i);
                // message.error(`Media element: ${mediaId} not found in doc`);
            }
            return i;
        });
        // render media components
    }

    let galleryList = editor.dom.select("vmgallery");
    if (galleryList && galleryList.length > 0) {
        galleryList.forEach(i => {
            if (i.innerText && !i.getAttribute('data-title')) {
                i.setAttribute('data-title', i.innerText);
            }
            i.setAttribute('data-mce-contenteditable', false);
            editor.dom.setHTML(i, RenderGalleryElement(editor, {title: i.getAttribute(`data-title`)}, 'gallery', obj, actions));
        });
    }

    let readmoreList = editor.dom.select("vmreadmore");
    if (readmoreList && readmoreList.length > 0) {
        readmoreList.forEach(i => {
            if (i.innerText && !i.getAttribute('data-title')) {
                i.setAttribute('data-title', i.innerText);
            }
            i.setAttribute('data-mce-contenteditable', false);
            editor.dom.setHTML(i, RenderReadmoreElement(editor, {title: i.getAttribute(`data-title`)}, 'readmore', obj, actions));
        });
    }
}

function UnknownElement(element) {
    return `<div class="rmedia--alert">Element ${element.type} not found</div>`;
}

function RenderGalleryElement(editor, element, type, state, actions) {
    const C = Elements.gallery;
    return C(element);
}

function RenderReadmoreElement(editor, element, type, state, actions) {
    const C = Elements.readmore;
    return C(element);
}

function RenderMediaElement(editor, element, type, state, actions) {
    const C = Elements[type] ? Elements[type] : UnknownElement;
    return C(element);
}
