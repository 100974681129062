import React from 'react';
import {Link} from 'react-router-dom';
import {message, Table, Button, Input, notification, Tooltip, Popconfirm, Tag, Modal} from 'antd';
import {AppConsumer} from "../../context/app";
import './sass/regions.scss';
import {documentsElLoad, createDocument, deleteDocumentRegion} from '../../store/documents/api';
import Config, { GetImagePath } from "../../config";
import Moment from 'moment';


const getCover = (media) => {
    if (media && Array.isArray(media)) {
        let image = null;
        media.forEach(i => {
            if (['Cover', 'RegionCover'].indexOf(i.type) + 1 > 0){
                return GetImagePath(i.files, '320');
            }
        });
        return image;
    }
    return null;
}


export class RegionsController extends React.Component {

    columns = [
        {
           title: 'Обложка',
           key: 'cover',
           width: 120,
           render: r => {
               return getCover(r.media);
           }
        },
        {
            title: 'Заголовок',
            key: `title`,
            render: r => {
                return <Link to={`/documents/regionmat/${r.id}`}>{r.title}</Link>
            },
        },
        {
            title: 'Категории',
            key: `category`,
            render: r => {
                if (r.options && r.options['regions_categories'] && Array.isArray(r.options['regions_categories'])) {
                    return r.options['regions_categories'].map(i => <Tag>{i}</Tag>);
                }
                return null;
            },
        },
        {
            title: 'Сайты',
            key: 'sites',
            width: 70,
            render: r => {
                let list = [];
                if (r.options && r.options['published_posts']) {
                    list = Object.keys(r.options["published_posts"]).map(i => {
                        return {'site': i, guid: r.options["published_posts"][i].guid, post_id: r.options["published_posts"][i].ID}
                    });
                }
                if (list.length === 0) return null;
                return (
                    <Button type={`primary`} onClick={() => {
                        this.setState({ modal_visible: true, modal_list: list });
                    }}>Посмотреть</Button>
                );
            }
        },
        {
            title: 'Дата публикации',
            key: 'created_at',
            render: r => {
                return Moment(r.publishedAt).format(Config.DATE_FORMAT)
            }
        },
        {
            key: 'actions',
            render: r => {
                return (
                    <div className={`region_actions`}>
                        <Tooltip title={`Редактировать материал`}><Button type={`primary`} icon={`edit`} onClick={() => {
                            this.openEditPage(r.id);
                        }}/></Tooltip>
                        <Popconfirm title={`Вы действительно хотите удалить материал?`} okText={`Удалить`} cancelText={`Закрыть`} onConfirm={() => this.onDeleteItem(r.id)}>
                            <Tooltip title={`Удалить материал`}><Button type={`danger`} icon={`delete`} /></Tooltip>
                        </Popconfirm>
                        <Tooltip title={`Скачать отчет`}><Button icon={`download`} onClick={() => {
                            this.downloadRegionReport(r.id);
                        }}/></Tooltip>

                    </div>
                );
            }
        }
    ]

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
            page: 0,
            total: 0,
            limit: 20,
            type: 'Regionmat',
            search: '',
            modal_list: [],
            modal_visible: false,
        };
    }

    loadList = () => {
        this.setState({loading: true}, () => {
            const {page, type, search, limit} = this.state;
            let filter = {};
            if (search) {
                filter['q'] = search;
            }
            documentsElLoad(type, page, filter, '-publishedAt', limit).then(res => {
                this.setState({
                    loading: false,
                    list: res.list || [],
                    total: res.total || 0,
                });
            }).catch(e => {
                console.error(`error load documents: `, e);
                message.error(`Ошибка загрузки документов`);
                this.setState({loading: false});
            });
        })
    }

    onSearch = (search) => {
        this.setState({ search, page: 1}, () => {
            this.loadList();
        })
    }

    openEditPage = (id) => {
        this.props.history.push(`/documents/regionmat/${id}`);
    }


    onDeleteItem = (document_id) => {
        this.setState({ loading: true }, async () => {
            await deleteDocumentRegion(document_id);
            setTimeout(() => {
                this.loadList();
            }, 2500);
        });
    }

    downloadRegionReport = (document_id) => {
        let host = window.location.hostname.indexOf('localhost') + 1 > 0 ? 'http://localhost:8086/api/backend' : 'https://emadm.vmdaily.ru/api/backend';
        window.open(`${host}/regions/${document_id}/report`);
    }

    openAddScreen = () => {
        this.setState({ loading: true }, async () => {
            try {
                let resp = await createDocument({
                    title: `Материал ${window.user.email}`,
                    slug: 'material',
                    type: this.state.type,
                    publishedAt: Moment(new Date()),
                    mcats: "611a6aee82682c1ef072c41c",
                });
                if (resp && resp.id) {
                    message.success(`Материал успешно создан! Вы будете переадресованы через несколько секунд`);
                    this.setState({ loading: false, }, () => {
                        this.loadList();
                        this.openEditPage(resp.id);
                    })
                }
            } catch (e) {
                notification.error({
                    message: `Ошибка создания материала`,
                    description: `Обратитесь пожалуйста к разработчикам`,
                })
                this.setState({ loading: false });
            }
        })
    }

    closeModal = () => {
        this.setState({modal_list: [], modal_visible: false});
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        const { modal_list, modal_visible } = this.state;
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <Modal title={`Список сайтов, на которых опубликован материал`} visible={modal_visible} width={'90%'} onOk={this.closeModal} onCancel={this.closeModal} destroyOnClose={true}>
                                <Table
                                    columns={[
                                        {
                                            title: 'Сайт',
                                            key: 'site',
                                            render: r => {
                                                return r.site;
                                            }
                                        },
                                        {
                                            title: 'Ссылка',
                                            key: 'link',
                                            render: r => {
                                                return <a href={r.guid} target={"_blank"} rel={`nofollow`}>{r.guid}</a>;
                                            }
                                        },
                                        {
                                            title: 'Ссылка в админке',
                                            key: 'admin_link',
                                            render: r => {
                                                return <a href={`https://${r.site}/wp-admin/post.php?post=${r.post_id}&action=edit`} target={"_blank"} rel={`nofollow`}>Редактировать</a>
                                            }
                                        }
                                    ]}
                                    bordered={true}
                                    dataSource={modal_list}
                                    rowKey={(item) => item.guid}
                                    pagination={{
                                        pageSize: 1000,
                                        defaultCurrent: 1,
                                        total: this.state.total,
                                        onChange: (page) => this.setState({ page }, () => { this.loadList() }),
                                        current: 1,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </Modal>
                            <div className="regions-materials">
                                <div className="box title">Список материалов</div>
                                <div className="box table">
                                    <Table
                                        title={() => (
                                            <div className={`table_actions`}>
                                                <Button type={`primary`} onClick={this.openAddScreen}>Добавить</Button>
                                                <Input.Search
                                                    onSearch={this.onSearch}
                                                    placeholder={`Введите поисковый запрос`}
                                                    enterButton={`Поиск`}
                                                />
                                            </div>
                                        )}
                                        bordered={true}
                                        loading={this.state.loading}
                                        columns={this.columns}
                                        dataSource={this.state.list}
                                        rowKey={(item) => item.id}
                                        pagination={{
                                            pageSize: this.state.limit,
                                            defaultCurrent: this.state.page,
                                            total: this.state.total,
                                            onChange: (page) => this.setState({ page }, () => { this.loadList() }),
                                            current: this.state.page,
                                            hideOnSinglePage: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}
