import React from 'react';
import { loadMediaList } from './../../../../store/media/api';
import MediaList from './list';
import { Modal } from 'antd';

export default class ImagesModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
            total: 0,
            limit: 60,
            loading: false,
            error: false,
            filter: {
                type:  '',
                search: '',
                range: null,
            },
            list: [],
			selectedImages: [],
		};
	}

	buildFilter = () => {
        let filter =  {
            type: this.state.filter.type === '' ? 'Image,Cover' : this.state.filter.type,
            q: this.state.filter.search || "",
        };

        if (this.state.filter.range && this.state.filter.range.length > 0) {
            filter.range = "createdAt";
            if (this.state.filter.range.length === 2) {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
                filter.to = this.state.filter.range[1].format('YYYY-MM-DDTHH:mm:ss');
            } else {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
            }
        }

        return filter;
    };

	onAddImage = (image) => {
		console.log(`image`, image);
	}

	loadList = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                // load media list from server
                window.scrollTo(0, 0);
                let filter = this.buildFilter();

                if (this.props.history) {
                    let url = `${window.location.pathname}?page=${this.state.page}&type=${this.state.filter.type || ""}&search=${encodeURIComponent(this.state.filter.search || "")}`;
                    if (this.state.filter.range && this.state.filter.range.length > 0) {
                        if (this.state.filter.range.length === 2) {
                            url = `${url}&from=${this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss')}&to=${this.state.filter.range[1].format('YYYY-MM-DDTHH:mm:ss')}`
                        } else {
                            url = `${url}&from=${this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss')}`
                        }
                    }
                    this.props.history.push(url);
                }

                loadMediaList(this.state.page, this.state.limit, filter, this.state.isPhoto, this.state.ignorePhoto, this.state.isBookmark, this.state.isExclude).then(async resp => {
                    let data = resp || {};
                    this.setState({
                        list: data.list || [],
                        total: data.total || 0,
                        loading: false,
                        error: false,
                    })
                }).catch(e => {
                    console.error(`error load media list: `, e);
                    this.setState({
                        loading: false,
                        error: true,
                    })
                });
            });
        }
    };

    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadList();
        });
    };

	componentDidMount() {
        this.loadList();
    }

	onFilter = (filter) => {
		this.setState({ filter, page: 1 }, () => {
			this.loadList();
		})
	}


	render() {
		return (
			<Modal
				visible={this.props.visible}
				destroyOnClose
				className={`images--modal__list instruments-dnd`}
				title={`Выберите изобрежения`}
				width={'80%'}
				footer={null}
				onCancel={this.props.onClose}
			
				>
				 <MediaList
					{...this.state}
					onPageChange={this.onPageChange}
					reloadList={this.reloadList}
					onFilter={this.onFilter}
					showMaterialModal={this.showMaterialModal}
					isPhoto={this.props.isPhotoUser || false}
					updatePhotoFilter={this.updatePhotoFilter}
					updatePhotoBookmark={this.updatePhotoBookmark}
					updatePhotoExclude={this.updatePhotoExclude}
					onAddImage={this.props.onAddImage}
					images={this.props.images}
				/>
			</Modal>
		);
	}
}