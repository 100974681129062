import Config from './../../../../config';



function prepareText(element) {
    let params = [element.title];
    if (element.properties) {
        Object.keys(element.properties).map(i => {
            if (element.properties[i]) {
                params.push(element.properties[i]);
            }
            return i;
        });
    }
    return (params.join(' / '));
}


export const ImageTemplate = (p) => {
    let path = p && p.files ? p.files.original.path : "";
    return `<div class="preview--block image"><img src="${Config.MEDIA.CDN.IMAGES}/${path}" /><div class="info">${prepareText(p)}</div></div>`;
};

export default ImageTemplate;
