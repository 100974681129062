export default function (p) {
    return (
        `<div class="rmedia rmedia-code mceNonEditable">
            <div>${p.embed}</div>
            <div class="button primary updateMedia mceNonEditable"></div>
            <div class="button danger deleteMedia mceNonEditable"></div>
        </div>
       `
    );
};


