import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, DatePicker, Tooltip, Spin } from 'antd';
import Moment from 'moment';
import { t } from '../../library/i18n';
import Config from './../../../config';
import DocumentSelect from './../autocomplete/documents';
import { P } from '../../../core/permissions';


export class DefaultFilter extends React.Component {

    static defaultProps = {
        onFilter: (v) => {},
        onCreate: () => {},
    };

    static propTypes = {
        onFilter: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const filter = this.props.filter || {};
        this.state = {
            search: filter.search || "",
            status: filter.status || "",
            tags: filter.tags || "",
            category: filter.category || "",
            workflow: filter.workflow || "",
            df: filter.df ? Moment(`${filter.df}T00:00:00`) : '',
            dt: filter.dt ? Moment(`${filter.dt}T00:00:00`) : '',
            loading: false,
        };
    }

    prepareFilter = (values) => {
        let result = {};
        Object.keys(values).map(i => {
            if (i && i !== 'loading' && values[i] !== '') {
                result[i] = values[i];
            }
            return i;
        });
        return result;
    };

    updateFilter = () => {
        this.props.onFilter(this.prepareFilter(this.state));
    };

    clearFilter = () => {
        this.setState({
            search: "",
            status: "",
            tags: "",
            category: "",
            df: '',
            dt: '',
            loading: true,
        }, () => {
            this.updateFilter();
            setTimeout(() => this.setState({ loading : false }), 200);
        });
    };

    isCustom = () => {
        const { type } = this.props;
        return !(['stt', 'tts'].indexOf(type) + 1 > 0);
    }

    render() {
        const props = {style: {width: '100%'}};
        const Statuses = Config.DOCUMENTS && Config.DOCUMENTS.STATUS ? Config.DOCUMENTS.STATUS : [];
        let propsDateFrom = {style: {width: '100%'}};
        let propsDateTill = {style: {width: '100%'}};
        if(this.state.df) {
            propsDateFrom.defaultValue = this.state.df;
        }
        if(this.state.dt) {
            propsDateTill.defaultValue = this.state.df;
        }

        let tagsOptions = {
            placeholder: t('SELECT_TAGS'),
            allowClear: true,
            showSearch: true,

        };

        if (this.state.tags) {
            tagsOptions.defaultValue = this.state.tags;
        }

        let categoryOptions = {
            placeholder: t('SELECT_PODCAST'),
            allowClear: true,
            showSearch: true,
        };

        if (this.state.category) {
            categoryOptions.defaultValue = this.state.category;
        }


        return (
            <Spin spinning={this.state.loading}>
                <div className={`filter--wrapper filter--article`}>
                    <div className="filter--actions">
                        <Button {...props} type={`primary`} onClick={this.props.onCreate}>{t('CREATE')}</Button>
                        <Tooltip title={t('CLEAR_FILTER')}>
                            {P('SHOW_BTN') && (<Button type={`primary`} icon={`reload`} onClick={this.clearFilter}/>)}
                        </Tooltip>
                    </div>
                    {this.state.loading === false && (
                        <div className="filter--options">
                            <div className="filter--row">
                                <Input.Search defaultValue={this.state.search} placeholder={t('TITLE_OR_URL')} onSearch={(v) => {
                                    this.setState({search: v}, () => this.updateFilter());
                                }}/>
                            </div>
                            {this.isCustom() && (
                            <>
                            {Config.WORKFLOW && Config.WORKFLOW.STATUSES && (
                                <div className={`filter--row`}>
                                    <Select style={{width: '100%'}} placeholder={t('SELECT_WORKFLOW')} allowClear={true} onChange={(v) => {
                                        this.setState({ workflow: v }, () => this.updateFilter());
                                    }}>
                                        <Select.Option value={""}>{t('ALL')}</Select.Option>
                                        {Config.WORKFLOW.STATUSES.map(i => <Select.Option value={i} key={`workflow_status_${i}`}>{t(i)}</Select.Option>)}
                                    </Select>
                                </div>
                            )}
                            {Statuses.length > 0 && (
                                <div className="filter--row">
                                    <Select defaultValue={this.state.status} {...props} placeholder={t('SELECT_STATUS')} allowClear={true} onChange={(v) => {
                                        this.setState({ status: v }, () => this.updateFilter());
                                    }}>
                                        <Select.Option value={""}>{t('ALL')}</Select.Option>
                                        {Statuses.map(i => <Select.Option value={i} key={`filter_status_${i}`}>{t(i)}</Select.Option>)}
                                    </Select>
                                </div>
                            )}
                            {this.props.type === 'episode' && (
                                <div className="filter--row">
                                <DocumentSelect
                                    type={"Podcasts"}
                                    props={{...props, ...categoryOptions}}
                                    onChange={(v) => {
                                        this.setState({category: v}, () => this.updateFilter());
                                    }}
                                />
                            </div>
                            )}
                            
                            <div className="filter--row">
                                <DatePicker {...propsDateFrom} placeholder={t('DATE_FROM')} onChange={(v) => {
                                    this.setState({df: v !== null ? v.format('YYYY-MM-DD') : ''}, () => this.updateFilter());
                                }}/>
                            </div>
                            <div className="filter--row">
                                <DatePicker {...propsDateTill} placeholder={t('DATE_TO')} onChange={(v) => {
                                    this.setState({dt: v !== null ? v.format('YYYY-MM-DD') : ''}, () => this.updateFilter());
                                }}/>
                            </div>
                            </> )}
                        </div>
                    )}
                </div>
            </Spin>
        );
    }
}

export default DefaultFilter;
