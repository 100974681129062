import makeRequest from './../../core/fetch';
import {generateParamsFromObject} from "../../core/library/url";


export function loadChat(page = 1, filter = {}) {
    let prepareFilter = generateParamsFromObject(filter);
    return makeRequest(`chat?page=${page}&${prepareFilter}`, `GET`);
}

export function updateMessage(id = '', data = {}) {
    return makeRequest(`chat/${id}`, `PUT`, data);
}
