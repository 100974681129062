import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class RedirectsAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'group',
                'label': t('LINK_PROPS_UTM'),
                'fields': [
            {
				'component': 'input',
				'label': t('LINK_TO_REDIRECT'),
				'name': "redirectTo",
				'props': {
					'type': 'text',
					'placeholder': t('LINK_TO_REDIRECT_PLACEHOLDER'),
				},
			},
            {
                'component': 'input',
                'label': t('UTM_SOURCE'),
                'name': 'options.utm_source',
                'props': {
                    'type': 'text',
                    'placeholder': t('UTM_SOURCE_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('UTM_MEDIUM'),
                'name': 'options.utm_medium',
                'props': {
                    'type': 'text',
                    'placeholder': t('UTM_MEDIUM_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('UTM_CAMPAIGN'),
                'name': 'options.utm_campaign',
                'props': {
                    'type': 'text',
                    'placeholder': t('UTM_CAMPAIGN_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('UTM_CONTENT'),
                'name': 'options.utm_content',
                'props': {
                    'type': 'text',
                    'placeholder': t('UTM_CONTENT_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('UTM_TERM'),
                'name': 'options.utm_term',
                'props': {
                    'type': 'text',
                    'placeholder': t('UTM_TERM_PLACEHOLDER'),
                }
            },
        ]}
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
		values.slug = 'slug';
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}

                />
            </div>
        );
    }
}

export default RedirectsAdd;
