import React from 'react';
import {Form, Button, message} from "antd";
import AutocompleteField from './../autocomplete/documents';
import { createDocument } from './../../../store/documents/api';
///api/backend/documents

const PrepareInitialValue = (p) => {
    if (p.initialValue && Array.isArray(p.initialValue)) {
        let ids = p.initialValue.map(i => {
            if (i.id) return i.id;
            return i;
        });
        return ids;
    }
    if (p.initialValue && p.initialValue.id) return p.initialValue.id;
    if (p.initialValue) return p.initialValue;
    return "no initial";
};


export const Autocomplete = (p) => {
    const Initial = PrepareInitialValue(p);
    let props = {
        rules: p.rules || [],
    };
    if (Initial !== "no initial") {
        props.initialValue = Initial;
    }

    return (
        <Form.Item label={p.label || ""} className={`row__with-btn`}>
            {p.decorator(p.name, props)(
                <AutocompleteField filter={p.filter || false} type={p.type} limit={p.limit || 250} props={p.props} initial={Initial}/>
            )}
            <Button type="primary" icon="plus" className='btn--acadd' onClick={() => {
                let body = {
                    category: "000000000000000000000000",
                    href: "",
                    question: "",
                    slug: "",
                    title: p.type,
                    type: p.type,
                    visible: false,
                    workflow: "DRAFT",
                };
                createDocument(body).then(res => {
                    message.success(`Document has been created`);
                    window.open(`/documents/${p.type.toLowerCase()}/${res.id}`);
                }).catch(e => {
                    console.error(`[createDocument]: `, e);
                    message.error(`Failed to create new document`);
                });
            }}/>
        </Form.Item>
    );
};
