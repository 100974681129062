import React from 'react';
import {Form, InputNumber} from 'antd';


export const FormInputNumber = (p) => {
    let cProps = {};
    if(p.use_formater) {
        cProps.formatter = (value) => `${value} ${p.use_formater}`;
    }

    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                rules: p.rules || [],
                initialValue: p.initialValue ? p.initialValue : "",
            })(
                <InputNumber {...p.props} {...cProps}/>
            )}
        </Form.Item>
    )
};
