import React from 'react';
import { Tag } from 'antd';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { t } from './../../library/i18n';
import Config from './../../../config';

export default class DocStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={`doc--status`}>
                <div className={`doc--status_row status`}>
                    <div className="label">{t('DOC_STATUS')}: </div>
                    <div className="value">
                        {this.props.published ? <Tag color={"green"}>{t('published')}</Tag> : <Tag color={"red"}>{t('unpublished')}</Tag>}
                    </div>
                </div>
                {this.props.createdBy && this.props.createdBy.id && (
                    <div className="doc--status_row">
                        <div className="label">{t('CREATE_BY')}: </div>
                        <div className="value">
                            <Link to={`/users/${this.props.createdBy.id}`}>{this.props.createdBy.email}</Link> {t('AT')} {Moment(this.props.createdAt).format(Config.DATE_FORMAT)}</div>
                    </div>
                )}

                {this.props.updatedBy && this.props.updatedBy.id && (
                    <div className="doc--status_row">
                        <div className="label">{t('UPDATED_BY')}: </div>
                        <div className="value"><Link to={`/users/${this.props.createdBy.id}`}>{this.props.updatedBy.email}</Link> {t('AT')} {Moment(this.props.updatedAt).format(Config.DATE_FORMAT)}</div>
                    </div>
                )}
            </div>
        );
    }
}
