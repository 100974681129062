import React from 'react';
import { Form, Select } from 'antd';
import { loadViews } from './../../../store/views/api';

export class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            value: 'default',
        };
    }


    componentDidMount() {
        loadViews(this.props.type).then(data => {
            this.setState({
                list: data.data && data.data.list ? data.data.list : [],
                loading: false,
            });
        }).catch(e => {
            console.error(`Error load view: `, e);
        });
    }

    getDefaultSelect = () => {
        let name = `Default`;
        if (this.props && this.props.item && this.props.item.type === 'Trend') {
            name = `топ+вкладки`;
        }
        return <Select.Option value={"default"}>{name}</Select.Option>;
    }

    render() {
        return (
            <Form.Item label={this.props.label || ""}>
                {this.props.decorator(this.props.name, {
                    rules: this.props.rules || [],
                    initialValue: this.props.initialValue ? this.props.initialValue : this.state.value,
                })(
                    <Select {...this.props.props || {}}>
                        {this.getDefaultSelect()}
                        {this.state.list.map(i => {
                            return (
                                <Select.Option value={i.path} key={`path_${i.path}`}>
                                    {i.title}
                                </Select.Option>
                            )
                        })}
                    </Select>
                )}
            </Form.Item>
        );
    }
}

export default View;
