import React from 'react';
import { message, Spin } from 'antd';
import './scss/index.scss';
// import Config from '../../config';
import { CommentsRequest } from '../../core/fetch';


export class CommentsController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            limit: 100,
            list: [],
            search: '',
            platform: '',
        };
    }
    

    loadList = () => {
        this.setState({ loading: true }, () => {
            const { page, limit, search, platform } = this.state;
            CommentsRequest(`/messages/?page=${page}&limit=${limit}&search=${search}&type=${platform}`).then(res => {
                console.log(res);
                setTimeout(() => {
                    message.error(`Ошибка компановки списка комментариев`);
                }, 2500);
            }).catch(e => {
                console.error(`[Messages.list]: `, e)
                message.error(`Ошибка загрузки списка комментариев`);
                this.setState({loading: false});
            });
        });
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        return (
            <div className='comments stopwords'>
                <Spin spinning={true} tip={`Идет загрузка`}>
                </Spin>
            </div>
        );
    }
}

export default CommentsController;