import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Tooltip, Typography, Tag, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { t } from './../../library/i18n';
import Cover from './../cover';
import Config from './../../../config';


const CardStatus = (p) => {
    if (p.published) return <Tag color={"#87d068"}>{t('published')}</Tag>;
    if (p.prepublished) return <Tag color={"#2db7f5"}>{t('prepublished')}</Tag>;
    return <Tag color={"#F50"}>{t('unpublished')}</Tag>;
};

export const TrendCard = (p) => {
    return (
        <div className={`list--card card--default card--${p.type}`}>
            <div className="card--info">
                <div className="header">
                    <div className="header--status">
                        <div className="date">{Moment(p.publishedAt).format(Config.DATE_FORMAT)}</div>
                        <div className="status"><CardStatus {...p}/></div>
                    </div>
                </div>
                <div className="content">
                    <div className="cover"><Cover {...p}/></div>
                    <div className="info">
                        <div className={"title"}>{p.title || t('NOT_SET')}</div>
                        <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>
                            {p.summary || t('NOT_SET')}
                        </Typography.Paragraph>
                    </div>
                </div>
                {((p.createdBy && p.createdBy.id) || (p.updatedBy && p.updatedBy.id)) && (
                    <div className="footer">
                        <div className={"footer--row card--dates"}>
                            {p.createdBy && p.createdBy.id && (<div>{t('CREATED')} <Link to={`/users/${p.createdBy.id}`}>{p.createdBy.email}</Link> {t('AT')} {Moment(p.createdAt).format(Config.DATE_FORMAT)}</div>)}
                            {p.updatedBy && p.updatedBy.id && (<div>{t('UPDATED')} <Link to={`/users/${p.updatedBy.id}`}>{p.updatedBy.email}</Link> {t('AT')} {Moment(p.updatedAt).format(Config.DATE_FORMAT)}</div>)}
                        </div>
                    </div>
                )}
            </div>
            <div className="card--actions">
                <Tooltip title={t('EDIT')}><Button type={`primary`} icon={`edit`} onClick={() => p.onEdit(p.id)}/></Tooltip>
                <Tooltip title={t('DELETE')}>
                    <Popconfirm title={t('SURE_DELETE')} okText={t('DELETE')} cancelText={t('CLOSE')} onConfirm={() => p.onDelete(p.id)}>
                        <Button type={`danger`} icon={`delete`}/>
                    </Popconfirm>
                </Tooltip>
            </div>
        </div>
    );
};

TrendCard.defaultProps = {
    title: '',
    summary: '',
    media: [],
    crosslinks: [],
    tags: [],
    authors: [],
    category: {},
};


TrendCard.propTypes = {
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(Object).isRequired,
    crosslinks: PropTypes.arrayOf(Object).isRequired,
    tags: PropTypes.arrayOf(Object).isRequired,
    authors: PropTypes.arrayOf(Object).isRequired,
    category: PropTypes.instanceOf(Object).isRequired,
};

export default TrendCard;
