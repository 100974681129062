import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import { t } from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type' : 'text',
                    'placeholder': 'Trend title',
                },
                'rules': [
                    { required: true, message: t('TITLE_ERROR_FILL') },
                ]
            },
            {
                'component': 'media',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'editor',
                'label': t('TEXT'),
                'name': 'text',
                'props': {},
            },
            {
                'component': 'slug',
                'label': t('HREF'),
                'name': 'href',
                'source_field': 'title',
                'rules': [
                    {required: true, message: t('SLUG_FILL_ERROR')},
                ]
            },

            {
                'component': 'group',
                'label': t('SEO_AND_VIEW'),
                'fields': [
                    {
                        'component': 'view',
                        'label': t('VIEW_FIELD'),
                        'name': 'view',
                        'type': 'page',
                    },
                    {
                        'component': 'input',
                        'label': t('SEO_TITLE'),
                        'name': "seo.title",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'seo title',
                        },
                    },
                    {
                        'component': 'textarea',
                        'label': t('SEO_DESCRIPTION'),
                        'name': "seo.description",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'seo description',
                        },
                    },
                    {
                        'component': 'input',
                        'label': t('REDIRECT_TO'),
                        'name': "redirectTo",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'redirect url',
                        },
                    }
                ],
            }
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.slug = values.href;
        values.media = media;
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                />
            </div>
        );
    }
}

export default AddPage;
