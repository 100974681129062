import React from 'react';
import {Form, Button} from "antd";
import { ImageTab } from './../editor_plg/insert/tabs/image';
import "./mgallery.scss";
import EditModal from "../../media/editModal";
import { GetImagePath } from './../../../../config';

export class MGalleryList extends React.Component {
    constructor(props) {
        super(props);
        let images = this.props.item && this.props.item.media ? this.props.item.media : [];
        if (this.props.item.options && this.props.item.options.media_sort && this.props.item.options.media_sort.length > 0) {
          let data_images = {};
          images.forEach(i => {
              data_images[i.id] = i;
          });
          images = [];
          this.props.item.options.media_sort.forEach(i => {
              if (data_images[i]) {
                  images.push(data_images[i]);
              }
          });
        }
        this.state = {
            images,
            editModal: false,
            editMedia: {},
        };
    }

    onInsert = (media) => {
        let images = [...this.state.images];
        images.push(media)
        this.setState({
            images,
        }, () => {
            this.props.updateMedia.set(media);
        })
    }

    sortMediaUp = (mediaId = '') => {
        let media_ids = this.state.images.map(i => i.id);
        let index = media_ids.indexOf(mediaId);
        if (index >= 0) {
            let images = [...this.state.images];
            let currentMedia = this.state.images[index];
            let prevMedia = this.state.images[index - 1];
            images[index - 1] = currentMedia;
            images[index] = prevMedia;
            this.setState({ images }, () => {
                this.props.updateMedia.setMediaSort(this.state.images);
            });
        }
    }

    sortMediaDown = (mediaId = '') => {
        let media_ids = this.state.images.map(i => i.id);
        let index = media_ids.indexOf(mediaId);
        if (index >= 0) {
            let images = [...this.state.images];
            let currentMedia = this.state.images[index];
            let prevMedia = this.state.images[index + 1];
            images[index + 1] = currentMedia;
            images[index] = prevMedia;
            this.setState({ images }, () => {
                this.props.updateMedia.setMediaSort(this.state.images);
            });
        }
    }


    onDeleteMedia = (mediaId = '') => {
        let images = [...this.state.images];
        images = images.filter(i => i.id !== mediaId);
        this.setState({ images }, () => {
            this.props.updateMedia.delete(mediaId);
        })
    }

    getExcludedMedia = () => {
       return [];
    }

    onEdit = (editMedia) => {
        this.setState({ editMedia, editModal: true})
    }

    closeModalEdit = (media) => {
        let images = this.state.images.map(i => {
            if (media && media.id === i.id) {
                return media;
            }
            return i;
        });
        this.setState({
            images,
            editMedia: {},
            editModal: false,
        })
    }

    onMediaSave = (media) => {
        let images = [...this.state.images];
        images = images.map(i => {
            if (i.id === media.id) {
                return media;
            }
            return i;
        });
        this.setState({ images });
    }

    render() {
        return (
            <Form.Item label={this.props.label || ""}>
                {this.state.editModal && (<EditModal
                    visible={this.state.editModal}
                    onClose={this.closeModalEdit}
                    item={this.state.editMedia}
                    onMediaSave={this.onMediaSave}
                />)}
                <div className={`mgallery`}>
                    <RenderMediaList
                        list={this.state.images}
                        sortUp={this.sortMediaUp}
                        sortDown={this.sortMediaDown}
                        onDelete={this.onDeleteMedia}
                        onEdit={this.onEdit}
                    />
                    <div className={`mgallery__filter`}>
                        <ImageTab
                            onInsert={this.onInsert}
                            media={[]}
                            showList={true}
                            limit={10}
                        />
                    </div>
                </div>
            </Form.Item>
        );
    }
}


// const GetMediaPath = (files) => {
//     if (!files || !files['original'] || !files['original'].path) {
//         return '/images/nocover.png';
//     }
//     let path = files['original']['path'].split('/');
//     path[path.length - 1] = `320_${path[path.length - 1]}`;
//     return `https://cdni.vm.ru/${path.join('/')}`;
// }


const getImageText = (file = {}) => {
    let params = [];
    if (file.title) {
        params.push(file.title)
    }
    if(file.description) {
        params.push(file.description);
    }

    if(file.keywords) {
        params.push(file.keywords);
    }

    if (file.properties && Object.keys(file.properties).length > 0) {
        Object.keys(file.properties).map(i => {
            if (typeof file.properties[i] === "string") {
                params.push(file.properties[i]);
            }
            return i;
        });
    }
    return params.join(' / ');
};

const RenderMediaList = ( { list, sortUp, sortDown, onDelete, onEdit } ) => {
    const listL = list.length - 1;
    return (
        <div className="mgallery__list">
            {list.map((i, index) => {
                return (
                    <div className={`mgallery__item`}>
                        <div className={`item__sort`}>
                            {index > 0 && (<Button icon={`up`} onClick={() => sortUp(i.id)}/>)}
                            {index < listL && <Button icon={`down`}onClick={() => sortDown(i.id)} />}
                        </div>
                        <div className={`item__info`}>
                            <div className={`cover`} style={{backgroundImage: `url(${GetImagePath(i.files || {})})`}}/>
                            <div className={`item__info__text`}>
                                {getImageText(i)}
                            </div>
                        </div>
                        <div className={`item__actions`}>
                            <Button type={`primary`} icon={`edit`} onClick={() => {
                                onEdit(i);
                            }}/>
                            <Button type={`danger`} icon={`delete`} onClick={() => {
                                onDelete(i.id);
                            }}/>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
