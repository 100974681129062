import React from 'react';
import { Button, Tabs, Input } from 'antd';
import MediaUpload from './media/upload';
import MediaList from './media/list';
import { t } from './../../library/i18n';
import Config from './../../../config';
import EditModal from './../media/editModal';

const RenderMedia = (p) => {
    const { type } = p;

    if (type === "Video") {
        let video_path = p.files && p.files.original ? p.files.original.path : null;
        let poster = p.files && p.files.cover ? p.files.cover.path : null;
        return <video style={{maxWidth: '600px'}} controls src={`${Config.MEDIA.CDN.VIDEO}/${video_path}`} poster={`${Config.MEDIA.CDN.VIDEO}/${poster}`} />;
    }

    let path = p.files.original.path;
    if (p.files.thumbnail) path = p.files.thumbnail.path;
    if (p.files.article) path = p.files.article.path;
    if (p.files['image-l']) path = p.files['image-l'].path;
    if (Config.MEDIA.CDN.IMAGES.indexOf('cdnimages.') + 1 > 0 || Config.MEDIA.CDN.IMAGES.indexOf('resizer.') + 1 > 0) {
        let params = path.split('/');
        params[params.length - 1] = `720_${params[params.length - 1]}`;
        path = params.join('/');
    }
    return <img src={`${Config.MEDIA.CDN.IMAGES}/${path}`} alt={p.title || ""}/>;
};

export class Media extends React.Component {
    constructor(props) {
        super(props);
        let media = this.props && this.props.initialValue ? this.props.initialValue.filter(i => i.type === this.props.type) : [];
        this.state = {
            currentTab: '1',
            media: media && media.length > 0 ? media[0] : {},
            mediaSelected: media && media.length > 0,
            editModal: false,
        };
    }

    selectMedia = (media) => {
        if (media.list) {
            this.setState({ media: media.list[0], mediaSelected: true })
        } else {
            this.setState({ media, mediaSelected: true })
        }
    };

    tabChangeHandle = (tab) => {
        this.setState({ currentTab: tab });
    };

    componentDidMount() {
        if (this.props.initialValue) {
            let media = this.props.initialValue.filter(i => i.type === this.props.type);
            if (media && media.length > 0) {
                this.setState({
                    media: media[0],
                    mediaSelected: true,
                });
            }
        }
    }

    componentWillReceiveProps(p) {
        if (p && p.initialValue) {
            let m = {};
            p.initialValue.map(i => {
                m[i.id] = i;
                return i;
            });

            if (m[this.state.media.id] && JSON.stringify(m[this.state.media.id]) !== JSON.stringify(this.state.media)) {
                this.setState({ media: m[this.state.media.id]});
            }
        }
    }


    closeModalEdit = (item) => {
        this.setState({
            editModal: false,
        }, () => {
            if (item && item.id) {
                this.props.updateMedia.set(item);
            }
        })
    };


    canEdit = () => {
        if (this.props.item.stt_status !== ""){
            return false;
        }
        if (['Article', 'News', 'Meaning'].indexOf(this.props.item.type) + 1 === 0) {
            return true;
        }
        if (this.props.item.published === true && window.user.permissions.indexOf('ONLY_EDIT') + 1 > 0) {
            return false;
        }

        return true;
    }

    isVideo = () => {
        const { type } = this.props;
        return type === "Video";
    }

    render() {
        const props = this.props.props || {};
        const { overwrite, overwrite_to } = props;
        let { name } = this.props;
        if (overwrite_to) {
            name = overwrite_to
        }

        return (
            <React.Fragment>
                <div className={"media-input"}>

                    {this.state.editModal && (<EditModal
                        visible={this.state.editModal}
                        onClose={this.closeModalEdit}
                        item={this.state.media}
                    />)}


                    <div className="title">{this.props.label}</div>
                    {this.state.mediaSelected === false && (
                        <Tabs defaultActiveKey={this.state.currentTab} onChange={this.tabChangeHandle} tabPosition={"left"}>
                            <Tabs.TabPane key={`1`} tab={this.isVideo() ? t('UPLOAD_NEW_VIDEO') : t('UPLOAD_NEW')}>
                                <MediaUpload {...this.props} selectMedia={this.selectMedia}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane key={`2`} tab={this.isVideo() ? t('UPLOAD_SEL_VIDEO') : t('UPLOAD_SEL')}>
                                <MediaList {...this.props} selectMedia={this.selectMedia}/>
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                    {this.state.mediaSelected === true && (
                        <div className={"media--selected"}>
                            <div style={{display: 'none'}}>
                                {this.props.decorator(overwrite ? name : 'cover', {
                                    initialValue: this.state.media.id,
                                })(
                                    <Input />
                                )}
                            </div>
                            <div className={`media--preview`}>
                                <RenderMedia {...this.state.media}/>
                            </div>
                            {!props.disabled && (
                                <div className={"media--actions"}>
                                    {this.canEdit() && (<Button.Group>
                                        <Button type={"primary"} icon={"edit"} onClick={() => this.setState({editModal: true}) }>{t('MEDIA.EDIT')}</Button>
                                        <Button type={"danger"} icon={"delete"} onClick={() => {
                                            let a = this.state.media;
                                            this.setState({ media : {}, mediaSelected: false }, () => {
                                                if (this.props.updateMedia && this.props.updateMedia.delete) {
                                                    this.props.updateMedia.delete(a.id);
                                                }
                                            });
                                        }}>{t('MEDIA.DELETE')}</Button>
                                    </Button.Group>)}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default Media;
