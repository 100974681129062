import React from 'react';
import {Table, Button, message, Popconfirm} from 'antd';
import WrappedQuestsForm from "./form";
import { loadPollQuestions, createQuestion,deleteQuestion,updateQuestion,updateQuestionSort } from './../../../../store/documents/api';


export class Questions extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
            item: {},
            modal: false,
        };

        this.columns = [
            {
                title: 'Вопрос',
                dataIndex: 'title',
            },
            {
                title: 'Количество голосов',
                dataIndex: 'total_votes',
            },
            {
                title: 'Действия',
                key: 'actions',
                render: (t, r, index) => {
                    return (
                        <Button.Group>
                            <Button disabled={index === 0} icon={`arrow-up`} onClick={() => {
                                this.onUpdateSort(index, 'up')
                            }}/>
                            <Button icon={`arrow-down`} disabled={index >= this.state.list.length - 1} onClick={() => {
                                this.onUpdateSort(index, 'down')
                            }}/>
                            <Button type={"primary"} icon={`edit`} onClick={() => {
                                this.setState({ item: r, modal: true });
                            }}/>
                            <Popconfirm title="Вы уверены, что хотите удалить вопрос?" okText="Да" cancelText={"Нет"} onConfirm={() => this.deleteHandle(r.id)}>
                                <Button type={"danger"} icon={`delete`} />
                            </Popconfirm>
                        </Button.Group>
                    );
                },
            }
        ];
    }

    deleteHandle = (id) => {
        this.setState({ loading: true, item: {}, modal: false}, () => {
            deleteQuestion(id).then(data => {
                message.success(`Вопрос удален`);
                this.setState({ loading: false }, () => {
                    this.loadQuests();
                });
            }).catch(e => {
                message.error(`Ошибка удаления вопроса`);
                this.setState({ loading: false }, () => {
                    this.loadQuests();
                });
            });
        });
    };

    loadQuests = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                loadPollQuestions(this.props.item.id).then(data => {
                    this.setState({
                        loading: false,
                        list: data && data.data && Array.isArray(data.data) ? data.data : []
                    })
                }).catch(e => {
                    message.error(`Ошибка загрузки вопросов`);
                    console.error(`error load questions: `, e);
                    this.setState({ loading: false });
                });
            });
        }
    };

    componentDidMount() {
        if (this.props.item && this.props.item.id) {
            this.loadQuests();
        }
    }


    getNextSortId = () => {
        let max = 0;
        this.state.list.forEach(i => {
            if (i.sort > max) {
                max = i.sort;
            }
        });

        return max + 1;
    };

    onCreateHandle = (values) => {
        values['sort'] = this.getNextSortId();
        this.setState({ loading: true, item: {}, modal: false }, () => {
            createQuestion(this.props.item.id, values).then(data => {
                message.success(`Вопрос успешно создан`);
                this.setState({ loading: false }, () => {
                    this.loadQuests();
                });
            }).catch(e => {
                message.error(`Ошибка создания вопроса, повторите позже`);
                this.setState({ loading: false });
            });
        });

    };

    onUpdateHandle = (id = '', values = {}) => {
        this.setState({ loading: true, item: {}, modal: false }, () => {
            updateQuestion(id, values).then(data => {
                message.success(`Вопрос успешно обновлен`);
                this.setState({ loading: false }, () => {
                    this.loadQuests();
                });
            }).catch(e => {
                message.error(`Ошибка обновления вопроса: `, e);
                this.setState({ loading: false });
            });
        })
    };

    onUpdateSort = (index, sort) => {
        let new_position = sort === "up" ? index - 1  : index + 1;

        if (Array.isArray(this.state.list) && this.state.list.length > 0) {
            let list = [...this.state.list];
            let buffer = list[index];
            list[index] = list[new_position];
            list[new_position] = buffer;

            list = list.map((i, index) => {
                let a = {...i};
                a.sort = index;
                return a;
            });

            this.setState({ list, loading: true }, () => {
                let updatedData = list.map(i => {
                    return {id: i.id, sort: i.sort, title: i.title};
                });
                updateQuestionSort({ list: updatedData } ).then(data => {
                    this.setState({ loading: false }, () => {
                        this.loadQuests();
                    });
                }).catch(e => {
                    message.error(`Ошибка обновление сорировки`);
                    this.setState({ loading: false });
                });
            });
        }
    };

    render() {

        return (
            <div className={`poll_quests`}>
                <Table
                    title={() => {
                        return (
                            <React.Fragment>
                                {this.state.modal === false && <Button type={`primary`} onClick={() => this.setState({ modal: true, item: {}})}>Создать</Button>}
                                {this.state.modal === true && <Button type={`primary`} onClick={() => this.setState({ modal: false, item: {}})}>Закрыть</Button>}
                                {this.state.modal && (<div className="quest__form--wrapper">
                                    <div className={`form__wrapper`}>
                                        <WrappedQuestsForm
                                            onCreate={this.onCreateHandle}
                                            onUpdate={this.onUpdateHandle}
                                            item={this.state.item}
                                        />
                                    </div>
                                </div>)}
                            </React.Fragment>
                        )
                    }}
                    columns={this.columns}
                    loading={this.state.loading}

                    dataSource={this.state.list}
                    rowKey={(item) => item.id}

                    pagination={{
                        pageSize: this.state.list.length,
                        defaultCurrent: 1,
                        total: this.state.list.length,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        );
    }
}