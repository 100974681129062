import React from 'react';
import { Spin, Timeline, Empty, message, Typography, Modal, Tag, Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'moment';
import {bindActionCreators} from 'redux';
import { t } from './../../library/i18n';
import * as Actions from "./../../../store/documents/actions";
import * as C from "./../../../store/documents/constants";
import Config from './../../../config';
import * as Cmedia from './../../../controllers/Documents/preview/components'


const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const GetMediaPath = (files) => {
    let keys = Object.keys(files);
    let media = files[keys[0]];
    return `${Config.MEDIA.CDN.IMAGES}/${media.path}`
};


class History extends React.Component {

    static propTypes = {
        actions: PropTypes.instanceOf(Object).isRequired,
        answer: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEmpty: false,
            history: [],
            modal: false,
            item: {},
        };
    }

    componentDidMount() {
        if (this.props.item && this.props.item.id) {
            this.props.actions.GetHistory(this.props.item.id);
        }
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.DOCUMENT_HISTORY_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.DOCUMENT_HISTORY_LOAD_FINISH:
                    const data = p.answer.data || {};
                    this.setState({
                        history: data.list || [],
                        loading: false,
                        isEmpty: !data.list || data.list.length === 0,
                    });
                    break;

                case C.DOCUMENT_HISTORY_LOAD_ERROR:
                    message.error(t('ERROR_LOAD_HISTORY'));
                    this.setState({ isEmpty: true, loading: false });
                    break;
                default: break;
            }
        }
    }

    prepareText = (p) => {
        let mediaHashTable = {};
        const item = p || {};

        if (item.media && Array.isArray(item.media)) {
            item.media.map(i => {
                mediaHashTable[i.id] = i;
                return i;
            });
        }
        let text = p.text || "";
        let div = document.createElement('div');
        div.innerHTML = text;
        let rmediaList = div.getElementsByTagName('rmedia');
        for (let i = 0; i < rmediaList.length; i++) {
            if (mediaHashTable[rmediaList[i].id]) {
                let mediaItem = mediaHashTable[rmediaList[i].id];
                if (Cmedia[mediaItem.type]) {
                    text = text.replace(`<rmedia id="${rmediaList[i].id}">&nbsp;</rmedia>`, Cmedia[mediaItem.type](mediaItem));
                }
            }
        }
        return text;
    };

    getCover = (p) => {
        const item = p || {};
        if (!item.media) return null;
        // get cover
        let cover = item.media.filter(i => i.type === "Cover");
        if (cover.length === 0) return null;
        cover = cover[0];
        return <img src={GetMediaPath(cover.files)} alt={item.title}/>;
    };

    diffTextByLine = (current_text, preview_text) => {
        let result = [];
        current_text = current_text ? current_text.split("\n") : [""];
        preview_text = preview_text ? preview_text.split("\n") : [""];
        preview_text.map((t, index) => {
            if (t !== current_text[index]) {
                result.push(`<span class="changed">${t}</span>`);
            } else {
                result.push(t);
            }
            return t;
        });
        return result.join("\n");
    };

    renderDiffHtml = (current, history) => {
       let hItem = {...history};
       hItem.text = hItem.text || "";
       hItem.text = this.diffTextByLine(current.text, hItem.text);

       console.log(current, history);

      return (
          <div className={`container--diff`}>
              <div className="diff--column">
                  <div className="title">{t('CURRENT_VERSION')}</div>
                  <div className="text--title">{current.title || ""}</div>
                  <div className="options">
                      <div><span>{t('SLUG')}</span>: {current.slug || ""}</div>
                      <div><span>{t('published')}</span>: {current.published ? t('PUBLISHED') : t('NOT_PUBLISHED')}</div>
                      <div><span>{t('published_at')}</span>: {Moment(current.publishedAt).format(Config.DATE_FORMAT)}</div>
                      <div><span>{t('WORKFLOW')}</span>: {t(current.workflow)}</div>
                      <div><span>{t('PUBLISH_AT_RSS')}</span>: {current.options && current.options.rss ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_GOOGLE')}</span>: {current.options && current.options.google_rss ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_SMI2')}</span>: {current.options && current.options.smi2 ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_DZEN')}</span>: {current.options && current.options.dzen ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_PULSE')}</span>: {current.options && current.options.pulse ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_MIRROR')}</span>: {current.options && current.options.mirror ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_MAIN')}</span>: {current.options && current.options.main ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_NEWSLINE')}</span>: {current.options && current.options.newsline ? t("YES") : t("NO")}</div>
                      <div><span>{t('REDIRECT_TO')}</span>: {current.redirectTo ? `${current.redirectTo}` : ``}</div>
                  </div>

                  <div className="cover">{this.getCover(current)}</div>
                  <div className="content" dangerouslySetInnerHTML={{__html: current.text  ? this.prepareText(current) : ""}}/>
              </div>
              <div className="diff--column">
                  <div className="title">{t('PREVIEW_VERSION')}</div>
                  <div className="text--title">{history.title || ""}</div>
                  <div className="options">
                      <div><span>{t('SLUG')}</span>: {history.slug || ""}</div>
                      <div><span>{t('published')}</span>: {history.published ? t('PUBLISHED') : t('NOT_PUBLISHED')}</div>
                      <div><span>{t('published_at')}</span>: {Moment(history.publishedAt).format(Config.DATE_FORMAT)}</div>
                      <div><span>{t('WORKFLOW')}</span>: {t(history.workflow)}</div>
                      <div><span>{t('PUBLISH_AT_RSS')}</span>: {history.options && history.options.rss ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_GOOGLE')}</span>: {history.options && history.options.google_rss ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_SMI2')}</span>: {history.options && history.options.smi2 ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_DZEN')}</span>: {history.options && history.options.dzen ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_PULSE')}</span>: {history.options && history.options.pulse ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_MIRROR')}</span>: {history.options && history.options.mirror ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_MAIN')}</span>: {history.options && history.options.main ? t("YES") : t("NO")}</div>
                      <div><span>{t('PUBLISH_AT_NEWSLINE')}</span>: {history.options && history.options.newsline ? t("YES") : t("NO")}</div>
                      <div><span>{t('REDIRECT_TO')}</span>: {history.redirectTo ? `${history.redirectTo}` : ``}</div>
                  </div>
                  <div className="cover">{this.getCover(history)}</div>
                  <div className="content" dangerouslySetInnerHTML={{__html: this.prepareText(hItem)}} />
              </div>
          </div>
      );
    };

    render() {
        if (this.state.isEmpty) return <Empty description={t('NO_HISTORY')}/>;
        return (
            <Spin spinning={this.state.loading} tip={t('LOADING_HISTORY')}>
                {this.state.modal && (
                    <Modal
                        title={t('DOCUMENT_HISTORY_EVENT')}
                        visible={this.state.modal}
                        width={'80%'}
                        height={'80%'}
                        onCancel={() => this.setState({ modal: false, item: {} })}
                        footer={[
                            <Button key={"back"} onClick={() => this.setState({ modal: false, item: {} })}>{t('CLOSE')}</Button>,
                            <Popconfirm
                                title={t('YOU_ARE_USER_RECOVERY')}
                                onConfirm={() => {
                                    console.log(this.state.item);
                                    this.props.updateItem(this.state.item);
                                    this.setState({ modal: false, item: {} });
                                }}
                                onCancel={() => {
                                    this.setState({ modal: false, item: {} });
                                }}
                            >
                                <Button type={`primary`} icon={"reload"}>{t('RECOVER')}</Button>
                            </Popconfirm>
                        ]}
                    >
                        <div className="history__text-diff">
                            { this.renderDiffHtml(this.props.item, this.state.item) }
                        </div>
                    </Modal>
                )}
                <div className="history">
                    <Typography.Title level={4}>{t('HISTORY')}: </Typography.Title>
                    <div className="history--wrapper">
                        <Timeline>
                            {this.state.history.map((i, index) => {
                                return (
                                    <Timeline.Item key={`time_line_${i.id}`}>
                                        <Tag onClick={() => {
                                            this.setState({ modal: true, item: i });
                                        }} style={{cursor: 'pointer'}} color={"blue"}>[{index + 1}] {i.updatedBy.email} {t('AT')} {Moment(i.updatedAt).format(Config.DATE_FORMAT)}</Tag>
                                    </Timeline.Item>
                                )
                            })}
                        </Timeline>
                    </div>
                </div>
            </Spin>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchProps
)(History);

