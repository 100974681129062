import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Tooltip, Popconfirm, Pagination, Empty, Icon, Tag} from 'antd';
import Config from './../../../config';
// import { Link } from 'react-router-dom';
import * as List from './../../../core/components/list';

const RenderText = ({ text }) => {
    const data = JSON.parse(text);
    if (!data || data.length === 0) {
        return null;
    }
    return (
        <div className="clist">
            <div className="clabel">Контакты: </div>
            <div className="cvalues">
                {data.map((i, index) => {
                    if (i.type === 'email') {
                        return (
                            <Tag key={`index_${index}`}><a href={`mailto: ${i.value}`}>{i.value}{i.comment ? `(${i.comment})`: ''}</a></Tag>
                        );
                    }

                    if (i.type === 'phone') {
                        return (
                            <Tag key={`index_${index}`}><a href={`tel: ${i.value}`}>{i.value}{i.comment ? `(${i.comment})`: ''}</a></Tag>
                        );
                    }

                    if (i.type === 'other') {
                        return (
                            <Tag key={`index_${index}`}>{i.value}{i.comment ? `(${i.comment})`: ''}</Tag>
                        );
                    }

                    if (['vk', 'facebook', 'instagram', 'telegram'].indexOf(i.type) === 1) {
                        return (
                            <Tag key={`index_${index}`}><a href={i.value}>{i.value}{i.comment ? `(${i.comment})`: ''}</a></Tag>
                        );
                    }

                    return (
                        <Tag key={`index_${index}`}>{i.value}{i.comment ? `(${i.comment})`: ''}</Tag>
                    );
                })}
            </div>
        </div>
    );
}

const ContactView = (props) => {
    const GetCover = () => {
        if (!props.media || !Array.isArray(props.media)) {
            return null;
        }

        let media = props.media.filter(i => i.type === 'Cover');
        if (!media || media.length === 0) {
            return null;
        }
        return `${Config.MEDIA.CDN.IMAGES}/${media[0].files.original.path}`;
    }
    const { title, subtitle, summary, text, tags, comment } = props;
    const cover = GetCover();
	return (
		<div className="contact--item">
            {cover && (
                <div className="item--column">
				    <div className="photo" style={{backgroundImage: `url(${cover})`}}/>
			    </div>
            )}
			
			<div className="item--column item__main--info">
                <div className="item__wrapper">
				    <div className="title">{title}</div>
				    {subtitle && (<div className="lead">{subtitle}</div>)}
                    {comment && (<div className="comment">{comment}</div>)}
				    {summary && (<div className="info"><Icon type="compass" /> {summary}</div>)}
                </div>
                {text && <RenderText text={text}/>}
                {(tags && tags.length > 0) && (<div className="tags">{tags.map(i => <Tag key={i.id}>{i.title}</Tag>)}</div>)}
			</div>
            <div className={`item--column item__actions`}>
                <Tooltip title={`Редактировать`} placement="left"><Button type="primary" onClick={() => props.onEdit(props.id)} icon="edit"/></Tooltip>
				<Popconfirm placement="left" title={`Вы уверены, что хотите удалить контакт?`} okText={`Да`} cancelText={`Нет`} onConfirm={() => props.onDelete(props.id)}>
                    <Tooltip title={`Удалить`} placement="left"><Button type="danger" icon="delete"/></Tooltip>
                </Popconfirm>
            </div>
		</div>
	)
}

export class ContactsView extends React.Component {
    static defaultProps = {
        items: [],
        limit: 0,
        total: 0,
        page: 0,
        filter: {},
        updatePageHandle: (page) => {},
        onFilter: (values) => {},
        onCreate: () => {},
        onDelete: () => {},
        onView: () => {},
        onEdit: () => {},
    };

    static propTypes = {
        items: PropTypes.arrayOf(Object).isRequired,
        filter: PropTypes.instanceOf(Object).isRequired,
        total: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        updatePageHandle: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onView: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    getListComponent = () => {
        let cName = `${this.props.type}_list`;
        if (List[cName]) {
            return List[cName];
        }
        return List['default_list'];
    };


    render() {
        
		return (
			<div className={`contacts__view`}>
				<div className={`contacts--filter`}>
                    <Button icon={`plus`} type={`primary`} onClick={() => this.props.onCreate()}/>
                    <Input.Search
                        placeholder={`Поиск...`}
                        enterButton="Искать"
                        onSearch={(search) => {
                            this.props.onFilter({search, page: 1});
                        }}
                    />
				</div>
                <Pagination 
                     onChange={this.props.updatePageHandle}
                     total={this.props.total}
                     pageSize={this.props.limit}
                     defaultCurrent={this.props.page}
                     current={this.props.page}
                     hideOnSinglePage={true}
                     showTotal={false}
                />
                {this.props.items.length === 0 && (<Empty title={`Контакты не найдены`}/>)}
				<div class={`contacts--list`}>
					{this.props.items.map(i => {
						return <ContactView onEdit={this.props.onEdit} key={`item_${i.id}`} {...i} {...this.props}/>;
					})}
				</div>
			</div>
		);
    }
}

export default ContactsView;
