import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tag } from 'antd';
import { t } from './../../library/i18n';


const IconAliases = {
    'Image': 'picture',
    'Cover': 'picture',
    'Video': 'video-camera',
    'Code': 'code',
    'ExternalVideo': 'youtube'
};

export const MediaProp = (p) => {
    let list = {};
    p.list.map(i => {
        if (!list[i.type]) list[i.type] = 0;
        list[i.type] += 1;
        return i;
    });

    let keys = Object.keys(list);

    return (
        <div className={`card--prop`}>
            {keys.map(i => {
                return (
                    <Tag key={`media_${i}`}>
                        {IconAliases[i] ? <Icon type={IconAliases[i]}/> : null}{t(i)}: {list[i]}
                    </Tag>
                );
            })}
        </div>
    );
};

MediaProp.defaultProps = {
    list: [],
};


MediaProp.propType = {
    list: PropTypes.arrayOf(Object).isRequired,
};

export default MediaProp;
