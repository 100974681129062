import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Select, Spin} from "antd";
import { t } from './../../core/library/i18n';
import Config from "../../config";

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};

class UploadMediaForm extends React.Component{

    static defaultProps = {loading: false};

    state = {submit: false};

    submitHandle = (e) => {
        e.preventDefault();
        this.setState({submit: true}, () => this.props.onUpload(this.props.file.uid));
    };

    render(){
        const formItemLayout = {
            labelCol: {
                xs: { span: 5 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 19 },
                sm: { span: 19 },
            },
        };
        const { getFieldDecorator } = this.props.form;
        return(
            <div style={{border: '1px solid #dee1e5', borderRadius: 4, padding: 20, marginTop: 10}}>
                <Spin tip={t(`LOADING`)} spinning={this.props.loading}>
                    <Form  onSubmit={this.submitHandle} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '50%'}}>
                            <Form.Item>
                                {getFieldDecorator('files', {
                                    initialValue: this.props.file || ''})(
                                    <div style={{width: '100%', height: '100%'}}>
                                        {this.props.file.type.split('/')[0] === 'image' && <img width={'50%'} height={'100%'} style={{maxHeight: 340}} alt={`${this.props.file.name}`} src={URL.createObjectURL(this.props.file)}/>}
                                        {this.props.file.type.split('/')[0] === 'video' && <video controls width={'50%'} height={'100%'} style={{maxHeight: 340}} src={URL.createObjectURL(this.props.file)}/>}
                                    </div>
                                )}
                            </Form.Item>
                        </div>
                        <div style={{width: '50%'}}>
                            <Form.Item>
                                {getFieldDecorator('type', {
                                    initialValue: capitalize(this.props.file.type.split('/')[0]) || ''
                                })(
                                    <React.Fragment/>
                                )}
                            </Form.Item>
                            <Form.Item label={t('TITLE')} {...formItemLayout}>
                                {getFieldDecorator('title', {
                                    initialValue: this.props.file.name || '',
                                    rules: [],
                                })(
                                    <Input.TextArea placeholder={t('TITLE_PLACEHOLDER')} />
                                )}
                            </Form.Item>
                            <Form.Item label={t('MEDIA_DESCRIPTION')} {...formItemLayout}>
                                {getFieldDecorator('description', {
                                    initialValue: '',
                                    rules: [],
                                })(
                                    <Input.TextArea placeholder={t('MEDIA_DESCRIPTION')}/>
                                )}
                            </Form.Item>
                            <Form.Item label={t('MEDIA_KEYWORDS')} {...formItemLayout}>
                                {getFieldDecorator('keywords', {
                                    initialValue: '',
                                    rules: [],
                                })(
                                    <Input.TextArea placeholder={t('MEDIA_KEYWORDS')}/>
                                )}
                            </Form.Item>
                            <Form.Item label={t('SOURCE')} {...formItemLayout}>
                                {getFieldDecorator('properties.source', {
                                    initialValue: this.props.file['properties.source'] || '',
                                    rules: [],
                                })(
                                    <Select placeholder="Select media source">
                                        {(Config.MEDIA.SOURCES && Config.MEDIA.SOURCES.length > 0) && Config.MEDIA.SOURCES.map(i => {
                                            return <Select.Option value={i} key={`media_source_${i}`}>{i}</Select.Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={t(`Copyright`)} {...formItemLayout}>
                                {getFieldDecorator('properties.copyright', {
                                    rules: [],
                                })(
                                    <Input placeholder={t(`Copyright_placeholder`)}/>
                                )}
                            </Form.Item>
                            <Form.Item label={t(`Copyright_url`)} {...formItemLayout}>
                                {getFieldDecorator('properties.copyrightUrl', {
                                    rules: [],
                                })(
                                    <Input placeholder={t(`Copyright_url_placeholder`)}/>
                                )}
                            </Form.Item>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button type={'primary'} htmlType={'submit'}>{t('ADD')}</Button>
                                <Button type={'danger'} style={{margin: '0 10px'}}
                                        onClick={() => this.props.onRemove(this.props.file)}>{t('REMOVE')}</Button>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        )
    };
}

UploadMediaForm.defaultProps = {
    file: {},
    onRemove: () => {},
    onUpload: () => {}
};

UploadMediaForm.propTypes = {
    file: PropTypes.instanceOf(Object).isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired
};

export default Form.create({ name: `UploadMediaForm` })(UploadMediaForm);
