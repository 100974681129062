import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Spin, Empty, message, Button } from 'antd';
import * as Actions from "./../../store/widgets/actions";
import * as C from "./../../store/widgets/constants";
import {AppConsumer} from './../../context/app';
import {t} from "../../core/library/i18n";
import DocsWidget from './../../components/list/docs_widget';
import { arrayMoveImmutable } from 'array-move';



const mapStateToProps = state => ({
    answer: state.WidgetReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class RenderWidgetHeader extends React.Component {
    static defaultProps = {
        context: {},
        item: {}
    };

    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
        item: PropTypes.instanceOf(Object).isRequired,
    };

    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: this.props.item && this.props.title ? `${t('WIDGETS_EDIT')}: ${this.props.item.title}` : t('WIDGETS_EDIT') ,
            showBack: true,
        })
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.title && p.item.title !== this.props.item.title) {
            p.context.actions.updateHeader({
                title: p.item && p.item.title ? `${t('WIDGETS_EDIT')}: ${p.item.title}` : t('WIDGETS_EDIT'),
                showBack: true,
            })
        }
    }

    render = () => null;
}

class EditWidgetController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingDocs: false,
            id: this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : false,
            item: {},

            total: 0,
            page: 1,
            limit: 0,
            list: [],
            search: '',
        };
    }

    onSaveWidget = () => {
        if (Array.isArray(this.state.item.data)) {
            let ids = this.state.item.data.map(i => i.id);
            this.props.actions.UpdateWidget(this.state.id, { data: ids });
        }
    };

    onRemove = (id) => {
        let stateItem = {...this.state.item};
        if(Array.isArray(stateItem.data)) {
            stateItem.data = stateItem.data.filter(i => i.id !== id);
            this.setState({ item: stateItem }, () => {
                this.onSaveWidget();
                this.loadDocs();
            })
        }
    };

    onUpdateSort = (index, sort) => {
        let new_position = sort === "up" ? index - 1  : index + 1;
        let stateItem = {...this.state.item};
        if (Array.isArray(stateItem.data) && stateItem.data.length > 0) {
            let buffer = stateItem.data[index];
            stateItem.data[index] = stateItem.data[new_position];
            stateItem.data[new_position] = buffer;
            this.setState({ item: stateItem }, () => {
                this.onSaveWidget();
            });
        }
    };

    onSortHandle = ({oldIndex, newIndex}) => {
        let stateItem = {...this.state.item};
        let items = [...stateItem.data];
        stateItem.data = arrayMoveImmutable(items, oldIndex, newIndex)
        this.setState({ item: stateItem }, () => {
            this.onSaveWidget()
        })
    }

    onAddItem = (item) => {
        let stateItem = {...this.state.item};
        stateItem.data = !stateItem.data || !Array.isArray(stateItem.data) ? [] : stateItem.data;
        if (stateItem.max_size === 0 || stateItem.max_size >= (stateItem.data.length + 1)) {
            stateItem.data = [item, ...stateItem.data];
            this.setState({item: stateItem}, () => {
                this.onSaveWidget();
                this.loadDocs();
            })
        } else {
            message.info(t('MAXIMUM_ELEMENT_IN_WIDGET'));
        }
    };

    onSearch = (search) => {
        this.setState({ search, page : 1 }, () => {
            this.loadDocs();
        });
    };

    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadDocs();
        });
    };

    loadDocs = () => {
        let types = this.state.item.data_type && Array.isArray(this.state.item.data_type) && this.state.item.data_type.length > 0 ? this.state.item.data_type : [];
        let exclude_ids = [];
        if (this.state.item.data && Array.isArray(this.state.item.data) && this.state.item.data.length > 0) {
            exclude_ids = this.state.item.data.map(i => i.id);
        }
        let cats = [];
        if (this.state.item.category && Array.isArray(this.state.item.category)) {
            cats = this.state.item.category.map(i => i.id);
        }
        this.props.actions.GetWidgetDocs(this.state.page, this.state.search, exclude_ids, types, cats)
    };

    componentDidMount() {
        if (this.state.id !== false) {
            this.props.actions.GetWidget(this.state.id);
        }
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.WIDGET_UPDATE_START:
                case C.DOCUMENT_UPDATE_START:
                    this.setState({ loading: true });
                    break;
                case C.WIDGET_UPDATE_ERROR:
                case C.DOCUMENT_UPDATE_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(t(`ERROR_UPDATE_WIDGET`));
                    });
                    break;
                case C.DOCUMENT_UPDATE_FINISH:
                    this.props.actions.GetWidget(this.state.id);
                    this.onSaveWidget();
                    break;
                case C.WIDGET_UPDATE_FINISH:
                    this.setState({ loading: false }, () => {
                        message.success(t(`WIDGET_UPDATED`));
                    });
                    break;
                case C.WIDGET_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.WIDGET_LOAD_ERROR:
                    message.error(t(`ERROR_LOAD_WIDGET`));
                    this.setState({ loading: false, id: false });
                    break;
                case C.WIDGET_DOCS_LOAD_ERROR:
                    message.error(t(`ERROR_LOAD_WIDGET_DOCS`));
                    this.setState({ loading: false,loadingDocs: false });
                    break;
                case C.WIDGET_DOCS_LOAD_START:
                    this.setState({ loadingDocs: true });
                    break;
                case C.WIDGET_DOCS_LOAD_FINISH:
                    let dataItems = p.answer.data ;
                    this.setState({
                        loadingDocs: false,
                        loading: false,
                        total: dataItems.total || 0,
                        limit: dataItems.limit || 0,
                        list: dataItems.list || [],
                    });
                    break;
                case C.WIDGET_LOAD_FINISH:
                    const data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    this.setState({
                        item: data,
                    }, () => {
                        this.loadDocs();
                    });
                    break;
                default: break;
            }
        }
    }

    updateItem = (id, options) => {
        this.props.actions.UpdateDocument(id, { options });
    };


    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <RenderWidgetHeader context={context} item={this.state.item}/>
                            {this.state.id === false && <Empty description={t('WIDGET_NOT_FOUND')}/>}
                            {this.state.id !== false && (
                                <Spin spinning={this.state.loading}>
                                    <div className={`app_view_table widget_view_edit`}>
                                        <div className={`widget_view_edit-actions`}>
                                            <Button type={`primary`} onClick={this.onSaveWidget}>{t('SAVE')}</Button>
                                        </div>
                                        <DocsWidget
                                            selected={this.state.item && this.state.item.data ? this.state.item.data : []}
                                            list={this.state.list}
                                            docs_limit={this.state.limit}
                                            docs_total={this.state.total}
                                            docs_page={this.state.page}
                                            docs_search={this.state.search}
                                            onSearch={this.onSearch}
                                            onPageChange={this.onPageChange}
                                            onAddItem={this.onAddItem}
                                            loadingDocs={this.state.loadingDocs}
                                            onRemove={this.onRemove}
                                            onUpdateSort={this.onUpdateSort}
                                            onUpdateItem={this.updateItem}
                                            onSortHandle={this.onSortHandle}
                                            widgetId={this.state.id}
                                        />
                                    </div>
                                </Spin>
                            )}
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        )
    }
}

export const EditWidget = connect(
    mapStateToProps,
    mapDispatchProps
)(EditWidgetController);

export default EditWidget;
