import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageHeader, Button, Divider, Spin, message } from 'antd';
import * as Actions from "./../../store/media/actions";
import * as C from './../../store/media/constants';
import {generateParamsFromObject, parseUrlParams, prepareParams} from "./../../core/library/url";
import MediaList from './../../components/media/list';
import MediaUpload from './upload';
import MediaSearch from './search';
import { t } from './../../core/library/i18n';
import {AppConsumer} from './../../context/app';


const mapStateToProps = state => ({
    answer: state.MediaReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class RenderMediaHeader extends React.Component {
    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('MEDIA_LIST'),
        })
    }

    render() {
        return null;
    }
}

class MediaIndexController extends React.Component {
    constructor(props) {
        super(props);
        let url = parseUrlParams();
        this.state = {
            isLoading: false,
            mediaList: [],
            total: 0,
            limit: 60,
            loading: false,
            filter: prepareParams(['eq', 'sort', 'type', 'source', 'from', 'to']),
            page: url.page ? parseInt(url.page, 10) : 1,
            showSearch: false,
            showUpload: true
        };
    }

    componentDidMount() {
        this.loadMedia();
    }

    loadMedia = () => {
        this.props.actions.GetMediaList(this.state.page, this.state.limit, this.state.filter)
    };

    reloadMedia = () => {
        this.setState({ isLoading: true}, () => {
            setTimeout(() => {
                this.loadMedia();
            }, 500);
        })
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {

                case C.MEDIA_START_LOAD :
                    this.setState({
                        isLoading: true
                    });
                    break;

                case C.MEDIA_FINISH_LOAD:
                    this.setState({
                        isLoading: false,
                        mediaList: p.answer.data.list || [],
                        total: p.answer.data.total || 0
                    });
                    break;

                case C.MEDIA_UPDATE_START:
                    this.setState({ isLoading: true });
                    break;
                case C.MEDIA_UPDATE_ERROR:
                    message.error(t("ERROR_UPDATE_MEDIA"));
                    break;

                case C.MEDIA_UPDATE_FINISH:
                    message.success(t(`MEDIA_HAS_BEEN_UPDATED`));
                    setTimeout(() => {
                        this.loadMedia();
                    }, 500);
                    break;
                default: break;
            }
        }
    }

    paginator = (page, limit, filter=this.state.filter) => {
        this.setState({
            page: page,
            filter: filter,
            limit: limit
        }, () => {
            this.props.history.push(`${window.location.pathname}?${generateParamsFromObject({...this.state.filter, ...{page: this.state.page}})}`);
            this.props.actions.GetMediaList(this.state.page, this.state.limit, this.state.filter)})
    };

    btns = () => {
        return([
            <Button key='1' type={'primary'} onClick={this.toggleSearch}>{t('SEARCH')}</Button>,
            <Button key='2' type={'default'} onClick={this.toggleUpload}>{this.state.showUpload ? t('CLOSE') : t('UPLOAD')}</Button>
        ])
    };

    toggleSearch = () => {
        let search = this.state.showSearch;
        this.setState({
            showSearch: !search,
            showUpload: false,
        })
    };

    toggleUpload = () => {
        let upload = this.state.showUpload;
        this.setState({
            showUpload: !upload,
            showSearch: false,
        })
    };


    hideMedia = (id = '') => {
        this.props.actions.UpdateMedia(id, {virtual: true});
    };

    render() {
        return (
            <React.Fragment>
                <AppConsumer>
                    {(context) => {
                       return (
                           <React.Fragment>
                               <RenderMediaHeader context={context}/>
                               <PageHeader title={t('MEDIA')} extra={this.btns()}>
                                   {this.state.showSearch && <MediaSearch filter={this.state.filter} paginator={this.paginator}/>}
                                   {this.state.showUpload && <MediaUpload onFinish={this.reloadMedia}/>}
                               </PageHeader>
                               <Divider type={"horizontal"} dashed={true} style={{marginTop: 0}}/>
                               <Spin spinning={this.state.isLoading}>
                                   <MediaList
                                       mediaList={this.state.mediaList}
                                       totalItems={this.state.total}
                                       itemsLimit={this.state.limit}
                                       currentPage={this.state.page}
                                       paginator={this.paginator}
                                       reloadMedia={this.reloadMedia}
                                       hideMedia={this.hideMedia}
                                   />
                               </Spin>
                           </React.Fragment>
                       );
                    }}
                </AppConsumer>
            </React.Fragment>);
    }
}

export const MediaIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(MediaIndexController);

export default MediaIndex;
