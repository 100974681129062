import React from 'react';
import PropTypes from 'prop-types';
import { t } from './../library/i18n';

export class ControllerLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const actions = (this.props.options && this.props.options.actions) || {};
        if (actions && actions.updateHeader) {
            if (this.props.isEdit) {
                actions.updateHeader({
                    title: `${t(`${this.props.type.toUpperCase()}_${this.props.layout_type}`)}: ${this.props.item.title}`,
                    showBack: this.props.showBack,
                });
            } else {
                actions.updateHeader({
                    title: t(`${this.props.type.toUpperCase()}_${this.props.layout_type}`),
                    showBack: this.props.showBack,
                });
            }

        }
    }

    componentWillReceiveProps(p) {
        if (p.type !== this.props.type || p.layout_type !== this.props.layout_type) {
            const actions = (this.props.options && this.props.options.actions) || {};
            if (actions && actions.updateHeader) {
                if (p.isEdit) {
                    actions.updateHeader({
                        title: `${t(`${p.type.toUpperCase()}_${p.layout_type}`)}: ${p.item.title}`,
                        showBack: p.showBack,
                    });
                } else {
                    actions.updateHeader({
                        title: t(`${p.type.toUpperCase()}_${p.layout_type}`),
                        showBack: p.showBack,
                    });
                }
            }
        }
    }

    render() {
        const RenderLayout = this.props.layout;
        return <RenderLayout {...this.props} />;
    }
}

ControllerLayout.defaultProps = {
    options: null,
    layout: null,
    layout_type: 'LIST',
    showBack: false,
    updateMedia: () => {},
};

ControllerLayout.propTypes = {
    options: PropTypes.instanceOf(Object).isRequired,
    layout: PropTypes.instanceOf(Object).isRequired,
    layout_type: PropTypes.string.isRequired,
    showBack: PropTypes.bool.isRequired,
    updateMedia: PropTypes.instanceOf(Object).isRequired,
};

export default ControllerLayout;
