import React from 'react';
import { Select, Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { t } from './../../library/i18n';
import {documentsElLoad } from './../../../store/documents/api';

export default class DocumentsComplete extends React.Component {

    static defaultProps = {
        limit: 100,
        type: 'All',
        props: {},
        initial: "",
        onChange: (v) => {},
    };

    static propTypes = {
        initial: PropTypes.any,
        limit: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        props: PropTypes.instanceOf(Object),
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            search: '',
            loading: false,
            initial: [],
        };
        this.loadDocuments = debounce(this.loadDocuments, 200);
    }

    loadInitialValues = () => {
        let filter = {published: true};
        if (this.props.initial && this.props.initial.length > 0 && filter.search === undefined){
            if (Array.isArray(this.props.initial)) {
                filter.includeIds = this.props.initial;
            } else {
                if (this.props.initial !== 'no initial') {
                    filter.includeIds = [this.props.initial];
                }
            }
        }

        if (this.props.filter) {
            //@TODO: add dynamic filter
            if (this.props.filter.trend) {
                filter.trend = this.props.filter.trend;
            }
        }

        if (Array.isArray(filter.includeIds) && filter.includeIds.length > 0) {
            documentsElLoad(this.props.type, 1, filter, '-publishedAt', this.props.limit).then(data => {
                let e = [];
                let list = [];
                if (data.list) {
                    list = data.list.filter(i => {
                        let result = e.indexOf(i.id) + 1 === 0;
                        e.push(i.id);
                        return result;
                    });
                }
                this.setState({
                    initial: list || [],
                    loading: false,
                }, () => {
                    this.loadDocuments();
                });
            }).catch(e => {
                console.error(`Error load complete list [${this.props.type}]: `, e);
                this.loadDocuments();
            });
        } else {
            this.loadDocuments();
        }
    };


    loadDocuments = value => {
        this.setState({
            list: [],
            loading: true,
        }, () => {

            let filter = { search: value, published: true };
            if (this.state.initial && this.state.initial.length > 0) {
                filter.excludeIds = this.state.initial.map(i => {
                    return i.id
                });
            }

            if (this.props.filter) {
                //@TODO: add dynamic filter
                if (this.props.filter.trend) {
                    filter.trend = this.props.filter.trend;
                }
            }


            // if (this.props.initial && this.props.initial.length > 0 && filter.search === undefined){
            //     if (Array.isArray(this.props.initial)) {
            //         filter.includeIds = this.props.initial;
            //     } else {
            //         if (this.props.initial !== 'no initial') {
            //             filter.includeIds = [this.props.initial];
            //         }
            //     }
            // }
            documentsElLoad(this.props.type, 1, filter, '-publishedAt', this.props.limit, this.props.type === 'Tag' || this.props.type === 'Author').then(data => {
                let e = [];
                let list = [];
                if (data.list) {
                    list = data.list.filter(i => {
                        let result = e.indexOf(i.id) + 1 === 0;
                        e.push(i.id);
                        return result;
                    });
                }
                this.setState({
                    list: list ? [...this.state.initial, ...list] : [],
                    loading: false,
                });
            }).catch(e => {
                console.error(`Error load complete list [${this.props.type}]: `, e);
            });
        });

    };

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.loadInitialValues();
        }, 700);
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        let p = {};
        if (this.props.initial && this.props.initial !== 'no initial') {
            p.defaultValue = this.props.initial;
        }
        return (
            <React.Fragment>
                <Select
                    {...this.props.props}
                    notFoundContent={this.state.loading ? <Spin /> : <Empty description={t('EMPTY_DOCUMENTS')}/>}
                    onSearch={this.loadDocuments}
                    onChange={this.props.onChange}
                    filterOption={this.props && this.props.props.filterOption ? this.props.props.filterOption : false}
                    {...p}
                >
                    {this.state.list.map(i => {
                        return !i.title ? null : <Select.Option key={`doc_${i.id}`} value={i.id}>{this.prepareName(i)}</Select.Option>
                    })}
                </Select>
            </React.Fragment>
        );
    }

    prepareName = (i) => {
        if (i.type === 'Author') {
            if (!i.summary) return i.title;
            return `${i.title}, ${i.summary}`;
        }
        return i.title;
    }
}
