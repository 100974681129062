import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class AddCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': 'Category name',
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'textarea',
                'label': t('SUMMARY'),
                'name': 'summary',
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('SUMMARY_PLACEHOLDER'),
                    'autosize': {
                        minRows: 6,
                    },
                }
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'props': {
                    'placeholder': t('SLUG_PLACEHOLDER')
                },
                'rules': [
                    {required: true, message: t('SLUG_FILL_ERROR')},
                ]
            },
            {
                'component': 'autocomplete',
                'type': 'Trend',
                'limit': 15,
                'label': t('TRENDS'),
                'name': 'trends',
                'props': {
                    placeholder: t('SELECT_TRENDS'),
                    allowClear: true,
                    showSearch: true,
                    mode: 'multiple',
                },
            },
            {
                'component': 'group',
                'label': t('SEO_AND_VIEW'),
                'fields': [
                    {
                        'component': 'view',
                        'label': t('VIEW_FIELD'),
                        'name': 'view',
                        'type': 'category',
                    },
                    {
                        'component': 'input',
                        'label': t('SEO_TITLE'),
                        'name': "seo.metaTitle",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'seo title',
                        },
                    },
                    {
                        'component': 'textarea',
                        'label': t('SEO_DESCRIPTION'),
                        'name': "seo.metaDescription",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'seo description',
                        },
                    },
                    {
                        'component': 'input',
                        'label': t('REDIRECT_TO'),
                        'name': "redirectTo",
                        'props': {
                            'type' : 'text',
                            'placeholder': 'redirect url',
                        },
                    }
                ],
            }
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                />
            </div>
        );
    }
}

export default AddCategory;
