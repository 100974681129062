export const P = (permission = '') => {
    window.user = window.user || {is_admin: false, permissions: []};
    if (!window.user) return false;
    if (window.user.is_admin) return true;
    return window.user.permissions.indexOf(permission.toUpperCase()) + 1 > 0;
};

export const PU = (permission = '') => {
    window.user = window.user || {is_admin: false, permissions: []};
    if (!window.user) return false;
    // if (window.user.is_admin) return true;
    return window.user.permissions.indexOf(permission.toUpperCase()) + 1 > 0;
};


export default P;
