/* eslint-disable */
function addslashes( str ) {
    return str.split('"').join("'")
}
export default function (p) {
    return (
        `<div data-title="${addslashes(p.title)}" data-link="${p.publicId}" class="rmedia rmedia--image readmore mceNonEditable">
            <div class="button danger deleteMedia mceNonEditable"></div>
        </div>
       `
    );
};


