import React from 'react';
import {Form, Input, Button, Checkbox} from 'antd';
import {t} from './../../core/library/i18n';

class WidgetItemForm extends React.Component {

    static defaultProps = {
        title: '',
        onSubmit: (title) => {},
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err) {
                console.log(values);
                this.props.onSubmit(values.title, values.options);
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        console.log(this.props);
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={t('DISPLAY_NAME')}>
                    {getFieldDecorator('title',{
                        initialValue: this.props.title || "",
                    })(
                        <Input />,
                    )}
                </Form.Item>
                <Form.Item label={t('BOLD_TEXT')}>
                    {getFieldDecorator('options.bold_text',{
                        valuePropName: 'checked',
                        initialValue: this.props.options && this.props.options.bold_text === true ? true : false,
                    })(
                        <Checkbox />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button htmlType={`submit`} type={`primary`}>{t('SAVE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

    const WrappedWidgetItemForm = Form.create({name: 'WrappedWidgetItemForm'})(WidgetItemForm);

    export default WrappedWidgetItemForm;
