import React from 'react';
import PropTypes from 'prop-types';
import {t} from "../../../core/library/i18n";
import {Button, Switch, Table} from "antd";
import Moment from 'moment';
import 'moment/locale/ru';
import Config from './../../../config';
import AuthorsFilter from "../../../components/filters/authors";

export class PollController extends React.Component {
    static defaultProps = {
        options: {
            actions: {
                updateHeader: () => {},
            }
        }
    };

    static propTypes = {
        options: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: t('TITLE'),
                dataIndex: 'title',
            },
            {
                title: t('TOTAL_VOTES'),
                key: 'totalVotes',
                render: r => r.totalVotes || 0,
            },
            {
                title: t('POLL_FINISH'),
                key: 'poll_finish',
                render: r => r.options && r.options.poll_finish ? `да` : `нет`,
            },
            {
                title: t('CREATED_AT'),
                key: 'created_at',
                render: r => {
                    return Moment(r.createdAt).format(Config.DATE_FORMAT);
                }
            },
            {
                title: t('PUBLISHED'),
                key: 'published',
                align: 'center',
                render: row => {
                    return <Switch size="small" checked={row.published} onChange={(published) => {
                        this.props.onUpdate(row.id, { published });
                    }} />;
                },
            },
            {
                title: t('ACTIONS'),
                key: 'actions',
                width: 120,
                render: row => {
                    return (
                        <Button.Group>
                            <Button type={`primary`} icon={`edit`} onClick={() => {
                                this.props.onEdit(row.id);
                            }}/>
                            <Button type={`danger`} icon={`delete`} onClick={() => {
                                this.props.onDelete(row.id);
                            }}/>
                        </Button.Group>
                    );
                }
            }

        ];
    }


    componentDidMount() {
        const OptionsContext = this.props.options || {};
        if (OptionsContext.actions && OptionsContext.actions.updateHeader) {
            OptionsContext.actions.updateHeader({
                title: t('POLLS_LIST'),
            })
        }
    }


    render() {
        return (
            <div className={`app_view_table ${this.props.type}_view`}>
                <div className="app_view--wrapper">
                    <div className={`app_view--table`}>
                        <Table
                            title={() => <AuthorsFilter onCreate={this.props.onCreate} onSearch={this.updateFilter}/>}
                            columns={this.columns}
                            bordered
                            dataSource={this.props.items}
                            rowKey={(item) => item.id}
                            pagination={{
                                pageSize: this.props.limit,
                                defaultCurrent: this.props.page,
                                total: this.props.total,
                                onChange: this.props.updatePageHandle,
                                current: this.props.page,
                                hideOnSinglePage: true,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}