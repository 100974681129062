import React from 'react';

import { loadMediaList, getUsingMedia } from './../../../store/media/api';
import { Spin, Button, Tag, Icon, Pagination } from 'antd';
import {t} from "../../../core/library/i18n";
import Config from "../../../config";
import Moment from 'moment';
import 'moment/locale/ru.js';
import { GalleryUpload } from './upload';
import { GalleryFilter } from './filter';


const TypeColours = {
    'Cover': '#108ee9',
    'Image': '#108ee9',
    'Video': '#b20932'
};


export class MediaModel extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            page: 1,
            total: 0,
            limit: this.props.limit || 60,
            loading: false,
            error: false,
            filter: {
                type: this.props.type ? this.props.type : 'Image,Cover',
                search: this.props.search ? this.props.search : '',
                range: null,
            },
            list: [],
        };
    }

    buildFilter = () => {
        let filter =  {
            type: this.state.filter.type === '' ? 'Image,Cover,Video,PDF,ExternalVideo' : this.state.filter.type,
            q: this.state.filter.search || "",
        };
        if (this.state.filter.range && this.state.filter.range.length > 0) {
            filter.range = "createdAt";
            if (this.state.filter.range.length === 2) {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
                filter.to = this.state.filter.range[1].format('YYYY-MM-DDTHH:mm:ss');
            } else {
                filter.from = this.state.filter.range[0].format('YYYY-MM-DDTHH:mm:ss');
            }
        }
        return filter;
    };

    loadList = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                let filter = this.buildFilter();
                loadMediaList(this.state.page, this.state.limit, filter, this.state.isPhoto, this.state.ignorePhoto, this.state.isBookmark, this.state.isExclude).then(async resp => {
                    let data = resp || {};
                    let ids = data.list ? data.list.map(i => i.id) : [];
                    if (data.list) {
                        try {
                            let mediaIds = await getUsingMedia(ids);
                            if (mediaIds.media) {
                                data.list = data.list.map(i => {
                                    let a = {...i};
                                    if (a.files && a.files["original"] && a.files["original"].path) {
                                        if (mediaIds.media.indexOf(a.files["original"].path) + 1 > 0) {
                                            a.isUsing = true;
                                        }
                                    }
                                    return a;
                                });
                            }
                        } catch (e) {
                            console.error(`error load using media: `, e);
                        }
                    }

                    this.setState({
                        list: data.list || [],
                        total: data.total || 0,
                        loading: false,
                        error: false,
                    })
                }).catch(e => {
                    console.error(`error load media list: `, e);
                    this.setState({
                        loading: false,
                        error: true,
                    })
                });
            });
        }
    };


    componentDidMount = () => {
        this.loadList();
    }


    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadList();
        });
    }
  
    onFilter = (filter) => {
        filter.type = this.props.type ? this.props.type : 'Image,Cover';
        this.setState({ filter, page: 1 }, () => {
            this.loadList();
        })
    }

    reloadList = () => {
        this.setState({
            page: 1,
            filter: { type: this.props.type ? this.props.type : 'Image,Cover'},
            error: false,
            items: [],
        }, () => {
            this.loadList();
        })
    };

   

    render() {
        console.log(this.state.list);
        return (
            <Spin spinning={this.state.loading} tip={`Идет загрузка`}>
                <GalleryUpload reloadList={this.reloadList}/>
                <div className={`modal__list--filter`}>
                    <div className="actions--row">
                        <GalleryFilter
                            filter={this.state.filter}
                            onFilter={this.onFilter}
                            onReload={this.reloadList}
                        />
                    </div>
                    <Pagination
                        onChange={this.onPageChange}
                        total={this.state.total}
                        pageSize={this.state.limit}
                        defaultCurrent={this.state.page}
                        current={this.state.page}
                        hideOnSinglePage={true}
                        showTotal={(total) => `${total} ${t('MEDIAGALLERY_OBJECT')}`}
                    />
                </div>
                <div className="modal_media_list">
                    {this.state.list.map(i => {
                        return <MediaModalItem {...i} deleteList={[]} onSetPoster={this.props.onSetPoster}/>
                    })}
                </div>
            </Spin>
        );
    }
}


class MediaModalItem extends React.Component {
    getMedia = () => {
        let dimensions = ['image-m', 'image-l', 'cover', 'preview', 'medium', 'original'];
        let path = '';
        let files = this.props.files;

        for (let index in dimensions) {
            let i = dimensions[index];
            if (files[i] && files[i].path) {
                path = files[i].path;
                break;
            }
        }

        let path_prefix = ``;
        let original_prefix = ``;
        switch (this.props.type) {
            case "Image":
            case "Cover":
                path_prefix = original_prefix = Config.MEDIA.CDN.IMAGES + "/";
                break;
            case "Video":
                path_prefix = original_prefix = Config.MEDIA.CDN.VIDEO + "/";
                break;
            case "PDF":
                path_prefix = Config.MEDIA.CDN.PDF + "/";
                original_prefix = Config.MEDIA.CDN.PDF + "/";
                break;
            default:
                break;
        }

        return {
            "preview": path ? `${path_prefix}${path}` : '',
            "original": files['original'] && files['original'].path ? `${original_prefix}${files['original'].path}` : '',
        };
    };

    getImageTitle = () => {
        let print_data = [];
        if (this.props['title']) {
            print_data.push(this.props['title']);
        }
        if (this.props['description']) {
            print_data.push(this.props['description']);
        }
        if (this.props['properties'] && this.props['properties']['copyright']) {
            print_data.push(this.props['properties']['copyright']);
        }
        return print_data.join(' / ');
    }

    /*@TODO: move to config*/
    generateSiteImage = () => {
        let files = this.props.files;
        let path = files['original']  ? files['original'].path : '';
        if (!path) return '';
        let params = path.split('/');
        params[params.length - 1] = `1280_${params[params.length - 1]}`;
        return `https://cdni.dvapiva.ru/${params.join('/')}`;
    }

    generateSiteVideo = () => {
        let files = this.props.files;
        if (files['preview'] && files['preview'].path) {
            return `https://video.dvapiva.ru/mp4/${files['preview'].path}`;
        }
        return false;
    }

    isBuilding = (media) => {
        if (media && media.type === 'Video' && ((media.transcoding === null || media.transcoding.progress < 100) && (media.transcoding && media.transcoding.status && media.transcoding.status !== "done"))) {
            return `pending`;
        }
        return ``;
    }

    getImageInfo = () => {
        if (['Image', 'Cover'].indexOf(this.props.type) + 1 > 0) {
            if (this.props.files['original'] && this.props.files['original'].width && this.props.files['original'].height) {
                return ` (${this.props.files['original'].width}:${this.props.files['original'].height} px)`
            }
        }
        return ``;
    }

    getImageTitle = () => {
        let params = [];
        if (this.props['title'] !== '') {
            params.push(this.props['title']);
        }
        if (this.props.properties && this.props.properties.copyright) {
            if (this.props.type === 'Video') {
                params.push('Видео: ' + this.props.properties.copyright)
            } else {
                if (['image', 'cover'].indexOf(this.props.type.toLowerCase()) + 1 > 0) {
                    params.push('Фото: ' + this.props.properties.copyright)
                }
            }
        }
        return params.join(' / ');
    }
    
    render() {
        const view = this.props.view;
        let media = this.getMedia();
        let blockBackground = media.preview ? {backgroundImage: `url(${media.preview})`} : ``;
        let previewImageProps = {
            className: this.props.deleteList.length === 0 ? `preview fimage type_${this.props.type.toLowerCase()} ${this.props.CAN_HIDE ? `can_bookmark` : ``}` : `preview type_${this.props.type.toLowerCase()} ${this.props.CAN_HIDE ? `can_bookmark` : ``}`, 
            style: blockBackground,
            href: media.original,
        };
        if (this.props.deleteList.length === 0) {
            previewImageProps['data-fancybox'] = true;
        } else {
            previewImageProps.onClick = (e) => {
                e.preventDefault();
                if (this.props.deleteList.indexOf(this.props.id) + 1 > 0) {
                    this.props.removeFromDelete(this.props.id);
                } else {
                    this.props.addToDelete(this.props.id);
                }
            };
        }
        return (
            <div className={`media__wrapper`}>
                <div className={`media__item item__${view} ${this.isBuilding(this.props)}`}>
                    <div className="image">
                        <Tag size={`small`} color={TypeColours[this.props.type] || ""} className={`image__tag`}>{t(`MEDIAGALLERY_TYPE_${this.props.type}`)}{this.props.media_id ? ` / ${this.props.media_id}` : ``}{this.getImageInfo()}</Tag>
                        <a {...previewImageProps}>
                            <div className={`created_at`}>
                                <span>{Moment(this.props.createdAt).format(Config.DATE_FORMAT)}</span>
                            </div>
                            {this.props.isUsing && <div className={`image--usage`}><Icon type="clock-circle"/></div>}
                            {this.props.expired && <div className={`image--expired`}><Icon type="info" /></div>}
                        </a>
                    </div>
                    {this.props.files && this.props.files['original'] && this.props.files['original'].path && <div className="item__footer"><Button type='primary' onClick={() => {
                        this.props.onSetPoster(this.props.files['original'].path);
                    }}>Выбрать</Button></div>}
                    
                </div>
            </div>
        );
    }
}

MediaModel.defaultProps = {
    limit: 60,
    type: 'Image,Cover',
    search: '',
};

