import * as API from './api';
import * as C from './constants';
import {DOCUMENT_HISTORY_LOAD_FINISH} from "./constants";

export function LoadDocuments(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 30) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENTS_LOAD_START, C.DOCUMENTS_LOAD_FINISH, C.DOCUMENTS_LOAD_ERROR],
        promise: API.loadDocuments(type, page, filter, sort, limit),
    };
}

export function LoadDocumentsSilent(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 30) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENTS_SILENT_LOAD_START, C.DOCUMENTS_SILENT_LOAD_FINISH, C.DOCUMENTS_SILENT_LOAD_ERROR],
        promise: API.loadDocuments(type, page, filter, sort, limit),
    };
}

export function UpdateDocument(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_UPDATE_START, C.DOCUMENT_UPDATE_FINISH, C.DOCUMENT_UPDATE_ERROR],
        promise: API.updateDocument(id, data),
    };
}

export function UpdateDocumentType(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_TYPE_START, C.DOCUMENT_TYPE_FINISH, C.DOCUMENT_TYPE_ERROR],
        promise: API.updateDocument(id, data),
    };
}


export function UpdateDocumentSilent(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_UPDATE_SILENT_START, C.DOCUMENT_UPDATE_SILENT_FINISH, C.DOCUMENT_UPDATE_SILENT_ERROR],
        promise: API.updateDocument(id, data),
    };
}

export function GetDocument(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_LOAD_START, C.DOCUMENT_LOAD_FINISH, C.DOCUMENT_LOAD_FINISH],
        promise: API.getDocumentInfo(id),
    };
}

export function CreateDocument(data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_CREATE_START, C.DOCUMENT_CREATE_FINISH, C.DOCUMENT_CREATE_ERROR],
        promise: API.createDocument(data),
    };
}

export function DeleteDocument(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_DELETE_START, C.DOCUMENT_DELETE_FINISH, C.DOCUMENT_DELETE_ERROR],
        promise: API.deleteDocument(id)
    };
}

export function GetHistory(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_HISTORY_LOAD_START, DOCUMENT_HISTORY_LOAD_FINISH, C.DOCUMENT_HISTORY_LOAD_ERROR],
        promise: API.getHistory(id),
    };
}

export function SetLock(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_LOCK_SET_START, C.DOCUMENT_LOCK_SET_FINISH, C.DOCUMENT_LOCK_SET_ERROR],
        promise: API.setDocumentLock(id),
    };
}

export function RemoveLock(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_LOCK_REMOVE_START, C.DOCUMENT_LOCK_REMOVE_FINISH, C.DOCUMENT_LOCK_REMOVE_ERROR],
        promise: API.removeLock(id),
    };
}

export function RemoveUserLock(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_LOAD_USER_REMOVE_START, C.DOCUMENT_LOAD_USER_REMOVE_FINISH, C.DOCUMENT_LOAD_USER_REMOVE_ERROR],
        promise: API.removeUserLock(id),
    };
}

export function GetStash(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_STASH_LOAD_START, C.DOCUMENT_STASH_LOAD_FINISH, C.DOCUMENT_STASH_LOAD_ERROR],
        promise: API.getStash(id),
    };
}

export function UpdateStash(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_STASH_UPDATE_START, C.DOCUMENT_STASH_UPDATE_FINISH, C.DOCUMENT_STASH_UPDATE_ERROR],
        promise: API.updateStash(id, data),
    };
}


export function DeleteStash(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_STASH_DELETE_START, C.DOCUMENT_STASH_DELETE_FINISH, C.DOCUMENT_STASH_DELETE_FINISH],
        promise: API.deleteStash(id),
    };
}

export function GetWorkflow(statuses = [], page = 1, search = '', options = null) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENTS_WORKFLOW_START, C.DOCUMENTS_WORKFLOW_FINISH, C.DOCUMENTS_WORKFLOW_ERROR],
        promise: API.getWorkflow(statuses, page, search, options),
    };
}

export function loadDocumentsEL(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 30) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENTS_LOAD_START, C.DOCUMENTS_LOAD_FINISH, C.DOCUMENTS_LOAD_ERROR],
        promise: API.documentsElLoad(type, page, filter, sort, limit),
    };
}

export function documentsElLoadDocs(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 30) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENTS_LOAD_START, C.DOCUMENTS_LOAD_FINISH, C.DOCUMENTS_LOAD_ERROR],
        promise: API.documentsElLoadDocs(type, page, filter, sort, limit),
    };
}

export function AddPush(data = {}) {
    return {
        type: "PROMISE",
        actions: [C.PUSHES_ADD_START, C.PUSHES_ADD_FINISH, C.PUSHES_ADD_ERROR],
        promise: API.addItem(data),
    };
}


export function UploadRegions(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.REGIONS_START_UPLOAD, C.REGIONS_FINISH_UPLOAD, C.REGIONS_ERROR_UPLOAD],
        promise: API.uploadRegionsSites(id)
    }
}
