/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Input, DatePicker, Select, Button, Form, Tag, Empty, message } from 'antd';
import moment from 'moment';
import Request from './../../core/fetch';
import { createDocument } from '../../store/documents/api';
import './style.scss';

export const RSSIndexController = (props) => {
	const [ item, setItem ] = useState(false);
	const [ filter, setFilter ] = useState({search: '',});
	return (
		<div className={`rss_controller`}>
			<div className={`rss_controller--filter`}>
				<WrapperFeedsFilter onFilter={setFilter} />
			</div>
			<div className={`rss_controller--wrapper`}>
				<div className={`column feed_items`}>
					<FeedsList
						onSelectItem={(d) => setItem(d)}
						selected={item}
						filter={filter}
					/>
				</div>
				<div className={`column feed_content`}>
					{item && <FeedItem {...item}/>}
					{item === false && <div style={{margin: 12}}><Empty description={`Выберите новость`}/></div>}
				</div>
			</div>
		</div>
	);
}

const FeedsList = (props) => {
	const limit = 20;
	const [ page, setPage ] = useState(0);
	const [ items, setItems ] = useState([]);
	const [ sources, setSources ] = useState({});
	const [ isFetching, setFetching ] = useState(false);
	const selected = props.selected || {id: false};

	const buildFilter = (current_page = 1) => {
		let req = `rss_agg?limit=${limit}&page=${current_page}`;

		if (props.filter.search) {
			req = `${req}&search=${props.filter.search}`;
		}

		if (props.filter.sources && props.filter.sources.length > 0) {
			req = `${req}&sources=${props.filter.sources.join('&sources=')}`;
		}

		if (props.filter.date_from) {
			req = `${req}&date_from=${props.filter.date_from}`;
		}

		if (props.filter.date_to) {
			req = `${req}&date_to=${props.filter.date_to}`;
		}
		return req;
	}
	
	const loadMore = (current_page = 1) => {
		return new Promise((resolve, reject) => {
			Request(buildFilter(current_page), "GET").then(res => {
				resolve(res.data && res.data.list ? res.data.list : []);
			});
		});
	}

	const loadList = (resetFilter = false) => {
		function loadNewList() {
			clearTimeout(window.cTimeout);
			Request(buildFilter(0), "GET").then(res => {
				if (resetFilter === true) {
					setItems(res.data && res.data.list ? res.data.list : []);
				} else {
					if (items.length > 0) {
						let itemIds = items.map(i => i.id);
						let resultList = items.filter(i => itemIds.indexOf(i.id) + 1 === 0);
						setItems(c => [...resultList, ...c]);
					} else {
						setItems(res.data && res.data.list ? res.data.list : []);
					}
				}
				
				window.cTimeout = setTimeout(() => {
					loadNewList();
				}, 10000);
			});
		}
		loadNewList();
	}


	useEffect(() => {
		async function fetchData() {
			Request("rss_agg/sources", "GET").then(async res => {
				if (res && res.data) {
					let result_sources = {};
					for(let i in res.data) {
						result_sources[res.data[i].source] = res.data[i];
					}
					setSources(result_sources);
				}
			});
			await setPage(0);
			await loadList(true);
		}
		fetchData();
	}, [props.filter]);


	const onScroll = (e) => {
		let scroll = e.target.scrollTop + e.target.offsetHeight;
		if (e.target.scrollHeight - scroll < 400 && isFetching === false && items.length > 0) {
			setPage(page + 1);
			setFetching(true);
		}
	}

	useEffect(() => {
		if (isFetching) {
			loadMore(page).then(dlist => {
				setItems(d => [...d, ...dlist]);
				setFetching(false);
			});
		}
	}, [isFetching]);


	return (
		<div className={`rss_feeds`} onScroll={onScroll}>
			{items.map(i => {
				return (
					<div key={i.id} className={`rss_item ${selected.id === i.id ? ` selected` : ``}`} onClick={() => props.onSelectItem(i)}>
						<div className={`title`}>{i.title}</div>
						{i.description && <div className={`description`}>{i.description}</div>}
						<div className={`feed__footer`}>
							<div className={`source-info`}>
								{i.category && <Tag>{i.category}</Tag>}
								{(i.feed_source && sources[i.feed_source]) && <Tag>{sources[i.feed_source].title}</Tag>}
							</div>
							<div className={`date`}>
								{moment(i.createdAt).fromNow()} / {moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}


const FeedItem = (props) => {
	const [ loading, setLoading ] = useState(false);

	const onCreateNews = () => {
		setLoading(true);
		createDocument({
			type: "News",
			title: props.title,
			slug: `news-${props.id}`,
			text: props.content,
			category: "000000000000000000000000",
			href: "",
			question: "",
			visible: false,
			workflow: "DRAFT",
		}).then(res => {
			console.log(`res`, res);
			message.success(`Новость успешно создана`);
			setTimeout(() => {
				setLoading(false);
				window.open(`/documents/news/${res.id}`);
			}, 1500);
		}).catch(e => {
			message.error(`Ошибка создания новости`);
			setLoading(false);
		});
	};


	return (
		<div className={`feed__item`}>
			<div className={`title`}>{props.title} <Button type="primary" size="small" loading={loading} onClick={onCreateNews}>Создать новость</Button></div>
			<div className={`block--info`}>
				<div className={`block--info__row`}><span>Дата импорта:</span> {moment(props.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
				<div className={`block--info__row`}><span>Дата новости:</span> {moment(props.pubDate).format('YYYY-MM-DD HH:mm:ss')}</div>
				{props.category && (<div className={`block--info__row`}><span>Категория:</span> {props.category}</div>)}
				{props.description && (<div className={`block--info__row`}><span>Короткое описание:</span> {props.description}</div>)}
			</div>
			<div className={`block--info__text`} dangerouslySetInnerHTML={{__html: props.content}}/>
		</div>
	);
}

const FeedsFilter = (props) => {
	const [ sourcesList, setSourcesList ] = useState([]);

	useEffect(() => {
		Request("rss_agg/sources", "GET").then(res => {
			setSourcesList(res.data || []);
		});
	}, []);

	const { getFieldDecorator } = props.form;

	const onSubmit = (e) => {
		e.preventDefault();
		props.form.validateFields((err, values) => {
			if (!err) {
				props.onFilter({
					search: values.search, 
					date_from: values.date && values.date[0] ? values.date[0].format('YYYY-MM-DD') : null,
					date_to: values.date && values.date[1] ? values.date[1].format('YYYY-MM-DD') : null,
					sources: values.sources,
				});
			}
		});
	}


	
	return (
		<Form className={`filter`} onSubmit={onSubmit}>
			<div className={`filter--column`}>
				<div className={`filter--row`}>
					<Form.Item className={`search--input`}>
						{getFieldDecorator('search')(
							<Input placeholder={`Введите поисковый запрос`} allowClear/>
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('date')(
							<DatePicker.RangePicker allowClear/>
						)}
					</Form.Item>
				</div>
				<div className={`filter--row`}>
					<Form.Item className={`sources`}>
						{getFieldDecorator('sources')(
							<Select mode={`tags`} allowClear placeholder={`Выберите истоники`}>
								{sourcesList.map(i => {
									return <Select.Option value={i.source} key={i.source}>{i.title}</Select.Option>
								})}
							</Select>
						)}
					</Form.Item>
					
				</div>
			</div>
			<div className={`filter--column action--column-name`}>
				<Button type="primary" htmlType={`submit`}>Поиск</Button>
			</div>
		</Form>
	);
}

const WrapperFeedsFilter = Form.create({name: 'feeds_filter_form'})(FeedsFilter)