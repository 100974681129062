import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, InputNumber } from 'antd';
import { t } from './../../../library/i18n';


class Code extends React.Component {

    static defaultProps = {
        onSubmit: () => {}
    };

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={t('EMBEDDED')}>
                    {getFieldDecorator('embed', {
                        rules: [{required: true, message: t('EMBEDDED_EMPTY')}],
                    })(<Input.TextArea rows={4}/>)}
                </Form.Item>
                <Form.Item label={t('WIDTH')}>
                    {getFieldDecorator('options.width', {})(<InputNumber />)}
                </Form.Item>
                <Form.Item label={t('HEIGHT')}>
                    {getFieldDecorator('options.height', {})(<InputNumber />)}
                </Form.Item>

                <Form.Item>
                    <Button onClick={this.onSubmit} type={`primary`}>{t('SAVE')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedCode = Form.create({ name: 'Code' })(Code);
export default WrappedCode;
