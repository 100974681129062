import React from 'react';
import {Table, Button, Layout, Tag, Modal} from 'antd';
import {t} from "../../core/library/i18n";
import Moment from 'moment';
import Form from './Email/form';


export class EmailController extends React.Component {

    state = {
        list: [
            {title: 'Тестовая рассылка 1', 'date': '2019-09-17 23:58:09.000', id: 1, status: 'sending', progress: '80%'},
            {title: 'Тестовая рассылка 2', 'date': '2019-09-15 14:35:04.000', id: 2, status: 'cancel'},
            {title: 'Тестовая рассылка 3', 'date': '2019-09-10 09:41:11.000', id: 3, status: 'send'},
            {title: 'Тестовая рассылка 4', 'date': '2019-09-07 21:22:51.000', id: 4, status: 'send'},
            {title: 'Тестовая рассылка 5', 'date': '2019-09-01 22:34:31.000', id: 5, status: 'prepare'},
        ],
        modal: true,
    };

    Columns = [
        {
            title: t('TITLE'),
            dataIndex: 'title',
        },
        {
            title: t('DATE'),
            key: 'date',
            render: r => Moment(r.date).format('YYYY-MM-DD HH:mm')
        },
        {
            title: t('STATUS'),
            key:'status',
            render: r => {
                let c = '';
                switch (r.status) {
                    case 'sending': c = 'blue';break;
                    case 'cancel': c = 'red';break;
                    case 'send': c = '#87d068';break;
                    case 'prepare': c = '#f50';break;
                    default: break;
                }
                return <Tag color={c}>{`${t(r.status)} ${r.progress ? ` ${r.progress}` : ''}`}</Tag>;
            }
        },
        {
            title: '',
            key: 'actions',
            render: r => {
                return (
                    <Button.Group>
                        {r.status === 'prepare' && <Button type={`primary`} icon={`play-circle`}/>}
                        <Button type={`primary`} icon={`eye`} />
                        {r.status === 'sending' && <Button type={`danger`} icon={`stop`}/>}
                        {r.status === 'cancel' && <Button type={`primary`} icon={`undo`}/>}
                        {r.status === 'prepare' && <Button type={`danger`} icon={`delete`}/>}

                    </Button.Group>
                )
            }
        }
    ];

    render() {
        return (
            <Layout.Content>
                <Modal onCancel={() => this.setState({modal: false})} visible={this.state.modal} title={t('CREATE_EMAIL')} width={'80%'} footer={[
                    <Button onClick={() => this.setState({modal: false})} type={`default`}>{t('CLOSE')}</Button>,
                    <Button type={`primary`}>{t('CREATE')}</Button>,
                ]}>
                    <Form />
                </Modal>
                <div style={{backgroundColor: '#FFF', margin: 12}}>
                    <Table
                        title={() => {
                            return (<React.Fragment>
                                <Button type={`primary`} onClick={() => this.setState({modal: true})}>{t('CREATE')}</Button>
                            </React.Fragment>)
                        }}
                        bordered
                        dataSource={this.state.list}
                        columns={this.Columns}
                        rowKey={(item) => item.id}
                    />
                </div>
            </Layout.Content>
        );
    }
}
