import React from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import Moment from 'moment';
import { Link } from 'react-router-dom';
import {Table, message, Input} from 'antd';
import {AppConsumer} from './../../context/app';
import * as Actions from "./../../store/documents/actions";
import * as C from './../../store/documents/constants';
import { t } from './../../core/library/i18n';
import connect from "react-redux/es/connect/connect";

const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class ArchiveHeader extends React.Component {

    static defaultProps = {
        context: {},
    };

    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };

    componentDidMount() {
        this.props.context.actions.updateHeader({
            title: t('ARCHIVE_LIST'),
        })
    }

    render() {
        return null;
    }
}

class ArchiveIndexController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            limit: 30,
            page: 1,
            search: '',
        };

        this.Columns = [
            {
                title: t('TITLE'),
                dataIndex: 'title',
                render: (t, row) => {
                    return <Link to={`/documents/${row.type.toLowerCase()}/${row.id}`}>{t}</Link>;
                },
            },
            {
                title: t('TYPE'),
                dataIndex: 'type',
                align: 'center',
                render: (type) => <Link to={`/documents/${type.toLowerCase()}`}>{t(`TYPE_${type}`)}</Link>,
            },
            {
                title: t('CREATED_BY'),
                dataIndex: 'createdBy',
                align: 'center',
                render: (text, row) => {
                    if (row.createdBy && row.createdBy.id) {
                        return <Link to={`/users/${row.createdBy.id}`}>{row.createdBy.email}</Link>;
                    }
                    return null;
                },
            },
            {
                title: t('CREATED_AT'),
                dataIndex: 'createdAt',
                align: 'center',
                render: text => Moment(text).format('YYYY-MM-DD HH:mm:ss')
            }
        ];
    }

    loadDocuments = () => {
        this.props.actions.GetWorkflow(["ARCHIVE"], this.state.page, this.state.search);
    };

    componentDidMount() {
        this.loadDocuments();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.DOCUMENTS_WORKFLOW_START:
                    this.setState({ loading: true });
                    break;
                case C.DOCUMENTS_WORKFLOW_FINISH:
                    this.setState({
                        loading: false,
                        items: p.answer.data.list || [],
                        total: p.answer.data.total ? parseInt(p.answer.data.total, 10) : 0,
                        limit: p.answer.data.limit ? parseInt(p.answer.data.limit, 10) : 0,
                    });
                    break;
                case C.DOCUMENTS_WORKFLOW_ERROR:
                    message.error(t('ERROR_LOAD_ARCHIVE'));
                    this.setState({ loading: false });
                    break;
                default: break;
            }
        }
    }

    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <ArchiveHeader context={context}/>
                            <div className={`archive--view`}>
                                <Table
                                    title={() => (
                                        <Input.Search
                                            onSearch={search => {
                                                this.setState({ search }, () => {
                                                    this.loadDocuments();
                                                });
                                            }}
                                            placeholder={t('SEARCH_PLACEHOLDER')}
                                            enterButton={t('SEARCH')}
                                        />
                                    )}
                                    bordered={true}
                                    loading={this.state.loading}
                                    columns={this.Columns}
                                    dataSource={this.state.items}
                                    rowKey={(item) => item.id}
                                    pagination={{
                                        pageSize: this.state.limit,
                                        defaultCurrent: this.state.page,
                                        total: this.state.total,
                                        onChange: (page) => this.setState({ page }, () => { this.loadDocuments() }),
                                        current: this.state.page,
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        )
    }
}

export const ArchiveIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(ArchiveIndexController);

export default ArchiveIndex;
