import React from 'react';
import PropTypes from 'prop-types';
import {Spin, message, Tooltip, Input, Button, Pagination, Icon, Switch} from 'antd';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as Actions from "./../../../../../../../store/media/actions";
import * as C from './../../../../../../../store/media/constants';
import { getUsingMedia } from './../../../../../../../store/media/api';
import {t} from './../../../../../../library/i18n'
import Config, { GetImagePath as getImgPath} from "../../../../../../../config";
import Moment from 'moment';

const GetImagePath = (files) => {
    return getImgPath(files, '320');
    // let keys = Object.keys(files);
    // let media = files[keys[0]];
    //
    // if (files.thumbnail) {
    //     media = files.thumbnail;
    // }
    //
    // if (files['image-l']) {
    //     // console.log(`find image: `, files['image-l']);
    //     media = files['image-l'];
    // }

    // let params = files['original'].path.split('/');
    // params[params.length - 1] = `320_${params[params.length - 1]}`;
    // return `https://cdni.vm.ru/${params.join('/')}`;

    // return `${Config.MEDIA.CDN.IMAGES}/${media.path}`
};

const GetVideoPath = (files) => {
    let keys = Object.keys(files);
    let media = files[keys[0]];

    if (files.thumbnail) {
        media = files.thumbnail;
    }
    return `${Config.MEDIA.CDN.VIDEO}/${media.path}`
};


const RenderImage = ({files}) => {
    return <div style={{backgroundImage: `url(` + GetImagePath(files) + `)`,height: 200, backgroundSize: 'cover'}} className={`cover--backgrounds`}/>
};

const RenderVideo = ({ files }) => {
    return (
        <div className={`cover--background`}>
            <video controls src={GetVideoPath(files)}></video>
        </div>
    );
};

const RenderExternal = (item) => {
   return <div className={`cover--background`} dangerouslySetInnerHTML={{__html: item.embed}} />
};

const mapStateToProps = state => ({
    answer: state.MediaReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class ImageListController extends React.Component {
    static defaultProps = {
        onInsert: () => {},
        type: 'Image,Cover',
        mediaTypesImage: '',
        limit: 30,
    };

    static propTypes = {
        onInsert: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        mediaTypesImage: PropTypes.string,
        limit: PropTypes.number,
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            page: 1,
            limit: this.props.limit,
            total: 0,
            search: '',
            userPhoto: false,
            isBookmark: false,
            isExclude: false,
        };
    }

    isRegion = () => {
        return window.location.pathname.indexOf('/regionmat/') + 1 > 0;
    }

    loadMedia = () => {
        let type = this.props.type;
        if (type === 'Image,Cover' && this.isRegion()) {
            type = 'Image,Cover,RegionCover,RegionImage';
        }
        this.props.actions.GetMediaList(this.state.page, this.state.limit, { type , q: this.state.search}, this.state.userPhoto, this.state.isBookmark, this.state.isExclude, this.props.excluded)
    };

    componentDidMount() {
        this.loadMedia();
    }

    componentWillReceiveProps = async (p) => {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.MEDIA_START_LOAD:
                    this.setState({loading: true});
                    break;
                case C.MEDIA_FINISH_LOAD:
                    const data = p.answer.data || {};
                    if (data.list && Array.isArray(data.list)) {
                        let ids = data.list.map(i => i.id);
                        let mediaIds = await getUsingMedia(ids);
                        if (mediaIds.media) {
                            data.list = data.list.map(i => {
                                let a = {...i};
                                if (a.files && a.files["original"] && a.files["original"].path) {
                                    if (mediaIds.media.indexOf(a.files["original"].path) + 1 > 0) {
                                        a.isUsing = true;
                                    }
                                }
                                return a;
                            });
                        }
                    }
                    this.setState({
                        items: data.list || [],
                        total: data.total && data.total !== "" ? parseInt(data.total, 10) : 0,
                        loading: false,
                    });
                    break;
                case C.MEDIA_ERROR_LOAD:
                    message.error(t('ERROR_LOAD_MEDIA'));
                    break;
                case C.MEDIA_CREATE_START:
                    this.setState({ loading: true });
                    break;
                case C.MEDIA_CREATE_ERROR:
                    message.error(t("MEDIA_CREATE_ERROR"));
                    this.setState({ loading: false });
                    break;
                case C.MEDIA_CREATE_FINISH:
                    this.setState({ loading: false }, () => {
                        let d = p.answer.data && p.answer.data.data ? p.answer.data.data : null;
                        if (d) {
                            this.props.onInsert(d);
                        } else {
                            message.error(t("MEDIA_CREATE_ERROR"));
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    getImageText = (file = {}) => {
        let params = [];
        if (file.title) {
            params.push(file.title)
        }
        if(file.description) {
            params.push(file.description);
        }

        if(file.keywords) {
            params.push(file.keywords);
        }

        if (file.properties && Object.keys(file.properties).length > 0) {
            Object.keys(file.properties).map(i => {
                if (typeof file.properties[i] === "string") {
                    params.push(file.properties[i]);
                }
                return i;
            });
        }
        return params.join(' / ');
    };

    insertMedia = (media) => {
        if (['Cover', 'Image', 'Video', 'RegionCover', 'RegionImage', 'RegionVideo'].indexOf(media.type) + 1 > 0) {
            let a = {...media};
            if (a.type === 'Cover') {
                a.type = 'Image';
            }

            if (a.type === 'RegionCover') {
                a.type = 'RegionImage';
            }

            if (a.type === 'Image' && this.isRegion()) {
                a.type = 'RegionImage';
            }

            a.virtual = true;
            a.original_id = a.id || "";

            if (Array.isArray(a.tags) && a.tags.length === 0) {
                a.tags = null;
            }

            if (Array.isArray(a.coverId) && a.coverId.length === 0) {
                a.coverId = null;
            }

            if (Array.isArray(a.docId) && a.docId.length === 0) {
                a.docId = null;
            }
            this.props.actions.CreateMedia(a);
            // this.props.onInsert(a);
        }
    };

    render() {
        return (
            <Spin spinning={this.state.loading} style={{width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{flexDirection: 'row', display: 'flex', marginBottom: 10}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20}}>
                            <Switch onChange={(userPhoto) => {
                                this.setState({userPhoto, page: 1}, () => {
                                    this.loadMedia();
                                })
                            }}/>
                            <span style={{marginLeft: 10, minWidth: 75}}>Весь архив</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20}}>
                            <Switch onChange={(isBookmark) => {
                                this.setState({isBookmark, page: 1}, () => {
                                    this.loadMedia();
                                })
                            }}/>
                            <span style={{marginLeft: 10, minWidth: 80}}>Избранное</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20}}>
                            <Switch onChange={(isExclude) => {
                                this.setState({isExclude, page: 1}, () => {
                                    this.loadMedia();
                                })
                            }}/>
                            <span style={{marginLeft: 10, minWidth: 80}}>Искл. копр.</span>
                        </div>
                    </div>
                    <div style={{paddingLeft: 12, paddingRight: 12, width: '100%'}}>
                        <Input.Search
                            enterButton={t('SEARCH')}
                            placeholder={t('SEARCH_PLACEHOLDER')}
                            onSearch={(search) => {
                                this.setState({ search , page : 1}, () => {
                                    this.loadMedia();
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={`media--list`}>
                    {this.state.items.map(i => {
                        const text = this.getImageText(i);
                        return (
                            <div className={`media--list_item`} key={`media_item_${i.id}`} style={{position: 'relative'}}>
                                <div style={{position: `relative`}}>
                                    {i.isUsing && <div className={`image--usage`}><Icon type="clock-circle" /></div>}
                                    {i.expired && <div className={`image--expired`}><Icon type="info" /></div>}
                                    {i.type === "Image" &&  <RenderImage files={i.files}/>}
                                    {i.type === "RegionCover" &&  <RenderImage files={i.files}/>}
                                    {i.type === "RegionImage" &&  <RenderImage files={i.files}/>}
                                    {i.type === "Cover" &&  <RenderImage files={i.files}/>}
                                    {i.type === "Video" &&  <RenderVideo files={i.files}/>}
                                    {i.type === "ExternalVideo" &&  <RenderExternal {...i}/>}
                                    <div className={`uploaded_at`} style={{backgroundColor: `rgba(0, 0, 0, 0.5)`, position: `absolute`, bottom: 0, left: 0, width: `100%`, color: `#FFF`, lineHeight: 1.3, padding: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '12px'}}>
                                        <span>{Moment(i.createdAt).format(Config.DATE_FORMAT)}</span>
                                    </div>
                                </div>
                                <div className="image--info">
                                    <Tooltip
                                        title={text}>{text.length > 60 ? `${text.substr(0, 57)}...` : text}</Tooltip>
                                </div>
                                <div className="actions">
                                    <Button type={`primary`} icon={'plus'} onClick={() => this.insertMedia(i)}>{t('ADD')}</Button>
                                </div>
                            </div>
                        );
                    })}
                    <Pagination
                        hideOnSinglePage={true}
                        current={this.state.page}
                        pageSize={this.state.limit}
                        total={this.state.total}
                        onChange={(page) => {
                            this.setState({ page }, () => {
                                this.loadMedia();
                            })
                        }}
                    />
                </div>
            </Spin>
        );
    }
}

export const MediaIndex = connect(
    mapStateToProps,
    mapDispatchProps
)(ImageListController);

export default MediaIndex;
