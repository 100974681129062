import React from 'react';
import { Form, Button, Table, DatePicker, message } from 'antd';
import Moment from 'moment';
import Fetcher from './../../core/fetch';


export class ViqeoReport extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            date_from: Moment(new Date()),
            date_to: Moment(new Date()),
            list: [],
        };
        this.test = {};
        this.columns = [
            {
                title: 'Дата',
                key: 'date',
                sorter: (a, b) => {
                    let aDate = parseInt(Moment(a.date).format('x'), 10);
                    let bDate = parseInt(Moment(b.date).format('x'), 10);
                    return aDate - bDate;
                },
                render: r => Moment(r.date).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                title: 'Название',
                key: 'name',
                render: r =>  {
                    return <a target={"_blank"} href={r.file_path}>{r.title || `[не заполнено]`}</a>;
                }
            },
            {
                title: 'Длительность',
                key: 'duration',
                sorter: (a, b) => {
                    return a.duration - b.duration;
                },
                render: r => `${Math.floor(r.duration/60)} (${r.duration} сек)`,
            },
            {
                title: 'Просмотры',
                key: 'views',
                sorter: (a, b) => {
                    return a.starts - b.starts;
                },
                render: r => `${r.starts} / ${r.completes}`,
            },
            {
                title: 'Ср. время просмотра',
                key: 'avg_time',
                sorter: (a, b) => {
                    return a.viewAbs - b.viewAbs;
                },
                render: r => {
                    let d = r.viewAbs.toFixed(0);
                    return `${Math.floor(d/60)} (${d} сек)`
                },
            },
            {
                title: '% Досмотров',
                key: 'avg_finish',
                // render: r => r.viewDepthPct.toFixed(2),
                sorter: (a, b) => {
                    let as = a.starts || 1;
                    let ab = b.starts || 1;
                    let percenta = a.completes / as;
                    let percentb = b.completes / ab;
                    return percenta - percentb;
                },
                render: r => {
                    let s = r.starts || 1;
                    let percent = r.completes / s;
                    return (percent * 100).toFixed(2)
                }
            }
            ,
            {
                title: '10 сек.',
                key: 'seconds_10',
                sorter: (a, b) => {
                    return a.sten - b.sten;
                },
                render: r => r.sten,
            },
            {
                title: '30 сек.',
                key: 'seconds_30',
                sorter: (a, b) => {
                    return a.sth - b.sth;
                },
                render: r => r.sth,
            },
            {
                title: '60 сек.',
                key: 'seconds_60',
                sorter: (a, b) => {
                    return a.ssx - b.ssx;
                },
                render: r => r.ssx,
            }
        ];
    }

    loadReport = () => {
        this.setState({ loading: true }, () => {
            Fetcher(`/reports/viqeo?date_from=${this.state.date_from.format(`YYYY-MM-DD`)}&date_to=${this.state.date_to.format(`YYYY-MM-DD`)}`, `GET`).then(data => {

                // let resultReport = {};
                // if (data && data.data) {
                //     data.data.forEach(i => {
                //         if (!resultReport[i.date]) {
                //             resultReport[i.date] = {};
                //         }
                //         if(!resultReport[i.date][i.video_id]) {
                //             resultReport[i.date][i.video_id] = i;
                //             resultReport[i.date][i.video_id].c = 1;
                //         } else {
                //             resultReport[i.date][i.video_id].viewDepthPct += i.viewDepthPct;
                //             resultReport[i.date][i.video_id].viewAbs += i.viewAbs;
                //             resultReport[i.date][i.video_id].starts += i.starts;
                //             resultReport[i.date][i.video_id].completes += i.completes;
                //             resultReport[i.date][i.video_id].unmutes += i.unmutes;
                //             resultReport[i.date][i.video_id].c += 1;
                //         }
                //     });
                // }
                //
                // Object.keys(resultReport).forEach(i => {
                //     Object.keys(resultReport[i]).forEach(j => {
                //         resultReport[i][j].viewDepthPct = resultReport[i][j].viewDepthPct/resultReport[i][j].c;
                //         resultReport[i][j].viewAbs = resultReport[i][j].viewAbs/resultReport[i][j].c;
                //     });
                // });
                //
                //
                // let list = [];
                // Object.keys(resultReport).forEach(i => {
                //     Object.keys(resultReport[i]).forEach(j => {
                //         list.push( resultReport[i][j] )
                //     })
                // })

                let list = data && data.data && Array.isArray(data.data) ? data.data : [];
                if (list.length > 0) {

                    list.sort((a, b) => {
                        let aDate = parseInt(Moment(a.date).format('x'), 10);
                        let bDate = parseInt(Moment(b.date).format('x'), 10);
                        return aDate - bDate;
                    });
                }

                this.setState({loading: false, list });
            }).catch(e => {
                this.setState({ loading: false, list: []});
                message.error(`Ошибка загрузки отчета с viqeo`);
            });
        });
    };

    updateFilters = (values) => {
        this.setState({
            date_from: values.date_from,
            date_to: values.date_to
        }, () => {
            this.loadReport();
        });
    };

    componentDidMount() {
        this.loadReport();
    }

    render() {
        return (
            <div className={`report budget--view`} style={{margin: 12, background: '#FFF', padding: 12}}>
                <Table
                    title={() => (
                        <WrappedViqeoForm
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            updateFilters={this.updateFilters}
                        />
                    )}
                    bordered
                    loading={this.state.loading}
                    dataSource={this.state.list}
                    columns={this.columns}
                    rowKey={(item) => `${item.date}_${item.video_id}`}
                    pagination={{
                        pageSize: this.state.list.length,
                        defaultCurrent: 1,
                        total: this.state.list.length,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        );
    }
}


class ViqeoForm extends React.Component {
    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateFilters(values);
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={`report--form`}>
                <Form layout={`inline`} onSubmit={this.onSubmit}>
                    <Form.Item label={`Дата начала`}>
                        {getFieldDecorator('date_from', {
                            initialValue: this.props.date_from,
                        })(<DatePicker placeholder={`Выберите дату`}/>)}
                    </Form.Item>
                    <Form.Item label={`Дата окончания`}>
                        {getFieldDecorator('date_to', {
                            initialValue: this.props.date_to,
                        })(<DatePicker placeholder={`Выберите дату`}/>)}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={`submit`} type={`primary`}>Показать</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrappedViqeoForm = Form.create({ name: 'ViqeoForm' })(ViqeoForm);
