import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddEpisode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};
        this.Fields = [
            {
                'component': 'autocomplete',
                'type': 'Podcasts',
                'limit': 15,
                'label': t('Podcast'),
                'name': 'category',
                'props': {
                    placeholder: t('SELECT_PODCAST'),
                    allowClear: true,
                    showSearch: true,
        
                },
                'rules': [
                    {required: true, message: t('SELECT_PODCAST')},
                ]
            },
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('SUBTITLE'),
                'name': "subtitle",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                },
            },
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'upload',
                'type': 'Podcast',
                'label': t('PODCAST_AUDIO'),
                'name': 'media',
                'accept': '.mp3',
                'overwrite': 'audio',
            },

            {
                'component': 'textarea',
                'label': t('TEXT'),
                'name': 'text',
                'props': {
                    rows: 8,
                },
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'rules': [
                    {required: true, message: t("SLUG_ERROR")},
                ]
            },
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'Укажите дату и время публикации',
                    showTime: true,
                }
            },
            // {
            //     'component': 'group',
		    //     'label': t('DATABASE_LIST'),
            //     'fields': [
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Author',
            //             'limit': 30,
            //             'label': t('AUTHORS'),
            //             'name': 'authors',
            //             'props': {
            //                 placeholder: t('AUTHORS_PLACEHOLDER'),
            //                 mode: 'multiple',
            //                 allowClear: true,
            //                 showSearch: true,
            //             },
            //             'rules': [
            //                 {required: true, message: t('SELECT_AUTHORS_ERROR')},
            //             ]
            //         },
                    // {
                    //     'component': 'autocomplete',
                    //     'type': 'Category',
                    //     'limit': 15,
                    //     'label': t('CATEGORY'),
                    //     'name': 'category',
                    //     'props': {
                    //         placeholder: t('SELECT_CATEGORIES'),
                    //         allowClear: true,
                    //         showSearch: true,
                
                    //     },
                    //     'rules': [
                    //         {required: true, message: t('SELECT_CATEGORIES')},
                    //     ]
                    // },
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Tag',
            //             'limit': 15,
            //             'label': t('TAGS'),
            //             'name': 'tags',
            //             'props': {
            //                 placeholder: t('SELECT_TAGS'),
            //                 allowClear: true,
            //                 showSearch: true,
            //                 mode: 'multiple',
            //             },
            //         },
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Trend',
            //             'limit': 15,
            //             'label': t('TRENDS'),
            //             'name': 'trends',
            //             'props': {
            //                 placeholder: t('SELECT_TRENDS'),
            //                 allowClear: true,
            //                 showSearch: true,
            //                 mode: 'multiple',
            //             },
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('IMPORTANT_NEWS'),
            //             'name': 'options.important_news',
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('BREAKING_NEWS'),
            //             'name': 'options.breaking_news',
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('EXCLUSIVE_NEWS'),
            //             'name': 'options.exclusive',
            //         },
            //     ]
            // }
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {});
        }
    }

    onSubmit = (values) => {
        console.log(`media`, values);
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }


        values.media = media;
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    openChat = () => {
        this.props.updateUrl(`${window.location.pathname}/chat`);
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showChatBtn={false}
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    openChat={this.openChat}
                    sendPush={this.props.sendPush}
                />
            </div>
        );
    }
}

export default AddEpisode;
