import React from 'react';
import { Form, Input, message, Modal, Button, Select, Spin, Table, Tag, Tooltip, Popconfirm } from 'antd';
import './scss/index.scss';
import Config from '../../config';
import { CommentsRequest } from '../../core/fetch';
import moment from 'moment';

const statuses = {
    'Created': 'orange',
    'Started': 'green',
};

export class CommentsServiceController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            page: 0,
            loading: false,
            form_loading: false,
            limit: 100,
            add_modal: false,
            edit_item: null,
            list: [],
        };

        this.columns = [
            {
                key: 'title',
                title: 'Заголовок',
                render: r => {
                    return r.name;
                },
            },
            {
                key: 'type',
                title: 'тип',
                render: r => {
                    return r.type;
                },
            },
            {
                key: 'url',
                title: 'URL',
                render: r => {
                    return <a href={r.url} target='_blank' rel="noopener noreferrer">{r.url}</a>;
                },
            },
            {
                key: 'status',
                title: 'Статус',
                render: r => {
                    return <Tag color={statuses[r.status] || ''}>{r.status}</Tag>;
                },
            },
            {
                key: 'updatedAt',
                title: 'Обновлен',
                render: r => {
                    return moment(r.updatedAt).format('LLL');
                },
            },
            {
                key: 'actions',
                title: '',
                render: r => {
                    return (
                        <Button.Group>
                            {r.status !== 'Started' && (
                                <Tooltip title={`Запустить сервис`}>
                                    <Button
                                        loading={r.status_loading || false}
                                        icon={`play-square`}
                                        onClick={() => this.startService(r.id)}
                                    />
                                </Tooltip>
                            )}
                            
                            <Tooltip title={`Редактировать сервис`}>
                                <Button
                                    loading={r.edit_loading || false}
                                    type={`primary`}
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({edit_item: r, add_modal: true});
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={`Удалить сервис`}>
                                <Popconfirm
                                    title={`Вы уверены, что хотите удалить сервис?`}
                                    okText='Да'
                                    cancelText='Нет'
                                    onConfirm={() => {
                                        this.deleteService(r.id);
                                    }}
                                >
                                    <Button loading={r.delete_loading || false} type={`danger`} icon='delete' />
                                </Popconfirm>
                            </Tooltip>
                        </Button.Group>
                    );
                },
            }
        ];
    }


    startService = (serviceId) => {
        this.setState({ list: this.updateServiceLoading(serviceId, 'status', true) }, () => {
            CommentsRequest(`/services/${serviceId}/start`).then(res => {
                message.success(`Сервис успешно запущен`);
                setTimeout(() => {
                    this.loadList();
                }, 1500);
            }).catch(e => {
                console.error(`[Service.start]: `, e);
                message.error(`Ошибка запуска сервиса`);
                this.setState({list: this.updateServiceLoading(serviceId, 'status', false)})
            });
        });
    }

    deleteService = (serviceId) => {
        this.setState({ list: this.updateServiceLoading(serviceId, 'delete', true) }, () => {
            CommentsRequest(`/services/${serviceId}`, 'DELETE').then(res => {
                message.success(`Сервис успешно удален`);
                setTimeout(() => {
                    this.loadList();
                }, 1500);
            }).catch(e => {
                console.error(`[Service.start]: `, e);
                message.error(`Ошибка удаления сервиса`);
                this.setState({list: this.updateServiceLoading(serviceId, 'delete', false)})
            });
        });
    }

    updateServiceLoading = (serviceId, type = 'status', status = false) => {
        let list = [...this.state.list];
        list = list.map(i => {
            if (i.id === serviceId) {
                if (type === 'status') {
                    return {...i, ...{status_loading: status}};
                }
                if (type === 'edit') {
                    return {...i, ...{edit_loading: status}};
                }
                if (type === 'delete') {
                    return {...i, ...{delete_loading: status}};
                }
            }
            return i;
        });
        return list;
    }

    updateService = (serviceId, service, type = 'status') => {
        this.setState({ list: this.updateService(serviceId, type, true) }, () => {
            CommentsRequest(`/services/${serviceId}`, 'PUT', service).then(res => {
                message.success(`Сервис успешно обновлен`);
                this.loadList();
            }).catch(e => {
                console.error(`[Service.update]: `, e);
                message.error(`Ошибка обновления сервиса`)
            });
        });
    }


    onFilter = (search) => {
        this.setState({search, page: 1}, () => {
            this.loadList();
        });
    }


    loadList = () => {
        if (this.state.loading === false) {
            this.setState({loading: true}, () => {
                CommentsRequest(`/services/?limit=${this.state.limit}&page=${this.state.page}&search=${this.state.search}`).then(res => {
                    this.setState({ loading: false, list: res.data || []});
                }).catch(e => {
                    console.error(`[service.List]: `, e);
                    this.setState({ loading: false });
                    message.error(`Failed to load service list`);
                });
            });
        }
    }

    componentDidMount = () => {
        this.loadList();
    }


    

    onCreate = (values) => {
        this.setState({form_loading: true}, () => {
            CommentsRequest(`/services/add`, `POST`, values).then(res => {
                message.success('Сервис успешно добавлен');
                setTimeout(() => {
                    this.setState({ add_modal: false}, () => {
                        this.setState({ form_loading: false  }, () => {
                            this.loadList();
                        });
                    });
                }, 1500);
            }).catch(e => {
                console.error(`[Service.Add]`, e);
                this.setState({form_loading: false});
                message.error(`Ошибка создания сервиса`)
            });
        });
    }

    onUpdate = (values) => {
        this.setState({form_loading: true}, () => {
            CommentsRequest(`/services/${this.state.edit_item.id}`, `PUT`, values).then(res => {
                message.success('Сервис успешно обновлен');
                setTimeout(() => {
                    this.setState({ add_modal: false, edit_item: null}, () => {
                        this.setState({ form_loading: false  }, () => {
                            this.loadList();
                        });
                    });
                }, 1500);
            }).catch(e => {
                console.error(`[Service.Update]`, e);
                this.setState({form_loading: false});
                message.error(`Ошибка обновления сервиса`)
            });
        });
    }

    render() {
        const { add_modal, form_loading, edit_item } = this.state;
        return (
            <div className='comments services'>
                {add_modal && (
                    <Modal
                        title={edit_item !== null ? "Создание нового сервиса" : "Редактирование сервиса"}
                        visible={add_modal}
                        width={720}
                        destroyOnClose={true}
                        onCancel={() => this.setState({add_modal: false, edit_item: null})}
                        footer={null}
                    >
                        <Spin spinning={form_loading}>
                            <ServiceAddForm
                                handleSubmit={this.onCreate}
                                handleUpdate={this.onUpdate}
                                item={edit_item}
                            />
                        </Spin>
                    </Modal>
                )}
                <ServiceFilter 
                    handleSubmit={this.onFilter}
                    openAddModal={() => this.setState({add_modal: true})}
                />
                <div className='comments--block table'>
                    <Table
                        columns={this.columns}
                        loading={this.state.loading}
                        bordered
                        dataSource={this.state.list}
                        size='small'
                        rowKey={(item) => item.id}
                        pagination={{
                            pageSize: this.state.limit,
                            defaultCurrent: this.state.page,
                            total: this.state.list.length,
                            onChange: (page) => {
                                this.setState({ page }, () => {
                                    this.loadList();
                                });
                            },
                            current: this.state.page,
                            hideOnSinglePage: true,
                            position:"both",
                        }}
                    />
                </div>
            </div>
        );
    }
}


class ServiceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (this.props.item === null) {
                this.props.handleSubmit(values)
            } else {
                this.props.handleUpdate(values);
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { item } = this.props;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={`Выберите тип сервиса`}>
                    {getFieldDecorator('type', {
                        initialValue: item && item.type ? item.type : '',
                        rules: [{required: true, message: 'Укажите тип сервиса'}]
                    })(
                        <Select placeholder="Выберите тип сервиса">
                            {Config.COMMENTS.SERVICES.map(i => {
                                return <Select.Option value={i} key={`service_${i}`}>{i}</Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={`Access token`}>
                    {getFieldDecorator('access_token', {
                        initialValue: item && item.access_token ? item.access_token : '',
                        rules: [{required: true, message: 'Укажите токен доступа к сервису'}]
                    })(<Input placeholder='Введите токен доступа' />)}
                </Form.Item>
                <Form.Item label={`Refresh token`}>
                    {getFieldDecorator('refresh_token', {
                        initialValue: item && item.refresh_token ? item.refresh_token : '',
                    })(<Input placeholder='Введите refresh токен' />)}
                </Form.Item>
                <Form.Item label={`Название сервиса`}>
                    {getFieldDecorator('name', {
                        initialValue: item && item.name ? item.name : '',
                        rules: [{required: true, message: 'Укажите имя сервиса'}]})(<Input placeholder='Введите название сервиса' />)}
                </Form.Item>
                <Form.Item label={`Ссылка на канал/страницу`}>
                    {getFieldDecorator('url', {
                        initialValue: item && item.url ? item.url : '',
                        rules: [{required: true, message: 'Укажите ссылку'}]})(<Input type="url" placeholder='Укажите ссылку на страницу, канал где будет идти сборка комментов' />)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType='submit'>{item !== null ? 'Обновить' : 'Создать'}</Button>
                </Form.Item>
            </Form>
        );
    }
}

const ServiceFilter = ({ handleSubmit, openAddModal }) => {
    return (
       <div className='comments--block filter'>
            <Button type='primary' onClick={openAddModal}>Создать</Button>
            <Input.Search
                placeholder='Введите поисковый запрос'
                enterButton="Поиск"
                onSearch={(s) => handleSubmit(s)}
            />
       </div>
    );
}

const ServiceAddForm = Form.create({ name: 'ServiceAdd' })(ServiceAdd);


export default CommentsServiceController;