import React from 'react';
import { Form, Button, Spin, DatePicker, message } from 'antd';
import Moment from 'moment';
import Fetcher from './../../core/fetch';
import { t } from './../../core/library/i18n';
import {Chart, Axis, Legend, Tooltip, Geom} from "bizcharts";


const Aliases = {
    "play": "Проигрывание",
    "click": "Клик по блоку",
    "pause": "Пауза",
    "muted": "Выключение звука",
    "unmuted": "Включение звука",
    "fullscreen_enter": "Во весь экран",
    "fullscreen_exit": "Выход из полного экрана"
};

export class StreamReport extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dateFrom: Moment(),
            dateTo: Moment(),
            docId: '5e2ea7c682682c6f2d944bb0',
            activeEvents: [],
            unactiveEvents: [],
        };
    }

    updateFilters = (data) => {
        this.setState({ dateFrom: data.date_from, dateTo: data.date_to}, () => {
            this.loadReport();
        });
    };

    loadReport = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                Fetcher(`/reports/stream/${this.state.docId}?date_from=${this.state.dateFrom.format('YYYY-MM-DD')}&date_to=${this.state.dateTo.format('YYYY-MM-DD')}`).then(data => {
                    let report = data.data || [];

                    // let isDay = this.state.dateTo.format('x') - this.state.dateFrom.format('x') > 0;
                    //
                    // report.map(i => {
                    //     let d = Moment(i.createdAt);
                    //     console.log(d.format("YYYY-MM-DD"));
                    // });


                    let reportActive = report.filter(i => ["play", "unmuted", "fullscreen_enter", "click"].indexOf(i.event) + 1 > 0);
                    let reportUnactive = report.filter(i => ["pause", "muted", "fullscreen_exit"].indexOf(i.event) + 1 > 0);
                    this.setState({
                        loading: false,
                        activeEvents: reportActive,
                        unactiveEvents: reportUnactive
                    });
                }).catch(e => {
                    console.error(`error load stream video: `, e);
                    message.error(t('ERROR_LOAD_REPORT_STREAM'));
                });
            });
        }
    };

    componentDidMount() {
        this.loadReport()
    }


    render() {
        return (
            <div className={`report budget--view`} >
                <Spin tip={t('LOADING')} spinning={this.state.loading}>

                    <div style={{margin: 12, background: '#FFF', padding: 12}}>
                        <WrappedViqeoForm
                            date_from={this.state.dateFrom}
                            date_to={this.state.dateTo}
                            updateFilters={this.updateFilters}
                        />
                    </div>

                    <div style={{margin: 12, background: '#FFF', padding: 12}}>
                        <h3>События (play, unmuted, fullscreen, click)</h3>
                        <StreamChartRender
                            data={this.state.activeEvents}
                            date_from={this.state.dateFrom}
                            date_to={this.state.dateTo}
                        />
                    </div>
                    <div style={{margin: 12, background: '#FFF', padding: 12}}>
                        <h3>События (pause, muted, exit fullscreen)</h3>
                        <StreamChartRender
                            data={this.state.unactiveEvents}
                            date_from={this.state.dateFrom}
                            date_to={this.state.dateTo}
                        />
                    </div>
                </Spin>
            </div>
        );
    }
}


class StreamChartRender extends React.Component {

    scale = {
        hkey: {
            alias: "Время",
        },
        count: {
            alias: "События"
        }
    };

    render() {
        let isDay = this.props.date_to.format('x') - this.props.date_from.format('x') > 0;

        let data = this.props.data || [];
        data = data.map(i => {
            let a = {...i};
            if (!isDay) {
                a.hkey = Moment(i.createdAt).format('H');
            } else {
                a.hkey = Moment(i.createdAt).format('YYYY-MM-DD');
            }
            if (Aliases[a.event]) {
                a.event = Aliases[a.event]
            }
            return a;
        });

        if (!isDay) {
            data.sort((a, b) => {
                return parseInt(a.hkey, 10) - parseInt(b.hkey, 10);
            });
        } else {
            data.sort((a, b) => {
                return Moment(a.hkey).format('x') - Moment(b.hkey).format('x');
            });
        }


        return (
            <div>
                <Chart height={400} data={data} forceFit scale={{
                    hkey: {alias: isDay ? `День` : `Час`},
                    count: {alias: 'События'}}
                }>
                    <Legend />
                    <Axis name="hkey" title={`Дата`}/>
                    <Axis name="count" title={`События`}/>
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="line"
                        position="hkey*count"
                        size={2}
                        color={"event"}
                        shape={"smooth"}
                    />
                    <Geom
                        type="point"
                        position="hkey*count"
                        size={4}
                        shape={"circle"}
                        color={"event"}
                        style={{
                            stroke: "#fff",
                            lineWidth: 1
                        }}
                    />
                </Chart>
            </div>
        );
    }
}

class ViqeoForm extends React.Component {
    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.updateFilters(values);
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={`report--form`}>
                <Form layout={`inline`} onSubmit={this.onSubmit}>
                    <Form.Item label={`Дата начала`}>
                        {getFieldDecorator('date_from', {
                            initialValue: this.props.date_from,
                        })(<DatePicker placeholder={`Выберите дату`}/>)}
                    </Form.Item>
                    <Form.Item label={`Дата окончания`}>
                        {getFieldDecorator('date_to', {
                            initialValue: this.props.date_to,
                        })(<DatePicker placeholder={`Выберите дату`}/>)}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={`submit`} type={`primary`}>Показать</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrappedViqeoForm = Form.create({ name: 'ViqeoForm' })(ViqeoForm);
