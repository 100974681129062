import * as API from './api';
import * as C from './constants';

// GetMediaList - return media list items
export function GetMediaList(page = 0, limit = 30, filter = {}, showUserPhoto = false, isBookmark = false, isExclude = false, excluded = []) {
    return {
        type: `PROMISE`,
        actions: [C.MEDIA_START_LOAD, C.MEDIA_FINISH_LOAD, C.MEDIA_ERROR_LOAD],
        promise: API.loadMedia(page, limit, filter, showUserPhoto, isBookmark, isExclude, excluded)
    };
}

export function GetMediaListDB(page = 0, limit = 30, filter = {}) {
    return {
        type: `PROMISE`,
        actions: [C.MEDIA_START_LOAD, C.MEDIA_FINISH_LOAD, C.MEDIA_ERROR_LOAD],
        promise: API.loadMediaDB(page, limit, filter)
    };
}

// update media by id
export function UpdateMedia(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.MEDIA_UPDATE_START, C.MEDIA_UPDATE_FINISH, C.MEDIA_UPDATE_ERROR],
        promise: API.updateMedia(id, data),
    };
}



// create new media
export function CreateMedia(data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.MEDIA_CREATE_START, C.MEDIA_CREATE_FINISH, C.MEDIA_CREATE_ERROR],
        promise: API.createMedia(data),
    };
}
