import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import { Typography, Layout, Table, Select, Button, message, Tooltip, Form } from 'antd';
import { AppConsumer } from './../../context/app';
import {t} from "../../core/library/i18n";
import * as Actions from "./../../store/chat/actions";
import * as C from './../../store/chat/constants';
import Moment from 'moment';
import 'moment/locale/ru';


const mapStateToProps = state => ({
    answer: state.ChatReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class ChatForm extends React.Component {

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values)
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout={`inline`} onSubmit={this.onSubmit}>
                <Form.Item label={`Статус`}>
                    {getFieldDecorator('deleted', {

                    })(
                        <Select style={{width: 120}}>
                            <Select.Option value={""}>Все</Select.Option>
                            <Select.Option value={true}>Удаленные</Select.Option>
                            <Select.Option value={false}>Не удаленные</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type={`primary`} htmlType={`submit`}>Искать</Button>
                </Form.Item>
            </Form>
        );
    }
}


const WChatForm = Form.create({ name: 'horizontal_login' })(ChatForm);

class RenderHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let p = this.props;
        if (p.actions && p.actions.updateHeader) {
            p.actions.updateHeader({
                title: t('CHAT_MESSAGES'),
            });
        }
    }

    render() {
        return  <Typography.Title level={2}>{t('CHAT_MESSAGES')}</Typography.Title>;
    }
};


class ChatIndex extends React.Component {

    columns = [
        {
            title: 'Дата',
            key: 'date',
            render: r => Moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Эпизод',
            key: 'episode',
            render: r => {
                if (r.episode_title === '') return `not set`;
                if (r.episode_title.length > 50) {
                    return (
                        <span>
                            <Tooltip title={r.episode_title}>{r.episode_title.substr(0, 47)}...</Tooltip>
                            [<a rel="noopener noreferrer"  target="_blank" href={`/documents/episode/${r.roomId}`}>админка</a>]
                            [<a rel="noopener noreferrer"  target="_blank" href={`https://absatz.media/tv/${r.episode_href}`}>сайт</a>]
                        </span>
                    )
                }
                return (
                    <span>
                        {r.episode_title}
                        [<a rel="noopener noreferrer"  target="_blank" href={`/documents/episode/${r.roomId}`}>админка</a>]
                        [<a rel="noopener noreferrer"  target="_blank" href={`https://absatz.media/tv/${r.episode_href}`}>сайт</a>]
                    </span>
                )
            }
        },
        {
            title: 'Профиль',
            key: 'profile',
            render: r => {
                if (!r.profile_id) return `Гость [${r.userIp || `error detect`}]`;
                return `${r.profile_id.login} [${r.userIp || `error detect`}]`
            },
        },
        {
            title: 'Сообщение',
            key: 'message',
            render: r => {
                if (r.message.length > 50) {
                    return <Tooltip title={r.message}>{r.message.substr(0, 47)}...</Tooltip>
                }
                return r.message;
            },
        },
        {
            title: 'Действия',
            key: 'actions',
            align: 'center',
            render: r => {
                if (r.c_deleted) {
                    return <Button onClick={() => this.updateMessage(r.id, false)} type={`primary`} icon={`redo`} />
                }
                return (
                    <Button onClick={() => this.updateMessage(r.id, true)} type={`danger`} icon={`delete`} />
                )
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: false,
            page: 1,
            total: 0,
            limit: 0,
            filter: {}
        };
    }


    updateMessage = (id, deleted) => {
        this.props.actions.UpdateChatMessage(id, {deleted});
    };

    loadMessages = () => {
        this.props.actions.LoadChat(this.state.page, this.state.filter);
    };


    componentDidMount() {
        this.loadMessages();
    }

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.CHAT_UPDATE_START:
                    this.setState({ loading: true });
                    break;
                case C.CHAT_UPDATE_FINISH:
                    message.success(`Комментарий успешно обновлен`);
                    this.loadMessages();
                    break;
                case C.CHAT_UPDATE_ERROR:
                    message.error(`Ошибка обновления комменария`);
                    this.setState({ loading: false });
                    break;
                case C.CHAT_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.CHAT_LOAD_ERROR:
                    message.error(`Ошибка загрузки чата`);
                    break;
                case C.CHAT_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    console.log(data.list);
                    this.setState({
                        loading: false,
                        total: data.total || 0,
                        limit: data.limit || 0,
                        list: data.list || [],
                    });
                    break;
                default: break;
            }
        }
    }


    render() {
        return (
            <Layout.Content style={{margin: 16,padding: 16, background: '#FFF'}}>
                <AppConsumer>
                    {context => {
                        return  <RenderHeader {...context}/>
                    }}
                </AppConsumer>

                <Table
                    bordered={true}
                    loading={this.state.loading}
                    title={() => (
                        <React.Fragment>
                            <WChatForm
                                onSubmit={(v) => {
                                    this.setState({ page: 1, filter: v}, () => {
                                        this.loadMessages();
                                    })
                                }}
                            />
                        </React.Fragment>)
                    }
                    className={`chat--table`}
                    columns={this.columns}
                    dataSource={this.state.list}
                    rowKey={(item) => item.id}
                    rowClassName={(r) => r.c_deleted ? ` deleted` : ''}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: (page) => this.setState({ page }, () => { this.loadMessages() }),
                        current: this.state.page,
                        hideOnSinglePage: true,
                        position: 'both',
                    }}
                />
            </Layout.Content>
        );
    }
}

export const ChatController = connect(
    mapStateToProps,
    mapDispatchProps
)(ChatIndex);

export default ChatController;

