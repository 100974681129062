import React from 'react';
import {Button, Icon, Tooltip, Tag} from 'antd';
import Config from './../../../../config';
import { t } from './../../../library/i18n';


const getPreviewPath = (files) => {
    if (Config.MEDIA.CDN.IMAGES.indexOf('cdnimages.') + 1 > 0 || Config.MEDIA.CDN.IMAGES.indexOf('resizer.') + 1 > 0) {
        let params = files.original.path.split('/');
        params[params.length - 1] = `320_${params[params.length - 1]}`;
        return `${Config.MEDIA.CDN.IMAGES}/${params.join('/')}`
    }
    return `${Config.MEDIA.CDN.IMAGES}/${files.original.path}`;
}

const getPreviewProps = (p) => {
    return {
        style: {
            backgroundSize: `cover`,
            backgroundImage: `url(${getPreviewPath(p.files)})`,
        }
    };
};

const RenderVideo = (p) => {
    let video_path = p.files && p.files.original ? p.files.original.path : null;
    let poster = p.files && p.files.cover ? p.files.cover.path : null;
    return <video style={{width: '100%'}} controls src={`${Config.MEDIA.CDN.VIDEO}/${video_path}`} poster={`${Config.MEDIA.CDN.VIDEO}/${poster}`} />;
}


export const MediaCard = (p) => {
    let options = [];
    if (p.title) {
        options.push(p.title);
    }
    if (p.description) {
        options.push(p.description);
    }
    if (p.keywords) {
        options.push(p.keywords);
    }
    if (p.properties) {
        if(p.properties.source) options.push(p.properties.source);
        if(p.properties.copyright) options.push(p.properties.copyright);
    }


    const getRatio = () => {
        if (!p.files || !p.files["original"] || !p.files["original"]["ratio"]) {
            return null;
        }
        if (p.files["original"]["ratio"] === "1.78" || p.files["original"]["ratio"] === "1.77") {
            return <div className='image--ratio'><Tag color='orange'>16/9</Tag></div>
        }
    }

    let info = options.join(' / ');
    const { type } = p;
    return (
        <div className={`media--card`}>
            {p.isUsing && <div className={`image--usage`}><Icon type="clock-circle" /></div>}
            {p.expired && <div className={`image--expired`}><Icon type="info" /></div>}
            {getRatio()}
            {type === "Video" ? <div className={`media--preview`}><RenderVideo {...p}/></div> : <div className={`media--preview`} {...getPreviewProps(p)} />}
            <div className={`media--info`}>
                <Tooltip title={info}>{info.length > 30 ? info.substr(0, 30) + '...' : info}</Tooltip>
            </div>
            <div className={`media--actions`}>
                <Button type={`primary`} onClick={() => p.selectMedia(p)}>{t('SELECT')}</Button>
            </div>
        </div>
    );
};

export default MediaCard;
