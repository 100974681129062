import React from 'react';
import PropTypes from 'prop-types';
import {Table, Switch, Button} from 'antd';
import GroupsFilter from './../filters/groups';

class GroupsTable extends React.Component {
    Columns = [
        {
            title: 'Title',
            dataIndex: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Default',
            key: `is_default`,
            render: row => {
                return <Switch defaultChecked={row.default} onChange={(value) => this.props.onUpdate(row.id, {default: value})}/>
            },
        },
        {
            title: 'Active',
            key: `is_active`,
            render: row => {
                return <Switch defaultChecked={row.active} onChange={(value) => this.props.onUpdate(row.id, {active: value})}/>
            },
        },
        {
            title: `Actions`,
            key: `actions`,
            render: row => {
                return (
                    <React.Fragment>
                        <div className="btn-actions">
                            <Button type={"primary"} onClick={() => this.props.onEdit(row.id)} icon={"edit"}>Edit</Button>
                            <Button type={"danger"} onClick={() => this.props.onDelete(row.id)} icon={"delete"}>Remove</Button>
                        </div>
                    </React.Fragment>
                )
            },
        }
    ];

    openFormCreate = () => {
        this.props.history.push(`${this.props.match.path}/add`);
    };

    componentDidMount() {
        if (this.props.onMounted) {
            this.props.onMounted();
        }
    }


    render() {
        return (
            <React.Fragment>
                <Table
                    title={() => {
                        return (
                            <GroupsFilter
                                filter={this.props.filter}
                                onUpdateFilter={this.props.onUpdateFilter}
                                createClickHandle={this.props.onCreate}
                            />
                        );
                    }}
                    loading={this.props.loading}
                    bordered
                    columns={this.Columns}
                    pagination={{
                        pageSize: this.props.limit,
                        defaultCurrent: this.props.page,
                        total: this.props.total,
                        onChange: this.props.onPageChange,
                        current: this.props.page,
                    }}
                    dataSource={this.props.items}
                    rowKey={(item) => item.id}
                />
            </React.Fragment>
        );
    }
}

GroupsTable.defaultProps = {
    items: [],
    page: 0,
    perPage: 0,
    isLoading: false,
    filter: {},
    onPageChange: () => {},
    onUpdateFilter: () => {},
    onUpdate: () => {},
    onDelete: () => {},
    onCreate: () => {},
    onEdit: () => {},
};

GroupsTable.propTypes = {
    filter: PropTypes.instanceOf(Object).isRequired,
    items: PropTypes.arrayOf(Object).isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};


export default (GroupsTable);
