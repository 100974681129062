import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Tooltip, Avatar, Dropdown } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import { t } from '../../core/library/i18n';

class MessageItem extends React.Component {

    static defaultProps = {
        socket: null,
    };

    deleteMessage = () => {
       this.props.socket.emit('delete_message', {id: this.props._id, room_id: this.props.roomId });
    };

    restoreMessage = () => {
        this.props.socket.emit('restore_message', {id: this.props._id, room_id: this.props.roomId });
    };

    addBan = (time) => {
        this.props.socket.emit('adm_add_ban', {
            user_id: this.props.profile_id ? this.props.profile_id._id : this.props.userIp,
            time,
            room_id: this.props.roomId,
            ip: this.props.userIp
        });
    };

    removeBan = () => {
        this.props.socket.emit('adm_remove_ban', {
            user_id: this.props.profile_id ? this.props.profile_id._id : this.props.userIp,
            room_id: this.props.roomId,
            ip: this.props.userIp
        });
    };

    render() {

        const menu = (
            <Menu>
                <Menu.Item><a href={'/'} onClick={(e) => {e.preventDefault(); this.addBan(900)}}>Бан на 15 минут</a></Menu.Item>
                <Menu.Item><a href={'/'} onClick={(e) => {e.preventDefault(); this.addBan(3600)}}>Бан на час</a></Menu.Item>
                <Menu.Item><a href={'/'} onClick={(e) => {e.preventDefault(); this.addBan(86400)}}>Бан на день</a></Menu.Item>
                <Menu.Item><a href={'/'} onClick={(e) => {e.preventDefault(); this.addBan(2678400)}}>Бан на месяц</a></Menu.Item>
                <Menu.Item><a href={'/'} onClick={(e) => {e.preventDefault(); this.removeBan()}}>Снять бан</a></Menu.Item>
            </Menu>
        );

        return (
            <div id={`message_${this.props._id}`} className={`message--item ${this.props.deleted ? ` deleted ` : ``}`}>
                <div className="message--column avatar">
                    <Avatar size={80} alt={this.props.profile_id ? this.props.profile_id.login : 'Гость'} src={this.props.profile_id ? this.props.profile_id.photo : null}/>
                </div>
                <div className="message--column info">
                    <div className="info">
                        <div className="user--name">
                            <div className="name">
                                {!this.props.profile_id || this.props.profile_id === null ? `Гость` : this.props.profile_id.login} ({this.props.userIp})
                                {this.props.userBanned && (
                                    <Tooltip title={`${t('USER_BANNED_TO')} ${this.props.userBanned} ${t('SECONDS')}`}>
                                        <Button type={`danger`} icon={`lock`}/>
                                    </Tooltip>
                                )}
                            </div>
                            <div className={`date`}>
                                <Tooltip title={moment(this.props.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                                    <span>{moment(this.props.createdAt).fromNow()}</span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="message">
                            {this.props.replay_to && (
                                <div className={`message-reply`} onClick={() => window.location.hash=`message_${this.props.replay_to._id}`}>
                                    <div className="user--name">{this.props.replay_to.profile_id === null ? `Гость` : this.props.replay_to.profile_id.email}</div>
                                    <div className="message">{this.props.replay_to.message}</div>
                                </div>
                            )}

                            {this.props.message}
                        </div>
                    </div>
                    <div className="actions">
                        {this.props.deleted === false && (<Tooltip title={t('DELETE_CHAT_MESSAGE')}><Button onClick={this.deleteMessage} type={`danger`} icon={`delete`} /></Tooltip>)}
                        {this.props.deleted === true && (<Tooltip title={t('RESTORE_CHAT_MESSAGE')} ><Button onClick={this.restoreMessage} type={`primary`} icon={`cloud`} /></Tooltip>)}
                        <Tooltip title={t('USER_BLOCKS')}>
                            <Dropdown overlay={menu}><Button type={`primary`} icon={`lock`} /></Dropdown>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }
}

export default class Messages extends React.Component {
    static defaultProps = {
        id: '',
        socket: null,
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        socket: PropTypes.objectOf(Object).isRequired,
    };

    state = {
        loading: true,
        messages: [],
        banned: {},
    };

    componentDidMount() {
        this.props.socket.emit('adm_join', {room: this.props.id});
        this.props.socket.on(`messages_${this.props.id}`, (data) => {
            let bannerUsers = {};
            if (data && data.list) {
                let banned = data.banned || [];
                if (Array.isArray(banned)) {
                    banned.map(i => {
                        bannerUsers[i.id] = i.time;
                        return i;
                    });
                }
                let messages = data.list || [];
                messages = messages.map(i => {
                    let user_id = !i.profile_id || i.profile_id === null ? i.userIp : i.profile_id._id;

                    if (bannerUsers[user_id]) {
                        i.userBanned = bannerUsers[user_id];
                    }
                    return i;
                });
                this.setState({ messages , banned: bannerUsers});
            }
        });
        this.props.socket.on(`delete_message_${this.props.id}`, (data) => {
            if (data && data.id) {
                let messages = this.state.messages.map((i) => {
                    let a = {...i};
                    if (a._id === data.id) {
                        a.deleted = true;
                    }
                    return a;
                });
                this.setState({ messages });
            }
        });

        this.props.socket.on(`restore_message_${this.props.id}`, (data) => {
            if (data && data.id) {
                let messages = this.state.messages.map((i) => {
                    let a = {...i};
                    if (a._id === data.id) {
                        a.deleted = false;
                    }
                    return a;
                });
                this.setState({ messages });
            }
        });

        this.props.socket.on('remove_ban', (data) => {
            if (data.id) {
                let banned = {...this.state.banned};
                if (banned[data.id]) {
                    delete banned[data.id];
                }
                let messages = this.getMessagesWithBanned(banned);
                this.setState({ messages , banned });
            }
        });

        this.props.socket.on('ban_user', (data) => {
            if (data.id) {
                let banned = {...this.state.banned};
                if (!banned[data.id]) {
                    banned[data.id] = data.time;
                }
                let messages = this.getMessagesWithBanned(banned);
                this.setState({ messages , banned });
            }
        });

        this.props.socket.on('message', (data) => {
            if (data._id) {
                this.setState({ messages: [data, ...this.state.messages ]});
            }
        });
    }

    getMessagesWithBanned(banned = {}) {
        let messages = this.state.messages || [];
        messages = messages.map(i => {
            let user_id = !i.profile_id || i.profile_id === null ? i.userIp : i.profile_id._id;
            if (banned[user_id]) {
                i.userBanned = banned[user_id];
            } else {
                if (i.userBanned) {
                    delete i.userBanned;
                }
            }
            return i;
        });
        return messages;
    }

    componentWillUnmount() {
        this.props.socket.off(`messages_${this.props.id}`);
        this.props.socket.off(`delete_message_${this.props.id}`);
        this.props.socket.off(`restore_message_${this.props.id}`);
        this.props.socket.off(`remove_ban`);
        this.props.socket.off(`ban_user`);
    }

    render() {
        return (
            <div className={`chat--messages_wrapper`}>
                {this.state.messages.map(i => {
                    return (
                        <MessageItem key={`message_${i._id}`} {...i} socket={this.props.socket}/>
                    );
                })}
            </div>
        );
    }
}
