import React from 'react';
import { Form, DatePicker } from 'antd';
import Moment from 'moment';

export const DatepickerForm = (p) => {
    let props = {
        rules: p.rules || [],
    };

    if (p.initialValue && p.initialValue !== undefined) {
        props.initialValue = p.initialValue ? Moment(p.initialValue) : false;
    }

    if (props.rules && props.rules.length > 0) {
        if (p.initialValue === undefined || !p.initialValue) {
            props.initialValue = Moment();
        }
    }

 

    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, props)(<DatePicker style={{minWidth: '320px'}} {...p.props} />)}
        </Form.Item>
    );
};

export default DatepickerForm;
