import React from 'react';
import { Form, Input, Switch } from 'antd';

export class GoogleField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: '',
            lng: '',
            render_map: false,
        };
    }

    componentDidMount() {

    }

    renderMap() {
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            center: {lat: -34.397, lng: 150.644},
            zoom: 8
        });

        var input = document.getElementById('pac-input');
        var searchBox = new window.google.maps.places.SearchBox(input);
        this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        this.map.addListener('bounds_changed', () => {
            searchBox.setBounds(this.map.getBounds());
        });

        var markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
            var places = searchBox.getPlaces();

            if (places.length === 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach((place) => {
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }
                var icon = {
                    url: place.icon,
                    size: new window.google.maps.Size(71, 71),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(17, 34),
                    scaledSize: new window.google.maps.Size(25, 25)
                };

                // Create a marker for each place.
                markers.push(new window.google.maps.Marker({
                    map: this.map,
                    icon: icon,
                    title: place.name,
                    position: place.geometry.location
                }));

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            this.map.fitBounds(bounds);
        });

        window.google.maps.event.addListener(this.map, "click", (event) => {
            var latitude = event.latLng.lat();
            var longitude = event.latLng.lng();
            this.setState({
                lat: latitude,
                lng: longitude,
            });
        });
    }

    render() {
        return (
           <React.Fragment>
               <Form.Item label={this.props.label || ""}>
                   {this.props.decorator(`props.coords.lat`, {
                       initialValue: this.state.lat,
                   })(<Input />)}
                   {this.props.decorator(`props.coords.lng`, {
                       initialValue: this.state.lng,
                   })(<Input />)}
               </Form.Item>
               отобразить карту: <Switch onChange={(render_map) => this.setState({ render_map }, () => {
                   this.renderMap();
           })}/>
               {this.state.render_map && (
                   <React.Fragment>
                       <input id="pac-input" className="controls" type="text" placeholder="Search Box" />
                       <div id="map" style={{width: '100%', height: '300px'}}></div>
                   </React.Fragment>
               )}

           </React.Fragment>
        );
    }
}

export default GoogleField;
