import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Spin, message} from 'antd';
import * as Actions from "./../../store/widgets/actions";
import * as C from "./../../store/widgets/constants";
import {t} from "../../core/library/i18n";
import {AppConsumer} from './../../context/app';
// import Config from "../../config";
import Filter from './Widgets/Filter';
import WidgetsReport from './Widgets/Table';
import Chart from './Widgets/Chart';
import Store from './../../core/library/store';

const mapStateToProps = state => ({
    answer: state.WidgetReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class WidgetHeader extends React.Component {

    static defaultProps = {
        context: {},
    };

    static propTypes = {
        context: PropTypes.instanceOf(Object).isRequired,
    };

    componentDidMount() {
        if (this.props.context && this.props.context.actions && this.props.context.actions.updateHeader) {
            this.props.context.actions.updateHeader({
                title: t('WIDGETS_REPORT'),
            })
        }
    }

    render() {
        return null;
    }
}



class WidgetController extends React.Component {


    constructor(props) {
        super(props);
        let selectedWidgets = ["5d0670fb3cf39dddcb0d2aa7"];

        // save widgets to memory
        if (Store.get(`widgets_report`)) {
            try {
                selectedWidgets = JSON.parse(Store.get('widgets_report'));
            } catch (e) {
                selectedWidgets = ["5d0670fb3cf39dddcb0d2aa7"];
            }
        }

        this.state = {
            loading: false,
            widgets: [],
            selectedWidgets: selectedWidgets,
            filter: {},
            showPrev: false,
            widgets_report: [],
            selectedMaterials: {},
        };
    }


    updatePrevStatus = () => {
        this.setState({ showPrev: !this.state.showPrev }, () => {
            this.loadReport();
        });
    };


    addMaterial = (doc = {}) => {
        if (doc.id) {
            let selectedMaterials = this.state.selectedMaterials;
            if (selectedMaterials[doc.id]) {
                delete selectedMaterials[doc.id];
            } else {
                selectedMaterials[doc.id] = doc;
            }
            this.setState({
                selectedMaterials
            });
        }
    };


    updateFilter = (data) => {
        this.setState({ filter: data }, () => {
            this.loadReport();
        });
    };

    loadWidgets = () => {
        this.props.actions.GetWidgets();
    };

    componentDidMount() {
        this.loadWidgets();
    }

    addWidget = (id) => {
        let selectedWidgets = this.state.selectedWidgets;
        if (this.state.selectedWidgets.indexOf(id) + 1 > 0) {
            selectedWidgets.splice(selectedWidgets.indexOf(id), 1);
        } else {
            selectedWidgets.push(id);
        }

        Store.set(`widgets_report`, JSON.stringify(selectedWidgets));


        this.setState({ selectedWidgets }, () => {
            this.loadReport();
        });
    };

    loadReport = () => {
        if (this.state.selectedWidgets.length === 0) {
            message.error(`Выберите виджеты для отчета`);
        } else {
            let filter = {};
            if (this.state.filter.date_from) {
                filter.date_from = this.state.filter.date_from;
            }

            if (this.state.filter.date_to) {
                filter.date_to = this.state.filter.date_to;
            }

            if (this.state.selectedWidgets.length > 0) {
                filter.widgets = this.state.selectedWidgets;
            }

            if (this.state.showPrev) {
                filter.showPrev = true;
            }

            this.props.actions.GetWidgetsReport(filter);
        }

    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.WIDGETS_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.WIDGETS_LOAD_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(`Ошибка загрузки виджетов`);
                        console.error(`error load widgets: `, p);
                    });
                    break;
                case C.WIDGETS_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    let list = data.list || [];
                    list = list.filter(i => {

                        return (!((i.data_type.length === 1 && i.data_type[0] === "Banner") || i.title.indexOf('Тестовый') + 1 > 0));
                    });

                    this.setState({ widgets: list || [], loading: false }, () => {
                        this.loadReport();
                    });
                    break;
                case C.WIDGETS_REPORT_LOAD_ERROR:
                    this.setState({ loading: false }, () => {
                        message.error(`Ошибка загрузки отчета`);
                        console.error(`error load widgets report: `, p);
                    });
                    break;
                case C.WIDGETS_REPORT_LOAD_START:
                    this.setState({ loading: true });
                    break;
                case C.WIDGETS_REPORT_LOAD_FINISH:
                    let report = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    console.log(report[0]);
                    this.setState({ widgets_report: report, loading: false });
                    break;
                default: break;
            }
        }
    }


    render() {
        return (
            <AppConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            <Spin spinning={this.state.loading} tip={t('LOAD_REPORT')}>
                                <WidgetHeader context={context}/>
                                <div className="report widgets--report">
                                    <div className="form">
                                        <Filter
                                            widgets={this.state.widgets}
                                            selected_widgets={this.state.selectedWidgets}
                                            addWidget={this.addWidget}
                                            onSubmit={this.updateFilter}
                                            onUpdatePrev={this.updatePrevStatus}
                                            prev={this.state.showPrev}
                                        />
                                    </div>
                                    {Object.keys(this.state.selectedMaterials).length > 0 && <div className={`chart`}><Chart materials={this.state.selectedMaterials} /></div>}
                                    <div className="widgets--list">
                                        {this.state.widgets_report && Array.isArray(this.state.widgets_report) && this.state.widgets_report.map(i => {
                                            return (
                                                <div className={`widget--list_item`} key={`widget_${i.id}`}>
                                                    <WidgetsReport widget={i} addMaterial={this.addMaterial}/>
                                                </div>
                                            );

                                        })}
                                    </div>
                                </div>
                            </Spin>
                        </React.Fragment>
                    );
                }}
            </AppConsumer>
        );
    }
}

export const WidgetReport = connect(
    mapStateToProps,
    mapDispatchProps
)(WidgetController);

export default WidgetReport;
