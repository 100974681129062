import React from 'react';
import { Table, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Store from './../../../core/library/store';


export default class WidgetsReport extends React.Component {
    static defaultProps = {
        widget: {},
        addMaterial: () => {},
    };

    static propTypes = {
        widget: PropTypes.instanceOf(Object).isRequired,
        addMaterial: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: '',
                key: 'selected',
                render: r => {
                    return <Checkbox value={r.id} onChange={(e) => {
                        this.props.addMaterial(r);
                    }}/>;
                },
            },
            {
                title: 'Материал',
                key: 'title',
                width: 220,
                render: r => {
                    return <Link to={`/documents/${r.type.toLowerCase()}/${r.id}`}>{r.title}</Link>;
                }
            },
            {
                title: 'нед.',
                key: 'week',
                sorter: (a, b) => a.report.week - b.report.week,
                render: r => r.report && r.report.week ? r.report.week : '',
            },
            {
                title: 'день',
                key: 'day',
                sorter: (a, b) => a.report.day - b.report.day,
                render: r => {
                   let w = r.report && r.report.day ? r.report.day : '';
                   if (w !== '' && r.day_diff) {
                       w = <span>{w} <span style={{color: 'green'}}>(+ {r.day_diff})</span></span>
                   }
                   return w;
                },
            },
        ];

        for(let i = 0; i <= 23; i ++) {
            this.columns.push({
                title: i,
                key: `hour_${i}`,
                align: 'center',
                sorter: (a, b) => a.report.by_hour[i] - b.report.by_hour[i],
                render: r => {
                    return r.report && r.report.by_hour && r.report.by_hour[i] ?  r.report.by_hour[i] : '';
                },
            })
        }

        this.state = {
            prevStat: {},
        }
    }


    getMaxByHour = (items, hour) => {
        if(!items || !Array.isArray(items)) return items;
        let sort_items = items.map(i => {
            return {
                ...i,
                ...{v: i.report.by_hour[hour]},
            }
        });
        sort_items.sort((a, b) => {
            return b.v - a.v;
        });
        return sort_items;
    };


    componentDidMount() {

        const wName = `widget_stats_${this.props.widget.id}`;

        let wMap = {};
        // const rItems = this.sortByHour(this.props.widget.data_prepare);

        if(Store.get(wName)) {
            try {
                let wStates = JSON.parse(Store.get(wName));
                wStates.forEach(i => {
                    wMap[i.id] = i.day;
                });
                // this.setState({prevStat: wMap});
            } catch(e) {
                console.error(`error decode stats`);
            }
        }


        this.setState({
            prevStat: wMap,
            items: this.sortByHour(this.props.widget.data_prepare, wMap),
        });
    }

    sortByHour = (items, wMap) => {
        if(!items) return [];
        let result_items = [];
        let exists_objects = {};
        for(let i = (new Date()).getHours(); i >= 0;i--) {
            items = this.getMaxByHour(items, i);
            if(items[0] && items[0].v !== 0) {
                exists_objects[items[0].id] = 1;
                result_items.push(items[0]);
                items.splice(0, 1);
            }
        }

        items.forEach(i => {
            if(!exists_objects[i.id]) {
                result_items.push(i);
                exists_objects[i.id] = 1;
            }
        });

        const wName = `widget_stats_${this.props.widget.id}`;


        if(this.state.prevStat) {
            result_items = result_items.map(i => {
                let a = {...i};
                if (wMap[a.id]) {
                    a.day_diff = a.report.day - wMap[a.id];
                    console.log(a.report.day, wMap[a.id], a.day_diff);
                }
                return a;
            });
        }


        let sData = result_items.map(i => {
            return {id: i.id, day: i.report.day};
        });
        Store.set(wName, JSON.stringify(sData));

        return result_items;
    };

    render() {


        // console.log(this.props.widget.data_prepare);
        return (
            <Table
                title={() => {
                    return <h4>{this.props.widget.title}</h4>
                }}
                columns={this.columns}
                pagination={{
                    pageSize: 1000,
                    defaultCurrent: 1,
                    total: 1000,
                    hideOnSinglePage: true,
                }}
                bordered
                size={`small`}
                dataSource={this.state.items}
                rowKey={(item) => item.id}
            />
        );
    }
}
