import * as API from './api';
import * as C from './constants';

export function GetWidgets(page = 0, search = '', wType, lang = '') {
    return {
        type: 'PROMISE',
        actions: [C.WIDGETS_LOAD_START, C.WIDGETS_LOAD_FINISH, C.WIDGETS_LOAD_ERROR],
        promise: API.getWidgets(page, search, wType, lang),
    };
}

export function AddWidget(widget = {}) {
    return {
        type: `PROMISE`,
        actions: [C.WIDGET_ADD_START, C.WIDGET_ADD_FINISH, C.WIDGET_ADD_ERROR],
        promise: API.addWidget(widget),
    };
}

export function UpdateWidget(id = '', widget = {}) {
    return {
        type: `PROMISE`,
        actions: [C.WIDGET_UPDATE_START, C.WIDGET_UPDATE_FINISH, C.WIDGET_UPDATE_ERROR],
        promise: API.updateWidget(id, widget),
    };
}

export function GetWidget(id = '') {
    return {
        type: `PROMISE`,
        actions: [C.WIDGET_LOAD_START, C.WIDGET_LOAD_FINISH, C.WIDGET_LOAD_ERROR],
        promise: API.getWidget(id),
    };
}

export function GetWidgetDocs(page = 1, search = '', excludeIds = [], dataTypes = [], cats = []) {
    return {
        type: `PROMISE`,
        actions: [C.WIDGET_DOCS_LOAD_START, C.WIDGET_DOCS_LOAD_FINISH, C.WIDGET_DOCS_LOAD_ERROR],
        promise: API.getWidgetDocsElastic(page, search, excludeIds, dataTypes, cats),
    };
}

export function GetWidgetsReport(filter) {
    return {
        type: `PROMISE`,
        actions: [C.WIDGETS_REPORT_LOAD_START, C.WIDGETS_REPORT_LOAD_FINISH, C.WIDGETS_REPORT_LOAD_ERROR],
        promise: API.loadReport(filter),
    };
}


export function UpdateSort(data = []) {
    return {
        type: `PROMISE`,
        actions: [C.WIDGETS_SORT_UPDATE_START, C.WIDGETS_SORT_UPDATE_FINISH, C.WIDGETS_SORT_UPDATE_ERROR],
        promise: API.updateIndexSort(data),
    };
}

export function UpdateDocument(id = '', data = {}) {
    return {
        type: `PROMISE`,
        actions: [C.DOCUMENT_UPDATE_START, C.DOCUMENT_UPDATE_FINISH, C.DOCUMENT_UPDATE_ERROR],
        promise: API.updateDocument(id, data),
    };
}
