import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Empty, Layout, Spin, notification, message} from 'antd';

import {AppConsumer} from './../../context/app';
import * as Actions from "./../../store/documents/actions";
import * as C from "./../../store/documents/constants";
import GetProp from './../../core/library/objects/getprop';
import {t} from './../../core/library/i18n';
import * as Views from './preview/index';
import Config from './../../config';
import LayoutController from './../../core/components/controller_layout';
import ucfirst from './../../core/library/string/ucfirst';


const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


class PreviewController extends React.Component {

    static defaultProps = {
        match: {},
    };

    static propTypes = {
        match: PropTypes.instanceOf(Object).isRequired,
        actions: PropTypes.instanceOf(Object).isRequired,
        answer: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        const docType = GetProp(this.props, 'match.params.type');
        const Id = GetProp(this.props, 'match.params.id');
        this.state = {
            id: Id || false,
            type: docType || "unknown",
            loading: false,
            item: {},
            isEdit: false,
        };
    }


    onCreate = (values) => {
        values["type"] = ucfirst(this.state.type);
        this.props.actions.CreateDocument(values);
    };

    onUpdate = (values) => {
        this.props.actions.UpdateDocument(this.state.id, values);
    };

    getLayout = () => {
        if (Config.VIEWS && Config.VIEWS.documents_preview && Config.VIEWS.documents_preview[this.state.type] && Views[Config.VIEWS.documents_preview[this.state.type]]) {
            return Views[Config.VIEWS.documents_preview[this.state.type]];
        }
        return Views["default"];
    };

    loadDocument = () => {
        this.props.actions.GetDocument(this.state.id);
    };

    onPreview = () => {
        this.props.history.push(`/documents/${this.state.type}/${this.state.id}/preview`);
    };

    componentDidMount() {
        if (this.state.id !== false && this.state.id !== "create") {
            this.loadDocument();
        }
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch(p.answer.state) {
                case C.DOCUMENT_UPDATE_START:
                    this.setState({ loading: true });
                    break;
                case C.DOCUMENT_UPDATE_ERROR:
                    notification.error({
                        message: t('ERROR_UPDATE_DOCUMENT'),
                    });
                    this.setState({ loading: false });
                    break;
                case C.DOCUMENT_UPDATE_FINISH:
                    message.success(t('DOCUMENT_UPDATED'));
                    setTimeout(() => {
                        this.loadDocument();
                    }, 500);
                    break;

                case C.DOCUMENT_LOAD_START:
                    this.setState({ loading: true });
                    break;

                case C.DOCUMENT_LOAD_FINISH:
                    const data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    this.setState({
                        loading: false,
                        item: data,
                        isEdit: true,
                    });
                    break;
                case C.DOCUMENT_LOAD_ERROR:
                    notification.error({
                        message: t('DOCUMENT_LOAD_ERROR'),
                        description: t('REPEAT_AGAIN_LATER'),
                    });
                    break;

                case C.DOCUMENT_CREATE_START:
                    this.setState({ loading: true });
                    break;
                case C.DOCUMENT_CREATE_ERROR:
                    this.setState({ loading: false }, () => {
                        console.error(`Error create document: `, p.answer);
                        notification.error({
                            message: t('DOCUMENT_CREATE_ERROR'),
                            description: t('REPEAT_AGAIN_LATER'),
                        });
                    });
                    break;
                case C.DOCUMENT_CREATE_FINISH:
                    notification.success({
                        message: t('DOCUMENT_CREATE_FINISH'),
                        delay: 3,
                    });
                    setTimeout(() => {
                        window.location.href = `/documents/${this.state.type}/${p.answer.data.id}`;
                    }, 3000);
                    break;
                default: break;
            }
        }
    };





    render() {
        if (this.state.type === "unknown") return <div className={`container container__empty`}><Empty/></div>;
        return (
            <Layout.Content>
                <Spin spinning={this.state.loading} wrapperClassName={`${this.state.loading ? "app_loading_nflex" : ""}`}
                      tip={t('LOADING_DOCUMENT')}>
                    <AppConsumer>
                        {context => {
                            const View = this.getLayout();
                            return (
                                <LayoutController
                                    isPreview={true}
                                    isEdit={this.state.isEdit}
                                    item={this.state.item}
                                    loading={this.state.loading}
                                    onUpdate={this.onUpdate}
                                    onCreate={this.onCreate}
                                    onPreview={this.onPreview}
                                    layout={View}
                                    options={context}
                                    layout_type={`PREVIEW`}
                                    showBack={true}
                                    {...this.state}
                                />
                            );
                        }}
                    </AppConsumer>
                </Spin>
            </Layout.Content>
        );
    }
}

export const PreviewDocument = connect(
    mapStateToProps,
    mapDispatchProps
)(PreviewController);

export default PreviewDocument;

