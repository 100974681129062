import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from './../../../core/library/i18n';
import PrepareInitialValue from './../../../core/library/form/prepare';


export class AddCovid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': 'TITLE',
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('moscow_recovered_total'),
                'name': "options.moscow_recovered_total",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('moscow_recovered_day'),
                'name': "options.moscow_recovered_day",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('moscow_infected_total'),
                'name': "options.moscow_infected_total",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('moscow_infected_day'),
                'name': "options.moscow_infected_day",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('moscow_dead_total'),
                'name': "options.moscow_dead_total",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'input',
                'label': t('moscow_dead_day'),
                'name': "options.moscow_dead_day",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_PLACEHOLDER'),
                }
            },
            {
                'component': 'input',
                'label': t('COVID_HREF'),
                'name': "options.href",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_HREF'),
                }
            },

            {
                'component': 'input',
                'label': t('COVID_TITLE'),
                'name': "options.covid_title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('COVID_TITLE'),
                }
            },
        ];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields);
        }
    }

    onSubmit = (values) => {
        if (this.props.item && this.props.item.id) {
            values.slug = 'covid';
            this.props.onUpdate(values);
        } else {
            values.slug = 'covid';
            this.props.onCreate(values);
        }
    };

    render() {

        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                />
            </div>
        );
    }
}

export default AddCovid;
