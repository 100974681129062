import React from 'react';
import {Form, Input, Button, Select, Checkbox} from 'antd';
import PropTypes from 'prop-types';
import Config from './../../config';
// import { MapSelector } from '../../components/map/selector';

class PushCreateFrom extends React.Component {
    static defaultProps = {
        onCreate: (data) => {},
        item: {},
    };

    static propTypes = {
        onCreate: PropTypes.func.isRequired,
        item: PropTypes.instanceOf(Object),
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.mapSelector = React.createRef();
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let material_coords = [];

                // prepare material coords
                if (this.props.item && this.props.item.geo) {
                    material_coords = this.props.item.geo.map(i => {
                        return i.point.coordinates;
                    })
                }


                if (values.is_web === false && values.is_mobile === false) {
                    values.is_web = true;
                }

                // load custom points
                let customPoints = this.mapSelector.current.getCoords();
                values.geo = customPoints.length > 0 ? customPoints : material_coords;
                this.props.onCreate(values);
            }
        });
    };

    prepareLink = (item) => {
        const d = Config.BASE_DOMAIN || "";
        switch(item.type) {
            case "News": return `${d}/news/${item.publicId}-${item.slug}`;
            case "Meaning": return `${d}/opinion/${item.publicId}-${item.slug}`;
            case "Episode": return `${d}/tv/${item.publicId}-${item.slug}`;
            default:
                return `${d}/${item.category.slug}/${item.publicId}-${item.slug}`;
        }
    };

    prepareImageUrl = (item) => {
        if (item.media && item.media.length > 0) {
            let cover = item.media.filter(i => i.type === 'Cover');
            if (cover.length === 0) {
                cover = item.media.filter(i => i.type === 'Image');
            }

            if (cover.length > 0) {
                let files = cover[0].files['image-l'] ? cover[0].files['image-l'] : cover[0].files['original'];
                return `${Config.MEDIA.CDN.IMAGES}/${files.path}`;
            }
            //
        }
        return "";
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item label={`Заголовок`}>
                    {getFieldDecorator('title', {
                        initialValue: this.props.item && this.props.item.title ? this.props.item.title : "",
                        rules: [
                            {required: true, message: 'Введите заголовок'},
                            {max: 160, message: 'Максимальная длина 160 знаков'}
                        ],
                    })(<Input placeholder={`Введите заголовок не больше 160 знаков`}/>)}
                </Form.Item>
                <Form.Item label={`Ссылка`}>
                    {getFieldDecorator('link', {
                        initialValue: this.props.item && this.props.item.publicId ? this.prepareLink(this.props.item) : "",
                        rules: [
                            {required: true, message: 'Введите ссылку'},
                        ],
                    })(<Input placeholder={`Введите ссылку`}/>)}
                </Form.Item>
                <Form.Item label={`Сообщение`}>
                    {getFieldDecorator('message', {
                        initialValue: this.props.item && this.props.item.summary ? this.props.item.summary : "",
                    })(<Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder={`Введите сообщение`}/>)}
                </Form.Item>

                <Form.Item label={`Ссылка на изображение`} help={'Протокол картинки должен быть строго https'}>
                    {getFieldDecorator('image', {
                        initialValue: this.props.item && this.props.item.title ? this.prepareImageUrl(this.props.item) : "",
                    })(<Input placeholder={`Вставе ссылку на изображение`}/>)}
                </Form.Item>
                <Form.Item label={`Топики`} help={`Сообщение будет оправлено только подписавшимся на этот топик, если выбраны "все" - то сообщение будет отправлено всем`}>
                    {getFieldDecorator('topics', {})(
                        <Select mode="multiple">
                            <Select.Option value={``}>Все</Select.Option>
                            {Config.REGIONS.map(i => {
                                return (
                                    <Select.Option disabled key={`topic_${i}`} value={i}>
                                        {i}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={`Тип отправки`}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {getFieldDecorator('is_web', {
                            valuePropName: 'checked',
                            initialValue: true,
                        })(
                            <Checkbox>Браузеры</Checkbox>
                        )}
                        {getFieldDecorator('is_mobile', {
                            valuePropName: 'checked',
                            initialValue: true,
                        })(
                            <Checkbox>Мобильное приложение</Checkbox>
                        )}
                    </div>
                </Form.Item>
               
                <Form.Item><Button htmlType={`submit`} type={`primary`}>Создать</Button></Form.Item>
            </Form>
        );
    }
}

const WrappedPushCreateFrom = Form.create({ name: 'PushCreateFrom' })(PushCreateFrom);
export default WrappedPushCreateFrom;
