import makeRequest from './../../core/fetch';
import {generateParamsFromObject} from "../../core/library/url";

export function loadProfiles(page = 1, filter = {}) {
    let prepareFilter = generateParamsFromObject(filter);
    return makeRequest(`/profiles?page=${page}&${prepareFilter}`, `GET`);
}
export function loadGroups(page = 1, filter = {}) {
    let prepareFilter = generateParamsFromObject(filter);
    return makeRequest(`/profiles/groups?page=${page}&${prepareFilter}`, `GET`);
}
export function loadPermissions(page = 1, filter = {}) {
    let prepareFilter = generateParamsFromObject(filter);
    return makeRequest(`/profiles/permissions?page=${page}&${prepareFilter}`, `GET`);
}

export function loadProfile(id = ''){
    return makeRequest(`/profiles/${id}`, `GET`);
}

export function loadGroup(id = '') {
    return makeRequest(`/profiles/groups/${id}`, `GET`);
}

export function createGroup(data = {}){
    return makeRequest(`/profiles/groups`, `POST`, data);
}
export function createPermission(data = {}) {
    return makeRequest(`/profiles/permissions`, `POST`, data);
}

export function updateProfile(id = '', data = {}) {
    return makeRequest(`/profiles/${id}`, `PUT`, data);
}

export function updateGroup(id = '', data = {}) {
    return makeRequest(`/profiles/groups/${id}`, `PUT`, data);
}

export function updatePermission(id = '', data = {}) {
    return makeRequest(`/profiles/permissions/${id}`, `PUT`, data);
}

export function deleteGroup(id = '') {
    return makeRequest(`/profiles/groups/${id}`, `DELETE`);
}

export function deletePermission(id = '') {
    return makeRequest(`/profiles/permissions/${id}`, `DELETE`);
}

