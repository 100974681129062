import React from 'react';
import { Form, Switch } from 'antd';

export const SwitchFields = (p) => {
    return (
        <Form.Item label={p.label || ""}>
            {p.decorator(p.name, {
                valuePropName: 'checked',
                initialValue: p.initialValue || false,
            })(<Switch {...p.props}/>)}
        </Form.Item>
    );
};

export default SwitchFields;
